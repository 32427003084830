import { useState, useEffect } from "react";
import FontFaceObserver from "fontfaceobserver";

export function useFontObserver(font: string) {
    const [isFontLoaded, setFontLoaded] = useState(false);

    useEffect(() => {
        var observer = new FontFaceObserver(font);

        observer.load().then(function () {
            setFontLoaded(true);
        });
    }, [font]);

    return isFontLoaded;
}
