/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    ThemeInputModel,
    ThemeInputModelFromJSON,
    ThemeInputModelFromJSONTyped,
    ThemeInputModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganisationInputModel
 */
export interface OrganisationInputModel {
    /**
     * 
     * @type {string}
     * @memberof OrganisationInputModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationInputModel
     */
    logoUri: string;
    /**
     * 
     * @type {ThemeInputModel}
     * @memberof OrganisationInputModel
     */
    defaultTheme: ThemeInputModel;
}

export function OrganisationInputModelFromJSON(json: any): OrganisationInputModel {
    return OrganisationInputModelFromJSONTyped(json, false);
}

export function OrganisationInputModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationInputModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'logoUri': json['logoUri'],
        'defaultTheme': ThemeInputModelFromJSON(json['defaultTheme']),
    };
}

export function OrganisationInputModelToJSON(value?: OrganisationInputModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'logoUri': value.logoUri,
        'defaultTheme': ThemeInputModelToJSON(value.defaultTheme),
    };
}


