import produce from "immer";
import { DesignContextViewModel, SubContextViewModel } from "../openapi/webservice";

export type ContextsState = DesignContextViewModel[];

export enum ActionType {
    set,
    updateDesignContext,
    updateSubContext,
}

interface SetContextAction {
    type: ActionType.set;
    value: DesignContextViewModel[];
}

interface UpdateDesignContextAction {
    type: ActionType.updateDesignContext;
    value: DesignContextViewModel;
}

interface UpdateSubContextAction {
    type: ActionType.updateSubContext;
    value: SubContextViewModel;
}

export type ContextsStateAction = SetContextAction | UpdateDesignContextAction | UpdateSubContextAction;

export function getInitialState(): ContextsState {
    return [];
}

export const ContextsStateReducer = (state: ContextsState, action: ContextsStateAction) => {
    switch (action.type) {
        case ActionType.set:
            return JSON.parse(JSON.stringify(action.value));
        case ActionType.updateDesignContext:
            return produce(state, (draft: ContextsState) => {
                const index = draft.findIndex((c) => c.id === action.value.id);

                if (index !== -1) {
                    draft[index] = action.value;
                }
            });
        case ActionType.updateSubContext:
            return produce(state, (draft: ContextsState) => {
                const contextId = draft.findIndex((c) => c.id === action.value.designContextId);

                if (contextId === -1) {
                    return;
                }

                const subContextId = draft[contextId].subContexts.findIndex((c) => c.id === action.value.id);

                if (subContextId === -1) {
                    return;
                }

                draft[contextId].subContexts[subContextId] = action.value;
            });
        default:
            return state;
    }
};
