import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./TextEditorInput.module.scss";
import classNames from "classnames";
import ModalCard from "../ModalCard/ModalCard";
import Title from "../Title/Title";
import Separator from "../Separator/Separator";
import ImageButton from "../ImageButton/ImageButton";
import { EditIcon, LinkIcon, PaperclipIcon, TickSquareIcon } from "../../assets/images/Images";
import WipWrapper from "../WipWrapper/WipWrapper";

type Props = {
    className: string;
    content: string;
    title: string;
    placeholder: string;
    onChange?: (content: string) => void;
    disabled: boolean;
};

const TextEditorInput = ({ className, content, title, placeholder, onChange, disabled }: Props) => {
    const [text, setText] = useState(content);
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const onTextChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((event) => {
        const value = event.currentTarget.value;
        setText(value);
    }, []);

    const onStartEdit = useCallback(() => setIsEditing(true), []);
    const onEndEdit = useCallback(() => {
        setIsEditing(false);
        const next = text.trim();
        if (!onChange || next === content) {
            return;
        }

        onChange(next);
    }, [text, content, onChange]);

    useEffect(() => {
        //basically focus textarea when isEditing gets changed to true
        if (!isEditing || !inputRef.current) {
            return;
        }

        const end = inputRef.current.value.length;
        inputRef.current.focus();
        inputRef.current.setSelectionRange(end, end);
    }, [isEditing, inputRef]);

    return (
        <ModalCard className={classNames(styles.container, className)}>
            <div className={styles.header}>
                <Title heading="h2" className={styles.title} label={title} />
                {!disabled && (
                    <>
                        <Separator className={styles.headerItem} orientation="|" />
                        <WipWrapper className={styles.headerItem} label="Linken">
                            <ImageButton src={LinkIcon} />
                        </WipWrapper>
                        <Separator className={styles.headerItem} orientation="|" />
                        <WipWrapper className={styles.headerItem} label="Bijlage">
                            <ImageButton src={PaperclipIcon} />
                        </WipWrapper>
                        <Separator className={styles.headerItem} orientation="|" />
                        {isEditing ? (
                            <ImageButton
                                className={styles.headerItem}
                                src={TickSquareIcon}
                                onClick={onEndEdit}
                                data-testid="EndEditButton"
                            />
                        ) : (
                            <ImageButton
                                className={styles.headerItem}
                                src={EditIcon}
                                onClick={onStartEdit}
                                data-testid="StartEditButton"
                            />
                        )}
                    </>
                )}
            </div>
            <textarea
                ref={inputRef}
                className={styles.input}
                value={text}
                data-testid="TextEditorInput"
                onChange={onTextChange}
                placeholder={placeholder}
                disabled={!isEditing || disabled}
            />
        </ModalCard>
    );
};

TextEditorInput.defaultProps = {
    className: "",
    disabled: false,
};

export default TextEditorInput;
