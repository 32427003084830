import React from "react";
import styles from "./ToggleSwitch.module.scss";
import classNames from "classnames";
import { HtmlInputProps } from "../ReusableTypes";
import Text from "../Text/Text";

type Props = HtmlInputProps & {
    className: string;
    labelChecked: string;
    labelUnchecked: string;
};

const ToggleSwitch = ({ className, labelChecked, labelUnchecked, ...props }: Props) => {
    return (
        <label className={classNames(styles.container, className)}>
            <input
                className={styles.checkbox}
                type="checkbox"
                data-testid={`ToggleSwitch_${labelUnchecked}_${labelChecked}`}
                {...props}
            />
            <span className={styles.slider}>
                <Text className={styles.labelUnchecked} label={labelUnchecked} />
                <Text className={styles.labelChecked} label={labelChecked} />
            </span>
        </label>
    );
};

ToggleSwitch.defaultProps = {
    className: "",
};

export default ToggleSwitch;
