import React, { useEffect, useMemo } from "react";
import { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import { initialUser, UserStateAction, UserStateReducer, ActionType } from "../reducers/UserStateReducer";
import { DateTime } from "luxon";
import { parseJwtPayload } from "../utils/JwtUtils";
import { UserState } from "../models/UserState";

export const UserContext = createContext<[UserState, Dispatch<UserStateAction>]>([initialUser, () => {}]);

const LOGGED_IN_USER_KEY = "KEY_USER_AUTH";
const CHECK_INTERVAL = 900000; // 15 minutes

export const UserContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const initial = useMemo(() => {
        const token = sessionStorage.getItem(LOGGED_IN_USER_KEY);
        if (!token) {
            return initialUser;
        }

        return { jwt: token, payload: parseJwtPayload(token) };
    }, []);

    const reducer = useReducer<typeof UserStateReducer>(UserStateReducer, initial);
    const [state, dispatch] = reducer;

    useEffect(() => {
        const jwt = state.jwt;
        sessionStorage.setItem(LOGGED_IN_USER_KEY, jwt || "");

        const check = () => {
            if (!jwt) {
                return;
            }

            const exp = state.payload.exp;
            const expiry = DateTime.fromJSDate(new Date(exp * 1000));
            const diff = expiry.diffNow("minutes").toObject();

            if (diff.minutes! <= 15) {
                dispatch({ type: ActionType.clear });
                window.alert("Inlogsessie verlopen, log nogmaals in.");
            }
        };

        check();
        const interval = setInterval(check, CHECK_INTERVAL);
        return () => clearInterval(interval);
    }, [state, dispatch]);

    return <UserContext.Provider value={reducer}>{children}</UserContext.Provider>;
};
