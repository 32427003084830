import { MemoBlockMemoViewModel, MemoViewModel } from "../openapi/webservice";

export type MemoLikeModel = MemoViewModel | MemoBlockMemoViewModel;

function isMemoBlockMemoViewModel(memo: MemoLikeModel): memo is MemoBlockMemoViewModel {
    return (memo as MemoBlockMemoViewModel).cellName !== undefined;
}

export function getCellName(memo: MemoLikeModel) {
    if (isMemoBlockMemoViewModel(memo)) {
        return memo.cellName;
    }

    return "";
}
