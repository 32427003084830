import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DotnetError } from "../models/DotnetError";
import { ClearNotification, NotifyJsonError, NotifyStringError } from "../utils/NotificationUtils";

export function useErrorResponseNotification(e: DotnetError | undefined) {
    const { t } = useTranslation();

    useEffect(() => {
        let id: string | undefined = undefined;
        if (!!e) {
            switch (e.type) {
                case "StringError":
                    id = e.message;
                    NotifyStringError(t("error.standard_message"), e, id);
                    break;
                case "JsonError":
                    id = e.traceId;
                    NotifyJsonError(t("error.standard_message"), e, id);
                    break;
            }
        }

        return () => {
            ClearNotification(id);
        };
    }, [e, t]);
}
