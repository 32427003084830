import Konva from "konva";
import React, { useCallback, useRef } from "react";
import {
    useCreateCell,
    useCreateConnection,
    useCreateContainer,
    useFetchBlueprint,
    useMoveContainer,
    useRemoveCell,
    useRemoveConnection,
    useRemoveContainer,
    useRenameCell,
    useRenameContainer,
} from "../../hooks/useBlueprintStateChanges";
import { useCanvasMode } from "../../hooks/useCanvasMode";
import { useContainerModalState } from "../../hooks/useContainerModalState";
import { DragType } from "../../models/DragType";
import { BLUEPRINT_DRAG_TYPE_KEY } from "../../utils/DragAndDropUtils";
import BlueprintCanvas from "./BlueprintCanvas";

type Props = {
    className: string;
    isCreateConnectionView: boolean;
};

const BlueprintCanvasContainer = (props: Props) => {
    const stageRef = useRef<Konva.Stage>(null);
    const [mode] = useCanvasMode();
    useFetchBlueprint();
    const onCreateContainer = useCreateContainer();
    const onCreateCell = useCreateCell();
    const onMoveContainer = useMoveContainer();
    const onCreateConnection = useCreateConnection();
    const onDeleteConnection = useRemoveConnection();
    const onRenameContainer = useRenameContainer();
    const onRenameCell = useRenameCell();
    const onDeleteContainer = useRemoveContainer();
    const onDeleteCell = useRemoveCell();
    const [, setModalState] = useContainerModalState();

    const onDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            const data = event.dataTransfer.getData(BLUEPRINT_DRAG_TYPE_KEY) as DragType;
            if (!stageRef.current) {
                return;
            }

            switch (data) {
                case DragType.Container:
                    return onCreateContainer(event, stageRef.current);
                case DragType.Cell:
                    return onCreateCell(event, stageRef.current);
            }
        },
        [onCreateContainer, onCreateCell],
    );

    return (
        <BlueprintCanvas
            {...props}
            mode={mode}
            stageRef={stageRef}
            onDrop={onDrop}
            onMoveContainer={onMoveContainer}
            onCreateConnection={onCreateConnection}
            onDeleteConnection={onDeleteConnection}
            onRenameContainer={onRenameContainer}
            onRenameCell={onRenameCell}
            onDeleteContainer={onDeleteContainer}
            onDeleteCell={onDeleteCell}
            onOpenChat={setModalState}
        />
    );
};

BlueprintCanvasContainer.defaultProps = {
    className: "",
};

export default BlueprintCanvasContainer;
