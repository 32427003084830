/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    ConnectionViewModel,
    ConnectionViewModelFromJSON,
    ConnectionViewModelFromJSONTyped,
    ConnectionViewModelToJSON,
    ContainerViewModel,
    ContainerViewModelFromJSON,
    ContainerViewModelFromJSONTyped,
    ContainerViewModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface BlueprintViewModel
 */
export interface BlueprintViewModel {
    /**
     * 
     * @type {string}
     * @memberof BlueprintViewModel
     */
    name: string;
    /**
     * 
     * @type {Array<ContainerViewModel>}
     * @memberof BlueprintViewModel
     */
    containers: Array<ContainerViewModel>;
    /**
     * 
     * @type {Array<ConnectionViewModel>}
     * @memberof BlueprintViewModel
     */
    connections: Array<ConnectionViewModel>;
}

export function BlueprintViewModelFromJSON(json: any): BlueprintViewModel {
    return BlueprintViewModelFromJSONTyped(json, false);
}

export function BlueprintViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlueprintViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'containers': ((json['containers'] as Array<any>).map(ContainerViewModelFromJSON)),
        'connections': ((json['connections'] as Array<any>).map(ConnectionViewModelFromJSON)),
    };
}

export function BlueprintViewModelToJSON(value?: BlueprintViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'containers': ((value.containers as Array<any>).map(ContainerViewModelToJSON)),
        'connections': ((value.connections as Array<any>).map(ConnectionViewModelToJSON)),
    };
}


