import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import { HtmlButtonProps } from "../ReusableTypes";

type ButtonType = "default" | "alternative" | "hollow";

type Props = HtmlButtonProps & {
    label: string;
    displayType: ButtonType;
};

const Button = ({ className, label, displayType, ...props }: Props) => {
    return (
        <button
            className={classNames(styles.container, styles[displayType], className)}
            data-testid="Button"
            {...props}
        >
            {label}
        </button>
    );
};

Button.defaultProps = {
    className: "",
    displayType: "default",
};

export default Button;
