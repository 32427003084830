/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrganisationInputModel,
    OrganisationInputModelFromJSON,
    OrganisationInputModelToJSON,
    OrganisationViewModel,
    OrganisationViewModelFromJSON,
    OrganisationViewModelToJSON,
} from '../models';

export interface CreateOrganisationRequest {
    organisationInputModel?: OrganisationInputModel;
}

export interface DeleteOrganisationRequest {
    organisationId: string;
}

export interface UpdateOrganisationRequest {
    organisationId: string;
    organisationInputModel?: OrganisationInputModel;
}

/**
 * no description
 */
export class OrganisationsApi extends runtime.BaseAPI {
    /**
     * Create a new organisation.
     */
    async createOrganisationRaw(requestParameters: CreateOrganisationRequest): Promise<runtime.ApiResponse<OrganisationViewModel>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Organisations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganisationInputModelToJSON(requestParameters.organisationInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationViewModelFromJSON(jsonValue));
    }
    /**
     * Create a new organisation.
     */
    async createOrganisation(requestParameters: CreateOrganisationRequest): Promise<OrganisationViewModel> {
        const response = await this.createOrganisationRaw(requestParameters);
        return await response.value();
    }
    /**
     * Delete an organisation.
     */
    async deleteOrganisationRaw(requestParameters: DeleteOrganisationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling deleteOrganisation.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Organisations/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete an organisation.
     */
    async deleteOrganisation(requestParameters: DeleteOrganisationRequest): Promise<void> {
        await this.deleteOrganisationRaw(requestParameters);
    }
    /**
     * Retrieve all organisation settings as per the current date.
     */
    async getOrganisationsRaw(): Promise<runtime.ApiResponse<Array<OrganisationViewModel>>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Organisations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganisationViewModelFromJSON));
    }
    /**
     * Retrieve all organisation settings as per the current date.
     */
    async getOrganisations(): Promise<Array<OrganisationViewModel>> {
        const response = await this.getOrganisationsRaw();
        return await response.value();
    }
    /**
     * Edit the current organisation.
     */
    async updateOrganisationRaw(requestParameters: UpdateOrganisationRequest): Promise<runtime.ApiResponse<OrganisationViewModel>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling updateOrganisation.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Organisations/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganisationInputModelToJSON(requestParameters.organisationInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationViewModelFromJSON(jsonValue));
    }
    /**
     * Edit the current organisation.
     */
    async updateOrganisation(requestParameters: UpdateOrganisationRequest): Promise<OrganisationViewModel> {
        const response = await this.updateOrganisationRaw(requestParameters);
        return await response.value();
    }
}
