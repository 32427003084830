import React from "react";
import styles from "./TempContextManagement.module.scss";
import classNames from "classnames";
import { DesignContextOverview } from "../../openapi/webservice";
import TempDesignContextManagementInput from "../RoughContextManagementInput/TempDesignContextManagementInput";
import TempSubContextManagementInput from "../RoughContextManagementInput/TempSubContextManagementInput";
import BrandLogo from "../BrandLogo/BrandLogo";
import Title from "../Title/Title";
import Text from "../Text/Text";

type Props = {
    className: string;
    data: DesignContextOverview;
};

const TempContextManagement = ({ className, data }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            {data.organisations.map((o) => {
                return (
                    <div key={o.id} className={styles.group}>
                        <div className={styles.organisation}>
                            <Title heading="h2" className={styles.organisationName} label={o.name} />
                            <BrandLogo className={styles.logo} src={o.logoUri} size="default" />
                        </div>
                        {o.designContexts.map((context) => {
                            return (
                                <div key={context.id} className={styles.contextSection}>
                                    <TempDesignContextManagementInput
                                        key={context.id}
                                        context={context}
                                        organisationId={o.id}
                                    />
                                    <div className={styles.subContextSection}>
                                        {context.subContexts.map((subContext) => (
                                            <TempSubContextManagementInput
                                                key={subContext.id}
                                                className={styles.subContext}
                                                subContext={subContext}
                                                context={context}
                                                organisationId={o.id}
                                            />
                                        ))}
                                    </div>
                                </div>
                            );
                        })}
                        {o.designContexts.length === 0 && (
                            <Text className={styles.empty} type="body" label="Geen contexten gevonden." />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

TempContextManagement.defaultProps = {
    className: "",
};

export default TempContextManagement;
