import produce from "immer";
import { parseJwtPayload } from "../utils/JwtUtils";
import { UserState } from "../models/UserState";

export enum ActionType {
    update,
    clear,
}

interface UpdateAction {
    type: ActionType.update;
    jwt: string | undefined;
}

interface ClearAction {
    type: ActionType.clear;
}

export type UserStateAction = UpdateAction | ClearAction;

export const initialUser: UserState = {
    jwt: undefined,
    payload: undefined,
};

export const UserStateReducer = (state: UserState, action: UserStateAction) => {
    switch (action.type) {
        case ActionType.update:
            return produce(state, (draft: UserState) => {
                const { jwt } = action;

                if (jwt) {
                    draft.jwt = jwt;
                    draft.payload = parseJwtPayload(jwt);
                } else {
                    draft.jwt = undefined;
                    draft.payload = undefined;
                }
            });
        case ActionType.clear:
            return { jwt: undefined, payload: undefined };
        default:
            return state;
    }
};
