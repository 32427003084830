import React, { useEffect, useMemo } from "react";
import { useContextStateChanges } from "../../hooks/useContextsStateChanges";
import { DesignContextViewModel } from "../../openapi/webservice";
import ContextModal from "./ContextModal";
import { useTranslation } from "react-i18next";
import { useContextInfo } from "../../hooks/useContextInfo";
import { useUser } from "../../hooks/useUser";
import { useGetOrganisationsCallback } from "../../hooks/useOrganisationsApi";
import { isAdminRole } from "../../utils/Permissions";
import { useErrorResponseNotification } from "../../hooks/useErrorNotification";

type Props = {
    className: string;
    isOpen: boolean;
    onClose?: () => void;
    list: DesignContextViewModel[];
};

const DuplicateContextModalContainer = ({ className, isOpen, onClose, list }: Props) => {
    const { t } = useTranslation();
    const info = useContextInfo();
    const [user] = useUser();
    const { createSubContext } = useContextStateChanges();
    const [{ error, value }, getOrganisations] = useGetOrganisationsCallback();
    const shouldFetch = useMemo(() => isAdminRole(user), [user]);

    useErrorResponseNotification(error);

    useEffect(() => {
        if (!shouldFetch) {
            return;
        }

        getOrganisations();
    }, [shouldFetch, getOrganisations]);

    return (
        <ContextModal
            className={className}
            isOpen={isOpen}
            onClose={onClose}
            submit={createSubContext}
            list={list}
            info={info}
            organisations={value}
            headerTitle={t("blueprint.duplication.modal.title")}
        />
    );
};

DuplicateContextModalContainer.defaultProps = {
    className: "",
};

export default DuplicateContextModalContainer;
