import { useContext, useMemo } from "react";
import { MemosApiContext } from "../contexts/MemosApiContext";
import { useApiCallback } from "./useApiCall";

export function useMemosApi() {
    return useContext(MemosApiContext);
}

export function useDeleteMemo() {
    const api = useMemosApi();

    const boundFunc = useMemo(() => api.deleteMemo.bind(api), [api]);

    return useApiCallback(boundFunc);
}
export function useUpdateMemo() {
    const api = useMemosApi();

    const boundFunc = useMemo(() => api.correct.bind(api), [api]);

    return useApiCallback(boundFunc);
}
export function useCreateMemo() {
    const api = useMemosApi();

    const boundFunc = useMemo(() => api.insertMemo.bind(api), [api]);

    return useApiCallback(boundFunc);
}
