/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    InheritanceType,
    InheritanceTypeFromJSON,
    InheritanceTypeFromJSONTyped,
    InheritanceTypeToJSON,
    MemoType,
    MemoTypeFromJSON,
    MemoTypeFromJSONTyped,
    MemoTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface MemoViewModel
 */
export interface MemoViewModel {
    /**
     * 
     * @type {string}
     * @memberof MemoViewModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MemoViewModel
     */
    cellId: string;
    /**
     * 
     * @type {MemoType}
     * @memberof MemoViewModel
     */
    type: MemoType;
    /**
     * 
     * @type {string}
     * @memberof MemoViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MemoViewModel
     */
    description: string;
    /**
     * 
     * @type {InheritanceType}
     * @memberof MemoViewModel
     */
    inheritanceType: InheritanceType;
    /**
     * 
     * @type {boolean}
     * @memberof MemoViewModel
     */
    hasChat: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemoViewModel
     */
    hasPlanning: boolean;
    /**
     * 
     * @type {number}
     * @memberof MemoViewModel
     */
    xPosition: number;
    /**
     * 
     * @type {number}
     * @memberof MemoViewModel
     */
    yPosition: number;
    /**
     * 
     * @type {number}
     * @memberof MemoViewModel
     */
    rotationInDegrees: number;
}

export function MemoViewModelFromJSON(json: any): MemoViewModel {
    return MemoViewModelFromJSONTyped(json, false);
}

export function MemoViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemoViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'cellId': json['cellId'],
        'type': MemoTypeFromJSON(json['type']),
        'name': json['name'],
        'description': json['description'],
        'inheritanceType': InheritanceTypeFromJSON(json['inheritanceType']),
        'hasChat': json['hasChat'],
        'hasPlanning': json['hasPlanning'],
        'xPosition': json['xPosition'],
        'yPosition': json['yPosition'],
        'rotationInDegrees': json['rotationInDegrees'],
    };
}

export function MemoViewModelToJSON(value?: MemoViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cellId': value.cellId,
        'type': MemoTypeToJSON(value.type),
        'name': value.name,
        'description': value.description,
        'inheritanceType': InheritanceTypeToJSON(value.inheritanceType),
        'hasChat': value.hasChat,
        'hasPlanning': value.hasPlanning,
        'xPosition': value.xPosition,
        'yPosition': value.yPosition,
        'rotationInDegrees': value.rotationInDegrees,
    };
}


