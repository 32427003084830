/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MemoInputModel,
    MemoInputModelFromJSON,
    MemoInputModelToJSON,
    MemoViewModel,
    MemoViewModelFromJSON,
    MemoViewModelToJSON,
} from '../models';

export interface CorrectRequest {
    id: string;
    memoInputModel?: MemoInputModel;
}

export interface DeleteMemoRequest {
    id: string;
}

export interface GetActiveMemoRequest {
    id: string;
}

export interface GetHistoricalMemoRequest {
    id: string;
    asOf?: Date;
}

export interface GetIfMemoHasChatRequest {
    id: string;
}

export interface GetIfMemoHasPlanningRequest {
    id: string;
}

export interface GetMemoRequest {
    id: string;
}

export interface InsertMemoRequest {
    memoInputModel?: MemoInputModel;
}

/**
 * no description
 */
export class MemosApi extends runtime.BaseAPI {
    /**
     * Edit an existing memo on the memoboard.
     */
    async correctRaw(requestParameters: CorrectRequest): Promise<runtime.ApiResponse<MemoViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling correct.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MemoInputModelToJSON(requestParameters.memoInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MemoViewModelFromJSON(jsonValue));
    }
    /**
     * Edit an existing memo on the memoboard.
     */
    async correct(requestParameters: CorrectRequest): Promise<MemoViewModel> {
        const response = await this.correctRaw(requestParameters);
        return await response.value();
    }
    /**
     * Remove an existing memo from the memoboard.
     */
    async deleteMemoRaw(requestParameters: DeleteMemoRequest): Promise<runtime.ApiResponse<MemoViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteMemo.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MemoViewModelFromJSON(jsonValue));
    }
    /**
     * Remove an existing memo from the memoboard.
     */
    async deleteMemo(requestParameters: DeleteMemoRequest): Promise<MemoViewModel> {
        const response = await this.deleteMemoRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve a memo for the currently active transaction.
     */
    async getActiveMemoRaw(requestParameters: GetActiveMemoRequest): Promise<runtime.ApiResponse<MemoViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getActiveMemo.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memos/{id}/active`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MemoViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve a memo for the currently active transaction.
     */
    async getActiveMemo(requestParameters: GetActiveMemoRequest): Promise<MemoViewModel> {
        const response = await this.getActiveMemoRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve a memo as of the given date.
     */
    async getHistoricalMemoRaw(requestParameters: GetHistoricalMemoRequest): Promise<runtime.ApiResponse<MemoViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getHistoricalMemo.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.asOf !== undefined) {
            queryParameters['asOf'] = (requestParameters.asOf as any).toUTCString();
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memos/{id}/historical`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MemoViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve a memo as of the given date.
     */
    async getHistoricalMemo(requestParameters: GetHistoricalMemoRequest): Promise<MemoViewModel> {
        const response = await this.getHistoricalMemoRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve if a memo has any associated chat messages.
     */
    async getIfMemoHasChatRaw(requestParameters: GetIfMemoHasChatRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getIfMemoHasChat.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memos/{id}/hasChat`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Retrieve if a memo has any associated chat messages.
     */
    async getIfMemoHasChat(requestParameters: GetIfMemoHasChatRequest): Promise<boolean> {
        const response = await this.getIfMemoHasChatRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve if a memo has any associated planning.
     */
    async getIfMemoHasPlanningRaw(requestParameters: GetIfMemoHasPlanningRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getIfMemoHasPlanning.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memos/{id}/hasPlanning`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.TextApiResponse(response) as any;
    }
    /**
     * Retrieve if a memo has any associated planning.
     */
    async getIfMemoHasPlanning(requestParameters: GetIfMemoHasPlanningRequest): Promise<boolean> {
        const response = await this.getIfMemoHasPlanningRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve a memo as of the current date.
     */
    async getMemoRaw(requestParameters: GetMemoRequest): Promise<runtime.ApiResponse<MemoViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMemo.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memos/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MemoViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve a memo as of the current date.
     */
    async getMemo(requestParameters: GetMemoRequest): Promise<MemoViewModel> {
        const response = await this.getMemoRaw(requestParameters);
        return await response.value();
    }
    /**
     * Add a new memo to the memoboard.
     */
    async insertMemoRaw(requestParameters: InsertMemoRequest): Promise<runtime.ApiResponse<MemoViewModel>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memos`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MemoInputModelToJSON(requestParameters.memoInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MemoViewModelFromJSON(jsonValue));
    }
    /**
     * Add a new memo to the memoboard.
     */
    async insertMemo(requestParameters: InsertMemoRequest): Promise<MemoViewModel> {
        const response = await this.insertMemoRaw(requestParameters);
        return await response.value();
    }
}
