import React, { useEffect } from "react";
import { RequestState } from "../../hooks/useApiCall";
import { useGetAdminContexts } from "../../hooks/useContextsApi";
import { ActionType } from "../../reducers/ContextsStateReducer";
import TempContextManagement from "./TempContextManagement";
import { useAdminContexts } from "../../hooks/useAdminContexts";

type Props = {
    className: string;
};

const TempContextManagementContainer = ({ className }: Props) => {
    const { state, value } = useGetAdminContexts();
    const [data, dispatch] = useAdminContexts();

    useEffect(() => {
        if (state !== RequestState.DONE) {
            return;
        }

        dispatch({ type: ActionType.set, value: value });
    }, [state, value, dispatch]);

    return <TempContextManagement className={className} data={data} />;
};

TempContextManagementContainer.defaultProps = {
    className: "",
};

export default TempContextManagementContainer;
