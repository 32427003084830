import React from "react";
import { UserEvent } from "@testing-library/user-event/dist/types/setup";
import { sleep } from "./AsyncUtils";

export const DRAG_MEMO_KEY = "NOTE";
export const BLUEPRINT_DRAG_VALUE_KEY = "BLUEPRINT_DRAG_VALUE";
export const BLUEPRINT_DRAG_TYPE_KEY = "BLUEPRINT_DRAG_TYPE";

export function onDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
}

export async function UserDragsOnCanvas(
    user: UserEvent,
    targetFrom: HTMLElement,
    dragFromClientX: number,
    dragFromClientY: number,
    targetTo: HTMLElement,
    dragToClientX: number,
    dragToClientY: number,
): Promise<void> {
    await user.pointer([
        { target: targetFrom, coords: { clientX: dragFromClientX, clientY: dragFromClientY }, keys: "[MouseLeft>]" },
        { target: targetTo, coords: { clientX: dragToClientX, clientY: dragToClientY } },
    ]);
    // @Hack (Lejun) needs to sleep if I want to perform multiple UserDragsAndDropsOnCanvas after each other.
    // Otherwise during screenshot testing only the first action will be performed
    await sleep(200);
}

export async function UserDragsAndDropsOnCanvas(
    user: UserEvent,
    targetFrom: HTMLElement,
    dragFromClientX: number,
    dragFromClientY: number,
    targetTo: HTMLElement,
    dragToClientX: number,
    dragToClientY: number,
): Promise<void> {
    await UserDragsOnCanvas(user, targetFrom, dragFromClientX, dragFromClientY, targetTo, dragToClientX, dragToClientY);
    await user.pointer([{ keys: "[/MouseLeft]" }]);
    // @Hack (Lejun) needs to sleep if I want to perform multiple UserDragsAndDropsOnCanvas after each other.
    // Otherwise during screenshot testing only the first action will be performed
    await sleep(200);
}

export async function UserHover(
    user: UserEvent,
    targetFrom: HTMLElement,
    dragFromClientX: number,
    dragFromClientY: number,
): Promise<void> {
    await user.pointer([{ target: targetFrom, coords: { clientX: dragFromClientX, clientY: dragFromClientY } }]);
    // @Hack (Lejun) needs to sleep if I want to perform multiple UserHover after each other.
    // Otherwise during screenshot testing only the first action will be performed
    await sleep(200);
}

export async function UserClick(
    user: UserEvent,
    targetFrom: HTMLElement,
    dragFromClientX: number,
    dragFromClientY: number,
): Promise<void> {
    await user.pointer([
        { target: targetFrom, coords: { clientX: dragFromClientX, clientY: dragFromClientY } },
        { keys: "[MouseLeft]" },
    ]);
    // @Hack (Lejun) needs to sleep if I want to perform multiple UserHover after each other.
    // Otherwise during screenshot testing only the first action will be performed
    await sleep(200);
}
