import React from "react";
import styles from "./CheckboxInput.module.scss";
import classNames from "classnames";
import Text from "../Text/Text";
import { HtmlInputProps } from "../ReusableTypes";

type Props = HtmlInputProps & {
    className: string;
    label: string;
};

const CheckboxInput = ({ className, label, ...props }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <label className={styles.label}>
                <input type="checkbox" data-testid="CheckboxInput" {...props} className={styles.input} />
                <Text label={label} className={styles.text} />
            </label>
        </div>
    );
};

CheckboxInput.defaultProps = {
    className: "",
};

export default CheckboxInput;
