import React, { useCallback, useState } from "react";
import styles from "./RoughOrganisationManagementInput.module.scss";
import classNames from "classnames";
import Button from "../Button/Button";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import TextInput from "../TextInput/TextInput";
import { useTranslation } from "react-i18next";
import { isValidString } from "../../utils/ValidationUtils";
import BrandLogo from "../BrandLogo/BrandLogo";

type Props = {
    className: string;
    name: string;
    uri: string;
    saveCallback?: (name: string, logoUri: string) => void;
    deleteCallback?: () => void;
};

function canSave(name: string, uri: string, originalName: string, originalUri: string) {
    // 1. Each field except for password is required
    // 2. When every required field is filled, check for any changes in value compared to original
    return (
        [name, uri].every((v) => v.trim().length > 0) &&
        (isValidString(name, originalName) || isValidString(uri, originalUri))
    );
}

const RoughOrganisationManagementInput = ({ className, name, uri, saveCallback, deleteCallback }: Props) => {
    const { t } = useTranslation();
    const [organisationName, setOrganisationName] = useState(name);
    const [logoUri, setLogoUri] = useState(uri);

    const [isDeleteModalOpen, setShowDeleteModal] = useState(false);
    const closeDeleteModal = useCallback(() => setShowDeleteModal(false), []);
    const openDeleteModal = useCallback(() => setShowDeleteModal(true), []);

    const onNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setOrganisationName(e.currentTarget.value);
    }, []);

    const onUriChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setLogoUri(e.currentTarget.value);
    }, []);

    const onSave = useCallback(
        () => saveCallback && saveCallback(organisationName.trim(), logoUri.trim()),
        [saveCallback, organisationName, logoUri],
    );
    const onDelete = useCallback(() => {
        deleteCallback && deleteCallback();
        closeDeleteModal();
    }, [deleteCallback, closeDeleteModal]);

    return (
        <div className={classNames(styles.container, className)}>
            <TextInput
                label=""
                className={styles.textInput}
                placeholder={t("organisation.name.placeholder")}
                value={organisationName}
                onChange={onNameChange}
                data-testid="RoughOrganisationManagementNameInput"
            />
            <div className={styles.uriInputContainer}>
                <TextInput
                    label=""
                    className={styles.uriInput}
                    placeholder={t("organisation.logo.placeholder")}
                    value={logoUri}
                    onChange={onUriChange}
                    data-testid="RoughOrganisationManagementLogoInput"
                />
                <BrandLogo src={logoUri} className={styles.logo} size="default" />
            </div>

            <Button
                label="Opslaan"
                displayType="alternative"
                onClick={onSave}
                disabled={!canSave(organisationName, logoUri, name, uri)}
                className={styles.button}
                data-testid="OrganisationSaveButton"
            />
            {deleteCallback && (
                <Button label="Verwijderen" displayType="hollow" onClick={openDeleteModal} className={styles.button} />
            )}
            {isDeleteModalOpen && (
                <ConfirmationModal
                    title="Bevestiging"
                    message={`Weet je zeker dat je "${name}" wilt verwijderen?`}
                    yesLabel="Ja, verwijder"
                    noLabel="Annuleren"
                    onConfirm={onDelete}
                    onCancel={closeDeleteModal}
                />
            )}
        </div>
    );
};

RoughOrganisationManagementInput.defaultProps = {
    className: "",
};

export default RoughOrganisationManagementInput;
