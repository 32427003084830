import React, { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import {
    getInitialState,
    ContextsState,
    ContextsStateAction,
    ContextsStateReducer,
} from "../reducers/ContextsStateReducer";

export const ContextsStateContext = createContext<[ContextsState, Dispatch<ContextsStateAction>]>([
    getInitialState(),
    () => {},
]);

export const ContextsStateContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const reducer = useReducer<typeof ContextsStateReducer>(ContextsStateReducer, getInitialState());

    return <ContextsStateContext.Provider value={reducer}>{children}</ContextsStateContext.Provider>;
};
