import React, { useCallback, useMemo, useState } from "react";
import styles from "./RoughUserManagementInput.module.scss";
import classNames from "classnames";
import Button from "../Button/Button";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import TextInput from "../TextInput/TextInput";
import { OrganisationViewModel, UserInputModel, UserViewModel } from "../../openapi/webservice";
import { useTranslation } from "react-i18next";
import DropdownList from "../DropdownList/DropdownList";
import ImageButton from "../ImageButton/ImageButton";
import { QuestionIcon, SendIcon } from "../../assets/images/Images";
import { MIN_PASSWORD_LENGTH, generatePassword, isValidString } from "../../utils/ValidationUtils";
import RoundImage from "../RoundImage/RoundImage";
import { useOrganisationOptions } from "../../hooks/useOrganisationOptions";

type Props = {
    className: string;
    user: UserViewModel;
    organisations: OrganisationViewModel[];
    saveCallback?: (input: UserInputModel) => void;
    deleteCallback?: () => void;
};

function canSave(
    name: string,
    username: string,
    avatar: string,
    org: OrganisationViewModel | undefined,
    password: string,
    original: UserViewModel,
) {
    // 1. Each field except for password is required
    // 2. When every required field is filled, check for any changes in value compared to original
    return (
        [name, username, avatar].every((v) => v.trim().length > 0) &&
        (isValidString(name, original.displayName) ||
            isValidString(username, original.username) ||
            isValidString(avatar, original.avatarUri) ||
            isValidString(org?.id || "", original.organisation.id) ||
            password.length >= MIN_PASSWORD_LENGTH)
    );
}

const RoughUserManagementInput = ({ className, user, organisations, saveCallback, deleteCallback }: Props) => {
    const { t } = useTranslation();
    const [name, setName] = useState(user.displayName);
    const [username, setUsername] = useState(user.username);
    const [uri, setUri] = useState(user.avatarUri);
    const [selectedOrg, setSelectedOrg] = useState<OrganisationViewModel | undefined>(user.organisation);
    const [password, setPassword] = useState("");

    const [isDeleteModalOpen, setShowDeleteModal] = useState(false);
    const closeDeleteModal = useCallback(() => setShowDeleteModal(false), []);
    const openDeleteModal = useCallback(() => setShowDeleteModal(true), []);

    const onNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setName(e.currentTarget.value);
    }, []);

    const onUriChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setUri(e.currentTarget.value);
    }, []);

    const onUsernameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setUsername(e.currentTarget.value);
    }, []);

    const onPasswordChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setPassword(e.currentTarget.value);
    }, []);

    const organisationOptions = useOrganisationOptions(organisations, setSelectedOrg);

    const defaultSelectedOrganisation = useMemo(
        () => organisationOptions.find((value) => value.id === user.organisation.id),
        [organisationOptions, user.organisation],
    );

    const onSave = useCallback(
        () =>
            saveCallback &&
            saveCallback({
                displayName: name.trim(),
                username: username.trim(),
                avatarUri: uri.trim(),
                organisation: selectedOrg!.id,
                password: password,
            }),
        [saveCallback, name, username, uri, selectedOrg, password],
    );
    const onDelete = useCallback(() => {
        deleteCallback && deleteCallback();
        closeDeleteModal();
    }, [deleteCallback, closeDeleteModal]);

    const onGeneratePassword = useCallback(() => {
        const next = generatePassword();
        setPassword(next);
    }, []);

    return (
        <div className={classNames(styles.container, className)}>
            <TextInput
                label=""
                className={styles.input}
                placeholder={t("users.name.placeholder")}
                value={name}
                onChange={onNameChange}
                data-testid="NameInput"
            />
            <TextInput
                label=""
                className={styles.input}
                placeholder={t("users.username.placeholder")}
                value={username}
                onChange={onUsernameChange}
                data-testid="UsernameInput"
            />
            <div className={classNames(styles.input, styles.avatarContainer)}>
                <TextInput
                    label=""
                    className={styles.avatarInput}
                    placeholder={t("users.avatar.placeholder")}
                    value={uri}
                    onChange={onUriChange}
                    data-testid="AvatarInput"
                />
                <RoundImage className={styles.avatar} size="small" src={uri} />
            </div>
            <div className={classNames(styles.input, styles.passwordContainer)}>
                <TextInput
                    label=""
                    className={styles.passwordInput}
                    placeholder={t("users.password.placeholder")}
                    value={password}
                    onChange={onPasswordChange}
                    data-testid="PasswordInput"
                />
                <ImageButton
                    className={styles.passwordGenerator}
                    src={QuestionIcon}
                    rounded
                    onClick={onGeneratePassword}
                    data-testid="GeneratePasswordButton"
                />
            </div>
            <DropdownList
                className={classNames(styles.input, styles.dropdown)}
                items={organisationOptions}
                initialSelectedItem={defaultSelectedOrganisation}
            />
            <ImageButton
                src={SendIcon}
                rounded
                background="dark"
                onClick={onSave}
                disabled={!canSave(name, username, uri, selectedOrg, password, user)}
                className={styles.button}
                data-testid="SaveButton"
            />
            {deleteCallback && (
                <Button label="❌" displayType="hollow" onClick={openDeleteModal} className={styles.button} />
            )}
            {isDeleteModalOpen && (
                <ConfirmationModal
                    title="Bevestiging"
                    message={`Weet je zeker dat je "${user.displayName}" met gebruikersnaam "${user.username}" wilt verwijderen?`}
                    yesLabel="Ja, verwijder"
                    noLabel="Annuleren"
                    onConfirm={onDelete}
                    onCancel={closeDeleteModal}
                />
            )}
        </div>
    );
};

RoughUserManagementInput.defaultProps = {
    className: "",
};

export default RoughUserManagementInput;
