import produce from "immer";

interface BaseObject {
    id: string;
}

export type State<T extends BaseObject> = T[];

export enum ActionType {
    set,
    update,
    add,
}

interface SetAction<T extends BaseObject> {
    type: ActionType.set;
    value: T[];
}

interface UpdateAction<T extends BaseObject> {
    type: ActionType.update;
    value: T;
}
interface AddAction<T extends BaseObject> {
    type: ActionType.add;
    value: T;
}

export type StateAction<T extends BaseObject> = SetAction<T> | UpdateAction<T> | AddAction<T>;

export function getInitialState<T extends BaseObject>(): State<T> {
    return [];
}

export const GenericStateReducer = <T extends BaseObject>(state: State<T>, action: StateAction<T>) => {
    switch (action.type) {
        case ActionType.set:
            return JSON.parse(JSON.stringify(action.value));
        case ActionType.update:
            return produce(state, (draft: State<T>) => {
                const index = draft.findIndex((c) => c.id === action.value.id);

                if (index !== -1) {
                    draft[index] = action.value;
                }
            });
        case ActionType.add:
            return produce(state, (draft: State<T>) => {
                draft.unshift(action.value);
            });
        default:
            return state;
    }
};
