import React, { useCallback, useEffect, useMemo, useState } from "react";
import { KonvaEventObject } from "konva/lib/Node";
import { Group, Image, Rect, Text } from "react-konva";
import "konva/lib/shapes/Text";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Image";
import "konva/lib/shapes/Arrow";
import { ContainerViewModel, DoubleDoubleTuple, CellViewModel } from "../../../openapi/webservice";
import { CONTAINER_NAME_HEIGHT, ICON_SIZE } from "../../../utils/CanvasConstants";
import {
    getBoundedXForBlueprintCanvas,
    getBoundedYForBlueprintCanvas,
    calculateContainerHeight,
    isExternalSystem,
    calculateContainerWidth,
    getContainerIcon,
} from "../../../utils/CanvasUtils";
import { Html } from "../../../konva/Html";
import styles from "../BlueprintCanvas.module.scss";
import { useFontObserver } from "../../../hooks/useFontObserver";
import { HighlightMode } from "../../../models/HighlightMode";
import CellView from "./CellView";
import TrashButton from "../../KonvaShared/TrashButton";
import { useKonvaHovering } from "../../../hooks/useKonvaEvents";
import { useTranslation } from "react-i18next";
import { getContainerBackgroundColor } from "../../../utils/ContainerUtils";
import useImage from "use-image";
import { ChatIcon, ChatAddIcon } from "../../../assets/images/Images";
import { ActionIcon } from "../../konva-shared/ActionIcon";

function shouldShowActionButton(
    hasCallback: boolean,
    isHovering: boolean,
    isEditing: boolean,
    highlight: HighlightMode,
) {
    return hasCallback && isHovering && !isEditing && highlight === "none";
}

function shouldRename(hasCallback: boolean, value1: string, value2: string) {
    return hasCallback && value1 !== value2;
}

function isContainerDraggable(isEditMode: boolean, highlight: HighlightMode) {
    return isEditMode && highlight !== "cell";
}

interface StandardContainerProps {
    container: ContainerViewModel;
    isEditMode: boolean;
    isDragging: boolean;
    isEditingContainer: boolean;
    onRenameCell?: (cellId: string, name: string) => void;
    highlight: HighlightMode;
    onCellClick?: (event: KonvaEventObject<MouseEvent>, cell: CellViewModel, container: ContainerViewModel) => void;
    onDeleteCell?: (cellId: string) => void;
    containerValue: string;
    onTextChange: React.ChangeEventHandler<HTMLInputElement>;
    startContainerEditMode: () => void;
    endContainerEditMode: () => void;
    containerHeight: number;
    containerWidth: number;
}

function StandardContainer({
    container,
    isEditMode,
    isDragging,
    isEditingContainer,
    onRenameCell,
    highlight,
    onCellClick,
    onDeleteCell,
    containerValue,
    onTextChange,
    endContainerEditMode,
    startContainerEditMode,
    containerHeight,
    containerWidth,
}: StandardContainerProps) {
    const { cellType, yPosition, xPosition, cells } = container;

    return (
        <>
            <Group opacity={highlight === "cell" ? 0.3 : 1}>
                <Rect
                    width={containerWidth}
                    height={containerHeight}
                    fill={getContainerBackgroundColor(cellType)}
                    cornerRadius={7}
                    shadowEnabled={isDragging}
                    shadowOffset={{ x: 5, y: 5 }}
                    shadowBlur={20}
                    shadowOpacity={0.5}
                />
                {isEditingContainer ? (
                    <Html
                        divProps={{
                            style: {
                                position: "absolute",
                                top: yPosition,
                                left: xPosition,
                            },
                        }}
                    >
                        <input
                            autoFocus
                            className={styles.containerInput}
                            defaultValue={containerValue}
                            onChange={onTextChange}
                            onBlur={endContainerEditMode}
                            onFocus={(e) => e.currentTarget.select()}
                            type="text"
                            maxLength={24}
                            style={{ width: containerWidth, height: CONTAINER_NAME_HEIGHT }}
                        />
                    </Html>
                ) : (
                    <Text
                        width={containerWidth}
                        height={CONTAINER_NAME_HEIGHT}
                        text={containerValue}
                        align="center"
                        onClick={isEditMode ? startContainerEditMode : undefined}
                        verticalAlign="middle"
                        fontSize={14}
                        wrap="word"
                        fill="#061133"
                        fontStyle="bold"
                        fontFamily="Open Sans"
                    />
                )}
            </Group>
            {cells.map((cell, index) => {
                const isLast = index === cells.length - 1;
                return (
                    <CellView
                        key={cell.id}
                        cell={cell}
                        container={container}
                        highlight={highlight}
                        index={index}
                        isEditMode={isEditMode}
                        onCellClick={onCellClick}
                        isLast={isLast}
                        onRenameCell={onRenameCell}
                        onDeleteCell={onDeleteCell}
                    />
                );
            })}
        </>
    );
}

interface ExternalSystemContainerProps {
    container: ContainerViewModel;
    isEditMode: boolean;
    isDragging: boolean;
    isEditingContainer: boolean;
    onCellClick?: (event: KonvaEventObject<MouseEvent>, cell: CellViewModel, container: ContainerViewModel) => void;
    containerValue: string;
    onTextChange: React.ChangeEventHandler<HTMLTextAreaElement>;
    startContainerEditMode: () => void;
    endContainerEditMode: () => void;
    containerHeight: number;
    containerWidth: number;
}

function ExternalSystemContainer({
    container,
    isEditMode,
    isDragging,
    isEditingContainer,
    onCellClick,
    containerValue,
    onTextChange,
    endContainerEditMode,
    startContainerEditMode,
    containerHeight,
    containerWidth,
}: ExternalSystemContainerProps) {
    const { cellType, yPosition, xPosition } = container;
    const [icon] = useImage(getContainerIcon(cellType));

    const onContainerClick = useCallback(
        (event: KonvaEventObject<MouseEvent>) => {
            if (!onCellClick) {
                return;
            }

            onCellClick(event, container.cells[0], container);
        },
        [onCellClick, container],
    );

    return (
        <Group onClick={onContainerClick}>
            <Rect
                width={containerWidth}
                height={containerHeight}
                fill={getContainerBackgroundColor(cellType)}
                cornerRadius={7}
                stroke="#061133"
                strokeWidth={1.5}
                shadowEnabled={isDragging}
                shadowOffset={{ x: 5, y: 5 }}
                shadowBlur={20}
                shadowOpacity={0.5}
            />
            {isEditingContainer ? (
                <Html
                    divProps={{
                        style: {
                            position: "absolute",
                            top: yPosition,
                            left: xPosition,
                        },
                    }}
                >
                    <textarea
                        autoFocus
                        className={styles.externalSystemInput}
                        defaultValue={containerValue}
                        onChange={onTextChange}
                        onBlur={endContainerEditMode}
                        onFocus={(e) => e.currentTarget.select()}
                        style={{ width: containerWidth, height: containerHeight }}
                    />
                </Html>
            ) : (
                <Text
                    width={containerWidth}
                    height={containerHeight}
                    text={containerValue}
                    align="center"
                    onClick={isEditMode ? startContainerEditMode : undefined}
                    verticalAlign="middle"
                    fontSize={14}
                    wrap="word"
                    fill="#061133"
                    fontFamily="Open Sans"
                />
            )}
            <Image image={icon} x={containerWidth - 28} y={containerHeight - 24} />
        </Group>
    );
}

type ContainerViewProps = {
    container: ContainerViewModel;
    isEditMode: boolean;
    onMoveContainer?: (containerId: string, position: DoubleDoubleTuple) => void;
    onRenameContainer?: (containerId: string, name: string) => void;
    onRenameCell?: (cellId: string, name: string) => void;
    highlight: HighlightMode;
    onCellClick?: (event: KonvaEventObject<MouseEvent>, cell: CellViewModel, container: ContainerViewModel) => void;
    onDeleteContainer?: (containerId: string) => void;
    onDeleteCell?: (cellId: string) => void;
    onOpenChat?: (containerId: string) => void;
};

const ContainerView = ({
    container,
    isEditMode,
    onMoveContainer,
    highlight,
    onRenameContainer,
    onRenameCell,
    onCellClick,
    onDeleteContainer,
    onDeleteCell,
    onOpenChat,
}: ContainerViewProps) => {
    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState(false);
    const [chatIcon] = useImage(ChatIcon);
    const [addChatIcon] = useImage(ChatAddIcon);
    const [isEditingContainer, setIsEditingContainer] = useState(false);
    const { id, xPosition, yPosition, name, hasChat } = container;
    const [containerValue, setContainerValue] = useState(name);
    const isFontLoaded = useFontObserver("Open Sans");
    const [isHovering, setIsHovering] = useState(false);
    const { onEnter, onLeave } = useKonvaHovering(isEditMode, setIsHovering);
    const containerHeight = useMemo(() => calculateContainerHeight(container), [container]);
    const containerWidth = useMemo(() => calculateContainerWidth(container), [container]);

    //Update name when new value comes in from external, for example changing transactions
    useEffect(() => setContainerValue(name), [name, setContainerValue]);
    const openChat = useCallback(() => onOpenChat && onOpenChat(id), [onOpenChat, id]);

    const onDragMove = useCallback(
        (e: KonvaEventObject<DragEvent>) => {
            // Konva requires bound to be handled from the `dragmove` event
            // Manipulating via the props doesn't work, because constantly bounding xPosition to -55 for example
            // won't trigger a rerender since the value didn't change, but on the canvas it did change.
            e.target.x(getBoundedXForBlueprintCanvas(e.target.x()));
            e.target.y(getBoundedYForBlueprintCanvas(e.target.y()));

            setIsDragging(true);
        },
        [setIsDragging],
    );

    const onDragEnd = useCallback(
        (e: KonvaEventObject<DragEvent>) => {
            setIsDragging(false);

            if (!onMoveContainer) {
                return;
            }

            onMoveContainer(id, { item1: e.target.x(), item2: e.target.y() });
        },
        [onMoveContainer, id, setIsDragging],
    );

    const startContainerEditMode = useCallback(() => {
        if (highlight !== "none") {
            return;
        }

        setIsHovering(false);
        setIsEditingContainer(true);
    }, [highlight]);

    const endContainerEditMode = useCallback(() => {
        setIsEditingContainer(false);

        if (shouldRename(!!onRenameContainer, name, containerValue)) {
            onRenameContainer!(id, containerValue);
        }
    }, [id, name, containerValue, onRenameContainer]);

    const onTextChange = useCallback<React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>((event) => {
        const text = event.currentTarget.value.trim();
        setContainerValue(text);
    }, []);

    const deleteCallback = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            e.cancelBubble = true;

            const containerType = t(`enum.${container.cellType}`);
            if (
                window.confirm(
                    t("blueprint.delete.container.confirm", { name: container.name, containerType: containerType }),
                ) &&
                onDeleteContainer
            ) {
                onDeleteContainer(container.id);
            }
        },
        [onDeleteContainer, container, t],
    );

    if (!isFontLoaded) {
        return null;
    }

    return (
        <>
            {isDragging && (
                // Placeholder element while dragging
                <Rect
                    x={xPosition}
                    y={yPosition}
                    width={containerWidth}
                    height={containerHeight}
                    fill="#f5f5f5"
                    cornerRadius={7}
                    stroke="#dbdde2"
                    strokeWidth={2}
                    dash={[10, 5]}
                />
            )}
            <Group
                x={xPosition}
                y={yPosition}
                draggable={isContainerDraggable(isEditMode, highlight)}
                onDragMove={onDragMove}
                onDragEnd={onDragEnd}
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
            >
                {isExternalSystem(container) ? (
                    <ExternalSystemContainer
                        container={container}
                        containerValue={containerValue}
                        endContainerEditMode={endContainerEditMode}
                        isDragging={isDragging}
                        isEditMode={isEditMode}
                        isEditingContainer={isEditingContainer}
                        onTextChange={onTextChange}
                        startContainerEditMode={startContainerEditMode}
                        onCellClick={onCellClick}
                        containerHeight={containerHeight}
                        containerWidth={containerWidth}
                    />
                ) : (
                    <StandardContainer
                        container={container}
                        containerValue={containerValue}
                        endContainerEditMode={endContainerEditMode}
                        highlight={highlight}
                        isDragging={isDragging}
                        isEditMode={isEditMode}
                        isEditingContainer={isEditingContainer}
                        onTextChange={onTextChange}
                        startContainerEditMode={startContainerEditMode}
                        onCellClick={onCellClick}
                        onDeleteCell={onDeleteCell}
                        onRenameCell={onRenameCell}
                        containerHeight={containerHeight}
                        containerWidth={containerWidth}
                    />
                )}
                {isEditMode ? (
                    <TrashButton
                        x={containerWidth - ICON_SIZE / 2}
                        y={-ICON_SIZE / 2}
                        visible={shouldShowActionButton(!!onDeleteContainer, isHovering, isEditingContainer, highlight)}
                        onClick={deleteCallback}
                    />
                ) : (
                    <ActionIcon
                        src={hasChat ? chatIcon : addChatIcon}
                        index={0}
                        visible={shouldShowActionButton(!!onDeleteContainer, isHovering, isEditingContainer, highlight)}
                        referenceHeight={0}
                        onClick={openChat}
                    />
                )}
            </Group>
        </>
    );
};

ContainerView.defaultProps = {
    highlight: "none",
};

export default ContainerView;
