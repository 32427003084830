import React, { useCallback } from "react";
import styles from "./TempContextManagementPage.module.scss";
import classNames from "classnames";
import TempContextManagementContainer from "../../components/TempContextManagement/TempContextManagementContainer";
import { Helmet } from "react-helmet";
import Title from "../../components/Title/Title";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import { AdminContextsStateContextProvider } from "../../contexts/AdminContextsStateContext";

type Props = {
    className: string;
};

const TempContextManagementPage = ({ className }: Props) => {
    const navigate = useNavigate();

    const toSettings = useCallback(() => navigate("/demo-instellingen"), [navigate]);

    return (
        <div className={classNames(styles.container, className)}>
            <Helmet title="Demo-instellingen Context">
                <meta name="description" content="Demo instellingen voor Business Rules Canvas" />
            </Helmet>
            <Button label="Naar instellingen" onClick={toSettings} displayType="alternative" />
            <Title className={classNames(styles.title)} label="Contexten bewerken" heading="h1" />
            <AdminContextsStateContextProvider>
                <TempContextManagementContainer />
            </AdminContextsStateContextProvider>
        </div>
    );
};

TempContextManagementPage.defaultProps = {
    className: "",
};

export default TempContextManagementPage;
