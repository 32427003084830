import React, { useEffect, useState } from "react";
import styles from "./ContextList.module.scss";
import classNames from "classnames";
import { DesignContextViewModel } from "../../openapi/webservice";
import ContextItem from "../ContextItem/ContextItem";
import { useSubContextId } from "../../hooks/useRouteParams";

type Props = {
    className: string;
    list: DesignContextViewModel[];
};

const ContextList = ({ className, list }: Props) => {
    const subContextId = useSubContextId();
    var [contextId, setSelectedContextId] = useState(
        () => list.find((context) => context.subContexts.find((subContext) => subContext.id === subContextId))?.id,
    );

    useEffect(() => {
        const id = list.find((context) => context.subContexts.find((subContext) => subContext.id === subContextId))?.id;

        setSelectedContextId((value) => {
            if (id !== undefined && value !== id) {
                return id;
            } else {
                return value;
            }
        });
    }, [subContextId, list]);

    return (
        <div className={classNames(styles.container, className)} data-testid="ContextList">
            {list.map((context) => (
                <ContextItem
                    key={context.id}
                    context={context}
                    selectedContext={contextId}
                    currentSubContext={subContextId}
                    onSelectContext={(id) => setSelectedContextId(id === contextId ? undefined : id)}
                />
            ))}
        </div>
    );
};

ContextList.defaultProps = {
    className: "",
    list: [],
};

export default ContextList;
