import React, { SetStateAction, useState } from "react";
import { createContext, Dispatch, PropsWithChildren } from "react";
import { TransactionViewModel } from "../openapi/webservice";

export const TimelineTransactionsContext = createContext<
    [TransactionViewModel[], Dispatch<SetStateAction<TransactionViewModel[]>>]
>([[], () => {}]);

export const TimelineTransactionsProvider = ({ children }: PropsWithChildren<{}>) => {
    const state = useState<TransactionViewModel[]>([]);

    return <TimelineTransactionsContext.Provider value={state}>{children}</TimelineTransactionsContext.Provider>;
};
