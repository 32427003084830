import produce from "immer";
import { BlueprintViewModel, ConnectionViewModel, ContainerViewModel } from "../openapi/webservice";
import { isInteractiveConnection } from "../utils/ContainerUtils";

export type BlueprintState = Pick<BlueprintViewModel, "containers" | "connections">;
export enum ActionType {
    insertContainer,
    updateContainer,
    deleteContainer,
    set,
    insertConnection,
    deleteConnection,
    setContainers,
}

interface SetAction {
    type: ActionType.set;
    value: BlueprintState;
}

interface InsertContainerAction {
    type: ActionType.insertContainer;
    value: ContainerViewModel;
}

interface UpdateContainerAction {
    type: ActionType.updateContainer;
    value: ContainerViewModel;
}

interface DeleteContainerAction {
    type: ActionType.deleteContainer;
    value: string;
}

interface InsertConnnectionAction {
    type: ActionType.insertConnection;
    value: ConnectionViewModel;
}

interface DeleteConnectionAction {
    type: ActionType.deleteConnection;
    value: string;
}

interface SetContainersAction {
    type: ActionType.setContainers;
    value: ContainerViewModel[];
}

export type BlueprintStateAction =
    | SetAction
    | InsertContainerAction
    | UpdateContainerAction
    | DeleteContainerAction
    | InsertConnnectionAction
    | DeleteConnectionAction
    | SetContainersAction;

export function getInitialState(): BlueprintState {
    return {
        containers: [],
        connections: [],
    };
}

export const BlueprintStateReducer = (state: BlueprintState, action: BlueprintStateAction) => {
    switch (action.type) {
        case ActionType.set:
            return produce(action.value, (draft) => {
                draft.connections = draft.connections.filter((c) => {
                    return isInteractiveConnection(c, action.value.containers);
                });
            });
        case ActionType.insertConnection:
            return {
                ...state,
                connections: produce(state.connections, (draft) => {
                    if (!isInteractiveConnection(action.value, state.containers)) {
                        return;
                    }

                    draft.push(action.value);
                }),
            };
        case ActionType.insertContainer:
            return {
                ...state,
                containers: produce(state.containers, (draft) => {
                    draft.push(action.value);
                }),
            };
        case ActionType.updateContainer:
            return {
                ...state,
                containers: produce(state.containers, (draft) => {
                    const index = draft.findIndex((container) => container.id === action.value.id);

                    if (index !== -1) {
                        draft[index] = action.value;
                    }
                }),
            };
        case ActionType.deleteConnection:
            return {
                ...state,
                connections: produce(state.connections, (draft) => {
                    const index = draft.findIndex((connection) => connection.id === action.value);

                    if (index !== -1) {
                        draft.splice(index, 1);
                    }
                }),
            };
        case ActionType.deleteContainer:
            return {
                ...state,
                containers: produce(state.containers, (draft) => {
                    const index = draft.findIndex((container) => container.id === action.value);

                    if (index !== -1) {
                        draft.splice(index, 1);
                    }
                }),
            };
        case ActionType.setContainers:
            return produce(state, (draft) => {
                draft.containers = action.value;
            });
        default:
            return state;
    }
};
