import React, { useCallback, useMemo } from "react";
import { useMemoModalState } from "../../hooks/useMemoModalState";
import { useMemos } from "../../hooks/useMemos";
import { useMemoStateChanges } from "../../hooks/useMemoStateChanges";
import MemoModal from "./MemoModal";

type Props = {
    className: string;
};

const MemoModalContainer = ({ className }: Props) => {
    const { onUpdate } = useMemoStateChanges();
    const [{ selectedMemo: memoId, tab }, setModalState] = useMemoModalState();
    const [memos] = useMemos();
    const memo = useMemo(() => memos.find((m) => m.id === memoId), [memos, memoId]);

    const onClose = useCallback(() => {
        setModalState({ selectedMemo: undefined, tab: undefined });
    }, [setModalState]);

    return (
        <MemoModal
            className={className}
            defaultTab={tab}
            memo={memo}
            isOpen={!!memo}
            onClose={onClose}
            onChange={onUpdate}
        />
    );
};

MemoModalContainer.defaultProps = {
    className: "",
};

export default MemoModalContainer;
