import React from "react";
import styles from "./DropdownList.module.scss";
import classNames from "classnames";
import { useSelect, UseSelectStateChange } from "downshift";
import { ArrowUpIcon } from "../../assets/images/Images";
import Icon from "../Icon/Icon";
import Text from "../Text/Text";

export interface DropdownItem {
    id: string;
    icon?: string;
    label: string;
    onSelect?: () => Promise<any> | any;
}

type Props = {
    className: string;
    items: DropdownItem[];
    initialSelectedItem?: DropdownItem;
    disabled?: boolean;
};

function getItemLabel(item: DropdownItem | null) {
    return item?.label || "-";
}

function onSelectedItemChange(changes: UseSelectStateChange<DropdownItem>) {
    if (changes.selectedItem?.onSelect) {
        changes.selectedItem?.onSelect();
    }
}

const DropdownList = ({ className, items, initialSelectedItem, disabled }: Props) => {
    const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, getItemProps } = useSelect<DropdownItem>({
        items,
        itemToString: getItemLabel,
        initialSelectedItem: initialSelectedItem,
        onSelectedItemChange: onSelectedItemChange,
    });

    return (
        <div className={classNames(styles.container, className)}>
            <button
                type="button"
                {...getToggleButtonProps()}
                className={classNames(styles.select, isOpen && styles.open)}
                data-testid="DropdownSelect"
                disabled={disabled}
            >
                {selectedItem?.icon && <Icon className={styles.icon} src={selectedItem?.icon} />}{" "}
                <Text className={styles.label} label={getItemLabel(selectedItem)} />
                {!disabled && <Icon className={styles.arrow} src={ArrowUpIcon} />}
            </button>
            {/* @NOTE(Lejun) tabindex should actually be used from the `getMenuProps`, but for some reason position: absolute doesn't work with a set tabindex */}
            <ul {...getMenuProps()} className={styles.optionsContainer} tabIndex={isOpen ? undefined : -1}>
                {isOpen &&
                    items.map((item, index) => (
                        <li
                            key={`${item}${index}`}
                            className={classNames(styles.option, item.id === selectedItem?.id && styles.selected)}
                            {...getItemProps({ item, index })}
                        >
                            {item.icon && (
                                <>
                                    <Icon className={styles.icon} src={item.icon} />{" "}
                                </>
                            )}
                            <Text className={styles.label} label={getItemLabel(item)} />
                        </li>
                    ))}
            </ul>
        </div>
    );
};

DropdownList.defaultProps = {
    className: "",
};

export default DropdownList;
