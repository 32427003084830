import { useCallback } from "react";
import { MemoViewModel } from "../openapi/webservice";
import { ActionType } from "../reducers/MemosStateReducer";
import { getBoundedXForMemoCanvas, getBoundedYForMemoCanvas } from "../utils/CanvasUtils";
import { useErrorResponseNotification } from "./useErrorNotification";
import { useMemos } from "./useMemos";
import { useDeleteMemo, useUpdateMemo } from "./useMemosApi";

export function useMemoStateChanges() {
    const [, dispatchMemos] = useMemos();
    const [{ error: updateError }, updateCallback] = useUpdateMemo();
    const [{ error: deleteError }, deleteCallback] = useDeleteMemo();
    useErrorResponseNotification(updateError);
    useErrorResponseNotification(deleteError);

    const onUpdate = useCallback(
        async (memo: MemoViewModel) => {
            const updated = await updateCallback({
                id: memo.id,
                memoInputModel: {
                    ...memo,
                    xPosition: getBoundedXForMemoCanvas(memo.xPosition),
                    yPosition: getBoundedYForMemoCanvas(memo.yPosition),
                },
            });
            dispatchMemos({ type: ActionType.update, value: updated });
        },
        [updateCallback, dispatchMemos],
    );
    const onDelete = useCallback(
        async (memo: MemoViewModel) => {
            const deleted = await deleteCallback({ id: memo.id });
            dispatchMemos({ type: ActionType.delete, value: deleted });
        },
        [deleteCallback, dispatchMemos],
    );

    return { onUpdate, onDelete };
}
