import React, { Dispatch } from "react";
import styles from "./ContextItem.module.scss";
import classNames from "classnames";
import { DesignContextViewModel } from "../../openapi/webservice";
import Icon from "../Icon/Icon";
import { ArrowUpIcon } from "../../assets/images/Images";
import { Link } from "react-router-dom";
import Text from "../../components/Text/Text";
import { getBlueprintRoute } from "../../utils/RouteUtils";

type Props = {
    className: string;
    context: DesignContextViewModel;
    selectedContext: string | undefined;
    currentSubContext: string | undefined;
    onSelectContext: Dispatch<string>;
};

const ContextItem = ({ className, context, selectedContext, currentSubContext, onSelectContext: onSelect }: Props) => {
    const selected = selectedContext === context.id;

    return (
        <div className={classNames(styles.container, className)} data-testid={"ContextItem-" + context.id}>
            <div
                className={classNames(styles.context, selected && styles.selected)}
                onClick={() => onSelect(context.id)}
            >
                <Text className={styles.contextName} label={context.name} />
                <Icon className={classNames(styles.icon, selected && styles.selected)} src={ArrowUpIcon} />
            </div>
            {selected && context.subContexts.length > 0 && <div className={classNames(styles.line)} />}
            {selected &&
                context.subContexts.map((subContext) => (
                    <Link
                        className={styles.subContextLink}
                        to={getBlueprintRoute(subContext.id, subContext.blueprintId)}
                        key={subContext.id}
                    >
                        <Text
                            className={classNames(
                                styles.subContextName,
                                subContext.id === currentSubContext && styles.selected,
                            )}
                            label={subContext.name}
                        />
                    </Link>
                ))}
            {selected && context.subContexts.length > 0 && <div className={classNames(styles.line)} />}
        </div>
    );
};

ContextItem.defaultProps = {
    className: "",
};

export default ContextItem;
