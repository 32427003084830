import React from "react";
import styles from "./BlueprintPage.module.scss";
import BlueprintSidebar from "../../components/BlueprintSidebar/BlueprintSidebar";
import { ContextsStateContextProvider } from "../../contexts/ContextsStateContext";
import BlueprintBoard from "../../components/BlueprintBoard/BlueprintBoard";
import HelpModalContainer from "../../components/HelpModal/HelpModalContainer";
import TimelineBarContainer from "../../components/TimelineBar/TimelineBarContainer";
import { CanvasModeProvider } from "../../contexts/CanvasModeContext";
import { TimelineTransactionsProvider } from "../../contexts/TimelineTransactionsContext";
import { BlueprintStateContextProvider } from "../../contexts/BlueprintStateContext";
import { useBlueprintId } from "../../hooks/useRouteParams";
import { ContainerModalProvider } from "../../contexts/ContainerModalContext";

const BlueprintPage = () => {
    const subContextId = useBlueprintId();

    return (
        <React.Fragment>
            <ContextsStateContextProvider>
                <div className={styles.container}>
                    <BlueprintSidebar className={styles.sidebar} />
                    <div className={styles.main} key={subContextId}>
                        {subContextId ? (
                            <>
                                <TimelineTransactionsProvider>
                                    <CanvasModeProvider>
                                        <ContainerModalProvider>
                                            <BlueprintStateContextProvider>
                                                <BlueprintBoard className={styles.board} />
                                            </BlueprintStateContextProvider>
                                            <TimelineBarContainer className={styles.timeline} />
                                        </ContainerModalProvider>
                                    </CanvasModeProvider>
                                </TimelineTransactionsProvider>
                            </>
                        ) : null}
                    </div>
                </div>
            </ContextsStateContextProvider>
            <HelpModalContainer />
        </React.Fragment>
    );
};

export default BlueprintPage;
