import React from "react";
import styles from "./Text.module.scss";
import classNames from "classnames";
import { HtmlParagraphProps } from "../ReusableTypes";

type TextStyle = "body" | "tiny" | "bold" | "tooltip" | "large";

type Props = Omit<HtmlParagraphProps, "children"> & {
    label: string;
    type: TextStyle;
};

const Text = ({ className, label, type, ...props }: Props) => {
    return (
        <p className={classNames(styles.container, styles[type], className)} {...props}>
            {label}
        </p>
    );
};

Text.defaultProps = {
    className: "",
    type: "body",
};

export default Text;
