import React, { SetStateAction } from "react";
import { createContext, Dispatch, PropsWithChildren } from "react";
import { useSearchParamsState } from "../hooks/useSearchParamsState";
import { SELECTED_CONTAINER_KEY } from "../utils/RouterConstants";

type Model = string | undefined;

type ContextType = [Model, Dispatch<SetStateAction<Model>>];

export const ContainerModalContext = createContext<ContextType>([undefined, () => {}]);

export const ContainerModalProvider = ({ children }: PropsWithChildren<{}>) => {
    const state = useSearchParamsState<Model>(SELECTED_CONTAINER_KEY, undefined);

    return <ContainerModalContext.Provider value={state}>{children}</ContainerModalContext.Provider>;
};
