import React, { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import { getInitialState, State, StateAction, GenericStateReducer } from "../reducers/GenericArrayStateReducer";
import { UserViewModel } from "../openapi/webservice";

type ReducerT = [State<UserViewModel>, Dispatch<StateAction<UserViewModel>>];

export const UsersStateContext = createContext<ReducerT>([getInitialState(), () => {}]);

export const UsersStateContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const reducer = useReducer(GenericStateReducer, getInitialState()) as ReducerT;

    return <UsersStateContext.Provider value={reducer}>{children}</UsersStateContext.Provider>;
};
