import React from "react";
import { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import {
    BlueprintState,
    BlueprintStateAction,
    BlueprintStateReducer,
    getInitialState,
} from "../reducers/BlueprintStateReducer";

export const BlueprintStateContext = createContext<[BlueprintState, Dispatch<BlueprintStateAction>]>([
    getInitialState(),
    () => {},
]);

export const BlueprintStateContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const reducer = useReducer<typeof BlueprintStateReducer>(BlueprintStateReducer, getInitialState());

    return <BlueprintStateContext.Provider value={reducer}>{children}</BlueprintStateContext.Provider>;
};
