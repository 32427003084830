import classNames from "classnames";
import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Title from "../../components/Title/Title";
import styles from "./TempSettingsPage.module.scss";

type Props = {
    className: string;
};

const TempSettingsPage = ({ className }: Props) => {
    const navigate = useNavigate();

    const toContextSettings = useCallback(() => navigate("/demo-instellingen/context"), [navigate]);
    const toOrganisationSettings = useCallback(() => navigate("/demo-instellingen/organisaties"), [navigate]);
    const toUserSettings = useCallback(() => navigate("/demo-instellingen/gebruikers"), [navigate]);

    return (
        <div className={classNames(styles.container, className)}>
            <Helmet title="Demo-instellingen">
                <meta name="description" content="Demo instellingen voor Business Rules Canvas" />
            </Helmet>
            <Title className={styles.title} label="Instellingen wijzigen" heading="h1" />
            <Button
                className={styles.nav}
                label="Naar context instellingen"
                onClick={toContextSettings}
                displayType="alternative"
            />
            <Button
                className={styles.nav}
                label="Naar organisatie instellingen"
                onClick={toOrganisationSettings}
                displayType="alternative"
            />
            <Button
                className={styles.nav}
                label="Naar gebruiker instellingen"
                onClick={toUserSettings}
                displayType="alternative"
            />
        </div>
    );
};

TempSettingsPage.defaultProps = {
    className: "",
};

export default TempSettingsPage;
