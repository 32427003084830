import React, { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import {
    AdminContextsStateAction,
    AdminContextsStateReducer,
    ContextsState,
    getInitialState,
} from "../reducers/AdminContextsStateReducer";

type ReducerType = [ContextsState, Dispatch<AdminContextsStateAction>];

export const AdminContextsStateContext = createContext<ReducerType>([getInitialState(), () => {}]);

export const AdminContextsStateContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const reducer = useReducer(AdminContextsStateReducer, getInitialState()) as ReducerType;

    return <AdminContextsStateContext.Provider value={reducer}>{children}</AdminContextsStateContext.Provider>;
};
