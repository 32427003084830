import React from "react";
import styles from "./FooterBar.module.scss";
import classNames from "classnames";
import PoweredBy from "../PoweredBy/PoweredBy";
import Text from "../Text/Text";
import Copyright from "../Copyright/Copyright";
import { useTranslation } from "react-i18next";

type Props = {
    className: string;
};

const FooterBar = ({ className }: Props) => {
    const { t } = useTranslation();
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.line}></div>
            <div className={styles.content}>
                <PoweredBy className={styles.poweredBy} size="small" theme="light" />
                <Text className={styles.quote} label={t("quotes.footer")} type="tiny" />
                <Copyright className={styles.copyright} size="small" />
            </div>
        </div>
    );
};

FooterBar.defaultProps = {
    className: "",
};

export default FooterBar;
