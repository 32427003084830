import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import styles from "./ConfirmationModal.module.scss";
import classNames from "classnames";
import ModalHeader from "../ModalHeader/ModalHeader";
import Title from "../Title/Title";
import Separator from "../Separator/Separator";
import Text from "../Text/Text";
import Button from "../Button/Button";

type Props = {
    className: string;
    title: string;
    message: string;
    yesLabel: string;
    noLabel: string;
    onConfirm: () => any;
    onCancel: () => any;
};

const ConfirmationModal = ({ className, title, message, yesLabel, noLabel, onConfirm, onCancel }: Props) => {
    const [isOpen, setOpen] = useState(true);

    const close = useCallback(() => {
        setOpen(false);
        onCancel();
    }, [setOpen, onCancel]);
    const confirm = useCallback(() => {
        setOpen(false);
        onConfirm();
    }, [setOpen, onConfirm]);

    return (
        <Modal
            shouldCloseOnOverlayClick={true}
            testId="ConfirmationModal"
            overlayClassName={styles.overlay}
            className={classNames(styles.container, className)}
            isOpen={isOpen}
        >
            <ModalHeader className={styles.header} color="main" onClose={close}>
                <Title className={classNames(styles.title, styles.headerItem)} label={title} heading="h2" />
                <Separator className={styles.headerItem} orientation="|" />
                <div className={styles.filler} />
            </ModalHeader>
            <div className={styles.contentContainer}>
                <Text className={styles.message} label={message} type="large" />
                <div className={styles.buttonContainer}>
                    <Button className={styles.button} displayType="hollow" label={noLabel} onClick={close} />
                    <Button className={styles.button} displayType="alternative" label={yesLabel} onClick={confirm} />
                </div>
            </div>
        </Modal>
    );
};

ConfirmationModal.defaultProps = {
    className: "",
};

export default ConfirmationModal;
