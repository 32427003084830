import React, { Suspense } from "react";
import "@fontsource/open-sans";
import "@fontsource/patrick-hand";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { createRoot } from "react-dom/client";
import "./styles/main.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n/i18n.ts";
import { initCrashReporting } from "./CrashReporting";
import { UserContextProvider } from "./contexts/UserContext";
import { TempProvider } from "./contexts/TempApiContext";
import { initAnalytics, LoggingErrorBoundary } from "./utils/Analytics";
import ApiProviderCollection from "./contexts/ApiProviderCollection";
import { initCssVariables } from "./utils/CanvasConstants";
import Modal from "react-modal";

initCrashReporting();
initAnalytics();
initCssVariables();
Modal.setAppElement("#root");

const container = document.getElementById("root");
if (!container) {
    throw Error("Container element not found in document");
}

const root = createRoot(container);
root.render(
    <React.Fragment>
        <LoggingErrorBoundary>
            <Suspense fallback="">
                <UserContextProvider>
                    <TempProvider>
                        <ApiProviderCollection>
                            <App />
                        </ApiProviderCollection>
                    </TempProvider>
                </UserContextProvider>
            </Suspense>
            <ToastContainer />
        </LoggingErrorBoundary>
    </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
