import React, { useCallback } from "react";
import styles from "./TempOrganisationManagementPage.module.scss";
import classNames from "classnames";
import { OrganisationsStateContextProvider } from "../../contexts/OrganisationsStateContext";
import TempOrganisationManagementContainer from "../../components/TempOrganisationManagement/TempOrganisationManagementContainer";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Button from "../../components/Button/Button";
import Title from "../../components/Title/Title";
import { useTranslation } from "react-i18next";

type Props = {
    className: string;
};

const TempOrganisationManagementPage = ({ className }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const toSettings = useCallback(() => navigate("/demo-instellingen"), [navigate]);
    return (
        <div className={classNames(styles.container, className)}>
            <Helmet title="Demo-instellingen Organisaties">
                <meta name="description" content="Demo instellingen voor Business Rules Canvas" />
            </Helmet>
            <Button label="Naar instellingen" onClick={toSettings} displayType="alternative" />
            <Title className={classNames(styles.title)} label={t("organisation.title")} heading="h1" />
            <OrganisationsStateContextProvider>
                <TempOrganisationManagementContainer />
            </OrganisationsStateContextProvider>
        </div>
    );
};

TempOrganisationManagementPage.defaultProps = {
    className: "",
};

export default TempOrganisationManagementPage;
