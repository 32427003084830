import React from "react";
import { Arrow } from "react-konva";
import "konva/lib/shapes/Arrow";
import { KonvaEventObject } from "konva/lib/Node";
import { ConnectionType } from "../../../models/ConnectionType";
import { ArrowConfig } from "konva/lib/shapes/Arrow";

type Props = {
    path: number[];
    onClick?: (event: KonvaEventObject<MouseEvent>) => void;
    type: ConnectionType;
};

function getArrowAttributes(type: ConnectionType): Partial<ArrowConfig> {
    switch (type) {
        case ConnectionType.System:
            return {
                strokeWidth: 1,
                pointerAtEnding: true,
                pointerLength: 8,
                pointerWidth: 8,
                lineCap: "round",
                lineJoin: "round",
                tension: 0.1,
                dash: [2, 2],
            };
        case ConnectionType.Domain:
            return {
                strokeWidth: 1,
                pointerAtEnding: false,
                pointerLength: 8,
                pointerWidth: 8,
                dash: [10, 10],
            };
        default:
            return {
                strokeWidth: 1,
                pointerAtEnding: true,
                pointerLength: 8,
                pointerWidth: 8,
            };
    }
}

//@NOTE(Lejun) Temporary function to clearly show arrows which have not been found by pathfinding
function getStrokeColor(path: number[]) {
    return path.length <= 4 ? "#804CE4" : "#061133";
}

const ArrowView = ({ path, onClick, type }: Props) => {
    if (path.length === 0) {
        return null;
    }

    return (
        <Arrow
            points={path}
            stroke={getStrokeColor(path)}
            fill={getStrokeColor(path)}
            name="connection"
            onClick={onClick}
            hitStrokeWidth={10}
            {...getArrowAttributes(type)}
        />
    );
};
ArrowView.defaultProps = {
    type: ConnectionType.Default,
};

export default ArrowView;
