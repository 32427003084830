import { useCallback } from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { CanvasMode } from "../models/CanvasMode";
import { TimelineView } from "../models/TimelineView";
import { TransactionState } from "../openapi/webservice";
import { TRANSACTION_ACTIVE_SEARCHPARAM_KEY, TRANSACTION_SEARCHPARAM_KEY } from "../utils/RouterConstants";
import { useCanvasMode } from "./useCanvasMode";
import { useErrorResponseNotification } from "./useErrorNotification";
import { useCreateNewTransaction, useDeleteAllChanges } from "./useTimelineApi";
import { useTimelineTransactions } from "./useTimelineTransactions";

export function useAddNewTransaction() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [mode] = useCanvasMode();
    const [{ error }, create] = useCreateNewTransaction();
    const [, dispatch] = useTimelineTransactions();
    useErrorResponseNotification(error);

    return useCallback(
        async (name: string, date: Date) => {
            if (mode !== CanvasMode.View) {
                return;
            }

            const created = await create(name, date);
            dispatch((list) => [...list, created]);
            searchParams.set(TRANSACTION_ACTIVE_SEARCHPARAM_KEY, "true");
            searchParams.set(TRANSACTION_SEARCHPARAM_KEY, created.validTimeStart.toISOString());
            setSearchParams(searchParams);
        },
        [create, dispatch, mode, searchParams, setSearchParams],
    );
}

export function useDeleteInProgressTransaction() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [{ error }, _delete] = useDeleteAllChanges();
    const [transactions, dispatch] = useTimelineTransactions();
    useErrorResponseNotification(error);

    return useCallback(async () => {
        const inProgressTransaction = transactions.find((t) => t.state === TransactionState.InProgress);
        if (!inProgressTransaction) {
            return;
        }

        await _delete();

        // Update state after successful deletion
        const nextList = transactions.filter((t) => t.state !== TransactionState.InProgress);
        if (nextList.length === 0) {
            // No transactions left, so back to landing page
            navigate("/plattegrond");
            return;
        }

        dispatch(nextList);

        //Update Route
        searchParams.delete(TRANSACTION_ACTIVE_SEARCHPARAM_KEY);
        const lastTransaction = nextList.at(-1);
        if (!lastTransaction) {
            searchParams.delete(TRANSACTION_ACTIVE_SEARCHPARAM_KEY);
        } else {
            searchParams.set(TRANSACTION_SEARCHPARAM_KEY, lastTransaction.validTimeStart.toISOString());
        }

        setSearchParams(searchParams);
    }, [_delete, dispatch, searchParams, setSearchParams, transactions, navigate]);
}

const blueprintRoute = "/subcontext/:subContextId/plattegrond/:blueprintId";
export function useTimelineView() {
    const isOnBlueprintRoute = useMatch(blueprintRoute);
    const [transactions] = useTimelineTransactions();
    const [searchParams] = useSearchParams();
    const transactionDate = searchParams.get(TRANSACTION_SEARCHPARAM_KEY);
    const lastTransaction = transactions.at(-1);

    if (!isOnBlueprintRoute || !lastTransaction || !transactionDate) {
        return TimelineView.None;
    } else if (lastTransaction.validTimeStart.getTime() !== new Date(transactionDate).getTime()) {
        return TimelineView.None;
    } else if (lastTransaction.state === TransactionState.InProgress) {
        return TimelineView.InProgress;
    } else if (lastTransaction.state === TransactionState.Solution) {
        return TimelineView.Solution;
    }

    return TimelineView.None;
}
