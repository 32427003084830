import classNames from "classnames";
import React, { PropsWithChildren, useCallback } from "react";
import { toast, Slide } from "react-toastify";
import styles from "./WipWrapper.module.scss";

type Props = {
    className: string;
    label?: string;
};

const WipWrapper = ({ className, label, children }: PropsWithChildren<Props>) => {
    const clickBaiter = useCallback(() => {
        toast.warn(
            label
                ? `De functionaliteit "${label}" is onder constructie.`
                : "Deze functionaliteit is onder constructie.",
            {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true,
                transition: Slide,
                bodyClassName: styles.text,
                toastId: `WipWrapper_${label?.replaceAll(" ", "_") || "defaultToast"}`,
            },
        );
    }, [label]);
    return (
        <div className={classNames(styles.wrapper, className)} onClick={clickBaiter}>
            {children}
        </div>
    );
};

WipWrapper.defaultProps = {
    className: "",
};

export default WipWrapper;
