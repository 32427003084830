import React, { InputHTMLAttributes, useMemo } from "react";
import styles from "./DataListInput.module.scss";
import Text from "../Text/Text";
import classNames from "classnames";

export interface DataListInputProps
    extends Omit<React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "multiple"> {
    className: string;
    label: string;
    options: string[];
}

/**
 * An input field that lets a user choose an option from a list that is filtered while typing.
 */
export default function DataListInput({ className, label, options, ...props }: DataListInputProps) {
    const dataListOptions = useMemo(() => options.map((option) => <option key={option} value={option} />), [options]);

    return (
        <div className={classNames(styles.container, className)} data-testid="DataListInputContainer">
            <label>
                <Text label={label} className={styles.text} />
                <input list={label} {...props} className={styles.input} data-testid="DataListInputInput" />
                <datalist id={label} data-testid="DataListInputDataList">
                    {dataListOptions}
                </datalist>
            </label>
        </div>
    );
}
