/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConnectionInputModel
 */
export interface ConnectionInputModel {
    /**
     * 
     * @type {string}
     * @memberof ConnectionInputModel
     */
    fromCellId: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionInputModel
     */
    toCellId: string;
}

export function ConnectionInputModelFromJSON(json: any): ConnectionInputModel {
    return ConnectionInputModelFromJSONTyped(json, false);
}

export function ConnectionInputModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectionInputModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fromCellId': json['fromCellId'],
        'toCellId': json['toCellId'],
    };
}

export function ConnectionInputModelToJSON(value?: ConnectionInputModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fromCellId': value.fromCellId,
        'toCellId': value.toCellId,
    };
}


