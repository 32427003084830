import React from "react";
import styles from "./Icon.module.scss";
import classNames from "classnames";

type Props = {
    className: string;
    src: string;
};

const Icon = ({ className, src }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <img className={styles.image} src={src} alt="Icon" />
        </div>
    );
};

Icon.defaultProps = {
    className: "",
};

export default Icon;
