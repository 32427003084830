import React from "react";
import styles from "./RoundImage.module.scss";
import classNames from "classnames";

type Size = "normal" | "small";

type Props = {
    className: string;
    src: string;
    size: Size;
};

const RoundImage = ({ className, src, size }: Props) => {
    return (
        <div
            style={{ backgroundImage: `url(${src})` }}
            className={classNames(styles.container, styles[size], className)}
        />
    );
};

RoundImage.defaultProps = {
    className: "",
    size: "normal",
};

export default RoundImage;
