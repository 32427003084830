import React, { ComponentProps } from "react";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Image";
import { Group, Rect, Image } from "react-konva";
import { ICON_SIZE } from "../../utils/CanvasConstants";

export type ActionIconProps = ComponentProps<typeof Group> & {
    src?: CanvasImageSource;
    index: number;
    referenceHeight: number;
};

export const ActionIcon = ({ src, index, referenceHeight, ...props }: ActionIconProps) => {
    return (
        <Group
            x={-ICON_SIZE / 2 + index * (ICON_SIZE + 4)}
            y={referenceHeight - ICON_SIZE / 2}
            height={ICON_SIZE}
            width={ICON_SIZE}
            {...props}
        >
            <Rect
                x={0}
                y={0}
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill="#fff"
                shadowEnabled
                shadowBlur={8}
                shadowOpacity={0.7}
                shadowOffset={{ x: 3, y: 3 }}
            />
            <Image image={src} x={4} y={4} height={ICON_SIZE - 8} width={ICON_SIZE - 8} />
        </Group>
    );
};
