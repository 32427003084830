/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LoginInputModel,
    LoginInputModelFromJSON,
    LoginInputModelToJSON,
    TokenReponse,
    TokenReponseFromJSON,
    TokenReponseToJSON,
} from '../models';

export interface LoginRequest {
    loginInputModel?: LoginInputModel;
}

/**
 * no description
 */
export class AuthenticationApi extends runtime.BaseAPI {
    /**
     * Attempt to login.
     */
    async loginRaw(requestParameters: LoginRequest): Promise<runtime.ApiResponse<TokenReponse>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Authentication/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginInputModelToJSON(requestParameters.loginInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TokenReponseFromJSON(jsonValue));
    }
    /**
     * Attempt to login.
     */
    async login(requestParameters: LoginRequest): Promise<TokenReponse> {
        const response = await this.loginRaw(requestParameters);
        return await response.value();
    }
}
