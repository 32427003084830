import React, { useCallback, useEffect, useMemo, useState } from "react";
import "konva/lib/shapes/Image";
import "konva/lib/shapes/Text";
import styles from "./MemoNote.module.scss";
import { Group, Image, Text } from "react-konva";
import { MemoType } from "../../openapi/webservice/models/MemoType";
import { CalendarIcon, ChatAddIcon, ChatIcon } from "../../assets/images/Images";
import { KonvaEventObject } from "konva/lib/Node";
import useImage from "use-image";
import { Html } from "../../konva/Html";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { MEMO_SIZE, ICON_SIZE } from "../../utils/CanvasConstants";
import { getBoundedXForMemoCanvas, getBoundedYForMemoCanvas } from "../../utils/CanvasUtils";
import { CanvasMode } from "../../models/CanvasMode";
import { getMemoBackground, isViewingInheritance } from "../../utils/MemoUtils";
import { useFontObserver } from "../../hooks/useFontObserver";
import { MemoViewOption } from "../../models/MemoViewOption";
import TrashButton from "../KonvaShared/TrashButton";
import { useKonvaHovering } from "../../hooks/useKonvaEvents";
import { ActionIcon } from "../konva-shared/ActionIcon";
import { InheritanceType } from "../../openapi/webservice";
import { CellRedirectButton } from "../konva-shared/CellRedirectButton";
import { getCellName, MemoLikeModel } from "../../models/MemoLikeViewModel";

const Shadows = {
    [MemoType.Computations]: { shadowBlur: 6, shadowOpacity: 0.3, shadowOffset: { x: 3, y: 6 } },
    [MemoType.Interfaces]: { shadowBlur: 8, shadowOpacity: 0.35, shadowOffset: { x: 3, y: 8 } },
    [MemoType.Mutations]: { shadowBlur: 12, shadowOpacity: 0.35, shadowOffset: { x: 4, y: 20 } },
    [MemoType.Processes]: { shadowBlur: 6, shadowOpacity: 0.2, shadowOffset: { x: 6, y: 7 } },
    [MemoType.Tables]: { shadowBlur: 12, shadowOpacity: 0.4, shadowOffset: { x: 1, y: 6 } },
    [MemoType.Validations]: { shadowBlur: 6, shadowOpacity: 0.3, shadowOffset: { x: 0, y: 8 } },
};

type Props = {
    note: MemoLikeModel;
    onChange?: (note: MemoLikeModel) => void;
    onDelete?: (note: MemoLikeModel) => void;
    onRedirectToCell?: (note: MemoLikeModel) => void;
    openMeta?: (note: MemoLikeModel, tab: MemoViewOption) => void;
    mode: CanvasMode;
    inheritance?: InheritanceType;
};

const padding = 2;
const textAreaStyle = { width: MEMO_SIZE, height: MEMO_SIZE, padding: padding };

const MemoNote = ({ note, onChange, onDelete, mode, openMeta, onRedirectToCell, inheritance }: Props) => {
    const { type, xPosition, yPosition, rotationInDegrees, name, hasChat, hasPlanning } = note;
    const { t } = useTranslation();
    const [memoImage] = useImage(getMemoBackground(type!));
    const [chatIcon] = useImage(ChatIcon);
    const [addChatIcon] = useImage(ChatAddIcon);
    const [planningIcon] = useImage(CalendarIcon);
    const [value, setValue] = useState(name || "");
    const [isEditing, setIsEditing] = useState(false);
    const isFontLoaded = useFontObserver("Patrick Hand");
    const [isHovering, setIsHovering] = useState(false);
    const isEditMode = useMemo(() => mode === CanvasMode.Edit, [mode]);
    const { onEnter, onLeave } = useKonvaHovering(isEditMode, setIsHovering);

    useEffect(() => {
        //update name when it comes from a different source (aka modal)
        setValue(name);
    }, [name]);

    const onTextChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((event) => {
        const text = event.currentTarget.value.trim();
        setValue(text);
    }, []);

    const onDragEnd = useCallback<(event: KonvaEventObject<DragEvent>) => void>(
        (event) => {
            if (!onChange) {
                return;
            }

            const next = produce(note, (draft) => {
                draft.name = value;
                draft.xPosition = event.target.x();
                draft.yPosition = event.target.y();
            });
            onChange(next);
        },
        [onChange, note, value],
    );

    const startEditMode = useCallback(() => setIsEditing(true), []);
    const endEditMode = useCallback(() => {
        setIsEditing(false);
        setIsHovering(false);

        if (onChange && note.name !== value) {
            const next = produce(note, (draft) => {
                draft.name = value;
            });
            onChange(next);
        }
    }, [note, value, onChange]);

    const deleteCallback = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            e.cancelBubble = true;

            if (window.confirm(t("memoboard.delete.confirm", { name: note.name })) && onDelete) {
                onDelete(note);
            }
        },
        [onDelete, note, t],
    );

    const redirectCallback = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            e.cancelBubble = true;
            onRedirectToCell && onRedirectToCell(note);
        },
        [onRedirectToCell, note],
    );

    const onDragMove = useCallback((e: KonvaEventObject<DragEvent>) => {
        // Konva requires bound to be handled from the `dragmove` event
        // Manipulating via the props doesn't work, because constantly bounding xPosition to -55 for example
        // won't trigger a rerender since the value didn't change, but on the canvas it did change.
        e.target.x(getBoundedXForMemoCanvas(e.target.x()));
        e.target.y(getBoundedYForMemoCanvas(e.target.y()));
    }, []);

    const shouldRenderRedirectToCell = useMemo(
        () => !isEditMode && isViewingInheritance(inheritance),
        [inheritance, isEditMode],
    );
    const shouldRenderDelete = useMemo(() => isEditMode, [isEditMode]);
    const shouldRenderMeta = useMemo(
        () => !isEditMode && !shouldRenderRedirectToCell,
        [isEditMode, shouldRenderRedirectToCell],
    );

    return (
        <Group
            draggable={isEditMode}
            x={xPosition}
            y={yPosition}
            rotation={rotationInDegrees}
            listening={isFontLoaded}
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
            onDragEnd={onDragEnd}
            onDragMove={onDragMove}
        >
            <Image
                x={0}
                y={0}
                image={memoImage}
                height={MEMO_SIZE}
                width={MEMO_SIZE}
                shadowEnabled
                {...Shadows[type]}
            />
            {isEditing ? (
                <Html>
                    <textarea
                        autoFocus
                        className={styles.input}
                        defaultValue={value}
                        onChange={onTextChange}
                        onBlur={endEditMode}
                        onFocus={(e) => e.currentTarget.select()}
                        rows={5}
                        style={textAreaStyle}
                    />
                </Html>
            ) : (
                isFontLoaded && (
                    <Text
                        x={0}
                        y={0}
                        height={MEMO_SIZE}
                        width={MEMO_SIZE}
                        text={value}
                        onClick={isEditMode ? startEditMode : undefined}
                        fontFamily="'Patrick Hand'"
                        align="center"
                        // verticalAlign="top"
                        // fontSize={14}
                        verticalAlign="middle"
                        fontSize={16}
                        ellipsis={true}
                        wrap="word"
                        padding={padding}
                        fill="#061133"
                    />
                )
            )}
            {shouldRenderDelete && (
                <TrashButton
                    x={MEMO_SIZE - ICON_SIZE / 2}
                    y={-ICON_SIZE / 2}
                    visible={!!onDelete && isHovering && !isEditing}
                    onClick={deleteCallback}
                />
            )}
            {shouldRenderMeta && (
                <>
                    <ActionIcon
                        src={hasChat ? chatIcon : addChatIcon}
                        index={0}
                        visible={isHovering}
                        referenceHeight={MEMO_SIZE}
                        onClick={() => {
                            openMeta && openMeta(note, MemoViewOption.CHAT);
                        }}
                    />
                    {hasPlanning && (
                        <ActionIcon
                            src={planningIcon}
                            index={1}
                            visible={isHovering}
                            referenceHeight={MEMO_SIZE}
                            onClick={() => {
                                openMeta && openMeta(note, MemoViewOption.PLANNING);
                            }}
                        />
                    )}
                </>
            )}

            {shouldRenderRedirectToCell && (
                <CellRedirectButton
                    x={(-ICON_SIZE * 1.3) / 2}
                    y={MEMO_SIZE - ICON_SIZE * 1.6}
                    width={MEMO_SIZE + ICON_SIZE * 1.3}
                    visible={isHovering}
                    onClick={redirectCallback}
                    name={getCellName(note)}
                    type={note.type}
                />
            )}
        </Group>
    );
};

MemoNote.defaultProps = {};

export default MemoNote;
