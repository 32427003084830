import React from "react";
import { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import { getInitialState, MemosState, MemosStateAction, MemosStateReducer } from "../reducers/MemosStateReducer";

export const MemosStateContext = createContext<[MemosState, Dispatch<MemosStateAction>]>([getInitialState(), () => {}]);

export const MemosStateContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const reducer = useReducer<typeof MemosStateReducer>(MemosStateReducer, getInitialState());

    return <MemosStateContext.Provider value={reducer}>{children}</MemosStateContext.Provider>;
};
