import React from "react";
import { useContextStateChanges } from "../../hooks/useContextsStateChanges";
import { DesignContextViewModel } from "../../openapi/webservice";
import ContextModal from "./ContextModal";

type Props = {
    className: string;
    isOpen: boolean;
    onClose?: () => void;
    list: DesignContextViewModel[];
};

const ContextModalContainer = ({ className, isOpen, onClose, list }: Props) => {
    const { createSubContext } = useContextStateChanges();

    return (
        <ContextModal className={className} isOpen={isOpen} onClose={onClose} submit={createSubContext} list={list} />
    );
};

ContextModalContainer.defaultProps = {
    className: "",
};

export default ContextModalContainer;
