import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./CompactBlueprint.module.scss";
import classNames from "classnames";
import { BlueprintViewModel, CellType, ConnectionViewModel } from "../../openapi/webservice";
import { useTranslation } from "react-i18next";
import Text from "../Text/Text";
import { BlueprintConnectionDownIcon, BlueprintConnectionRightIcon, CrossWhiteIcon } from "../../assets/images/Images";
import { useBlueprintId, useCellId, useSubContextId } from "../../hooks/useRouteParams";
import { getContainerBackgroundColor, isInteractiveConnection } from "../../utils/ContainerUtils";
import { createSearchParams, To, useNavigate, useSearchParams } from "react-router-dom";
import { getMemoboardRoute, getTransactionSearchParams } from "../../utils/RouteUtils";
import Title from "../Title/Title";
import ImageButton from "../ImageButton/ImageButton";
import { isExternalSystemConnection } from "../../utils/CanvasUtils";

type EnhancedConnectionViewModel = ConnectionViewModel & {
    containerName: string;
    cellName: string;
    link: To;
    cellType: CellType;
};

type Props = {
    className: string;
    blueprint: BlueprintViewModel;
};

const CompactBlueprint = ({ className, blueprint }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const selectedRef = useRef<HTMLDivElement>(null);
    const subContextId = useSubContextId();
    const blueprintId = useBlueprintId();
    const [searchParams] = useSearchParams();
    const cellId = useCellId();
    const [connectionOptions, setConnectionOptions] = useState<Array<EnhancedConnectionViewModel>>([]);

    const container = useMemo(() => {
        const selectedContainer = blueprint.containers.find((c) => c.cells.find((cell) => cell.id === cellId));
        const containers = blueprint.containers;
        const cells = containers.flatMap((c) => c.cells);
        const connections = blueprint.connections
            .filter((c) => isInteractiveConnection(c, containers))
            .filter((c) => !isExternalSystemConnection(c, containers));
        const enhancedContainer = selectedContainer
            ? {
                  ...selectedContainer,
                  cells: selectedContainer.cells.map((cell) => {
                      const incoming = connections
                          .filter((connection) => connection.toCellId === cell.id)
                          .map((connection) => {
                              const cellOut = cells.find((c) => c.id === connection.fromCellId);
                              const containerOut = containers.find((c) => c.id === cellOut?.containerId);
                              const link: To = {
                                  pathname: getMemoboardRoute(subContextId, blueprintId, cellOut?.id),
                                  search: createSearchParams(getTransactionSearchParams(searchParams)).toString(),
                              };
                              return {
                                  ...connection,
                                  containerName: containerOut?.name || "",
                                  cellName: cellOut?.name || "",
                                  link,
                                  cellType: containerOut?.cellType,
                              } as EnhancedConnectionViewModel;
                          });
                      const outgoing = connections
                          .filter((connection) => connection.fromCellId === cell.id)
                          .map((connection) => {
                              const cellIn = cells.find((c) => c.id === connection.toCellId);
                              const containerIn = containers.find((c) => c.id === cellIn?.containerId);
                              const link: To = {
                                  pathname: getMemoboardRoute(subContextId, blueprintId, cellIn?.id),
                                  search: createSearchParams(getTransactionSearchParams(searchParams)).toString(),
                              };
                              return {
                                  ...connection,
                                  containerName: containerIn?.name || "",
                                  cellName: cellIn?.name || "",
                                  link,
                                  cellType: containerIn?.cellType,
                              } as EnhancedConnectionViewModel;
                          });
                      const link: To = {
                          pathname: getMemoboardRoute(subContextId, blueprintId, cell?.id),
                          search: createSearchParams(getTransactionSearchParams(searchParams)).toString(),
                      };

                      return { ...cell, incoming, outgoing, link };
                  }),
              }
            : undefined;

        return enhancedContainer;
    }, [blueprint, searchParams, blueprintId, subContextId, cellId]);

    useEffect(() => {
        if (!container || !selectedRef.current) {
            return;
        }

        selectedRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }, [selectedRef, container]);

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.scrollContainer}>
                {container && (
                    <div
                        key={container.id}
                        className={styles.containerElement}
                        style={{ background: getContainerBackgroundColor(container.cellType) }}
                    >
                        <Text className={styles.title} type="bold" label={container.name} />
                        {container.cells.map((cell, index) => {
                            const isFirst = index === 0;
                            const isSelected = cell.id === cellId;
                            return (
                                <React.Fragment key={cell.id}>
                                    {!isFirst && (
                                        <img
                                            src={BlueprintConnectionDownIcon}
                                            className={styles.innerConnection}
                                            alt={`Connection down-${index}`}
                                            onClick={() => {
                                                if (isSelected) {
                                                    return;
                                                }

                                                navigate(cell.link);
                                            }}
                                            data-testid={`InnerConnection-${index}`}
                                        />
                                    )}
                                    <div className={classNames(styles.block, isSelected && styles.selected)}>
                                        <div
                                            ref={isSelected ? selectedRef : undefined}
                                            className={classNames(styles.cell)}
                                            onClick={() => {
                                                if (isSelected) {
                                                    return;
                                                }

                                                navigate(cell.link);
                                            }}
                                            data-testid={`Cell-${index}`}
                                        >
                                            <Text
                                                className={styles.cellName}
                                                label={cell.name}
                                                type={isSelected ? "bold" : "body"}
                                            />
                                        </div>
                                        {cell.incoming.length > 0 && (
                                            <img
                                                src={BlueprintConnectionRightIcon}
                                                className={classNames(styles.outerConnection, styles.incoming)}
                                                alt={`Connections incoming: ${cell.incoming.length}`}
                                                onClick={() => {
                                                    if (cell.incoming.length > 1) {
                                                        setConnectionOptions(cell.incoming);
                                                    } else {
                                                        navigate(cell.incoming[0].link);
                                                    }
                                                }}
                                                data-testid="IncomingConnection"
                                            />
                                        )}
                                        {cell.outgoing.length > 0 && (
                                            <img
                                                src={BlueprintConnectionRightIcon}
                                                className={classNames(styles.outerConnection, styles.outgoing)}
                                                alt={`Connections outgoing: ${cell.outgoing.length}`}
                                                onClick={() => {
                                                    if (cell.outgoing.length > 1) {
                                                        setConnectionOptions(cell.outgoing);
                                                    } else {
                                                        navigate(cell.outgoing[0].link);
                                                    }
                                                }}
                                                data-testid="OutgoingConnection"
                                            />
                                        )}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}
            </div>
            {connectionOptions.length > 0 && (
                <div className={styles.options}>
                    <div className={styles.overlay} />
                    <div className={styles.scroller}>
                        <div className={styles.optionsContainer}>
                            <Title
                                className={styles.label}
                                label={`${t("memoboard.sidebar.connection.to")}:`}
                                heading="h2"
                            />
                            <ImageButton
                                className={styles.close}
                                src={CrossWhiteIcon}
                                rounded={true}
                                background="dark"
                                onClick={() => setConnectionOptions([])}
                                data-testid="CloseConnectionOptions"
                            />
                            {connectionOptions.map((m, index) => {
                                return (
                                    <div
                                        key={`option-${m.id}`}
                                        className={styles.containerElement}
                                        onClick={() => {
                                            navigate(m.link);
                                            setConnectionOptions([]);
                                        }}
                                        data-testid={`ConnectionOption-${index}`}
                                        style={{ background: getContainerBackgroundColor(m.cellType) }}
                                    >
                                        <Text className={styles.title} type="bold" label={m.containerName} />
                                        <div className={classNames(styles.block)}>
                                            <div className={classNames(styles.cell)}>
                                                <Text className={styles.cellName} label={m.cellName} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

CompactBlueprint.defaultProps = {
    className: "",
};

export default CompactBlueprint;
