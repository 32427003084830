import React from "react";
import styles from "./PoweredBy.module.scss";
import classNames from "classnames";
import { FooterLogoDark, FooterLogoWhite, SixblocksLogoAnimatedDark } from "../../assets/images/Images";

type Theme = "light" | "dark";
type Size = "normal" | "small";

type Props = {
    className: string;
    theme: Theme;
    size: Size;
    animated: boolean;
};

function getThemeStyle(theme: Theme) {
    switch (theme) {
        case "dark":
            return styles.dark;
        case "light":
            return styles.light;
    }
}

function getLogo(theme: Theme, isAnimated: boolean) {
    switch (true) {
        case theme === "light" && isAnimated:
            return SixblocksLogoAnimatedDark;
        case theme === "dark":
            return FooterLogoWhite;
        case theme === "light":
            return FooterLogoDark;
    }
}

function getSizeStyle(size: Size) {
    switch (size) {
        case "normal":
            return styles.normalSize;
        case "small":
            return styles.smallSize;
    }
}

const PoweredBy = ({ className, size, theme, animated }: Props) => {
    return (
        <div
            className={classNames(
                styles.container,
                getThemeStyle(theme),
                getSizeStyle(size),
                animated && styles.animated,
                className,
            )}
        >
            <p className={styles.text}>Powered by</p>
            <img className={styles.logo} src={getLogo(theme, animated)} alt="Logo SixBlocks" />
        </div>
    );
};

PoweredBy.defaultProps = {
    className: "",
    size: "normal",
    theme: "dark",
    animated: false,
};

export default PoweredBy;
