import React, { useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { HELP_MODAL_KEY } from "../../utils/RouterConstants";
import HelpModal from "./HelpModal";

const HelpModalContainer = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get(HELP_MODAL_KEY);

    const onClose = useCallback(() => {
        const next = new URLSearchParams(searchParams.toString());
        next.delete(HELP_MODAL_KEY);
        setSearchParams(next, { replace: true });
    }, [setSearchParams, searchParams]);

    return <HelpModal type={type as any} isOpen={!!type} onClose={onClose} />;
};

export default HelpModalContainer;
