import React, { useCallback } from "react";
import { DesignContextViewModel } from "../../openapi/webservice";
import RoughContextManagementInput from "./RoughContextManagementInput";
import { useAdminContextStateChanges } from "../../hooks/useAdminContextsStateChanges";

type Props = {
    className: string;
    context: DesignContextViewModel;
    organisationId: string;
};

// @NOTE(Lejun) As the name suggests, this is a rough component used for some internal usage.
//              In later versions this should definitely be replaced.
const TempDesignContextManagementInput = ({ className, context: { id, name }, organisationId }: Props) => {
    const { updateDesignContext } = useAdminContextStateChanges();

    const onSave = useCallback(
        (value: string) => {
            updateDesignContext(organisationId, id, { name: value });
        },
        [updateDesignContext, id, organisationId],
    );

    return <RoughContextManagementInput title="" name={name} className={className} saveCallback={onSave} />;
};

TempDesignContextManagementInput.defaultProps = {
    className: "",
};

export default TempDesignContextManagementInput;
