import React, { useCallback, useState } from "react";
import { useOrganisationsStateChanges } from "../../hooks/useOrganisationsStateChanges";
import RoughAddOrganisationInput from "./RoughAddOrganisationInput";

type Props = {
    className: string;
};

// @NOTE(Lejun) As the name suggests, this is a rough component used for some internal usage.
//              In later versions this should definitely be replaced.
const TempAddOrganisationInputContainer = ({ className }: Props) => {
    const { create } = useOrganisationsStateChanges();
    const [version, setVersion] = useState(1);

    const onSave = useCallback(
        async (name: string, logoUri: string) => {
            await create({
                name,
                logoUri,
                defaultTheme: {
                    cellColor: "#F5F5F5",
                    clusterColor: "#F98525",
                    connectionColor: "#061133",
                    domainColor: "#28B6EB",
                },
            });
            setVersion((value) => (value + 1) % 1000);
        },
        [create],
    );

    return <RoughAddOrganisationInput key={version} className={className} saveCallback={onSave} />;
};

TempAddOrganisationInputContainer.defaultProps = {
    className: "",
};

export default TempAddOrganisationInputContainer;
