import Konva from "konva";
import { useCallback, useEffect } from "react";
import { CanvasMode } from "../models/CanvasMode";
import { DragType } from "../models/DragType";
import {
    CellInputModel,
    CellType,
    ContainerInputModel,
    ContainerViewModel,
    DoubleDoubleTuple,
    UpdateContainerPositionsRequest,
} from "../openapi/webservice";
import { ActionType, BlueprintStateAction } from "../reducers/BlueprintStateReducer";
import {
    CONTAINER_CENTER_X,
    CONTAINER_CENTER_Y,
    EXTERNAL_SYSTEM_CENTER_X,
    EXTERNAL_SYSTEM_CENTER_Y,
} from "../utils/CanvasConstants";
import {
    applyCollisionPhysics,
    getBoundedXForBlueprintCanvas,
    getBoundedYForBlueprintCanvas,
    getCellIndexAtPosition,
    getContainerAtPosition,
    getRelativeCanvasPoint,
    isExternalSystem,
} from "../utils/CanvasUtils";
import { BLUEPRINT_DRAG_TYPE_KEY, BLUEPRINT_DRAG_VALUE_KEY } from "../utils/DragAndDropUtils";
import { RequestState } from "./useApiCall";
import {
    useDeleteCell,
    useDeleteConnection,
    useDeleteContainer,
    useGetBlueprint,
    useInsertCell,
    useInsertConnection,
    useInsertContainer,
    useUpdateCellName,
    useUpdateContainerName,
    useUpdateContainerDescription,
    useUpdateContainerPosition,
    useUpdateContainerPositionBulk,
} from "./useBlueprintsApi";
import { useBlueprintState } from "./useBlueprintState";
import { useCanvasMode } from "./useCanvasMode";
import { useErrorResponseNotification } from "./useErrorNotification";
import { useBlueprintId } from "./useRouteParams";

export function useFetchBlueprint() {
    const [, dispatch] = useBlueprintState();
    const { state, value } = useGetBlueprint();

    useEffect(() => {
        if (state !== RequestState.DONE) {
            return;
        }

        dispatch({ type: ActionType.set, value });
    }, [state, value, dispatch]);
    return value;
}

// Temporary, remove after bulk move api call is implemented
async function updateBulkMove(
    containers: ContainerViewModel[],
    updated: ContainerViewModel,
    blueprintId: string,
    updateBulk: (requestParameters: UpdateContainerPositionsRequest) => Promise<ContainerViewModel[]>,
    dispatch: (value: BlueprintStateAction) => void,
) {
    const phy = applyCollisionPhysics(containers, updated);

    const updatedList = await updateBulk({
        blueprintId,
        containerPositionBulkUpdateInputModel: { inputs: phy },
    });

    dispatch({ type: ActionType.setContainers, value: updatedList });
}

export function useCreateContainer() {
    const [mode] = useCanvasMode();
    const blueprintId = useBlueprintId();
    const [{ error }, createContainer] = useInsertContainer();
    const [{ error: errorUpdateBulk }, updateBulk] = useUpdateContainerPositionBulk();
    const [state, dispatch] = useBlueprintState();
    useErrorResponseNotification(error);
    useErrorResponseNotification(errorUpdateBulk);

    return useCallback(
        async (event: React.DragEvent<HTMLDivElement>, stage: Konva.Stage) => {
            const data = event.dataTransfer.getData(BLUEPRINT_DRAG_VALUE_KEY);
            if (!Object.values(CellType).includes(data as any) || mode !== CanvasMode.Edit) {
                return;
            }

            const center = isExternalSystem(data as CellType)
                ? { x: EXTERNAL_SYSTEM_CENTER_X, y: EXTERNAL_SYSTEM_CENTER_Y }
                : { x: CONTAINER_CENTER_X, y: CONTAINER_CENTER_Y };

            const position = getRelativeCanvasPoint(stage, event);
            const input: ContainerInputModel = {
                name: "",
                description: "",
                cellType: data as CellType,
                xPosition: getBoundedXForBlueprintCanvas(position.x - center.x),
                yPosition: getBoundedYForBlueprintCanvas(position.y - center.y),
            };

            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }
            const created = await createContainer({ blueprintId, containerInputModel: input });
            updateBulkMove(state.containers, created, blueprintId, updateBulk, dispatch);
        },
        [blueprintId, createContainer, dispatch, mode, state, updateBulk],
    );
}

export function useCreateCell() {
    const [mode] = useCanvasMode();
    const blueprintId = useBlueprintId();
    const [{ error }, createCell] = useInsertCell();
    const [{ containers }, dispatch] = useBlueprintState();
    const [{ error: errorUpdateBulk }, updateBulk] = useUpdateContainerPositionBulk();
    useErrorResponseNotification(error);
    useErrorResponseNotification(errorUpdateBulk);

    return useCallback(
        async (event: React.DragEvent<HTMLDivElement>, stage: Konva.Stage) => {
            const data = event.dataTransfer.getData(BLUEPRINT_DRAG_TYPE_KEY);
            if (data !== DragType.Cell || mode !== CanvasMode.Edit) {
                return;
            }

            const position = getRelativeCanvasPoint(stage, event);
            // get first hit
            const container = getContainerAtPosition(containers, position);

            // Dropped on nothing or on external system
            if (!container || isExternalSystem(container)) {
                return;
            }

            const input: CellInputModel = {
                name: "",
                index: getCellIndexAtPosition(container, position),
            };

            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }
            const created = await createCell({ blueprintId, containerId: container.id, cellInputModel: input });
            updateBulkMove(containers, created, blueprintId, updateBulk, dispatch);
        },
        [blueprintId, createCell, dispatch, mode, containers, updateBulk],
    );
}

export function useMoveContainer() {
    const [mode] = useCanvasMode();
    const blueprintId = useBlueprintId();
    const [{ error: errorUpdate }, update] = useUpdateContainerPosition();
    const [{ error: errorUpdateBulk }, updateBulk] = useUpdateContainerPositionBulk();
    const [state, dispatch] = useBlueprintState();
    useErrorResponseNotification(errorUpdate);
    useErrorResponseNotification(errorUpdateBulk);

    return useCallback(
        async (containerId: string, position: DoubleDoubleTuple) => {
            if (mode !== CanvasMode.Edit) {
                return;
            }

            const input: DoubleDoubleTuple = {
                item1: getBoundedXForBlueprintCanvas(position.item1!),
                item2: getBoundedYForBlueprintCanvas(position.item2!),
            };

            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }

            const updated = await update({ blueprintId, containerId, doubleDoubleTuple: input });
            updateBulkMove(state.containers, updated, blueprintId, updateBulk, dispatch);
        },
        [blueprintId, update, dispatch, mode, state, updateBulk],
    );
}

export function useCreateConnection() {
    const [mode] = useCanvasMode();
    const blueprintId = useBlueprintId();
    const [{ error }, insert] = useInsertConnection();
    const [, dispatch] = useBlueprintState();
    useErrorResponseNotification(error);

    return useCallback(
        async (fromCellId: string, toCellId: string) => {
            if (mode !== CanvasMode.Edit) {
                return;
            }

            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }
            const created = await insert({ blueprintId, connectionInputModel: { fromCellId, toCellId } });
            dispatch({ type: ActionType.insertConnection, value: created });
        },
        [blueprintId, insert, dispatch, mode],
    );
}

export function useRemoveConnection() {
    const [mode] = useCanvasMode();
    const blueprintId = useBlueprintId();
    const [{ error }, _delete] = useDeleteConnection();
    const [, dispatch] = useBlueprintState();
    useErrorResponseNotification(error);

    return useCallback(
        async (connectionId: string) => {
            if (mode !== CanvasMode.Edit) {
                return;
            }

            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }
            await _delete({ blueprintId, connectionId });
            dispatch({ type: ActionType.deleteConnection, value: connectionId });
        },
        [blueprintId, _delete, dispatch, mode],
    );
}

export function useRenameContainer() {
    const [mode] = useCanvasMode();
    const blueprintId = useBlueprintId();
    const [, update] = useUpdateContainerName();
    const [, dispatch] = useBlueprintState();

    return useCallback(
        async (containerId: string, name: string) => {
            if (mode !== CanvasMode.Edit) {
                return;
            }

            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }
            const updated = await update({ blueprintId, containerId, body: name });
            dispatch({ type: ActionType.updateContainer, value: updated });
        },
        [blueprintId, update, dispatch, mode],
    );
}

export function useChangeContainerDescription() {
    const blueprintId = useBlueprintId();
    const [{ error }, update] = useUpdateContainerDescription();
    const [, dispatch] = useBlueprintState();
    useErrorResponseNotification(error);

    return useCallback(
        async (containerId: string, description: string) => {
            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }
            const updated = await update({ blueprintId, containerId, body: description });
            dispatch({ type: ActionType.updateContainer, value: updated });
        },
        [blueprintId, update, dispatch],
    );
}

export function useRenameCell() {
    const [mode] = useCanvasMode();
    const blueprintId = useBlueprintId();
    const [{ error }, update] = useUpdateCellName();
    const [, dispatch] = useBlueprintState();
    useErrorResponseNotification(error);

    return useCallback(
        async (cellId: string, name: string) => {
            if (mode !== CanvasMode.Edit) {
                return;
            }

            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }
            const updated = await update({ blueprintId, cellId, body: name });
            dispatch({ type: ActionType.updateContainer, value: updated });
        },
        [blueprintId, update, dispatch, mode],
    );
}

export function useRemoveContainer() {
    const [mode] = useCanvasMode();
    const blueprintId = useBlueprintId();
    const [{ error }, _delete] = useDeleteContainer();
    const [, dispatch] = useBlueprintState();
    useErrorResponseNotification(error);

    return useCallback(
        async (containerId: string) => {
            if (mode !== CanvasMode.Edit) {
                return;
            }

            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }
            const blueprint = await _delete({ blueprintId, containerId });
            dispatch({ type: ActionType.set, value: blueprint });
        },
        [blueprintId, _delete, dispatch, mode],
    );
}

export function useRemoveCell() {
    const [mode] = useCanvasMode();
    const blueprintId = useBlueprintId();
    const [{ error }, _delete] = useDeleteCell();
    const [, dispatch] = useBlueprintState();
    useErrorResponseNotification(error);

    return useCallback(
        async (cellId: string) => {
            if (mode !== CanvasMode.Edit) {
                return;
            }

            if (!blueprintId) {
                throw new Error("Missing blueprint id");
            }
            const blueprint = await _delete({ blueprintId, cellId });
            dispatch({ type: ActionType.set, value: blueprint });
        },
        [blueprintId, _delete, dispatch, mode],
    );
}
