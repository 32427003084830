import React, { useCallback } from "react";
import { OrganisationViewModel } from "../../openapi/webservice";
import RoughOrganisationManagementInput from "./RoughOrganisationManagementInput";
import { useOrganisationsStateChanges } from "../../hooks/useOrganisationsStateChanges";

type Props = {
    className: string;
    organisation: OrganisationViewModel;
};

// @NOTE(Lejun) As the name suggests, this is a rough component used for some internal usage.
//              In later versions this should definitely be replaced.
const TempOrganisationManagementInput = ({ className, organisation: { id, name, logoUri, defaultTheme } }: Props) => {
    const { update, _delete } = useOrganisationsStateChanges();

    const onSave = useCallback(
        (organisationName: string, uri: string) => {
            update(id, { defaultTheme, name: organisationName, logoUri: uri });
        },
        [update, id, defaultTheme],
    );

    const onDelete = useCallback(() => {
        _delete(id);
    }, [_delete, id]);

    return (
        <RoughOrganisationManagementInput
            name={name}
            uri={logoUri}
            className={className}
            saveCallback={onSave}
            deleteCallback={onDelete}
        />
    );
};

TempOrganisationManagementInput.defaultProps = {
    className: "",
};

export default TempOrganisationManagementInput;
