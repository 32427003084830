import * as d3 from "d3";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { TransactionViewModel } from "../openapi/webservice";
import { TRANSACTION_ACTIVE_SEARCHPARAM_KEY, TRANSACTION_SEARCHPARAM_KEY } from "../utils/RouterConstants";
import { getTransactionSearchParams } from "../utils/RouteUtils";
import { TICK_INTERVAL, MONTH_IN_MILLIS } from "../utils/TimelineConstants";
import { isInProgress } from "../utils/TransactionUtils";
import { useMultiSearchParamsState } from "./useSearchParamsState";

type Model = {
    [TRANSACTION_SEARCHPARAM_KEY]: string | undefined;
    [TRANSACTION_ACTIVE_SEARCHPARAM_KEY]: boolean | undefined;
};

export function useTimeline(transactions: Array<TransactionViewModel>) {
    const [searchParams] = useSearchParams();
    const { atDate: atDateString } = getTransactionSearchParams(searchParams);

    const lastTransaction = useMemo(
        () =>
            transactions.reduce<TransactionViewModel | undefined>((current, next) => {
                if (!current) {
                    return next;
                }

                return current.validTimeStart > next.validTimeStart ? current : next;
            }, undefined),
        [transactions],
    );

    const [, setTransactionState] = useMultiSearchParamsState<Model>({
        [TRANSACTION_SEARCHPARAM_KEY]: lastTransaction?.validTimeStart.toISOString(),
        [TRANSACTION_ACTIVE_SEARCHPARAM_KEY]: (lastTransaction && isInProgress(lastTransaction.state)) || undefined,
    });

    const { t } = useTranslation();

    useEffect(() => {
        // Sometimes the transactions are not loaded yet, therefore the "default" state should be updated.
        // However when there already is an `atDateString` value, we don't have to update it.
        if (!lastTransaction || atDateString) {
            return;
        }

        setTransactionState({
            [TRANSACTION_SEARCHPARAM_KEY]: lastTransaction.validTimeStart.toISOString(),
            [TRANSACTION_ACTIVE_SEARCHPARAM_KEY]: isInProgress(lastTransaction.state) || undefined,
        });
    }, [lastTransaction, atDateString, setTransactionState]);

    const selectedDate = useMemo(() => (atDateString ? new Date(atDateString).getTime() : undefined), [atDateString]);

    const data = useMemo(() => {
        return transactions.map((transaction) => ({
            payload: transaction,
            y: 0,
            x: transaction.validTimeStart.getTime(),
            selected: selectedDate === transaction.validTimeStart.getTime(),
            onClick: () =>
                setTransactionState({
                    [TRANSACTION_SEARCHPARAM_KEY]: transaction.validTimeStart.toISOString(),
                    [TRANSACTION_ACTIVE_SEARCHPARAM_KEY]: isInProgress(transaction.state) || undefined,
                }),
        }));
    }, [transactions, selectedDate, setTransactionState]);

    const todayData = useMemo(
        () => [
            {
                y: 0,
                x: new Date().getTime(),
                label: t("timeline.today"),
                hideTooltip: true,
                selected: true,
            },
        ],
        [t],
    );

    const [ticks, highlightTicks, domain] = useMemo(() => {
        const combined = [...data, ...todayData].sort((a, b) => a.x - b.x);

        const lowerBound = combined[0];
        const upperBound = combined.at(-1)!;
        const diff = upperBound.x - lowerBound.x;
        const padding = Math.max(diff / TICK_INTERVAL, MONTH_IN_MILLIS);
        const lowerDate = new Date(lowerBound.x - padding);
        const upperDate = new Date(upperBound.x + padding);
        const domain = d3.scaleTime().domain([lowerDate, upperDate]).nice();
        const ticks = domain.ticks(TICK_INTERVAL * 4);

        // Pad a little bit so the first tick doesn't start at x = 0
        const derivedDomain = [
            ticks[0].getTime() - padding / (TICK_INTERVAL + 1),
            ticks.at(-1)!.getTime() + padding / (TICK_INTERVAL + 1),
        ];

        return [
            ticks.map((x) => x.getTime()),
            ticks
                .map((x) => x.getTime())
                .filter((i, index) => {
                    return index % TICK_INTERVAL === 0;
                }),
            derivedDomain,
        ];
    }, [data, todayData]);

    return { data, todayData, ticks, highlightTicks, domain };
}
