// Based on example from React Router, probably replace this based on actual oidc implementation
// source: https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx

import React, { ComponentType, PropsWithChildren } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";

type Props = {};

export type BouncerProps = PropsWithChildren<Props>;

export const Bouncer = ({ children }: BouncerProps) => {
    const [user] = useUser();
    let location = useLocation();

    if (!user.jwt) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return <React.Fragment>{children}</React.Fragment>;
};

export const withBouncer = <P extends {}>(component: ComponentType<P>) => {
    const WrappedComponent = component;

    const BouncerWrapper = (props: P) => (
        <Bouncer>
            <WrappedComponent {...props} />
        </Bouncer>
    );

    return BouncerWrapper;
};
