export const MEMO_SIZE = 90;
export const MEMO_CANVAS_MAX_HEIGHT = 560;
export const MEMO_CANVAS_MAX_WIDTH = 1400;
export const MEMO_CENTER_POINT = Math.floor(MEMO_SIZE / 2);

// @TODO these values not final
export const BLUEPRINT_CANVAS_MAX_HEIGHT = 560;
export const BLUEPRINT_CANVAS_MAX_WIDTH = 1400;
export const CELL_HEIGHT = 69;
export const CELL_CENTER_Y = Math.floor(CELL_HEIGHT / 2);
export const INNER_CELL_ARROW_LENGTH = 32;
export const CONTAINER_WIDTH = 180;
export const EXTERNAL_SYSTEM_WIDTH = 100;
export const EXTERNAL_SYSTEM_HEIGHT = EXTERNAL_SYSTEM_WIDTH;
export const CONTAINER_NAME_HEIGHT = 45;
export const CELL_MARGIN = 16;
export const CELL_WIDTH = CONTAINER_WIDTH - 2 * CELL_MARGIN;

export const MEMO_MIN_X = -MEMO_CENTER_POINT;
export const MEMO_MIN_Y = -MEMO_CENTER_POINT;
export const MEMO_MAX_X = MEMO_CANVAS_MAX_WIDTH - MEMO_CENTER_POINT;
export const MEMO_MAX_Y = MEMO_CANVAS_MAX_HEIGHT - MEMO_CENTER_POINT;

export const CONTAINER_CENTER_Y = Math.floor(CONTAINER_NAME_HEIGHT / 2);
export const CONTAINER_CENTER_X = Math.floor(CONTAINER_WIDTH / 2);
export const EXTERNAL_SYSTEM_CENTER_Y = Math.floor(EXTERNAL_SYSTEM_HEIGHT / 2);
export const EXTERNAL_SYSTEM_CENTER_X = Math.floor(EXTERNAL_SYSTEM_WIDTH / 2);
export const CONTAINER_MIN_X = -CONTAINER_CENTER_X;
export const CONTAINER_MIN_Y = -CONTAINER_CENTER_Y;
export const CONTAINER_MAX_X = BLUEPRINT_CANVAS_MAX_WIDTH - CONTAINER_CENTER_X;
export const CONTAINER_MAX_Y = BLUEPRINT_CANVAS_MAX_HEIGHT - CONTAINER_CENTER_Y;

export const CONTAINER_SPACE_BUBBLE = 20;
export const CONTAINER_DIAGONAL_OFFSET = 20;

export const ICON_SIZE = 24;

export const COLOR_AUBERGINE = "#5b3061";
export const COLOR_PURPLE = "#b44784";
export const COLOR_ORANGE = "#f98526";
export const COLOR_GREEN = "#86bc44";
export const COLOR_RED = "#ca1d2a";
export const COLOR_BLUE = "#28b6ea";
export const COLOR_AUBERGINE_MUTED = "#d5cad6";
export const COLOR_PURPLE_MUTED = "#ebd0df";
export const COLOR_ORANGE_MUTED = "#fde0c7";
export const COLOR_GREEN_MUTED = "#e0edcf";
export const COLOR_RED_MUTED = "#f1c5c8";
export const COLOR_BLUE_MUTED = "#c8ecf9";

export function initCssVariables() {
    document.documentElement.style.setProperty("--memo-size", `${MEMO_SIZE}px`);
    document.documentElement.style.setProperty("--memo-canvas-max-height", `${MEMO_CANVAS_MAX_HEIGHT}px`);
    document.documentElement.style.setProperty("--memo-canvas-max-width", `${MEMO_CANVAS_MAX_WIDTH}px`);
    document.documentElement.style.setProperty("--memo-color-aubergine", COLOR_AUBERGINE);
    document.documentElement.style.setProperty("--memo-color-purple", COLOR_PURPLE);
    document.documentElement.style.setProperty("--memo-color-orange", COLOR_ORANGE);
    document.documentElement.style.setProperty("--memo-color-green", COLOR_GREEN);
    document.documentElement.style.setProperty("--memo-color-red", COLOR_RED);
    document.documentElement.style.setProperty("--memo-color-blue", COLOR_BLUE);
    document.documentElement.style.setProperty("--memo-color-aubergine-muted", COLOR_AUBERGINE_MUTED);
    document.documentElement.style.setProperty("--memo-color-purple-muted", COLOR_PURPLE_MUTED);
    document.documentElement.style.setProperty("--memo-color-orange-muted", COLOR_ORANGE_MUTED);
    document.documentElement.style.setProperty("--memo-color-green-muted", COLOR_GREEN_MUTED);
    document.documentElement.style.setProperty("--memo-color-red-muted", COLOR_RED_MUTED);
    document.documentElement.style.setProperty("--memo-color-blue-muted", COLOR_BLUE_MUTED);
    document.documentElement.style.setProperty("--blueprint-canvas-max-height", `${BLUEPRINT_CANVAS_MAX_HEIGHT}px`);
    document.documentElement.style.setProperty("--blueprint-canvas-max-width", `${BLUEPRINT_CANVAS_MAX_WIDTH}px`);
    document.documentElement.style.setProperty("--blueprint-container-width", `${CONTAINER_WIDTH}px`);
    document.documentElement.style.setProperty("--blueprint-cell-margin", `${CELL_MARGIN}px`);
    document.documentElement.style.setProperty("--blueprint-cell-width", `${CELL_WIDTH}px`);
    document.documentElement.style.setProperty("--blueprint-cell-height", `${CELL_HEIGHT}px`);
    document.documentElement.style.setProperty("--blueprint-cell-center-y", `${CELL_CENTER_Y}px`);
    document.documentElement.style.setProperty("--blueprint-cell-inner-arrow-height", `${INNER_CELL_ARROW_LENGTH}px`);
}
