import React, { useCallback, useEffect, useState } from "react";
import styles from "./MemoCanvas.module.scss";
import classNames from "classnames";
import { Layer, Stage } from "react-konva";
import MemoNote from "../MemoNote/MemoNote";
import { MemoViewModel } from "../../openapi/webservice/models/MemoViewModel";
import { CanvasMode } from "../../models/CanvasMode";
import MemoModalContainer from "../MemoModal/MemoModalContainer";
import { MemoViewOption } from "../../models/MemoViewOption";
import { onDragOver } from "../../utils/DragAndDropUtils";
import { InheritanceType, MemoType } from "../../openapi/webservice";
import { getValueOrDefault } from "../../utils/RetrievalUtils";
import { useWindowResizeListener } from "../../hooks/useWindowResizeListener";
import { useRefState } from "../../hooks/useRefState";
import { MEMO_CANVAS_MAX_HEIGHT, MEMO_CANVAS_MAX_WIDTH, MEMO_SIZE } from "../../utils/CanvasConstants";
import { useMemoBlockState } from "../../hooks/useMemoBlockState";
import { isMemoOutsideOfViewBounds } from "../../utils/CanvasUtils";
import { MemoLikeModel } from "../../models/MemoLikeViewModel";

type Props = {
    className: string;
    type?: MemoType;
    list: MemoViewModel[];
    onChange?: (note: MemoViewModel) => void;
    onDelete?: (note: MemoViewModel) => void;
    onRedirectToCell?: (note: MemoLikeModel) => void;
    onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
    openMeta?: (note: MemoViewModel, tab: MemoViewOption) => void;
    mode: CanvasMode;
    inheritance?: InheritanceType;
    disabled: boolean;
};

const MemoCanvas = ({
    className,
    type,
    list,
    onChange,
    onDelete,
    onDrop: onDropMemo,
    disabled,
    mode,
    inheritance,
    onRedirectToCell,
    openMeta,
}: Props) => {
    const [parentRef, setRef] = useRefState<HTMLDivElement>();
    const [, updateState] = useMemoBlockState();
    useWindowResizeListener();
    const [isHovering, setIsHovering] = useState(false);
    const onDragEnter = useCallback(() => setIsHovering(true), [setIsHovering]);
    const onDragLeave = useCallback(() => setIsHovering(false), [setIsHovering]);
    const onDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            onDropMemo && onDropMemo(event);
            setIsHovering(false);
        },
        [onDropMemo],
    );

    useEffect(() => {
        updateState({
            isMemoOutsideOfViewport: isMemoOutsideOfViewBounds(
                list,
                getValueOrDefault(parentRef?.clientWidth, MEMO_CANVAS_MAX_WIDTH + MEMO_SIZE),
                getValueOrDefault(parentRef?.clientHeight, MEMO_CANVAS_MAX_HEIGHT + MEMO_SIZE),
            ),
        });
    }, [list, updateState, parentRef]);

    return (
        <div
            ref={setRef}
            className={classNames(
                styles.container,
                type && styles[type.toLowerCase()],
                isHovering && styles.hovering,
                className,
            )}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            data-testid="MemoCanvas"
        >
            <Stage
                width={getValueOrDefault(parentRef?.clientWidth, 0)}
                height={getValueOrDefault(parentRef?.clientHeight, 0)}
                listening={!disabled}
            >
                <Layer>
                    {list.map((memo) => (
                        <MemoNote
                            key={memo.id}
                            note={memo}
                            onChange={onChange}
                            onDelete={onDelete}
                            mode={mode}
                            openMeta={openMeta}
                            onRedirectToCell={onRedirectToCell}
                            inheritance={inheritance}
                        />
                    ))}
                </Layer>
            </Stage>
            <MemoModalContainer />
        </div>
    );
};

MemoCanvas.defaultProps = {
    className: "",
    disabled: false,
    list: [],
};

export default MemoCanvas;
