import React, { useCallback } from "react";
import styles from "./DefaultNavBar.module.scss";
import classNames from "classnames";
import ImageButton from "../ImageButton/ImageButton";
import { GlobalSearch, Overview, Redo, Settings, Undo } from "../../assets/images/Images";
import WipWrapper from "../WipWrapper/WipWrapper";
import Title from "../Title/Title";
import Text from "../Text/Text";
import RoundImage from "../RoundImage/RoundImage";
import { useTranslation } from "react-i18next";
import BrandLogo from "../BrandLogo/BrandLogo";
import useComponentVisible from "../../hooks/useComponentVisible";
import { CSSTransition } from "react-transition-group";
import CanvasCard from "../CanvasCard/CanvasCard";
import Separator from "../Separator/Separator";
import screenfull from "screenfull";
import { useNavigate } from "react-router-dom";
import { UserViewModel } from "../../openapi/webservice";

export type Props = {
    user: UserViewModel;
    className: string;
    logout?: () => void;
};

function toggleFullscreen() {
    screenfull.toggle();
}

const DefaultNavBar = ({ className, user, logout }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigateToHome = useCallback(() => navigate("/plattegrond"), [navigate]);
    const { ref, isComponentVisible, toggle: toggleProfileMenu } = useComponentVisible(false);

    return (
        <div className={classNames(styles.container, className)}>
            <BrandLogo className={styles.partnerLogo} src={user?.organisation.logoUri} onClick={navigateToHome} />
            <div className={styles.controls}>
                <WipWrapper label="Ongedaan maken">
                    <ImageButton src={Undo} aria-label="Ongedaan maken" rounded />
                </WipWrapper>
                <ImageButton src={Redo} disabled rounded />
            </div>
            <Title className={styles.title} label={t("navbar.title")} heading="h1" />
            <div className={styles.controls}>
                <WipWrapper>
                    <ImageButton src={Overview} aria-label="Oogje" rounded />
                </WipWrapper>
                <WipWrapper label="Zoeken">
                    <ImageButton src={GlobalSearch} aria-label="Zoeken" rounded />
                </WipWrapper>
                <WipWrapper label="Instellingen">
                    <ImageButton src={Settings} aria-label="Instellingen" rounded />
                </WipWrapper>
            </div>
            <div
                className={classNames(styles.profileInfo, isComponentVisible && styles.active)}
                ref={ref}
                onClick={toggleProfileMenu}
                data-testid="ProfileMenuButton"
            >
                <RoundImage className={styles.profileAvatar} src={user?.avatarUri} />
                <Text className={styles.profileName} label={user?.displayName} />
                <CSSTransition
                    in={isComponentVisible}
                    timeout={300}
                    unmountOnExit
                    mountOnEnter
                    classNames={{
                        enterActive: styles.enterActive,
                        enterDone: styles.enterDone,
                        exitActive: styles.exitActive,
                        exitDone: styles.exitDone,
                    }}
                >
                    <CanvasCard className={styles.dropdown} data-testid="ProfileMenu">
                        <div
                            className={styles.dropdownItem}
                            onClick={toggleFullscreen}
                            data-testid="ProfileMenuToggleFullscreen"
                        >
                            <Text
                                label={
                                    screenfull.isFullscreen ? t("navbar.exit_fullscreen") : t("navbar.enter_fullscreen")
                                }
                                type="body"
                            />
                        </div>
                        <Separator orientation="-" />
                        <div className={styles.dropdownItem} onClick={logout} data-testid="ProfileMenuLogout">
                            <Text label={t("navbar.logout")} type="body" />
                        </div>
                    </CanvasCard>
                </CSSTransition>
            </div>
        </div>
    );
};

DefaultNavBar.defaultProps = {
    className: "",
    partnerName: "",
    partnerAvatarUri: "",
    partnerLogoUri: "",
} as Partial<Props>;

export default DefaultNavBar;
