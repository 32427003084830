import { URLSearchParamsInit } from "react-router-dom";
import { TRANSACTION_ACTIVE_SEARCHPARAM_KEY, TRANSACTION_SEARCHPARAM_KEY } from "./RouterConstants";

// Taken from react-router-dom types
type UpdateSearchParamsFunction = (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
        | {
              replace?: boolean | undefined;
              state?: any;
          }
        | undefined,
) => void;

export function getTransactionSearchParams(searchParams: URLSearchParams) {
    const transactionDate = searchParams.get(TRANSACTION_SEARCHPARAM_KEY);
    const isActive = searchParams.get(TRANSACTION_ACTIVE_SEARCHPARAM_KEY);
    return {
        ...(!!transactionDate && { [TRANSACTION_SEARCHPARAM_KEY]: transactionDate }),
        ...(!!isActive && { [TRANSACTION_ACTIVE_SEARCHPARAM_KEY]: isActive }),
    };
}

export function getTransactionSearchParamsAsKey(searchParams: URLSearchParams) {
    const transactionDate = searchParams.get(TRANSACTION_SEARCHPARAM_KEY);
    const isActive = searchParams.get(TRANSACTION_ACTIVE_SEARCHPARAM_KEY);

    return `${transactionDate} -- ${isActive}`;
}

export function getBlueprintRoute(subContextId: string | undefined, blueprintId: string | undefined) {
    return `/subcontext/${subContextId}/plattegrond/${blueprintId}`;
}

export function getMemoboardRoute(
    subContextId: string | undefined,
    blueprintId: string | undefined,
    cellId: string | undefined,
) {
    return `/subcontext/${subContextId}/plattegrond/${blueprintId}/memobord/${cellId}`;
}

export function updateSearchParams(
    searchParams: URLSearchParams,
    update: UpdateSearchParamsFunction,
    key: string,
    value: string | undefined,
) {
    const currentValue = searchParams.get(key) || undefined;
    if (currentValue === value) {
        return;
    }

    if (value === undefined) {
        searchParams.delete(key);
    } else {
        searchParams.set(key, value.toString());
    }
    update(searchParams, { replace: true });
}
