import React, { useEffect, useRef } from "react";
import styles from "./ChatWindow.module.scss";
import classNames from "classnames";
import ModalCard from "../ModalCard/ModalCard";
import { ChatMessageViewModel } from "../../openapi/webservice";
import Title from "../Title/Title";
import Separator from "../Separator/Separator";
import WipWrapper from "../WipWrapper/WipWrapper";
import ImageButton from "../ImageButton/ImageButton";
import { useTranslation } from "react-i18next";
import { FilterIcon, SearchIcon } from "../../assets/images/Images";
import ChatMessage from "../ChatMessage/ChatMessage";
import ChatInput from "../ChatInput/ChatInput";

type Props = {
    className: string;
    messages: ChatMessageViewModel[];
    onSubmit?: (text: string) => void;
};

const ChatWindow = ({ className, messages, onSubmit }: Props) => {
    const { t } = useTranslation();
    const bottomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Only on mount scroll to bottom to see the most recent messages
        bottomRef.current?.scrollIntoView();
    }, [messages.length]);

    return (
        <ModalCard className={classNames(styles.container, className)}>
            <div className={styles.header}>
                <Title heading="h2" className={classNames(styles.headerItem, styles.title)} label={t("chat.title")} />
                <Separator className={styles.headerItem} orientation="|" />
                <div className={styles.filler} />
                <Separator className={styles.headerItem} orientation="|" />
                <WipWrapper className={styles.headerItem} label="Zoeken">
                    <ImageButton src={SearchIcon} />
                </WipWrapper>
                <Separator className={styles.headerItem} orientation="|" />
                <WipWrapper className={styles.headerItem} label="Filteren">
                    <ImageButton src={FilterIcon} />
                </WipWrapper>
            </div>
            <div className={styles.messages}>
                {messages.map((message, index) => (
                    <ChatMessage key={index} className={styles.message} message={message} />
                ))}

                <div ref={bottomRef} />
            </div>
            <ChatInput className={styles.input} placeholder={t("chat.input.placeholder")} onSubmit={onSubmit} />
        </ModalCard>
    );
};

ChatWindow.defaultProps = {
    className: "",
};

export default ChatWindow;
