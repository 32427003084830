import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DesignContextInputModel, SubContextInputModel } from "../openapi/webservice";
import { ActionType } from "../reducers/ContextsStateReducer";
import { notifySuccess } from "../utils/NotificationUtils";
import {
    useDeleteSubContext,
    useGetAdminContextsCallback,
    useUpdateDesignContext,
    useUpdateSubContext,
} from "./useContextsApi";
import { useErrorResponseNotification } from "./useErrorNotification";
import { useAdminContexts } from "./useAdminContexts";

export function useAdminContextStateChanges() {
    const { t } = useTranslation();
    const [, dispatch] = useAdminContexts();
    const [{ error: deleteSubContextError }, deleteSubContextCallback] = useDeleteSubContext();
    const [{ error: updateSubContextError }, updateSubContextCallback] = useUpdateSubContext();
    const [{ error: updateContextError }, updateContextCallback] = useUpdateDesignContext();
    const [{ error: getContextsError }, getContextsCallback] = useGetAdminContextsCallback();
    useErrorResponseNotification(deleteSubContextError);
    useErrorResponseNotification(updateSubContextError);
    useErrorResponseNotification(updateContextError);
    useErrorResponseNotification(getContextsError);

    const deleteSubContext = useCallback(
        async (contextId: string, subContextId: string) => {
            await deleteSubContextCallback({
                contextId,
                subContextId,
            });

            const overview = await getContextsCallback();
            dispatch({ type: ActionType.set, value: overview });

            notifySuccess(t("toast.success.delete"));
        },
        [deleteSubContextCallback, getContextsCallback, dispatch, t],
    );

    const updateSubContext = useCallback(
        async (organisationId: string, contextId: string, subContextId: string, input: SubContextInputModel) => {
            const response = await updateSubContextCallback({
                contextId,
                subContextId,
                subContextInputModel: input,
            });
            dispatch({ type: ActionType.updateSubContext, value: response, organisationId });

            notifySuccess(t("toast.success.update"));
        },
        [updateSubContextCallback, dispatch, t],
    );

    const updateDesignContext = useCallback(
        async (organisationId: string, contextId: string, input: DesignContextInputModel) => {
            const response = await updateContextCallback({
                id: contextId,
                designContextInputModel: input,
            });
            dispatch({ type: ActionType.updateDesignContext, value: response, organisationId });

            notifySuccess(t("toast.success.update"));
        },
        [updateContextCallback, dispatch, t],
    );

    return useMemo(
        () => ({ deleteSubContext, updateSubContext, updateDesignContext }),
        [deleteSubContext, updateSubContext, updateDesignContext],
    );
}
