import React, { useCallback, useState } from "react";
import styles from "./TimelineBar.module.scss";
import classNames from "classnames";
import CanvasCard from "../CanvasCard/CanvasCard";
import Timeline from "../Timeline/Timeline";
import WipWrapper from "../WipWrapper/WipWrapper";
import ImageButton from "../ImageButton/ImageButton";
import { AddIcon, ContractIcon, FilterIcon, TrashIcon, ZoomIcon } from "../../assets/images/Images";
import { TransactionViewModel } from "../../openapi/webservice";
import AddTransactionModal from "../AddTransactionModal/AddTransactionModal";
import { TimelineView } from "../../models/TimelineView";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useDeleteInProgressTransaction } from "../../hooks/useTimelineStateChanges";

type Props = {
    className: string;
    transactions: TransactionViewModel[];
    view: TimelineView;
};

const TimelineBar = ({ className, transactions, view }: Props) => {
    const { t } = useTranslation();
    const [isAddModalOpen, setShowAddModal] = useState(false);
    const [isDeleteModalOpen, setShowDeleteModal] = useState(false);
    const deleteTransaction = useDeleteInProgressTransaction();
    const closeAddModal = useCallback(() => setShowAddModal(false), [setShowAddModal]);
    const openAddModal = useCallback(() => setShowAddModal(true), [setShowAddModal]);
    const closeDeleteModal = useCallback(() => setShowDeleteModal(false), [setShowDeleteModal]);
    const openDeleteModal = useCallback(() => setShowDeleteModal(true), [setShowDeleteModal]);
    const onConfirmDelete = useCallback(async () => {
        await deleteTransaction();
        closeDeleteModal();
    }, [deleteTransaction, closeDeleteModal]);

    return (
        <CanvasCard className={classNames(styles.container, className)}>
            <Timeline className={styles.timeline} transactions={transactions} />
            <div className={styles.actions}>
                <WipWrapper label="Filteren">
                    <ImageButton src={FilterIcon} />
                </WipWrapper>
                <WipWrapper label="Zoomen">
                    <ImageButton src={ZoomIcon} />
                </WipWrapper>
                <WipWrapper label="Inklappen" className={classNames(view === TimelineView.None && styles.fill)}>
                    <ImageButton src={ContractIcon} className={styles.button} />
                </WipWrapper>
                {view === TimelineView.Solution && (
                    <ImageButton src={AddIcon} onClick={openAddModal} data-testid="OpenAddTransactionModal" />
                )}
                {view === TimelineView.InProgress && (
                    <ImageButton src={TrashIcon} onClick={openDeleteModal} data-testid="OpenDeleteTransactionModal" />
                )}
            </div>
            <AddTransactionModal
                // key is used to clear content of modal
                key={isAddModalOpen ? "open" : "closed"}
                isOpen={isAddModalOpen}
                onClose={closeAddModal}
            />
            {isDeleteModalOpen && (
                <ConfirmationModal
                    title={t("timeline.delete.modal.title")}
                    message={t("timeline.delete.modal.message")}
                    yesLabel={t("timeline.delete.modal.yes")}
                    noLabel={t("timeline.delete.modal.no")}
                    onConfirm={onConfirmDelete}
                    onCancel={closeDeleteModal}
                />
            )}
        </CanvasCard>
    );
};

TimelineBar.defaultProps = {
    className: "",
    view: TimelineView.Solution,
};

export default TimelineBar;
