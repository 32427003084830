import React, { useEffect, useState } from "react";
import styles from "./BlueprintSidebar.module.scss";
import classNames from "classnames";
import CanvasCard from "../CanvasCard/CanvasCard";
import Title from "../Title/Title";
import { useTranslation } from "react-i18next";
import WipWrapper from "../WipWrapper/WipWrapper";
import ImageButton from "../ImageButton/ImageButton";
import { AddIcon, BookIcon, SidebarCollapse } from "../../assets/images/Images";
import Icon from "../Icon/Icon";
import Separator from "../Separator/Separator";
import ContextModalContainer from "../ContextModal/ContextModalContainer";
import { useContexts } from "../../hooks/useContexts";
import ContextList from "../ContextList/ContextList";
import { ActionType } from "../../reducers/ContextsStateReducer";
import { useGetContexts } from "../../hooks/useContextsApi";
import { RequestState } from "../../hooks/useApiCall";

type Props = {
    className: string;
};

const BlueprintSidebar = ({ className }: Props) => {
    const { state, value } = useGetContexts();
    const { t } = useTranslation();
    var [isOpen, openModal] = useState(false);
    const [contexts, dispatchContexts] = useContexts();

    useEffect(() => {
        if (state !== RequestState.DONE) {
            return;
        }

        dispatchContexts({ type: ActionType.set, value: value ?? [] });
    }, [state, value, dispatchContexts]);

    return (
        <CanvasCard className={classNames(styles.container, className)}>
            <div className={styles.top}>
                <div className={styles.description}>
                    <Icon className={styles.icon} src={BookIcon} />
                    <Title className={styles.label} label={t("blueprint.sidebar.title")} heading="h2" />
                </div>
                <Separator orientation="|" />
                <ImageButton src={AddIcon} onClick={() => openModal(true)} data-testid="OpenContextModal" />
                <Separator orientation="|" />
                <WipWrapper label="Zijmenu inklappen">
                    <ImageButton src={SidebarCollapse} />
                </WipWrapper>
            </div>
            <div className={styles.contexts}>{contexts.length > 0 && <ContextList list={contexts} />}</div>
            <ContextModalContainer
                // key is used to clear content of modal
                key={isOpen ? "open" : "closed"}
                isOpen={isOpen}
                onClose={() => openModal(false)}
                list={contexts}
            />
        </CanvasCard>
    );
};

BlueprintSidebar.defaultProps = {
    className: "",
};

export default BlueprintSidebar;
