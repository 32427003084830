import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useSearchParamsState<T extends string | number | boolean | undefined>(key: string, defaultValue: T) {
    const [searchParams, setSearchParams] = useSearchParams();
    const state = useState<T>(() => (searchParams.get(key) as T) || defaultValue);
    const [value] = state;

    useEffect(() => {
        if (value === undefined) {
            searchParams.delete(key);
        } else {
            searchParams.set(key, value.toString());
        }

        setSearchParams(searchParams, { replace: true });

        // Having searchParams and setSearchParams in the same effect will cause infinite rerenders.
        // However there's no other way if I want to keep existing searchParams and only update `key`.
        // Using setSearchParams like `setSearchParams({[key]: value})` will lose all other searchParams.
        // Waiting for https://github.com/remix-run/react-router/issues/8287 to be merged.
        // For now use disable-next-line
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, searchParams, setSearchParams]);

    return state;
}

export function useMultiSearchParamsState<T extends Record<string, string | number | boolean | undefined>>(
    defaultValue: T,
) {
    const [searchParams, setSearchParams] = useSearchParams();
    const state = useState<T>(() =>
        Object.entries(defaultValue).reduce(
            (current, [key, val]) => ({
                ...current,
                [key]: searchParams.get(key) || val,
            }),
            {} as T,
        ),
    );
    const [value] = state;

    useEffect(() => {
        Object.entries(value).forEach(([key, v]) => {
            if (v === undefined) {
                searchParams.delete(key);
            } else {
                searchParams.set(key, v.toString());
            }
        });

        setSearchParams(searchParams, { replace: true });
    }, [value, searchParams, setSearchParams]);

    return state;
}
