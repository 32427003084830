/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    OrganisationViewModel,
    OrganisationViewModelFromJSON,
    OrganisationViewModelFromJSONTyped,
    OrganisationViewModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserViewModel
 */
export interface UserViewModel {
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserViewModel
     */
    avatarUri: string;
    /**
     * 
     * @type {OrganisationViewModel}
     * @memberof UserViewModel
     */
    organisation: OrganisationViewModel;
}

export function UserViewModelFromJSON(json: any): UserViewModel {
    return UserViewModelFromJSONTyped(json, false);
}

export function UserViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'username': json['username'],
        'avatarUri': json['avatarUri'],
        'organisation': OrganisationViewModelFromJSON(json['organisation']),
    };
}

export function UserViewModelToJSON(value?: UserViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'username': value.username,
        'avatarUri': value.avatarUri,
        'organisation': OrganisationViewModelToJSON(value.organisation),
    };
}


