import React, { PropsWithChildren } from "react";
import { Configuration, UsersApi } from "../openapi/webservice";
import { CreateApi, ApiContextProvider } from "./WebServiceApiContext";

const createApi: CreateApi<UsersApi> = (params) => new UsersApi(new Configuration(params));

export const UsersApiContext = React.createContext(createApi({}));

export const UsersApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={UsersApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
