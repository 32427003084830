import React from "react";
import styles from "./TinyLink.module.scss";
import classNames from "classnames";
import { HtmlAnchorProps } from "../ReusableTypes";

type Props = HtmlAnchorProps & {
    className: string;
};

const TinyLink = ({ className, ...props }: Props) => {
    return (
        <a
            target="_blank"
            rel="noopener"
            data-testid="TinyLink"
            {...props}
            className={classNames(styles.container, className)}
        />
    );
};

TinyLink.defaultProps = {
    className: "",
};

export default TinyLink;
