import React from "react";
import styles from "./ContainerModal.module.scss";
import classNames from "classnames";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import Title from "../Title/Title";
import Separator from "../Separator/Separator";
import TextEditorInput from "../TextEditorInput/TextEditorInput";
import ChatWindowContainer from "../ChatWindow/ChatWindowContainer";
import { ChatType } from "../../models/ChatType";
import ModalHeader from "../ModalHeader/ModalHeader";
import { ContainerViewModel } from "../../openapi/webservice";
import { useVerifiedActiveTransaction } from "../../hooks/useVerifiedActiveTransaction";
import { TransactionValidation } from "../../models/TransactionValidation";

type Props = Omit<Modal.Props, "overlayClassName" | "className"> & {
    className: string;
    onClose?: () => void;
    onChange?: (value: string) => void;
    container?: ContainerViewModel;
};

const ContainerModal = ({ className, container, onClose, onChange, ...props }: Props) => {
    const { t } = useTranslation();
    const validation = useVerifiedActiveTransaction();

    return (
        <Modal
            shouldCloseOnOverlayClick={true}
            testId="ContainerModal"
            overlayClassName={styles.overlay}
            className={classNames(styles.container, className)}
            {...props}
        >
            {container && (
                <>
                    <ModalHeader className={styles.header} color="main" onClose={onClose}>
                        <Title className={styles.title} label={container.name} heading="h2" />
                        <Separator className={styles.headerItem} orientation="|" />
                        <div className={styles.filler} />
                    </ModalHeader>

                    <div className={styles.contentContainer}>
                        <TextEditorInput
                            className={styles.description}
                            content={container.description}
                            title={t("modal.description.title")}
                            placeholder={t("modal.description.placeholder_blueprint", {
                                type: t(`enum.${container.cellType}`),
                            })}
                            onChange={onChange}
                            disabled={validation !== TransactionValidation.Valid}
                        />
                        <ChatWindowContainer className={styles.content} id={container.id} type={ChatType.Container} />
                    </div>
                </>
            )}
        </Modal>
    );
};

ContainerModal.defaultProps = {
    className: "",
};

export default ContainerModal;
