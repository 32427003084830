import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Logo, SixblocksShamblesIcon } from "../../assets/images/Images";
import { useBlueprintName } from "../../hooks/useBlueprintsApi";
import { useErrorResponseNotification } from "../../hooks/useErrorNotification";
import { useSelectedTransaction } from "../../hooks/useSelectedTransaction";
import { useSetTransactionToSolution } from "../../hooks/useTimelineApi";
import { useVerifiedActiveTransaction } from "../../hooks/useVerifiedActiveTransaction";
import { TransactionValidation } from "../../models/TransactionValidation";
import { formatToFull } from "../../utils/DateTimeUtils";
import { TRANSACTION_ACTIVE_SEARCHPARAM_KEY, TRANSACTION_SEARCHPARAM_KEY } from "../../utils/RouterConstants";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import ValidateTransactionModal from "../ValidateTransactionModal/ValidateTransactionModal";
import DropdownList, { DropdownItem } from "./DropdownList";

type Props = {
    className: string;
    disabled?: boolean;
};

const BlueprintStatusDropdownContainer = (props: Props) => {
    const { t } = useTranslation();
    const [{ error }, setToSolution] = useSetTransactionToSolution();
    const blueprintName = useBlueprintName();
    const transaction = useSelectedTransaction();
    const [searchParams, setSearchParams] = useSearchParams();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const transactionDate = searchParams.get(TRANSACTION_SEARCHPARAM_KEY) || "today";
    const validation = useVerifiedActiveTransaction();
    useErrorResponseNotification(error);

    const confirm = useCallback(async () => {
        setShowConfirm(false);
        setShowValidation(true);
        await setToSolution();
    }, [setShowConfirm, setToSolution, setShowValidation]);

    const complete = useCallback(async () => {
        setShowValidation(false);
        searchParams.delete(TRANSACTION_ACTIVE_SEARCHPARAM_KEY);
        setSearchParams(searchParams, { replace: true });
    }, [searchParams, setSearchParams, setShowValidation]);

    const close = useCallback(async () => {
        setShowConfirm(false);
    }, [setShowConfirm]);

    const { items, selected } = useMemo<{ items: DropdownItem[]; selected?: DropdownItem }>(() => {
        if (validation === TransactionValidation.LoadingOrNotFound) {
            return { items: [], selected: undefined };
        }

        const items: DropdownItem[] = [
            {
                id: "Solution",
                label: "Solution",
                icon: Logo,
                onSelect: () => {
                    setShowConfirm(true);
                    return Promise.resolve();
                },
            },
        ];

        let selected = items[0];

        if (validation === TransactionValidation.Valid) {
            const inProgressOption = {
                id: "In progress",
                label: "In progress",
                icon: SixblocksShamblesIcon,
            };
            items.unshift(inProgressOption);

            selected = inProgressOption;
        }

        return { items, selected };
    }, [validation, setShowConfirm]);

    return (
        <>
            <DropdownList
                key={validation + transactionDate + showConfirm}
                {...props}
                disabled={props.disabled || items.length <= 1}
                items={items}
                initialSelectedItem={selected}
            />
            {showConfirm && transaction && (
                <ConfirmationModal
                    title={t("blueprint.confirmation.solution.title", { name: blueprintName })}
                    message={t("blueprint.confirmation.solution.message", {
                        name: blueprintName,
                        transaction: transaction.name,
                        date: formatToFull(transaction.validTimeStart),
                    })}
                    yesLabel={t("blueprint.confirmation.solution.confirm")}
                    noLabel={t("blueprint.confirmation.solution.cancel")}
                    onConfirm={confirm}
                    onCancel={close}
                />
            )}
            {showValidation && transaction && (
                <ValidateTransactionModal
                    title={t("blueprint.validation.title", { name: blueprintName })}
                    subtitle={t("blueprint.validation.details", {
                        name: transaction.name,
                        date: formatToFull(transaction.validTimeStart),
                    })}
                    onConfirm={complete}
                />
            )}
        </>
    );
};

BlueprintStatusDropdownContainer.defaultProps = {
    className: "",
};

export default BlueprintStatusDropdownContainer;
