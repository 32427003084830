import { KonvaEventObject } from "konva/lib/Node";
import React, { useCallback, useEffect, useState } from "react";
import { Group, Rect, Arrow, Text } from "react-konva";
import "konva/lib/shapes/Text";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Arrow";
import { CellViewModel, ContainerViewModel } from "../../../openapi/webservice";
import {
    CONTAINER_NAME_HEIGHT,
    CELL_HEIGHT,
    INNER_CELL_ARROW_LENGTH,
    CELL_MARGIN,
    CELL_WIDTH,
    CONTAINER_CENTER_X,
    ICON_SIZE,
} from "../../../utils/CanvasConstants";
import { HighlightMode } from "../../../models/HighlightMode";
import { Html } from "../../../konva/Html";
import styles from "../BlueprintCanvas.module.scss";
import { useFontObserver } from "../../../hooks/useFontObserver";
import { useKonvaHovering } from "../../../hooks/useKonvaEvents";
import TrashButton from "../../KonvaShared/TrashButton";
import { useTranslation } from "react-i18next";

function shouldShowDelete(hasCallback: boolean, isHovering: boolean, isEditing: boolean, highlight: HighlightMode) {
    return hasCallback && isHovering && !isEditing && highlight === "none";
}

type CellViewProps = {
    container: ContainerViewModel;
    cell: CellViewModel;
    isEditMode: boolean;
    onCellClick?: (event: KonvaEventObject<MouseEvent>, cell: CellViewModel, container: ContainerViewModel) => void;
    onRenameCell?: (cellId: string, name: string) => void;
    onDeleteCell?: (cellId: string) => void;
    isLast: boolean;
    index: number;
    highlight: HighlightMode;
};

const CellView = ({
    container,
    cell,
    isEditMode,
    onCellClick,
    onRenameCell,
    onDeleteCell,
    isLast,
    index,
    highlight,
}: CellViewProps) => {
    const { t } = useTranslation();
    const { id, name } = cell;
    const [isEditingCell, setIsEditingCell] = useState(false);
    const [value, setValue] = useState(name || "");
    const isFontLoaded = useFontObserver("Open Sans");
    const [isHovering, setIsHovering] = useState(false);
    const { onEnter, onLeave } = useKonvaHovering(isEditMode, setIsHovering);

    //Update name when new value comes in from external, for example changing transactions
    useEffect(() => setValue(name), [name, setValue]);

    const endEditingCell = useCallback(() => {
        setIsEditingCell(false);

        if (onRenameCell && name !== value) {
            onRenameCell(id, value);
        }
    }, [id, name, value, onRenameCell]);

    const onTextChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((event) => {
        const text = event.currentTarget.value.trim();
        setValue(text);
    }, []);

    const onCellViewClick = useCallback(
        (event: KonvaEventObject<MouseEvent>) => {
            if (isEditMode && highlight === "none") {
                setIsEditingCell(true);
                setIsHovering(false);
            }

            if (!onCellClick) {
                return;
            }

            onCellClick(event, cell, container);
        },
        [onCellClick, cell, container, highlight, isEditMode],
    );

    const deleteCallback = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            e.cancelBubble = true;

            if (window.confirm(t("blueprint.delete.cell.confirm", { name: cell.name })) && onDeleteCell) {
                onDeleteCell(cell.id);
            }
        },
        [onDeleteCell, cell, t],
    );

    if (!isFontLoaded) {
        return null;
    }

    return (
        <Group x={0} y={CONTAINER_NAME_HEIGHT + index * (CELL_HEIGHT + INNER_CELL_ARROW_LENGTH)}>
            <Group
                x={CELL_MARGIN}
                width={CELL_WIDTH}
                height={CELL_HEIGHT}
                onClick={onCellViewClick}
                name="cell"
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
            >
                <Rect
                    width={CELL_WIDTH}
                    height={CELL_HEIGHT}
                    fill={highlight === "cell" ? "#ffffff" : "#F5F5F5"}
                    stroke="#061133"
                    cornerRadius={7}
                    strokeWidth={1}
                />
                {isEditingCell ? (
                    <Html>
                        <textarea
                            autoFocus
                            className={styles.cellInput}
                            defaultValue={value}
                            onChange={onTextChange}
                            onBlur={endEditingCell}
                            onFocus={(e) => e.currentTarget.select()}
                            rows={5}
                            style={{ width: CELL_WIDTH, height: CELL_HEIGHT }}
                        />
                    </Html>
                ) : (
                    <Text
                        x={4}
                        width={CELL_WIDTH - 8}
                        height={CELL_HEIGHT}
                        text={value}
                        align="center"
                        verticalAlign="middle"
                        fontSize={14}
                        ellipsis={true}
                        wrap="word"
                        fill="#061133"
                        fontFamily="Open Sans"
                    />
                )}
                {isEditMode && (
                    <TrashButton
                        x={CELL_WIDTH - ICON_SIZE / 2}
                        y={-ICON_SIZE / 2}
                        visible={shouldShowDelete(!!onDeleteCell, isHovering, isEditingCell, highlight)}
                        onClick={deleteCallback}
                    />
                )}
            </Group>
            {!isLast && (
                <Arrow
                    points={[
                        CONTAINER_CENTER_X,
                        CELL_HEIGHT,
                        CONTAINER_CENTER_X,
                        CELL_HEIGHT + INNER_CELL_ARROW_LENGTH,
                    ]}
                    strokeWidth={1}
                    pointerAtEnding={true}
                    pointerLength={8}
                    pointerWidth={8}
                    stroke="#061133"
                    fill="#061133"
                />
            )}
        </Group>
    );
};

export default CellView;
