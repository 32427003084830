import { faker } from "@faker-js/faker";
import {
    FakeAvatar,
    FakeAvatar2,
    FakeAvatar3,
    FakeAvatar4,
    FakeAvatar5,
    PlaceholderLogo,
} from "../assets/images/Images";
import {
    ChatMessageViewModel,
    TransactionState,
    TransactionViewModel,
    DesignContextViewModel,
    SubContextViewModel,
    CellType,
    ContainerViewModel,
    CellViewModel,
    BlueprintViewModel,
    BreadcrumbViewModel,
    MemoBlockMemoViewModel,
    MemoViewModel,
    OrganisationViewModel,
    UserViewModel,
    DesignContextOverview,
    OrganisationOverview,
} from "../openapi/webservice";
import { InheritanceType } from "../openapi/webservice/models/InheritanceType";
import { MemoType } from "../openapi/webservice/models/MemoType";
import {
    BLUEPRINT_CANVAS_MAX_HEIGHT,
    BLUEPRINT_CANVAS_MAX_WIDTH,
    CONTAINER_SPACE_BUBBLE,
    CONTAINER_WIDTH,
    MEMO_MAX_X,
    MEMO_MAX_Y,
    MEMO_MIN_X,
    MEMO_MIN_Y,
} from "./CanvasConstants";

const FAKE_USERS = (faker.seed(2) &&
    [FakeAvatar, FakeAvatar2, FakeAvatar3, FakeAvatar4, FakeAvatar5].map((avatar) => ({
        name: `${faker.name.firstName()} ${faker.name.lastName()}`,
        avatar: avatar,
    }))) as Array<{
    name: string;
    avatar: string;
}>;

export const TEMP_SUBCONTEXT_GUID = "d9e32ab8-27e4-44a7-bc39-08da6b4384c4";

export const TEMP_CELL_GUID = (faker.seed(2380) && faker.datatype.uuid()) as string;

export const TEMP_TRANSACTION_GUID = (faker.seed(2381) && faker.datatype.uuid()) as string;

export const TEMP_BLUEPRINT_GUID = (faker.seed(2382) && faker.datatype.uuid()) as string;

export const TEMP_DESIGN_CONTEXT_GUID = (faker.seed(2383) && faker.datatype.uuid()) as string;

export const TEMP_CONTAINER_GUID = (faker.seed(2384) && faker.datatype.uuid()) as string;

function setSeedIfSet(seed?: number) {
    if (!seed) {
        return;
    }

    faker.seed(seed);
}

setSeedIfSet(2380);

export const generateMemoNotes = (n: number, type: MemoType, seed?: number): MemoViewModel[] => {
    setSeedIfSet(seed);

    return Array(n)
        .fill(0)
        .map(() => ({
            id: faker.datatype.uuid(),
            cellId: faker.datatype.uuid(),
            type: type,
            name: faker.company.bs(),
            description: faker.hacker.phrase(),
            inheritanceType: InheritanceType.OWNED,
            hasChat: faker.datatype.boolean(),
            hasPlanning: faker.datatype.boolean(),
            rotationInDegrees: faker.datatype.number({ min: -10, max: 10 }),
            xPosition: faker.datatype.number({ min: MEMO_MIN_X, max: MEMO_MAX_X }),
            yPosition: faker.datatype.number({ min: MEMO_MIN_Y, max: MEMO_MAX_Y }),
        }));
};

export const generateMemoBlockMemoNotes = (n: number, type: MemoType, seed?: number): MemoBlockMemoViewModel[] => {
    return generateMemoNotes(n, type, seed).map((memo) => ({ ...memo, cellName: faker.commerce.productName() }));
};

export const generateUsers = (n: number, seed?: number): UserViewModel[] => {
    setSeedIfSet(seed);

    return Array(n)
        .fill(0)
        .map(() => {
            const user = faker.helpers.arrayElement(FAKE_USERS);
            return {
                id: faker.datatype.uuid(),
                displayName: user.name,
                avatarUri: user.avatar,
                organisation: generateOrganisationModels(1)[0],
                username: faker.name.lastName(),
            };
        });
};

export const generateChatMessages = (n: number, seed?: number): ChatMessageViewModel[] => {
    setSeedIfSet(seed);

    return Array(n)
        .fill(0)
        .map(() => {
            const user = FAKE_USERS[faker.datatype.number({ min: 0, max: FAKE_USERS.length - 1 })];

            return {
                userId: faker.datatype.uuid(),
                name: user.name,
                avatar: user.avatar,
                timestamp: faker.date.between("2021-01-01T00:00:00.000Z", "2022-07-01T00:00:00.000Z"),
                isFavorite: faker.datatype.boolean(),
                messageContent: faker.lorem.sentences(faker.datatype.number({ min: 1, max: 6 })),
            };
        });
};

/**
 * @NOTE Last transaction returns as "In progress" if n is even
 */
export const generateTimeLineTransactions = (n: number, seed?: number): TransactionViewModel[] => {
    setSeedIfSet(seed);

    return Array(n)
        .fill(0)
        .map(() => ({
            id: faker.datatype.uuid(),
            subContextId: faker.datatype.uuid(),
            name: faker.company.bs(),
            validTimeStart: faker.date.between("2019-01-01T00:00:00.000Z", "2022-07-01T00:00:00.000Z"),
            validTimeEnd: new Date("3000-01-01T00:00:00.000Z"),
            state: TransactionState.Solution,
        }))
        .sort((a, b) => a.validTimeStart.getTime() - b.validTimeStart.getTime())
        .map((value, index) => ({
            ...value,
            state: index === n - 1 && n % 2 === 0 ? TransactionState.InProgress : TransactionState.Solution,
        }));
};

export const generateContexts = (n: number, seed?: number): DesignContextViewModel[] => {
    setSeedIfSet(seed);

    return Array(n)
        .fill(0)
        .map(() => {
            var id = faker.datatype.uuid();
            return {
                id: id,
                name: faker.company.bs().substring(0, 28),
                subContexts: generateSubContexts(5, id),
            };
        });
};

export const generateSubContexts = (n: number, contextId: string, seed?: number): SubContextViewModel[] => {
    setSeedIfSet(seed);

    return Array(n)
        .fill(0)
        .map(() => {
            var id = faker.datatype.uuid();
            return {
                id: id,
                name: faker.company.bs().substring(0, 28),
                designContextId: contextId,
                blueprintId: id,
            };
        });
};

export const generateOrganisationOverview = (n: number, seed?: number): OrganisationOverview[] => {
    setSeedIfSet(seed);

    return generateOrganisationModels(n).map((org) => ({
        ...org,
        designContexts: generateContexts(faker.helpers.arrayElement([0, 0, 2, 3, 5])),
    }));
};

export const generateDesignContextOverview = (n: number, seed?: number): DesignContextOverview => {
    setSeedIfSet(seed);

    return { organisations: generateOrganisationOverview(n) };
};

export const generateCellModels = (n: number, containerId: string, seed?: number): CellViewModel[] => {
    setSeedIfSet(seed);

    return Array(n)
        .fill(0)
        .map((_, index) => {
            return {
                id: faker.datatype.uuid(),
                containerId: containerId,
                name: faker.commerce.productName(),
                order: index,
                index: index,
                connections: [],
            };
        });
};

export const generateContainerModels = (n: number, seed?: number): ContainerViewModel[] => {
    setSeedIfSet(seed);

    return Array(n)
        .fill(0)
        .map(() => {
            const id = faker.datatype.uuid();
            return {
                id: id,
                name: faker.commerce.department(),
                description: "",
                cellType: CellType.CLUSTER,
                //@NOTE(Lejun) Probably change min: 1 to min: 0 when pathfinding for arrows is implemented
                cells: generateCellModels(faker.datatype.number({ min: 1, max: 3 }), id),
                xPosition: faker.datatype.number({ min: 0, max: BLUEPRINT_CANVAS_MAX_WIDTH - 260 }),
                yPosition: faker.datatype.number({ min: 0, max: BLUEPRINT_CANVAS_MAX_HEIGHT - 100 }),
                hasChat: faker.datatype.boolean(),
            };
        });
};

export const generateOrganisationModels = (n: number, seed?: number): OrganisationViewModel[] => {
    setSeedIfSet(seed);

    return Array(n)
        .fill(0)
        .map(() => {
            return {
                id: faker.datatype.uuid(),
                name: faker.company.companyName(),
                logoUri: PlaceholderLogo,
                defaultTheme: {
                    cellColor: "#F5F5F5",
                    clusterColor: "#F98525",
                    connectionColor: "#061133",
                    domainColor: "#28B6EB",
                },
            };
        });
};

export const generateBlueprint = (seed?: number): BlueprintViewModel => {
    setSeedIfSet(seed);

    const containers = generateContainerModels(3).map((c, index) => {
        return {
            ...c,
            xPosition: 50 + index * (CONTAINER_WIDTH + 2 * CONTAINER_SPACE_BUBBLE + 20),
        };
    });
    const cells1 = containers[0].cells[0];
    const cells2 = containers[1].cells[0];
    const cells3 = containers[2].cells[0];
    return {
        name: "Transformatie PA - Pensioenuitvoerder 1",
        containers,
        connections: [
            { id: faker.datatype.uuid(), fromCellId: cells1.id, toCellId: cells2.id },
            { id: faker.datatype.uuid(), fromCellId: cells2.id, toCellId: cells3.id },
        ],
    };
};

const CONTAINER_CHAIN = [
    CellType.EXTERNALSOURCE,
    CellType.CLUSTER,
    CellType.CLUSTER,
    CellType.DOMAIN,
    CellType.EXTERNALSINK,
];
export const generateFullBlueprintChain = (seed?: number): BlueprintViewModel => {
    setSeedIfSet(seed);

    const containers = generateContainerModels(CONTAINER_CHAIN.length).map((c, index) => {
        return {
            ...c,
            cellType: CONTAINER_CHAIN[index],
            xPosition: 50 + index * (CONTAINER_WIDTH + 2 * CONTAINER_SPACE_BUBBLE + 20),
        };
    });
    const source1 = containers[0].cells[0];
    const cluster1 = containers[1].cells[0];
    const cluster2 = containers[2].cells[0];
    const domain1 = containers[3].cells[0];
    const sink1 = containers[4].cells[0];

    return {
        name: "Transformatie PA - Pensioenuitvoerder 1",
        containers,
        connections: [
            { id: faker.datatype.uuid(), fromCellId: source1.id, toCellId: cluster1.id },
            { id: faker.datatype.uuid(), fromCellId: cluster1.id, toCellId: cluster2.id },
            { id: faker.datatype.uuid(), fromCellId: cluster2.id, toCellId: domain1.id },
            { id: faker.datatype.uuid(), fromCellId: cluster1.id, toCellId: sink1.id },
        ],
    };
};

export const generateBlueprintBreadcrumb = (seed?: number): BreadcrumbViewModel => {
    setSeedIfSet(seed);

    return {
        designContextId: TEMP_DESIGN_CONTEXT_GUID,
        designContextName: faker.company.companyName().substring(0, 28),
        subContextId: TEMP_SUBCONTEXT_GUID,
        subContextName: faker.commerce.department().substring(0, 28),
        blueprintId: TEMP_BLUEPRINT_GUID,
    };
};

export const generateMemoboardBreadcrumb = (seed?: number): BreadcrumbViewModel => {
    setSeedIfSet(seed);

    return {
        designContextId: TEMP_DESIGN_CONTEXT_GUID,
        designContextName: faker.company.companyName().substring(0, 28),
        subContextId: TEMP_SUBCONTEXT_GUID,
        subContextName: faker.commerce.department().substring(0, 28),
        blueprintId: TEMP_BLUEPRINT_GUID,
        containerId: TEMP_CONTAINER_GUID,
        containerName: faker.commerce.product(),
        cellId: TEMP_CELL_GUID,
        cellName: faker.commerce.productName(),
    };
};
