import React, { ReactNode } from "react";
import CanvasCard from "../CanvasCard/CanvasCard";
import styles from "./BoardLayout.module.scss";
import classNames from "classnames";

type Props = {
    className: string;
    mainActionContent: ReactNode;
    content: ReactNode;
    help: ReactNode;
    secondaryActionContent: ReactNode;
    hideSecondary?: boolean;
};

const BoardLayout = ({ className, mainActionContent, content, help, secondaryActionContent, hideSecondary }: Props) => {
    return (
        <CanvasCard className={classNames(styles.container, className, hideSecondary && styles.hideSecondary)}>
            <div className={classNames(styles.mainAction, styles.gridBlock)}>{mainActionContent}</div>
            <div className={classNames(styles.content, styles.gridBlock)}>{content}</div>
            <div className={classNames(styles.help, styles.gridBlock)}>{help}</div>
            {!hideSecondary && (
                <div className={classNames(styles.secondaryAction, styles.gridBlock)}>{secondaryActionContent}</div>
            )}
        </CanvasCard>
    );
};

BoardLayout.defaultProps = {
    className: "",
};

export default BoardLayout;
