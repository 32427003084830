import {
    MemoNoteComputationsIcon,
    MemoNoteInterfacesIcon,
    MemoNoteMutationsIcon,
    MemoNoteProcessesIcon,
    MemoNoteTablesIcon,
    MemoNoteValidationsIcon,
} from "../assets/images/Images";
import { HeaderColor } from "../models/HeaderColor";
import { InheritanceType, MemoType } from "../openapi/webservice";
import {
    COLOR_AUBERGINE_MUTED,
    COLOR_BLUE_MUTED,
    COLOR_GREEN_MUTED,
    COLOR_ORANGE_MUTED,
    COLOR_PURPLE_MUTED,
    COLOR_RED_MUTED,
} from "./CanvasConstants";

export function getMemoBackground(type: MemoType) {
    let background;
    switch (type) {
        case MemoType.Computations:
            background = MemoNoteComputationsIcon;
            break;
        case MemoType.Tables:
            background = MemoNoteTablesIcon;
            break;
        case MemoType.Interfaces:
            background = MemoNoteInterfacesIcon;
            break;
        case MemoType.Validations:
            background = MemoNoteValidationsIcon;
            break;
        case MemoType.Mutations:
            background = MemoNoteMutationsIcon;
            break;
        case MemoType.Processes:
            background = MemoNoteProcessesIcon;
            break;
    }

    return background;
}

export function getMemoColor(type?: MemoType) {
    let color: HeaderColor;
    switch (type) {
        case MemoType.Computations:
            color = "orange";
            break;
        case MemoType.Tables:
            color = "aubergine";
            break;
        case MemoType.Interfaces:
            color = "red";
            break;
        case MemoType.Validations:
            color = "green";
            break;
        case MemoType.Mutations:
            color = "purple";
            break;
        case MemoType.Processes:
            color = "blue";
            break;
        default:
            color = "none";
    }

    return color;
}

export function getMemoColorHex(type?: MemoType) {
    let color;
    switch (type) {
        case MemoType.Computations:
            color = COLOR_ORANGE_MUTED;
            break;
        case MemoType.Tables:
            color = COLOR_AUBERGINE_MUTED;
            break;
        case MemoType.Interfaces:
            color = COLOR_RED_MUTED;
            break;
        case MemoType.Validations:
            color = COLOR_GREEN_MUTED;
            break;
        case MemoType.Mutations:
            color = COLOR_PURPLE_MUTED;
            break;
        case MemoType.Processes:
            color = COLOR_BLUE_MUTED;
            break;
    }

    return color;
}

const VIEWABLE_INHERITANCE_TYPES = [InheritanceType.HORIZONTAL, InheritanceType.VERTICAL];

export function isViewingInheritance(type?: InheritanceType) {
    return !!type && VIEWABLE_INHERITANCE_TYPES.includes(type);
}
