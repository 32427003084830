import React, { useEffect } from "react";
import { RequestState } from "../../hooks/useApiCall";
import TempOrganisationManagement from "./TempOrganisationManagement";
import { useGetOrganisations } from "../../hooks/useOrganisationsApi";
import { useOrganisations } from "../../hooks/useOrganisations";
import { ActionType } from "../../reducers/GenericArrayStateReducer";

type Props = {
    className: string;
};

const TempOrganisationManagementContainer = ({ className }: Props) => {
    const { state, value } = useGetOrganisations();
    const [organisations, dispatch] = useOrganisations();

    useEffect(() => {
        if (state !== RequestState.DONE) {
            return;
        }

        dispatch({ type: ActionType.set, value: value ?? [] });
    }, [state, value, dispatch]);

    return <TempOrganisationManagement className={className} organisations={organisations} />;
};

TempOrganisationManagementContainer.defaultProps = {
    className: "",
};

export default TempOrganisationManagementContainer;
