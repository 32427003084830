export const TRANSACTION_SEARCHPARAM_KEY = "atDate";
export const TRANSACTION_ACTIVE_SEARCHPARAM_KEY = "isActiveTransaction";
export const HELP_MODAL_KEY = "getHelp";
export const MODE_KEY = "canvasMode";
export const SELECTED_MEMO_KEY = "selectedMemo";
export const SELECTED_CONTAINER_KEY = "selectedContainer";
export const TAB_KEY = "tab";
export const INHERITANCE_KEY = "inheritance";

export const FILTER_CLUSTER_KEY = "filterCluster";
export const FILTER_DOMAIN_KEY = "filterDomain";
export const FILTER_EXTERNAL_SINK_KEY = "filterSink";
export const FILTER_EXTERNAL_SOURCE_KEY = "filterSource";
