import React, { PropsWithChildren } from "react";
import { Configuration, MemosApi } from "../openapi/webservice";
import { CreateApi, ApiContextProvider } from "./WebServiceApiContext";

const createApi: CreateApi<MemosApi> = (params) => new MemosApi(new Configuration(params));

export const MemosApiContext = React.createContext(createApi({}));

export const MemosApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={MemosApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
