/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DoubleDoubleTuple
 */
export interface DoubleDoubleTuple {
    /**
     * 
     * @type {number}
     * @memberof DoubleDoubleTuple
     */
    item1?: number;
    /**
     * 
     * @type {number}
     * @memberof DoubleDoubleTuple
     */
    item2?: number;
}

export function DoubleDoubleTupleFromJSON(json: any): DoubleDoubleTuple {
    return DoubleDoubleTupleFromJSONTyped(json, false);
}

export function DoubleDoubleTupleFromJSONTyped(json: any, ignoreDiscriminator: boolean): DoubleDoubleTuple {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item1': !exists(json, 'item1') ? undefined : json['item1'],
        'item2': !exists(json, 'item2') ? undefined : json['item2'],
    };
}

export function DoubleDoubleTupleToJSON(value?: DoubleDoubleTuple | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item1': value.item1,
        'item2': value.item2,
    };
}


