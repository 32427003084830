import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserInputModel } from "../openapi/webservice";
import { notifySuccess } from "../utils/NotificationUtils";
import { useErrorResponseNotification } from "./useErrorNotification";
import { useCreateUser, useDeleteUser, useGetUsersCallback, useUpdateUser } from "./useUsersApi";
import { useUsers } from "./useUsers";
import { ActionType } from "../reducers/GenericArrayStateReducer";

export function useUsersStateChanges() {
    const { t } = useTranslation();
    const [, dispatch] = useUsers();
    const [{ error: createError }, createCallback] = useCreateUser();
    const [{ error: deleteError }, deleteCallback] = useDeleteUser();
    const [{ error: updateError }, updateCallback] = useUpdateUser();
    const [{ error: getError }, getCallback] = useGetUsersCallback();
    useErrorResponseNotification(createError);
    useErrorResponseNotification(deleteError);
    useErrorResponseNotification(updateError);
    useErrorResponseNotification(getError);

    const create = useCallback(
        async (input: UserInputModel) => {
            const response = await createCallback({
                userInputModel: input,
            });

            dispatch({ type: ActionType.add, value: response });
            notifySuccess(t("toast.success.create"));
        },
        [createCallback, dispatch, t],
    );

    const _delete = useCallback(
        async (id: string) => {
            await deleteCallback({
                userId: id,
            });

            const users = await getCallback();
            dispatch({ type: ActionType.set, value: users });

            notifySuccess(t("toast.success.delete"));
        },
        [deleteCallback, getCallback, dispatch, t],
    );

    const update = useCallback(
        async (id: string, input: UserInputModel) => {
            const response = await updateCallback({
                userId: id,
                userInputModel: input,
            });
            dispatch({ type: ActionType.update, value: response });

            notifySuccess(t("toast.success.update"));
        },
        [updateCallback, dispatch, t],
    );

    return useMemo(() => ({ create, _delete, update }), [create, _delete, update]);
}
