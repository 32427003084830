import { useContext, useMemo } from "react";
import { useApiCall, useApiCallback } from "./useApiCall";
import { UsersApiContext } from "../contexts/UsersApiContext";

export function useUsersApi() {
    return useContext(UsersApiContext);
}

export function useGetSelf() {
    const api = useUsersApi();
    const boundFunc = useMemo(() => api.getSelf.bind(api), [api]);

    return useApiCall(boundFunc);
}

export function useGetUsers() {
    const api = useUsersApi();
    const boundFunc = useMemo(() => api.getUsers.bind(api), [api]);

    return useApiCall(boundFunc);
}

export function useGetUsersCallback() {
    const api = useUsersApi();
    const boundFunc = useMemo(() => api.getUsers.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useCreateUser() {
    const api = useUsersApi();
    const boundFunc = useMemo(() => api.createUser.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useDeleteUser() {
    const api = useUsersApi();
    const boundFunc = useMemo(() => api.deleteUser.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useUpdateUser() {
    const api = useUsersApi();
    const boundFunc = useMemo(() => api.updateUser.bind(api), [api]);

    return useApiCallback(boundFunc);
}
