import React, { PropsWithChildren } from "react";
import { AuthenticationApiProvider } from "./AuthenticationApiContext";
import { BlueprintsApiProvider } from "./BlueprintsApiContext";
import { ChatApiProvider } from "./ChatApiContext";
import { ContextsApiProvider } from "./ContextsApiContext";
import { UsersApiProvider } from "./UsersApiContext";
import { MemoboardApiProvider } from "./MemoboardApiContext";
import { MemosApiProvider } from "./MemosApiContext";
import { TimelineApiProvider } from "./TimelineApiContext";
import { OrganisationsApiProvider } from "./OrganisationsApiContext";

const ApiProviderCollection = ({ children }: PropsWithChildren<{}>) => (
    <AuthenticationApiProvider>
        <UsersApiProvider>
            <BlueprintsApiProvider>
                <ContextsApiProvider>
                    <ChatApiProvider>
                        <TimelineApiProvider>
                            <OrganisationsApiProvider>
                                <MemoboardApiProvider>
                                    <MemosApiProvider>{children}</MemosApiProvider>
                                </MemoboardApiProvider>
                            </OrganisationsApiProvider>
                        </TimelineApiProvider>
                    </ChatApiProvider>
                </ContextsApiProvider>
            </BlueprintsApiProvider>
        </UsersApiProvider>
    </AuthenticationApiProvider>
);

export default ApiProviderCollection;
