/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChatMessageViewModel
 */
export interface ChatMessageViewModel {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageViewModel
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageViewModel
     */
    avatar: string;
    /**
     * 
     * @type {Date}
     * @memberof ChatMessageViewModel
     */
    timestamp: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessageViewModel
     */
    isFavorite: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageViewModel
     */
    messageContent: string;
}

export function ChatMessageViewModelFromJSON(json: any): ChatMessageViewModel {
    return ChatMessageViewModelFromJSONTyped(json, false);
}

export function ChatMessageViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'name': json['name'],
        'avatar': json['avatar'],
        'timestamp': (new Date(json['timestamp'])),
        'isFavorite': json['isFavorite'],
        'messageContent': json['messageContent'],
    };
}

export function ChatMessageViewModelToJSON(value?: ChatMessageViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'name': value.name,
        'avatar': value.avatar,
        'timestamp': (value.timestamp.toISOString()),
        'isFavorite': value.isFavorite,
        'messageContent': value.messageContent,
    };
}


