import React, { useCallback, useState } from "react";
import styles from "./AddTransactionModal.module.scss";
import classNames from "classnames";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import Title from "../Title/Title";
import { AddIcon } from "../../assets/images/Images";
import Separator from "../Separator/Separator";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import ModalHeader from "../ModalHeader/ModalHeader";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import Text from "../Text/Text";
import { useAddNewTransaction } from "../../hooks/useTimelineStateChanges";
import { offsetToAmsterdamTime } from "../../utils/DateTimeUtils";

registerLocale("nl", nl);

type Props = Omit<Modal.Props, "overlayClassName" | "className"> & {
    className: string;
    onClose?: () => void;
};

const AddTransactionModal = ({ className, onClose, ...props }: Props) => {
    const submit = useAddNewTransaction();
    const [transactionName, setTransactionName] = useState("");
    const [transactionDate, setTransactionDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));

    const { t } = useTranslation();

    const onTransactionNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            setTransactionName(e.currentTarget.value);
        },
        [setTransactionName],
    );

    const onTransactionDateChange = useCallback(
        (e: Date | null) => {
            if (e === null) {
                return;
            }

            setTransactionDate(e);
        },
        [setTransactionDate],
    );

    const onSubmit = useCallback(async () => {
        const correctedDate = offsetToAmsterdamTime(transactionDate);
        await submit(transactionName, correctedDate);
        onClose && onClose();
    }, [submit, onClose, transactionName, transactionDate]);

    return (
        <Modal
            shouldCloseOnOverlayClick={true}
            testId="AddTransactionModal"
            overlayClassName={styles.overlay}
            className={classNames(styles.container, className)}
            {...props}
        >
            <ModalHeader className={styles.header} color="main" onClose={onClose}>
                <div className={classNames(styles.title, styles.headerItem)}>
                    <Icon className={styles.icon} src={AddIcon} />
                    <Title className={styles.label} label={t("timeline.modal.title")} heading="h2" />
                </div>
                <Separator className={styles.headerItem} orientation="|" />
                <div className={styles.filler} />
            </ModalHeader>
            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    <div className={styles.column}>
                        <TextInput
                            maxLength={28}
                            className={styles.textInput}
                            label={t("timeline.modal.transaction_name")}
                            placeholder={t("timeline.modal.transaction_name")}
                            value={transactionName}
                            onChange={onTransactionNameChange}
                        />
                    </div>
                    <div className={styles.column}>
                        <div>
                            <Text label={t("timeline.modal.transaction_date")} className={styles.text} />
                            <DatePicker
                                selected={transactionDate}
                                showTimeSelect
                                locale="nl"
                                inline
                                onChange={onTransactionDateChange}
                                timeIntervals={60}
                            />
                        </div>
                    </div>
                </div>
                <Button
                    className={styles.button}
                    label={t("timeline.modal.create")}
                    onClick={onSubmit}
                    disabled={transactionName.length === 0}
                />
            </div>
        </Modal>
    );
};

AddTransactionModal.defaultProps = {
    className: "",
};

export default AddTransactionModal;
