import React, { useCallback, useState } from "react";
import styles from "./RoughAddUserInput.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { QuestionIcon } from "../../assets/images/Images";
import { OrganisationViewModel, UserInputModel } from "../../openapi/webservice";
import { MIN_PASSWORD_LENGTH, generatePassword } from "../../utils/ValidationUtils";
import Button from "../Button/Button";
import DropdownList from "../DropdownList/DropdownList";
import ImageButton from "../ImageButton/ImageButton";
import RoundImage from "../RoundImage/RoundImage";
import TextInput from "../TextInput/TextInput";
import Text from "../Text/Text";
import { useOrganisationOptions } from "../../hooks/useOrganisationOptions";

type Props = {
    className: string;
    organisations: OrganisationViewModel[];
    saveCallback: (input: UserInputModel) => void;
};

function canSave(name: string, username: string, avatar: string, org: string, password: string) {
    // 1. Each field except for password is required
    // 2. When every required field is filled, check for any changes in value compared to original
    return [name, username, avatar, org].every((v) => v.trim().length > 0) && password.length >= MIN_PASSWORD_LENGTH;
}

const RoughAddUserInput = ({ className, organisations, saveCallback }: Props) => {
    const { t } = useTranslation();
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [uri, setUri] = useState("");
    const [selectedOrg, setSelectedOrg] = useState<OrganisationViewModel | undefined>(undefined);
    const [password, setPassword] = useState("");

    const onNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setName(e.currentTarget.value);
    }, []);

    const onUriChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setUri(e.currentTarget.value);
    }, []);

    const onUsernameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setUsername(e.currentTarget.value);
    }, []);

    const onPasswordChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setPassword(e.currentTarget.value);
    }, []);

    const organisationOptions = useOrganisationOptions(organisations, setSelectedOrg);

    const onSave = useCallback(
        () =>
            saveCallback({
                displayName: name.trim(),
                username: username.trim(),
                avatarUri: uri.trim(),
                organisation: selectedOrg?.id || "",
                password: password,
            }),
        [saveCallback, name, username, uri, selectedOrg, password],
    );

    const onGeneratePassword = useCallback(() => {
        const next = generatePassword();
        setPassword(next);
    }, []);

    return (
        <div className={classNames(styles.container, className)}>
            <TextInput
                className={styles.input}
                label={t("users.name.placeholder")}
                value={name}
                onChange={onNameChange}
                data-testid="NameInput"
            />
            <TextInput
                className={styles.input}
                label={t("users.username.placeholder")}
                value={username}
                onChange={onUsernameChange}
                data-testid="UsernameInput"
            />
            <div className={classNames(styles.input, styles.avatarContainer)}>
                <TextInput
                    className={styles.avatarInput}
                    label={t("users.avatar.placeholder")}
                    value={uri}
                    onChange={onUriChange}
                    data-testid="AvatarInput"
                />
                <RoundImage className={styles.avatar} size="normal" src={uri} />
            </div>
            <div className={classNames(styles.input, styles.passwordContainer)}>
                <TextInput
                    className={styles.passwordInput}
                    label={t("users.password.placeholder")}
                    value={password}
                    onChange={onPasswordChange}
                    data-testid="PasswordInput"
                />
                <ImageButton
                    className={styles.passwordGenerator}
                    src={QuestionIcon}
                    rounded
                    onClick={onGeneratePassword}
                    data-testid="GeneratePasswordButton"
                />
            </div>
            <div className={classNames(styles.input)}>
                <Text label="Organisatie" className={styles.label} />
                <DropdownList className={classNames(styles.input, styles.dropdown)} items={organisationOptions} />
            </div>

            <Button
                label={t("users.form.add")}
                displayType="alternative"
                onClick={onSave}
                className={styles.button}
                disabled={!canSave(name, username, uri, selectedOrg?.id || "", password)}
                data-testid="SaveButton"
            />
        </div>
    );
};

RoughAddUserInput.defaultProps = {
    className: "",
};

export default RoughAddUserInput;
