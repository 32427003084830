/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    OrganisationOverview,
    OrganisationOverviewFromJSON,
    OrganisationOverviewFromJSONTyped,
    OrganisationOverviewToJSON,
} from './';

/**
 * 
 * @export
 * @interface DesignContextOverview
 */
export interface DesignContextOverview {
    /**
     * 
     * @type {Array<OrganisationOverview>}
     * @memberof DesignContextOverview
     */
    organisations: Array<OrganisationOverview>;
}

export function DesignContextOverviewFromJSON(json: any): DesignContextOverview {
    return DesignContextOverviewFromJSONTyped(json, false);
}

export function DesignContextOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): DesignContextOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisations': ((json['organisations'] as Array<any>).map(OrganisationOverviewFromJSON)),
    };
}

export function DesignContextOverviewToJSON(value?: DesignContextOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisations': ((value.organisations as Array<any>).map(OrganisationOverviewToJSON)),
    };
}


