import React, { useCallback, useMemo, useState } from "react";
import styles from "./ContextModal.module.scss";
import classNames from "classnames";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import Title from "../Title/Title";
import { BookIcon } from "../../assets/images/Images";
import Separator from "../Separator/Separator";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import ModalHeader from "../ModalHeader/ModalHeader";
import DatePicker, { registerLocale } from "react-datepicker";
import nl from "date-fns/locale/nl";
import Text from "../Text/Text";
import DataListInput from "../DataListInput/DataListInput";
import { CreateSubContextInputModel, DesignContextViewModel, OrganisationViewModel } from "../../openapi/webservice";
import { formatFromDate, offsetToAmsterdamTime } from "../../utils/DateTimeUtils";
import { ContextInfo } from "../../models/ContextInfo";
import { useOrganisationOptions } from "../../hooks/useOrganisationOptions";
import DropdownList from "../DropdownList/DropdownList";

registerLocale("nl", nl);

type Props = Omit<Modal.Props, "overlayClassName" | "className"> & {
    className: string;
    onClose?: () => void;
    submit?: (input: CreateSubContextInputModel) => Promise<void>;
    list: DesignContextViewModel[];
    info?: ContextInfo;
    headerTitle?: string;
    organisations?: Array<OrganisationViewModel>;
};

const ContextModal = ({ className, onClose, submit, list, info, headerTitle, organisations, ...props }: Props) => {
    const [contextName, setContextName] = useState("");
    const [subContextName, setSubContextName] = useState("");
    const [transactionName, setTransactionName] = useState("");
    const [transactionDate, setTransactionDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
    const [selectedOrg, setSelectedOrg] = useState<OrganisationViewModel | undefined>(undefined);

    const { t } = useTranslation();
    const title = useMemo(() => headerTitle || t("blueprint.sidebar.modal.title"), [t, headerTitle]);

    const organisationOptions = useOrganisationOptions(organisations, setSelectedOrg);

    const onContextNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            setContextName(e.currentTarget.value);
        },
        [setContextName],
    );

    const onSubContextNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            setSubContextName(e.currentTarget.value);
        },
        [setSubContextName],
    );

    const onTransactionNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            setTransactionName(e.currentTarget.value);
        },
        [setTransactionName],
    );

    const onTransactionDateChange = useCallback(
        (e: Date | null) => {
            if (e === null) {
                return;
            }

            setTransactionDate(e);
        },
        [setTransactionDate],
    );

    const onSubmit = useCallback(async () => {
        if (!submit) {
            return;
        }

        const correctedDate = offsetToAmsterdamTime(transactionDate);

        try {
            await submit({
                contextName,
                subContextName,
                transactionName,
                validTimeStart: correctedDate,
                fromTransaction: info?.transaction?.id,
                toOrganisation: selectedOrg?.id,
            });
            onClose && onClose();
        } catch (e) {
            console.warn("Something went wrong while submitting context", e);
        }
    }, [
        submit,
        onClose,
        contextName,
        subContextName,
        transactionName,
        transactionDate,
        info?.transaction?.id,
        selectedOrg,
    ]);

    return (
        <Modal
            shouldCloseOnOverlayClick={true}
            testId="ContextModal"
            overlayClassName={styles.overlay}
            className={classNames(styles.container, className)}
            {...props}
        >
            <ModalHeader className={styles.header} color="main" onClose={onClose}>
                <div className={classNames(styles.title, styles.headerItem)}>
                    <Icon className={styles.icon} src={BookIcon} />
                    <Title className={styles.label} label={title} heading="h2" />
                </div>
                <Separator className={styles.headerItem} orientation="|" />
                <div className={styles.filler} />
            </ModalHeader>
            {info && (
                <div className={styles.conditionalContainer}>
                    <Text
                        label={t("blueprint.duplication.modal.context", {
                            context: info.contextName,
                            subcontext: info.subContextName,
                        })}
                    />
                    {info.transaction && (
                        <Text
                            label={t("blueprint.duplication.modal.transaction", {
                                name: info.transaction.name,
                                date: formatFromDate(info.transaction.validTimeStart),
                            })}
                        />
                    )}
                </div>
            )}
            {organisations && (
                <div className={styles.conditionalContainer}>
                    <Text label={t("organisation.form.header")} className={styles.inputHeader} />
                    <DropdownList className={styles.dropdown} items={organisationOptions} />
                </div>
            )}
            <div className={styles.contentContainer}>
                <div className={styles.content}>
                    <div className={styles.column}>
                        <DataListInput
                            maxLength={28}
                            className={styles.textInput}
                            label={t("blueprint.sidebar.modal.context")}
                            placeholder={t("blueprint.sidebar.modal.context")}
                            value={contextName}
                            onChange={onContextNameChange}
                            options={list.map((context) => context.name)}
                        />
                        <TextInput
                            maxLength={28}
                            className={styles.textInput}
                            label={t("blueprint.sidebar.modal.sub_context")}
                            placeholder={t("blueprint.sidebar.modal.sub_context")}
                            value={subContextName}
                            onChange={onSubContextNameChange}
                        />
                        <TextInput
                            maxLength={28}
                            className={styles.textInput}
                            label={t("blueprint.sidebar.modal.transaction_name")}
                            placeholder={t("blueprint.sidebar.modal.transaction_name")}
                            value={transactionName}
                            onChange={onTransactionNameChange}
                        />
                    </div>
                    <div className={styles.column}>
                        <div>
                            <Text label={t("blueprint.sidebar.modal.transaction_date")} className={styles.text} />
                            <DatePicker
                                selected={transactionDate}
                                showTimeSelect
                                locale="nl"
                                inline
                                onChange={onTransactionDateChange}
                                timeIntervals={60}
                            />
                        </div>
                    </div>
                </div>
                <Button className={styles.button} label={t("blueprint.sidebar.modal.create")} onClick={onSubmit} />
            </div>
        </Modal>
    );
};

ContextModal.defaultProps = {
    className: "",
};

export default ContextModal;
