/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MemoType {
    Tables = 'Tables',
    Interfaces = 'Interfaces',
    Validations = 'Validations',
    Mutations = 'Mutations',
    Processes = 'Processes',
    Computations = 'Computations'
}

export function MemoTypeFromJSON(json: any): MemoType {
    return MemoTypeFromJSONTyped(json, false);
}

export function MemoTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemoType {
    return json as MemoType;
}

export function MemoTypeToJSON(value?: MemoType | null): any {
    return value as any;
}

