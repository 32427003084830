import { KonvaEventObject } from "konva/lib/Node";
import { useCallback, useMemo } from "react";

const toCursor = (e: KonvaEventObject<MouseEvent>, cursor: string) => {
    const stage = e.target.getStage();

    if (!stage) {
        return;
    }

    stage.container().style.cursor = cursor;
};

const toMoveCursor = (e: KonvaEventObject<MouseEvent>) => {
    toCursor(e, "move");
};

const toPointerCursor = (e: KonvaEventObject<MouseEvent>) => {
    toCursor(e, "pointer");
};

const toDefaultCursor = (e: KonvaEventObject<MouseEvent>) => {
    toCursor(e, "default");
};

export function useKonvaHovering(isEditMode: boolean, setIsHovering: (value: React.SetStateAction<boolean>) => void) {
    const onEnter = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            if (isEditMode) {
                toMoveCursor(e);
            } else {
                toPointerCursor(e);
            }
            setIsHovering(true);
        },
        [isEditMode, setIsHovering],
    );

    const onLeave = useCallback(
        (e: KonvaEventObject<MouseEvent>) => {
            toDefaultCursor(e);
            setIsHovering(false);
        },
        [setIsHovering],
    );

    return useMemo(() => {
        return { onEnter, onLeave };
    }, [onEnter, onLeave]);
}
