import React, { useCallback, useState } from "react";
import Modal from "react-modal";
import styles from "./BlueprintFilterModal.module.scss";
import classNames from "classnames";
import ModalHeader from "../ModalHeader/ModalHeader";
import Title from "../Title/Title";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { useBlueprintFilter } from "../../hooks/useBlueprintFilter";
import {
    FILTER_CLUSTER_KEY,
    FILTER_DOMAIN_KEY,
    FILTER_EXTERNAL_SINK_KEY,
    FILTER_EXTERNAL_SOURCE_KEY,
} from "../../utils/RouterConstants";

type Props = {
    className: string;
    onClose?: () => void;
};

const BlueprintFilterModal = ({ className, onClose }: Props) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(true);
    const { filters, update } = useBlueprintFilter();
    const { filterCluster, filterDomain, filterSink, filterSource } = filters;

    const close = useCallback(() => {
        setOpen(false);
        onClose && onClose();
    }, [setOpen, onClose]);

    const updateClusters = useCallback(() => {
        update(FILTER_CLUSTER_KEY, !filterCluster);
    }, [update, filterCluster]);
    const updateDomains = useCallback(() => {
        update(FILTER_DOMAIN_KEY, !filterDomain);
    }, [update, filterDomain]);
    const updateSources = useCallback(() => {
        update(FILTER_EXTERNAL_SOURCE_KEY, !filterSource);
    }, [update, filterSource]);
    const updateSinks = useCallback(() => {
        update(FILTER_EXTERNAL_SINK_KEY, !filterSink);
    }, [update, filterSink]);

    return (
        <Modal
            shouldCloseOnOverlayClick={false}
            testId="BlueprintFilterModal"
            overlayClassName={styles.overlay}
            className={classNames(styles.container, className)}
            isOpen={isOpen}
        >
            <ModalHeader className={styles.header} color="main" onClose={close}>
                <Title
                    className={classNames(styles.title, styles.headerItem)}
                    label={t("blueprint.filter.title")}
                    heading="h2"
                />
            </ModalHeader>
            <div className={styles.contentContainer}>
                <Text className={styles.sectionHeader} label={t("blueprint.filter.section_1")} type="tooltip" />
                <div className={styles.option}>
                    <Text className={styles.optionName} label={t("enum.CLUSTER_ALTERNATIVE")} />
                    <ToggleSwitch
                        className={styles.toggle}
                        labelChecked={t("blueprint.filter.on")}
                        labelUnchecked={t("blueprint.filter.off")}
                        defaultChecked={!filterCluster}
                        onChange={updateClusters}
                        data-testid="ToggleFilterCluster"
                    />
                </div>
                <div className={styles.option}>
                    <Text className={styles.optionName} label={t("enum.DOMAIN")} />
                    <ToggleSwitch
                        className={styles.toggle}
                        labelChecked={t("blueprint.filter.on")}
                        labelUnchecked={t("blueprint.filter.off")}
                        defaultChecked={!filterDomain}
                        onChange={updateDomains}
                        data-testid="ToggleFilterDomain"
                    />
                </div>
                <Text className={styles.sectionHeader} label={t("blueprint.filter.section_2")} type="tooltip" />
                <div className={styles.option}>
                    <Text className={styles.optionName} label={t("enum.EXTERNAL_SOURCE")} />
                    <ToggleSwitch
                        className={styles.toggle}
                        labelChecked={t("blueprint.filter.on")}
                        labelUnchecked={t("blueprint.filter.off")}
                        defaultChecked={!filterSource}
                        onChange={updateSources}
                        data-testid="ToggleFilterSource"
                    />
                </div>
                <div className={styles.option}>
                    <Text className={styles.optionName} label={t("enum.EXTERNAL_SINK")} />
                    <ToggleSwitch
                        className={styles.toggle}
                        labelChecked={t("blueprint.filter.on")}
                        labelUnchecked={t("blueprint.filter.off")}
                        defaultChecked={!filterSink}
                        onChange={updateSinks}
                        data-testid="ToggleFilterSink"
                    />
                </div>
            </div>
        </Modal>
    );
};

BlueprintFilterModal.defaultProps = {
    className: "",
};

export default BlueprintFilterModal;
