import React, { Fragment } from "react";
import styles from "./HelpModal.module.scss";
import Modal from "react-modal";
import classNames from "classnames";
import ModalHeader from "../ModalHeader/ModalHeader";
import { BookIcon, CanvasPlan, MemoCanvasIcon, QuestionIcon } from "../../assets/images/Images";
import Icon from "../Icon/Icon";
import Separator from "../Separator/Separator";
import Title from "../Title/Title";
import { useTranslation } from "react-i18next";
import Text from "../Text/Text";

export type HelpType = "memo" | "blueprint";

function Blueprint() {
    const { t } = useTranslation();
    return (
        <Fragment key="blueprint">
            <div className={styles.titleContainer}>
                <Title className={styles.title} heading="h1" label={t("help.blueprint.title")} />
                <img className={styles.icon} src={CanvasPlan} alt="Icon" />
            </div>
            <br />
            <Text label={t("help.blueprint.body.1")} />
            <br />
            <Text label={t("help.blueprint.body.2")} />
            <Text label={t("help.blueprint.body.3")} />
            <Text label={t("help.blueprint.body.4")} />
            <Text label={t("help.blueprint.body.5")} />
            <br />
            <Text label={t("help.blueprint.body.6")} />
            <br />
            <Text label={t("help.blueprint.body.7")} />
            <br />
            {/* Taken from embed vimeo video and added &dnt=1 */}
            <iframe
                src="https://player.vimeo.com/video/634362769?h=6cac3cf6e5&dnt=1"
                width="533"
                height="300"
                frameBorder="0"
                allow="fullscreen; picture-in-picture"
                allowFullScreen
                title="Embedded Sixblocks video"
            ></iframe>
        </Fragment>
    );
}

function Memo() {
    const { t } = useTranslation();
    return (
        <Fragment key="memo">
            <div className={styles.titleContainer}>
                <Title className={styles.title} heading="h1" label={t("help.memo.title")} />
                <img className={styles.icon} src={MemoCanvasIcon} alt="Icon" />
            </div>
            <br />
            <Text label={t("help.memo.body.1")} />
            <br />
            {/* Taken from embed vimeo video and added &dnt=1 */}
            <iframe
                src="https://player.vimeo.com/video/634362769?h=6cac3cf6e5&dnt=1"
                width="533"
                height="300"
                frameBorder="0"
                allow="fullscreen; picture-in-picture"
                allowFullScreen
                title="Embedded Sixblocks video"
            ></iframe>
        </Fragment>
    );
}

function Unknown() {
    const { t } = useTranslation();
    return (
        <Fragment key="unknown">
            <div className={styles.titleContainer}>
                <Title className={styles.title} heading="h1" label={t("help.unknown.title")} />
                <img className={styles.icon} src={QuestionIcon} alt="Icon" />
            </div>
            <br />
            <Text label={t("help.unknown.body.1")} />
        </Fragment>
    );
}

function HelpContent({ type }: { type?: HelpType }) {
    let content = null;
    switch (type) {
        case "blueprint":
            content = <Blueprint />;
            break;
        case "memo":
            content = <Memo />;
            break;
        default:
            content = <Unknown />;
            break;
    }

    return content;
}

type Props = Omit<Modal.Props, "overlayClassName" | "className"> & {
    className: string;
    onClose?: () => void;
    type?: HelpType;
};

const HelpModal = ({ className, onClose, type, ...props }: Props) => {
    const { t } = useTranslation();
    return (
        <Modal
            shouldCloseOnOverlayClick={true}
            testId="HelpModal"
            overlayClassName={styles.overlay}
            className={classNames(styles.container, className)}
            {...props}
        >
            <ModalHeader className={styles.header} color="main" onClose={onClose}>
                <div className={classNames(styles.title, styles.headerItem)}>
                    <Icon className={styles.icon} src={BookIcon} />
                    <Title className={styles.label} label={t("help.title")} heading="h2" />
                </div>
                <Separator className={styles.headerItem} orientation="|" />
                <div className={styles.filler} />
            </ModalHeader>
            <div className={styles.contentContainer}>
                <HelpContent type={type} />
            </div>
        </Modal>
    );
};

HelpModal.defaultProps = {
    className: "",
};

export default HelpModal;
