import React, { useCallback, useState } from "react";
import styles from "./LoginPage.module.scss";
import classNames from "classnames";
import PoweredBy from "../../components/PoweredBy/PoweredBy";
import Title from "../../components/Title/Title";
import TextInput from "../../components/TextInput/TextInput";
import CheckboxInput from "../../components/CheckboxInput/CheckboxInput";
import TinyLink from "../../components/TinyLink/TinyLink";
import Button from "../../components/Button/Button";
import { useTranslation } from "react-i18next";
import WipWrapper from "../../components/WipWrapper/WipWrapper";
import { Helmet } from "react-helmet";

type Props = {
    className: string;
    submit?: (username: string, password: string) => void;
};

const LoginPage = ({ className, submit }: Props) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const { t } = useTranslation();

    const onUsernameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setUsername(e.currentTarget.value);
    }, []);

    const onPasswordChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setPassword(e.currentTarget.value);
    }, []);

    const onSubmit = useCallback(() => {
        submit && submit(username, password);
    }, [submit, username, password]);

    return (
        <div className={classNames(styles.container, className)}>
            <Helmet title="Login">
                <meta name="description" content="Inloggen bij Business Rules Canvas" />
            </Helmet>
            <div className={styles.cover}>
                <div className={styles.img} />
                <div className={styles.front} />
            </div>
            <div className={styles.loginContainer}>
                <div className={styles.form}>
                    <Title className={styles.title} label={t("login.title")} heading="h1" />
                    <TextInput
                        className={styles.textInput}
                        label={t("email")}
                        placeholder={t("email")}
                        type="text"
                        name="username"
                        onChange={onUsernameChange}
                        data-testid="UsernameInput"
                    />
                    <TextInput
                        className={styles.textInput}
                        label={t("password")}
                        placeholder={t("password")}
                        type="password"
                        onChange={onPasswordChange}
                        data-testid="PasswordInput"
                    />
                    <div className={styles.options}>
                        <CheckboxInput label={t("login.remember_me")} className={styles.checkbox} />
                        <WipWrapper label={t("login.password_forgotten")}>
                            <TinyLink>{t("login.password_forgotten")}</TinyLink>
                        </WipWrapper>
                    </div>
                    <Button
                        className={styles.button}
                        label={t("login.submit")}
                        disabled={password.length < 3}
                        onClick={onSubmit}
                        data-testid="LoginButton"
                    />
                </div>
                <PoweredBy className={styles.footer} size="normal" theme="light" animated={true} />
            </div>
        </div>
    );
};

LoginPage.defaultProps = {
    className: "",
};

export default LoginPage;
