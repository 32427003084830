import React from "react";
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from "recharts";
import styles from "./Timeline.module.scss";
import classNames from "classnames";
import { TransactionViewModel } from "../../openapi/webservice";
import Text from "../Text/Text";
import { useTimeline } from "../../hooks/useTimeline";
import { formatFromMillis } from "../../utils/DateTimeUtils";
import { isInProgress } from "../../utils/TransactionUtils";
import { useWindowResizeListener } from "../../hooks/useWindowResizeListener";

type Props = {
    className: string;
    transactions: Array<TransactionViewModel>;
};

const POINT_SIZE = 14.392;
function DataPointShape(args: any) {
    //Sorry missing types, just print the args...
    const compensation = (POINT_SIZE - args.width) / 2;
    const { state } = args.payload.payload;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            cy={args.cy - compensation}
            y={args.y - compensation}
            cx={args.cx - compensation}
            x={args.x - compensation}
            width={`${POINT_SIZE}`}
            height={`${POINT_SIZE}`}
            viewBox={`0 0 ${POINT_SIZE} ${POINT_SIZE}`}
            transform={`translate(${args.cx - compensation},${args.cy - compensation})`}
            className={styles.point}
            data-testid="DataPoint"
            onClick={args.onClick}
        >
            <path
                id="TransactionVector"
                d="M3.037,8.676h2.6c2.169,0,3.037-.868,3.037-3.037v-2.6C8.676.868,7.809,0,5.64,0h-2.6C.868,0,0,.868,0,3.037v2.6C0,7.809.868,8.676,3.037,8.676Z"
                transform="translate(1.061 7.196) rotate(-45)"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                className={classNames(
                    styles.shape,
                    args.selected && styles.selected,
                    isInProgress(state) && styles.inProgress,
                )}
            />
        </svg>
    );
}

function TodayShape(args: any) {
    //Sorry missing types, just print the args...
    const width = 13.072;
    const height = 12.398;

    const xCompensation = (width - args.width) / 2;
    const yCompensation = (height - args.height) / 2 + 16;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            cy={args.cy - yCompensation}
            y={args.y - yCompensation}
            cx={args.cx - xCompensation}
            x={args.x - xCompensation}
            width={`${width}`}
            height={`${height}`}
            viewBox={`0 0 ${width} ${height}`}
            transform={`translate(${args.cx - xCompensation},${args.cy - yCompensation})`}
            className={styles.today}
            data-testid="TodayPoint"
        >
            <g id="direct-down" transform="translate(-1.888 -2.204)">
                <path
                    id="TodayVector"
                    d="M4,9.823.236,2.835A1.949,1.949,0,0,1,2.862.212a6.066,6.066,0,0,0,5.755,0,1.949,1.949,0,0,1,2.625,2.623L7.483,9.823A1.99,1.99,0,0,1,4,9.823Z"
                    transform="translate(2.686 2.998)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    className={classNames(styles.shape)}
                />
            </g>
        </svg>
    );
}

function CustomTooltip(args: any) {
    //Sorry missing types, just print the args...
    const { active, payload } = args;
    if (active && payload && payload.length && !payload[0].payload.hideTooltip) {
        return (
            <div className={styles.tooltip} style={{ transform: "translate(-50%, -20px)" }} data-testid="Tooltip">
                <Text className={styles.tooltipText} label={formatFromMillis(payload[0].value)} />
            </div>
        );
    }
    return null;
}

const TICK_SIZE = 28;

const CustomTick = (tickProps: any) => {
    //Sorry missing types, just print the args...
    const { x, y, payload, labelTicks, tickFormatter } = tickProps;
    const { value } = payload;
    const offset = TICK_SIZE / 4 - 1;

    if (labelTicks.includes(value)) {
        return (
            <>
                <path d={`M${x},${y + offset}v${-TICK_SIZE}`} stroke="#061133" strokeWidth="1.5" />
                {tickFormatter && (
                    <text
                        className={styles.tickLabel}
                        x={x}
                        y={y + offset + TICK_SIZE / 2 + 2}
                        textAnchor="middle"
                    >{`${tickFormatter(value)}`}</text>
                )}
            </>
        );
    } else {
        return <path d={`M${x},${y - TICK_SIZE / 4 + offset}v${-TICK_SIZE / 2}`} stroke="#061133" strokeWidth="1.5" />;
    }
};

const Timeline = ({ className, transactions }: Props) => {
    const { data, todayData, ticks, highlightTicks, domain } = useTimeline(transactions);
    const resizeKey = useWindowResizeListener();

    return (
        <div className={classNames(styles.container, className)}>
            <ResponsiveContainer width="100%" height="100%" key={resizeKey}>
                {/* Margin is for centering the graph, might change this later depending on design */}
                <ScatterChart margin={{ left: 0, right: 0, top: 0, bottom: 10.5 }}>
                    <XAxis
                        xAxisId={0}
                        type="number"
                        dataKey="x"
                        name="transactionDates"
                        domain={domain}
                        tickFormatter={formatFromMillis}
                        axisLine={true}
                        stroke="#061133"
                        ticks={ticks}
                        tick={<CustomTick labelTicks={highlightTicks} />}
                        interval={0}
                        tickLine={false}
                    />
                    <YAxis type="number" dataKey="y" hide={true} domain={[0, 1]} />
                    <Tooltip cursor={false} content={CustomTooltip} offset={0} />
                    <Scatter name="transactions" data={data} shape={DataPointShape} />
                    <Scatter name="today" data={todayData} shape={TodayShape} isAnimationActive={false}>
                        <LabelList dataKey="label" position="top" offset={22} className={styles.todayLabel} />
                    </Scatter>
                </ScatterChart>
            </ResponsiveContainer>
        </div>
    );
};

Timeline.defaultProps = {
    className: "",
};

export default Timeline;
