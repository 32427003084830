const getVariable = (name: string, required = false): string => {
    const result = process.env[name];

    if (result === null || result === undefined) {
        if (required) {
            throw Error(`Could not find required env variable '${name}'`);
        } else if (process.env["NODE_ENV"] !== "test") {
            console.warn(`Optional env variable '${name}' is not set.`);
        }
    }

    return result || "";
};

// Define all environment values here, so we can guard against Oopsies in runtime.
// Structured as { [variable]: required }
const AllVars = {
    NODE_ENV: false,
    REACT_APP_FULL_DEV_MODE: false,
    REACT_APP_GLITCHTIP_DSN: false,
    REACT_APP_TEST_MAKE_SCREENSHOTS: false,
    REACT_APP_GA_ID: false,
    REACT_APP_BACKEND_URL: false,
};

type EnvKeys = keyof typeof AllVars;

export const Env = Object.entries(AllVars).reduce(
    (envObject, [key, required]) => ({ ...envObject, [key]: getVariable(key, required) }),
    {},
) as { [key in EnvKeys]: string } & typeof process.env;
