import React, { useCallback, useState } from "react";
import styles from "./MemoInput.module.scss";
import classNames from "classnames";
import { MemoType } from "../../openapi/webservice/models/MemoType";
import { getMemoBackground } from "../../utils/MemoUtils";

type Props = {
    className: string;
    type: MemoType;
    name: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
};

const MemoInput = ({ className, type, name, onChange, disabled }: Props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [value, setValue] = useState(name || "");
    const startEditMode = useCallback(() => {
        setIsEditing(true);
    }, []);
    const endEditMode = useCallback(() => {
        setIsEditing(false);
        const next = value.trim();
        if (onChange && name !== next) {
            onChange(next);
        }
    }, [name, value, onChange]);

    const onTextChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((event) => {
        const text = event.currentTarget.value;
        setValue(text);
    }, []);

    return (
        <div
            className={classNames(styles.container, className)}
            style={{ backgroundImage: `url(${getMemoBackground(type)})` }}
        >
            {isEditing && !disabled ? (
                <textarea
                    className={styles.input}
                    data-testid="MemoInput"
                    autoFocus
                    onBlur={endEditMode}
                    onChange={onTextChange}
                    value={value}
                    onFocus={(e) => e.currentTarget.select()}
                    onKeyDown={(e) => {
                        if (e.key === "Escape") {
                            e.preventDefault();
                            e.currentTarget.blur();
                        }
                    }}
                />
            ) : (
                <div
                    className={styles.textContainer}
                    data-testid="MemoText"
                    onClick={!disabled ? startEditMode : undefined}
                >
                    <p className={styles.text}>{value.trim()}</p>
                </div>
            )}
        </div>
    );
};

MemoInput.defaultProps = {
    className: "",
};

export default MemoInput;
