import React, { useCallback, useEffect, useMemo } from "react";
import MemoCanvas from "./MemoCanvas";
import { RequestState } from "../../hooks/useApiCall";
import { useGetMemos } from "../../hooks/useMemoboardApi";
import { useCreateMemo } from "../../hooks/useMemosApi";
import { DRAG_MEMO_KEY } from "../../utils/DragAndDropUtils";
import { MemoType } from "../../openapi/webservice/models/MemoType";
import { MemoInputModel } from "../../openapi/webservice/models/MemoInputModel";
import { useMemos } from "../../hooks/useMemos";
import { ActionType } from "../../reducers/MemosStateReducer";
import { MEMO_CANVAS_MAX_WIDTH, MEMO_CENTER_POINT, MEMO_SIZE } from "../../utils/CanvasConstants";
import { getBoundedXForMemoCanvas, getBoundedYForMemoCanvas } from "../../utils/CanvasUtils";
import { useCanvasMode } from "../../hooks/useCanvasMode";
import { CanvasMode } from "../../models/CanvasMode";
import { useMemoStateChanges } from "../../hooks/useMemoStateChanges";
import { useBlueprintId, useCellId, useInheritance, useSubContextId } from "../../hooks/useRouteParams";
import { useMemoModalState } from "../../hooks/useMemoModalState";
import { useErrorResponseNotification } from "../../hooks/useErrorNotification";
import { isViewingInheritance } from "../../utils/MemoUtils";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { getMemoboardRoute, getTransactionSearchParams } from "../../utils/RouteUtils";
import { MemoLikeModel } from "../../models/MemoLikeViewModel";

type Props = {
    className: string;
    type: MemoType;
};

const MemoCanvasContainer = ({ className, type }: Props) => {
    const navigate = useNavigate();
    const subContextId = useSubContextId();
    const blueprintId = useBlueprintId();
    const cellId = useCellId();
    const [searchParams] = useSearchParams();
    const [, setModalState] = useMemoModalState();
    const { state, value } = useGetMemos(cellId!, type);
    const [mode] = useCanvasMode();
    const [memos, dispatchMemos] = useMemos();
    const [{ error }, createCallback] = useCreateMemo();
    const { onUpdate, onDelete } = useMemoStateChanges();
    const inheritance = useInheritance();
    useErrorResponseNotification(error);

    const create = useCallback(
        async (event: React.DragEvent<HTMLDivElement>) => {
            if (!event.dataTransfer.getData(DRAG_MEMO_KEY) || mode !== CanvasMode.Edit) {
                return;
            }

            const target = event.currentTarget.getBoundingClientRect();
            const relativeX = event.clientX - target.left;
            const relativeY = event.clientY - target.top;
            const relativeSum = Math.round(relativeX + relativeY);
            const randomRotation = (relativeSum % 10) * Math.pow(-1, relativeSum % 2);
            const memo: MemoInputModel = {
                cellId: cellId!,
                description: "",
                name: "",
                rotationInDegrees: randomRotation,
                type,
                xPosition: getBoundedXForMemoCanvas(relativeX - MEMO_CENTER_POINT),
                yPosition: getBoundedYForMemoCanvas(relativeY - MEMO_CENTER_POINT),
            };

            const created = await createCallback({ memoInputModel: memo });
            dispatchMemos({ type: ActionType.insert, value: created });
        },
        [cellId, type, createCallback, dispatchMemos, mode],
    );

    const redirectToCell = useCallback(
        (memo: MemoLikeModel) =>
            navigate({
                pathname: getMemoboardRoute(subContextId, blueprintId, memo.cellId),
                search: createSearchParams(getTransactionSearchParams(searchParams)).toString(),
            }),
        [navigate, subContextId, blueprintId, searchParams],
    );

    useEffect(() => {
        if (state !== RequestState.DONE) {
            return;
        }

        dispatchMemos({ type: ActionType.set, value: value.memos });
    }, [state, value, dispatchMemos]);

    const displayedMemos = useMemo(() => {
        let items = memos;

        if (isViewingInheritance(inheritance)) {
            const spacing = 20;
            const memosPerRow = Math.round(MEMO_CANVAS_MAX_WIDTH / (MEMO_SIZE + spacing));

            // This would currently fit 44 memos (11x4)
            items = items.map((memo, index) => {
                return {
                    ...memo,
                    xPosition: spacing + (spacing + MEMO_SIZE) * (index % memosPerRow),
                    yPosition: spacing + (spacing + MEMO_SIZE) * Math.floor(index / memosPerRow),
                };
            });
        }

        return items;
    }, [inheritance, memos]);

    return (
        <MemoCanvas
            type={type}
            list={displayedMemos}
            className={className}
            onChange={onUpdate}
            onDelete={onDelete}
            onDrop={create}
            disabled={state !== RequestState.DONE}
            mode={mode}
            inheritance={inheritance}
            onRedirectToCell={redirectToCell}
            openMeta={(note, tab) => {
                setModalState({
                    selectedMemo: note.id,
                    tab: tab,
                });
            }}
        />
    );
};

MemoCanvasContainer.defaultProps = {
    className: "",
};

export default MemoCanvasContainer;
