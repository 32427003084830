/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InheritanceType {
    OWNED = 'OWNED',
    VERTICAL = 'VERTICAL',
    HORIZONTAL = 'HORIZONTAL',
    ANY = 'ANY'
}

export function InheritanceTypeFromJSON(json: any): InheritanceType {
    return InheritanceTypeFromJSONTyped(json, false);
}

export function InheritanceTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InheritanceType {
    return json as InheritanceType;
}

export function InheritanceTypeToJSON(value?: InheritanceType | null): any {
    return value as any;
}

