/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSubContextInputModel
 */
export interface CreateSubContextInputModel {
    /**
     * 
     * @type {string}
     * @memberof CreateSubContextInputModel
     */
    contextName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubContextInputModel
     */
    subContextName: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateSubContextInputModel
     */
    validTimeStart: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateSubContextInputModel
     */
    transactionName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubContextInputModel
     */
    fromTransaction?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubContextInputModel
     */
    toOrganisation?: string;
}

export function CreateSubContextInputModelFromJSON(json: any): CreateSubContextInputModel {
    return CreateSubContextInputModelFromJSONTyped(json, false);
}

export function CreateSubContextInputModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSubContextInputModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contextName': json['contextName'],
        'subContextName': json['subContextName'],
        'validTimeStart': (new Date(json['validTimeStart'])),
        'transactionName': json['transactionName'],
        'fromTransaction': !exists(json, 'fromTransaction') ? undefined : json['fromTransaction'],
        'toOrganisation': !exists(json, 'toOrganisation') ? undefined : json['toOrganisation'],
    };
}

export function CreateSubContextInputModelToJSON(value?: CreateSubContextInputModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contextName': value.contextName,
        'subContextName': value.subContextName,
        'validTimeStart': (value.validTimeStart.toISOString()),
        'transactionName': value.transactionName,
        'fromTransaction': value.fromTransaction,
        'toOrganisation': value.toOrganisation,
    };
}


