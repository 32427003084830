import React, { useCallback, useState } from "react";
import styles from "./RoughAddOrganisationInput.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { isValidString } from "../../utils/ValidationUtils";
import Button from "../Button/Button";
import TextInput from "../TextInput/TextInput";
import BrandLogo from "../BrandLogo/BrandLogo";

type Props = {
    className: string;
    saveCallback: (name: string, logoUri: string) => void;
};

const RoughAddOrganisationInput = ({ className, saveCallback }: Props) => {
    const { t } = useTranslation();
    const [organisationName, setOrganisationName] = useState("");
    const [logoUri, setLogoUri] = useState("");

    const onNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setOrganisationName(e.currentTarget.value);
    }, []);

    const onUriChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setLogoUri(e.currentTarget.value);
    }, []);

    const onSave = useCallback(
        () => saveCallback && saveCallback(organisationName.trim(), logoUri.trim()),
        [saveCallback, organisationName, logoUri],
    );
    return (
        <div className={classNames(styles.container, className)}>
            <TextInput
                className={styles.textInput}
                label={t("organisation.name.placeholder")}
                value={organisationName}
                onChange={onNameChange}
                data-testid="RoughAddOrganisationNameInput"
            />
            <div className={styles.uriInputContainer}>
                <TextInput
                    className={styles.uriInput}
                    label={t("organisation.logo.placeholder")}
                    value={logoUri}
                    onChange={onUriChange}
                    data-testid="RoughAddOrganisationLogoInput"
                />
                <BrandLogo src={logoUri} className={styles.logo} size="default" />
            </div>
            <Button
                label={t("organisation.form.add")}
                displayType="alternative"
                onClick={onSave}
                disabled={!isValidString(organisationName, "") || !isValidString(logoUri, "")}
                className={styles.button}
                data-testid="OrganisationSaveButton"
            />
        </div>
    );
};

RoughAddOrganisationInput.defaultProps = {
    className: "",
};

export default RoughAddOrganisationInput;
