import React, { useEffect } from "react";
import { useGetTransactions } from "../../hooks/useTimelineApi";
import { useTimelineView } from "../../hooks/useTimelineStateChanges";
import { useTimelineTransactions } from "../../hooks/useTimelineTransactions";
import TimelineBar from "./TimelineBar";

type Props = {
    className: string;
};

const TimelineBarContainer = ({ className }: Props) => {
    const [transactions, setTransactions] = useTimelineTransactions();
    const { value } = useGetTransactions();
    const view = useTimelineView();

    useEffect(() => {
        if (!value) {
            return;
        }

        setTransactions(value);
    }, [setTransactions, value]);

    return <TimelineBar className={className} transactions={transactions} view={view} />;
};

TimelineBarContainer.defaultProps = {
    className: "",
};

export default TimelineBarContainer;
