import produce from "immer";
import { DesignContextOverview, DesignContextViewModel, SubContextViewModel } from "../openapi/webservice";

export type ContextsState = DesignContextOverview;

export enum ActionType {
    set,
    updateDesignContext,
    updateSubContext,
}

interface SetContextAction {
    type: ActionType.set;
    value: DesignContextOverview;
}

interface UpdateDesignContextAction {
    type: ActionType.updateDesignContext;
    value: DesignContextViewModel;
    organisationId: string;
}

interface UpdateSubContextAction {
    type: ActionType.updateSubContext;
    value: SubContextViewModel;
    organisationId: string;
}

export type AdminContextsStateAction = SetContextAction | UpdateDesignContextAction | UpdateSubContextAction;

export function getInitialState(): ContextsState {
    return { organisations: [] };
}

export const AdminContextsStateReducer = (state: ContextsState, action: AdminContextsStateAction) => {
    switch (action.type) {
        case ActionType.set:
            return JSON.parse(JSON.stringify(action.value));
        case ActionType.updateDesignContext:
            return produce(state, (draft: ContextsState) => {
                const organisationIndex = draft.organisations.findIndex((o) => o.id === action.organisationId);
                if (organisationIndex === -1) {
                    return;
                }

                const index = draft.organisations[organisationIndex].designContexts.findIndex(
                    (c) => c.id === action.value.id,
                );

                if (index !== -1) {
                    draft.organisations[organisationIndex].designContexts[index] = action.value;
                }
            });
        case ActionType.updateSubContext:
            return produce(state, (draft: ContextsState) => {
                const organisationIndex = draft.organisations.findIndex((o) => o.id === action.organisationId);
                if (organisationIndex === -1) {
                    return;
                }

                const contextId = draft.organisations[organisationIndex].designContexts.findIndex(
                    (c) => c.id === action.value.designContextId,
                );

                if (contextId === -1) {
                    return;
                }

                const subContextId = draft.organisations[organisationIndex].designContexts[
                    contextId
                ].subContexts.findIndex((c) => c.id === action.value.id);

                if (subContextId === -1) {
                    return;
                }

                draft.organisations[organisationIndex].designContexts[contextId].subContexts[subContextId] =
                    action.value;
            });
        default:
            return state;
    }
};
