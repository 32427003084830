import React from "react";
import styles from "./MemoboardPage.module.scss";
import MemoSidebar from "../../components/MemoSidebar/MemoSidebar";
import MemoBoard from "../../components/MemoBoard/MemoBoard";
import { CanvasModeProvider } from "../../contexts/CanvasModeContext";
import { MemoModalProvider } from "../../contexts/MemoModalContext";
import HelpModalContainer from "../../components/HelpModal/HelpModalContainer";
import TimelineBarContainer from "../../components/TimelineBar/TimelineBarContainer";
import { TimelineTransactionsProvider } from "../../contexts/TimelineTransactionsContext";

const MemoboardPage = () => {
    return (
        <React.Fragment>
            <TimelineTransactionsProvider>
                <CanvasModeProvider>
                    <MemoModalProvider>
                        <div className={styles.container}>
                            <MemoSidebar className={styles.sidebar} />
                            <div className={styles.main}>
                                <MemoBoard className={styles.board} />
                                <TimelineBarContainer className={styles.timeline} />
                            </div>
                        </div>
                    </MemoModalProvider>
                </CanvasModeProvider>
            </TimelineTransactionsProvider>
            <HelpModalContainer />
        </React.Fragment>
    );
};

export default MemoboardPage;
