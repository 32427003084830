import React from "react";
import styles from "./Separator.module.scss";
import classNames from "classnames";

type Props = {
    className: string;
    orientation: "-" | "|";
};

const Separator = ({ className, orientation }: Props) => {
    return (
        <div
            className={classNames(
                styles.container,
                orientation === "-" ? styles.horizontal : styles.vertical,
                className,
            )}
        />
    );
};

Separator.defaultProps = {
    className: "",
};

export default Separator;
