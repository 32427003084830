import React, { PropsWithChildren } from "react";
import { Configuration, BlueprintsApi } from "../openapi/webservice";
import { CreateApi, ApiContextProvider } from "./WebServiceApiContext";

const createApi: CreateApi<BlueprintsApi> = (params) => new BlueprintsApi(new Configuration(params));

export const BlueprintsApiContext = React.createContext(createApi({}));

export const BlueprintsApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={BlueprintsApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
