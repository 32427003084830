import React from "react";
import styles from "./ModalCard.module.scss";
import classNames from "classnames";
import { HtmlDivProps } from "../ReusableTypes";

type Props = Omit<HtmlDivProps, "className"> & {
    className: string;
};
const ModalCard = ({ className, ...props }: Props) => {
    return <div className={classNames(styles.container, className)} {...props} />;
};

ModalCard.defaultProps = {
    className: "",
};

export default ModalCard;
