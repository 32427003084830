import React, { SetStateAction, useState } from "react";
import { createContext, Dispatch, PropsWithChildren } from "react";

type Model = { isMemoOutsideOfViewport: boolean };

type ContextType = [Model, Dispatch<SetStateAction<Model>>];

export const MemoBlockContext = createContext<ContextType>([{ isMemoOutsideOfViewport: false }, () => {}]);

export const MemoBlockProvider = ({ children }: PropsWithChildren<{}>) => {
    const state = useState<Model>({ isMemoOutsideOfViewport: false });

    return <MemoBlockContext.Provider value={state}>{children}</MemoBlockContext.Provider>;
};
