import React, { PropsWithChildren } from "react";
import { Configuration, TimelineApi } from "../openapi/webservice";
import { CreateApi, ApiContextProvider } from "./WebServiceApiContext";

const createApi: CreateApi<TimelineApi> = (params) => new TimelineApi(new Configuration(params));

export const TimelineApiContext = React.createContext(createApi({}));

export const TimelineApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={TimelineApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
