import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { TRANSACTION_SEARCHPARAM_KEY } from "../utils/RouterConstants";
import { TimelineDependantFetch } from "../utils/TimelineDependantFetch";
import { useApiCallback } from "./useApiCall";
import { useBlueprintsApi } from "./useBlueprintsApi";
import { useMemoboardApi } from "./useMemoboardApi";
import { useBlueprintId, useCellId } from "./useRouteParams";
import { useVerifiedActiveTransaction } from "./useVerifiedActiveTransaction";

export function useGetBlueprintBreadcrumb() {
    const api = useBlueprintsApi();
    const blueprintId = useBlueprintId();

    const boundFunc = useMemo(() => api.getBlueprintBreadcrumb.bind(api, { id: blueprintId! }), [api, blueprintId]);

    return useApiCallback(boundFunc);
}

export function useGetMemoboardBreadcrumb() {
    const api = useMemoboardApi();
    const [searchParams] = useSearchParams();
    const cellId = useCellId();
    const atDateString = searchParams.get(TRANSACTION_SEARCHPARAM_KEY);
    const validation = useVerifiedActiveTransaction();

    const boundFunc = useMemo(
        () =>
            TimelineDependantFetch(
                validation,
                api.getActiveCellBreadcrumb.bind(api, { id: cellId! }),
                api.getHistoricalCellBreadcrumb.bind(api, {
                    id: cellId!,
                    asOf: atDateString ? new Date(atDateString) : new Date(),
                }),
                {
                    designContextId: undefined,
                    designContextName: undefined,
                    subContextId: undefined,
                    subContextName: undefined,
                    blueprintId: undefined,
                    containerId: undefined,
                    containerName: undefined,
                    cellId: undefined,
                    cellName: undefined,
                },
            ),
        [validation, api, atDateString, cellId],
    );

    return useApiCallback(boundFunc);
}

export function useBreadcrumb(level: number) {
    const blueprintBreadcrumb = useGetBlueprintBreadcrumb();
    const memoboardBreadcrumb = useGetMemoboardBreadcrumb();

    let response;

    switch (level) {
        case 1:
            response = blueprintBreadcrumb;
            break;
        case 2:
            response = memoboardBreadcrumb;
            break;
        default:
            throw new Error(`Unknown breadcrumb level: ${level}`);
    }

    const callback = response[1];

    useEffect(() => {
        callback();
    }, [callback]);

    return response;
}
