/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    CellType,
    CellTypeFromJSON,
    CellTypeFromJSONTyped,
    CellTypeToJSON,
    CellViewModel,
    CellViewModelFromJSON,
    CellViewModelFromJSONTyped,
    CellViewModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContainerViewModel
 */
export interface ContainerViewModel {
    /**
     * 
     * @type {string}
     * @memberof ContainerViewModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContainerViewModel
     */
    description: string;
    /**
     * 
     * @type {Array<CellViewModel>}
     * @memberof ContainerViewModel
     */
    cells: Array<CellViewModel>;
    /**
     * 
     * @type {CellType}
     * @memberof ContainerViewModel
     */
    cellType: CellType;
    /**
     * 
     * @type {number}
     * @memberof ContainerViewModel
     */
    xPosition: number;
    /**
     * 
     * @type {number}
     * @memberof ContainerViewModel
     */
    yPosition: number;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerViewModel
     */
    hasChat: boolean;
}

export function ContainerViewModelFromJSON(json: any): ContainerViewModel {
    return ContainerViewModelFromJSONTyped(json, false);
}

export function ContainerViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'cells': ((json['cells'] as Array<any>).map(CellViewModelFromJSON)),
        'cellType': CellTypeFromJSON(json['cellType']),
        'xPosition': json['xPosition'],
        'yPosition': json['yPosition'],
        'hasChat': json['hasChat'],
    };
}

export function ContainerViewModelToJSON(value?: ContainerViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'cells': ((value.cells as Array<any>).map(CellViewModelToJSON)),
        'cellType': CellTypeToJSON(value.cellType),
        'xPosition': value.xPosition,
        'yPosition': value.yPosition,
        'hasChat': value.hasChat,
    };
}


