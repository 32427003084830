/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChatMessageInputModel,
    ChatMessageInputModelFromJSON,
    ChatMessageInputModelToJSON,
    ChatMessageViewModel,
    ChatMessageViewModelFromJSON,
    ChatMessageViewModelToJSON,
} from '../models';

export interface GetChatForContainerRequest {
    id: string;
    query?: string;
}

export interface GetChatForMemoRequest {
    id: string;
    query?: string;
}

export interface InsertChatForContainerRequest {
    id: string;
    chatMessageInputModel?: ChatMessageInputModel;
}

export interface InsertChatForMemoRequest {
    id: string;
    chatMessageInputModel?: ChatMessageInputModel;
}

/**
 * no description
 */
export class ChatApi extends runtime.BaseAPI {
    /**
     * Get the chat associated with the given container.
     */
    async getChatForContainerRaw(requestParameters: GetChatForContainerRequest): Promise<runtime.ApiResponse<Array<ChatMessageViewModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getChatForContainer.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters['Query'] = requestParameters.query;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Chat/container/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChatMessageViewModelFromJSON));
    }
    /**
     * Get the chat associated with the given container.
     */
    async getChatForContainer(requestParameters: GetChatForContainerRequest): Promise<Array<ChatMessageViewModel>> {
        const response = await this.getChatForContainerRaw(requestParameters);
        return await response.value();
    }
    /**
     * Get the chat associated with the given memo.
     */
    async getChatForMemoRaw(requestParameters: GetChatForMemoRequest): Promise<runtime.ApiResponse<Array<ChatMessageViewModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getChatForMemo.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.query !== undefined) {
            queryParameters['Query'] = requestParameters.query;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Chat/memo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChatMessageViewModelFromJSON));
    }
    /**
     * Get the chat associated with the given memo.
     */
    async getChatForMemo(requestParameters: GetChatForMemoRequest): Promise<Array<ChatMessageViewModel>> {
        const response = await this.getChatForMemoRaw(requestParameters);
        return await response.value();
    }
    /**
     * Add a new message to the chat associated with the given container.
     */
    async insertChatForContainerRaw(requestParameters: InsertChatForContainerRequest): Promise<runtime.ApiResponse<ChatMessageViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insertChatForContainer.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Chat/container/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatMessageInputModelToJSON(requestParameters.chatMessageInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageViewModelFromJSON(jsonValue));
    }
    /**
     * Add a new message to the chat associated with the given container.
     */
    async insertChatForContainer(requestParameters: InsertChatForContainerRequest): Promise<ChatMessageViewModel> {
        const response = await this.insertChatForContainerRaw(requestParameters);
        return await response.value();
    }
    /**
     * Add a new message to the chat associated with the given memo.
     */
    async insertChatForMemoRaw(requestParameters: InsertChatForMemoRequest): Promise<runtime.ApiResponse<ChatMessageViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling insertChatForMemo.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Chat/memo/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChatMessageInputModelToJSON(requestParameters.chatMessageInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ChatMessageViewModelFromJSON(jsonValue));
    }
    /**
     * Add a new message to the chat associated with the given memo.
     */
    async insertChatForMemo(requestParameters: InsertChatForMemoRequest): Promise<ChatMessageViewModel> {
        const response = await this.insertChatForMemoRaw(requestParameters);
        return await response.value();
    }
}
