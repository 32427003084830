import { useParams, useSearchParams } from "react-router-dom";
import { InheritanceType } from "../openapi/webservice";
import { INHERITANCE_KEY } from "../utils/RouterConstants";

export function useCellId() {
    const { cellId } = useParams<{ cellId: string }>();

    return cellId;
}

export function useSubContextId() {
    const { subContextId } = useParams<{ subContextId: string | undefined }>();

    return subContextId;
}

export function useBlueprintId() {
    const { blueprintId } = useParams<{ blueprintId: string | undefined }>();

    return blueprintId;
}

export function useInheritance() {
    const [searchParams] = useSearchParams();
    return (searchParams.get(INHERITANCE_KEY) as InheritanceType) || undefined;
}
