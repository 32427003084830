export const MIN_PASSWORD_LENGTH = 8;

export function isValidString(value: string, original: string) {
    const trimmedValue = value.trim();

    return trimmedValue.length > 0 && trimmedValue !== original.trim();
}

export function generatePassword(length = 20) {
    return Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map((x) => x.toString(36).slice(-1))
        .join("");
}
