import { TransactionValidation } from "../models/TransactionValidation";

export function TimelineDependantFetch<T>(
    validation: TransactionValidation,
    fetchActive: () => Promise<T>,
    fetchHistorical: () => Promise<T>,
    defaultValue: T,
) {
    return function () {
        switch (validation) {
            case TransactionValidation.LoadingOrNotFound:
                return Promise.resolve(defaultValue);
            case TransactionValidation.Valid:
                return fetchActive();
            case TransactionValidation.Invalid:
                return fetchHistorical();
        }
    };
}
