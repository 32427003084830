import { useCallback, useMemo, useState } from "react";

export type RefState<T> = [T, (r: T) => void];

export function useRefState<T>(): RefState<T> {
    const [ref, setRef] = useState<T | undefined>(undefined);
    const setter = useCallback((r: T) => setRef(r), []);

    return useMemo(() => {
        return [ref, setter] as RefState<T>;
    }, [ref, setter]);
}
