import React from "react";
import styles from "./ImageButton.module.scss";
import classNames from "classnames";
import { HtmlButtonProps } from "../ReusableTypes";

type Background = "light" | "dark";

type Props = HtmlButtonProps & {
    className: string;
    src: string;
    rounded: boolean;
    background: Background;
    active?: boolean;
    activeSrc?: string;
    hide: boolean;
};

function getImage(src: string, active?: boolean, activeSrc?: string) {
    if (active && activeSrc) {
        return activeSrc;
    }

    return src;
}

const ImageButton = ({ className, src, rounded, background, active, activeSrc, hide, disabled, ...props }: Props) => {
    return (
        <button
            data-testid="ImageButton"
            aria-label="ImageButton"
            {...props}
            disabled={disabled || hide}
            className={classNames(
                styles.container,
                rounded && styles.rounded,
                styles[background],
                active && styles.active,
                hide && styles.hide,
                className,
            )}
        >
            <img
                className={styles.image}
                src={getImage(src, active, activeSrc)}
                alt="Icon"
                draggable={props.draggable}
            />
        </button>
    );
};

ImageButton.defaultProps = {
    className: "",
    rounded: false,
    background: "light",
    hide: false,
};

export default ImageButton;
