import React from "react";
import { PropsWithChildren } from "react";
import {
    BlueprintsApi,
    CellViewModel,
    ChatMessageViewModel,
    ConnectionViewModel,
    ContainerViewModel,
    DesignContextOverview,
    DesignContextViewModel,
    OrganisationsApi,
    SubContextViewModel,
    TimelineApi,
    TransactionState,
    TransactionViewModel,
    UsersApi,
} from "../openapi/webservice";
import { AuthenticationApi } from "../openapi/webservice/apis/AuthenticationApi";
import { ChatApi } from "../openapi/webservice/apis/ChatApi";
import { ContextsApi } from "../openapi/webservice/apis/ContextsApi";
import { MemoboardApi } from "../openapi/webservice/apis/MemoboardApi";
import { MemosApi } from "../openapi/webservice/apis/MemosApi";
import { InheritanceType } from "../openapi/webservice/models/InheritanceType";
import { MemoType } from "../openapi/webservice/models/MemoType";
import { MemoViewModel } from "../openapi/webservice/models/MemoViewModel";
import {
    generateBlueprint,
    generateBlueprintBreadcrumb,
    generateChatMessages,
    generateContexts,
    generateDesignContextOverview,
    generateMemoboardBreadcrumb,
    generateMemoNotes,
    generateOrganisationModels,
    generateTimeLineTransactions,
    generateUsers,
} from "../utils/FakeItTillYouMakeIt";
import { cyrb53 } from "../utils/HashUtils";
import { AuthenticationApiContext } from "./AuthenticationApiContext";
import { ChatApiContext } from "./ChatApiContext";
import { ContextsApiContext } from "./ContextsApiContext";
import { MemoboardApiContext } from "./MemoboardApiContext";
import { MemosApiContext } from "./MemosApiContext";
import { TimelineApiContext } from "./TimelineApiContext";
import { faker } from "@faker-js/faker";
import produce from "immer";
import { BlueprintsApiContext } from "./BlueprintsApiContext";
import { sleep } from "../utils/AsyncUtils";
import { UsersApiContext } from "./UsersApiContext";
import { OrganisationsApiContext } from "./OrganisationsApiContext";

function getInitialBlueprint() {
    return generateBlueprint(55555512);
}

function getInitialAdminContexts() {
    return generateDesignContextOverview(3, 123912);
}
function getInitialContexts() {
    return generateContexts(3, 123912);
}

function getInitialUsers() {
    return generateUsers(20, 937);
}

function getInitialOrganisations() {
    return generateOrganisationModels(5, 951);
}

function getInitialTimelineTransactions() {
    return generateTimeLineTransactions(3, 7333);
}

function getInitialChatMessages(): { [key: string]: ChatMessageViewModel[] } {
    return {};
}

function getInitialMemoBlocks(): { [type in MemoType]: MemoViewModel[] } {
    return {
        [MemoType.Tables]: generateMemoNotes(3, MemoType.Tables, 3),
        [MemoType.Interfaces]: [],
        [MemoType.Mutations]: [],
        [MemoType.Processes]: [],
        [MemoType.Validations]: [],
        [MemoType.Computations]: [
            {
                ...generateMemoNotes(1, MemoType.Computations, 77)[0],
                xPosition: 30,
                yPosition: 80,
                rotationInDegrees: 0,
                hasChat: true,
                hasPlanning: true,
            },
        ],
    };
}

let memoBlockLists = getInitialMemoBlocks();
let users = getInitialUsers();
let organisations = getInitialOrganisations();
let containerChatMessages = getInitialChatMessages();
let memoChatMessages = getInitialChatMessages();
let timelineTransactions = getInitialTimelineTransactions();

const MockAuthenticationAPI = {
    login: async (request) => {
        const { username, password } = request.loginInputModel!;
        let token;

        if (username === "demo@sixblockssolution.com" && password === "123456blokjes") {
            token = { token: "jwt" };
        }

        return Promise.resolve(token);
    },
} as AuthenticationApi;

const MockUsersAPI = {
    getSelf: () => {
        return Promise.resolve(generateUsers(1, 1252)[0]);
    },
    getUsers: () => {
        return Promise.resolve(users);
    },
    createUser: (request) => {
        const [user] = generateUsers(1);
        const { organisation, ...inputFields } = request.userInputModel!;
        const next = {
            ...user,
            ...inputFields,
            organisation: { ...generateOrganisationModels(1)[0], id: organisation },
        };

        users = [...users, next];
        return Promise.resolve(user);
    },
    deleteUser: (request) => {
        users = users.filter((u) => u.id !== request.userId);
        return Promise.resolve();
    },
    updateUser: (request) => {
        const input = request.userInputModel!;
        const index = users.findIndex((u) => u.id === request.userId);
        if (index === -1) {
            throw new Error("Update failed, user not found");
        }

        const next = produce(users, (draft) => {
            const { organisation, ...inputFields } = input;
            draft[index] = {
                ...inputFields,
                id: request.userId,
                organisation: { ...generateOrganisationModels(1)[0], id: organisation },
            };
        });

        users = next;
        return Promise.resolve(users[index]);
    },
} as UsersApi;

const MockOrganisationsAPI = {
    getOrganisations: () => {
        return Promise.resolve(organisations);
    },
    createOrganisation: (request) => {
        const next = {
            ...generateOrganisationModels(1)[0],
            name: request.organisationInputModel!.name,
            logoUri: request.organisationInputModel!.logoUri,
        };

        organisations = [...organisations, next];
        return Promise.resolve(next);
    },
    deleteOrganisation: (request) => {
        organisations = organisations.filter((o) => o.id !== request.organisationId);
        return Promise.resolve();
    },
    updateOrganisation: (request) => {
        const input = request.organisationInputModel!;
        const index = organisations.findIndex((o) => o.id === request.organisationId);
        if (index === -1) {
            throw new Error("Update failed, organisation not found");
        }

        const next = produce(organisations, (draft) => {
            draft[index] = {
                ...input,
                id: request.organisationId,
            };
        });

        organisations = next;
        return Promise.resolve(organisations[index]);
    },
} as OrganisationsApi;

const MockTimelineAPI = {
    // eslint-disable-next-line
    getTimeline: (request) => {
        return Promise.resolve(timelineTransactions);
    },
    // eslint-disable-next-line
    setInProgressTransactionToSolution: (request) => {
        timelineTransactions = produce(timelineTransactions, (draft) => {
            draft.map((m) => {
                m.state = TransactionState.Solution;
                return m;
            });
        });

        return sleep(100);
    },
    createNewTransaction: (request) => {
        const next: TransactionViewModel = {
            ...request.transactionInputModel!,
            subContextId: request.subContextId,
            state: TransactionState.InProgress,
            validTimeEnd: new Date(),
            id: faker.datatype.uuid(),
        };

        timelineTransactions = produce(timelineTransactions, (draft) => {
            draft.push(next);
        });

        return Promise.resolve(next);
    },
} as TimelineApi;

const MockMemoboardAPI = {
    getMemosByTypeActive: (request) => {
        let list = memoBlockLists[request.type!];
        if (request.inheritance === InheritanceType.HORIZONTAL || request.inheritance === InheritanceType.VERTICAL) {
            list = list.map((memo) => ({ ...memo, name: `Inheritance: ${request.inheritance}\n${memo.name}` }));
        }
        return Promise.resolve({ memos: list });
    },
    getMemosByTypeHistorical: (request) => {
        let list = memoBlockLists[request.type!];
        if (request.inheritance === InheritanceType.HORIZONTAL || request.inheritance === InheritanceType.VERTICAL) {
            list = list.map((memo) => ({ ...memo, name: `Inheritance: ${request.inheritance}\n${memo.name}` }));
        }
        return Promise.resolve({ memos: list });
    },
    // eslint-disable-next-line
    getActiveCellBreadcrumb: (request) => {
        return Promise.resolve(generateMemoboardBreadcrumb(9));
    },
    // eslint-disable-next-line
    getHistoricalCellBreadcrumb: (request) => {
        return Promise.resolve(generateMemoboardBreadcrumb(99));
    },
} as MemoboardApi;

const MockMemosAPI = {
    insertMemo: (request) => {
        const hash = cyrb53(
            request.memoInputModel?.name! +
                request.memoInputModel?.xPosition +
                request.memoInputModel?.yPosition +
                request.memoInputModel?.rotationInDegrees +
                request.memoInputModel?.cellId +
                request.memoInputModel?.type,
        );

        const [memo] = generateMemoNotes(1, MemoType.Computations, hash);
        const next = { ...memo, ...request.memoInputModel };

        memoBlockLists[request.memoInputModel?.type!].push(next);
        return Promise.resolve(next);
    },

    correct: (request) => {
        const list = memoBlockLists[request.memoInputModel?.type!];
        const index = list.findIndex((model) => model.id === request.id);
        if (index === -1) {
            return Promise.reject(`Memo with id "${request.id}" not found `);
        }

        const next = {
            ...request.memoInputModel!,
            inheritanceType: InheritanceType.OWNED,
            id: request.id,
            hasPlanning: false,
            hasChat: false,
        };

        memoBlockLists[request.memoInputModel?.type!][index] = next;
        return Promise.resolve(next);
    },
    deleteMemo: (request) => {
        let result: MemoViewModel | undefined = undefined;

        for (const list of Object.values(memoBlockLists)) {
            const index = list.findIndex((model) => model.id === request.id);
            if (index > -1) {
                const [deleted] = list.splice(index, 1);
                result = deleted;
            }
        }

        if (!result) {
            return Promise.reject(`Deleting memo with id "${request.id}" not found.`);
        }

        return Promise.resolve(result);
    },
} as MemosApi;

const MockChatAPI = {
    getChatForContainer: (request) => {
        if (!containerChatMessages[request.id]) {
            containerChatMessages[request.id] = generateChatMessages(3, 10);
        }
        return Promise.resolve(containerChatMessages[request.id]);
    },
    getChatForMemo: (request) => {
        if (!memoChatMessages[request.id]) {
            memoChatMessages[request.id] = generateChatMessages(5, 11);
        }
        return Promise.resolve(memoChatMessages[request.id]);
    },
    insertChatForContainer: (request) => {
        const [message] = generateChatMessages(1);
        const next = { ...message, ...request.chatMessageInputModel };

        containerChatMessages[request.id] = [...containerChatMessages[request.id], next];
        return Promise.resolve(next);
    },
    insertChatForMemo: (request) => {
        const [message] = generateChatMessages(1);
        const next = { ...message, ...request.chatMessageInputModel };

        memoChatMessages[request.id] = [...memoChatMessages[request.id], next];
        return Promise.resolve(next);
    },
} as ChatApi;

let designContexts = getInitialContexts();
let adminDesignContexts = getInitialAdminContexts();

enum ContextScope {
    Unknown,
    Organisation,
    All,
}

function getContextScope(
    organisationContexts: DesignContextViewModel[],
    overview: DesignContextOverview,
    contextId: string,
) {
    const allContexts = overview.organisations.flatMap((o) => o.designContexts);

    if (organisationContexts.find((c) => c.id === contextId)) {
        return ContextScope.Organisation;
    } else if (allContexts.find((c) => c.id === contextId)) {
        return ContextScope.All;
    }

    return ContextScope.Unknown;
}

function getScopedDesignContexts(scope: ContextScope, contextId: string): DesignContextViewModel[] {
    switch (scope) {
        case ContextScope.All:
            return (
                adminDesignContexts.organisations.find((o) => !!o.designContexts.find((c) => c.id === contextId))
                    ?.designContexts || []
            );
        case ContextScope.Organisation:
            return designContexts;
        default:
            return [];
    }
}

function setScopedDesignContexts(scope: ContextScope, contextId: string, next: DesignContextViewModel[]) {
    switch (scope) {
        case ContextScope.All:
            const orgIndex = adminDesignContexts.organisations.findIndex(
                (o) => !!o.designContexts.find((c) => c.id === contextId),
            );

            if (orgIndex === -1) {
                return;
            }

            adminDesignContexts = produce(adminDesignContexts, (draft) => {
                draft.organisations[orgIndex].designContexts = next;
            });
            break;
        case ContextScope.Organisation:
            designContexts = next;
            break;
        default:
            break;
    }
}

const MockContextsAPI = {
    getAllDesignContexts: () => {
        return Promise.resolve(adminDesignContexts);
    },
    getDesignContexts: () => {
        return Promise.resolve(designContexts);
    },
    createSubContext: (request) => {
        const { contextName, subContextName } = request.createSubContextInputModel!;
        let added: SubContextViewModel;
        const index = designContexts.findIndex((c) => c.name === contextName);
        const next = produce(designContexts, (draft) => {
            let context;
            if (index >= 0) {
                context = draft[index];
            } else {
                context = {
                    id: faker.datatype.uuid(),
                    name: contextName,
                    subContexts: [],
                };
                draft.push(context);
            }

            const subContext = {
                blueprintId: faker.datatype.uuid(),
                id: faker.datatype.uuid(),
                designContextId: context.id,
                name: subContextName,
            } as SubContextViewModel;

            context.subContexts.push(subContext);
            added = subContext;
        });

        designContexts = next;

        return Promise.resolve({ contexts: next, created: added! });
    },
    updateSubContext: (request) => {
        const { name } = request.subContextInputModel!;
        const contextId = request.contextId;
        const scope = getContextScope(designContexts, adminDesignContexts, contextId);
        const contexts = getScopedDesignContexts(scope, contextId);
        const contextIndex = contexts.findIndex((c) => c.id === contextId);
        if (contextIndex === -1) {
            throw new Error("Update failed, design context not found");
        }

        const subContextIndex = contexts[contextIndex].subContexts.findIndex((c) => c.id === request.subContextId);
        if (subContextIndex === -1) {
            throw new Error("Update failed, subcontext not found");
        }

        const next = produce(contexts, (draft) => {
            draft[contextIndex].subContexts[subContextIndex].name = name;
        });

        setScopedDesignContexts(scope, contextId, next);

        return Promise.resolve(next[contextIndex].subContexts[subContextIndex]);
    },
    updateDesignContext: (request) => {
        const { name } = request.designContextInputModel!;
        const contextId = request.id;
        const scope = getContextScope(designContexts, adminDesignContexts, contextId);
        const contexts = getScopedDesignContexts(scope, contextId);
        const contextIndex = contexts.findIndex((c) => c.id === contextId);
        if (contextIndex === -1) {
            throw new Error("Update failed, design context not found");
        }

        const next = produce(contexts, (draft) => {
            draft[contextIndex].name = name;
        });

        setScopedDesignContexts(scope, contextId, next);

        return Promise.resolve(next[contextIndex]);
    },
    deleteSubContext: (request) => {
        const { contextId, subContextId } = request;
        const scope = getContextScope(designContexts, adminDesignContexts, contextId);
        const contexts = getScopedDesignContexts(scope, contextId);
        const contextIndex = contexts.findIndex((c) => c.id === contextId);
        if (contextIndex === -1) {
            throw new Error("Update failed, design context not found");
        }

        const subContextIndex = contexts[contextIndex].subContexts.findIndex((c) => c.id === subContextId);
        if (subContextIndex === -1) {
            throw new Error("Update failed, subcontext not found");
        }

        const next = produce(contexts, (draft) => {
            draft[contextIndex].subContexts.splice(subContextIndex, 1);

            if (draft[contextIndex].subContexts.length === 0) {
                draft.splice(contextIndex, 1);
            }
        });

        setScopedDesignContexts(scope, contextId, next);

        return Promise.resolve();
    },
} as ContextsApi;

export let blueprint = getInitialBlueprint();

const MockBlueprintsAPI = {
    // eslint-disable-next-line
    getHistoricalBlueprint: (request) => {
        return Promise.resolve(blueprint);
    },
    // eslint-disable-next-line
    getActiveBlueprint: (request) => {
        return Promise.resolve({ ...blueprint, name: "Active" });
    },
    insertConnection: (request) => {
        const connection = { ...request.connectionInputModel!, id: faker.datatype.uuid() } as ConnectionViewModel;

        blueprint = produce(blueprint, (draft) => {
            draft.connections.push(connection);
        });

        return Promise.resolve(connection);
    },
    insertContainer: (request) => {
        var containerId = faker.datatype.uuid();
        const container = {
            ...request.containerInputModel!,
            id: containerId,
            cells: [
                {
                    id: faker.datatype.uuid(),
                    containerId: containerId,
                    connections: [],
                    name: "",
                    index: 0,
                },
            ],
            hasChat: false,
        } as ContainerViewModel;

        blueprint = produce(blueprint, (draft) => {
            draft.containers.push(container);
        });
        return Promise.resolve(container);
    },
    insertNewCell: (request) => {
        const cell = {
            ...request.cellInputModel!,
            id: faker.datatype.uuid(),
            connections: [],
            containerId: request.containerId,
        } as CellViewModel;

        blueprint = produce(blueprint, (draft) => {
            const container = draft.containers.find((model) => model.id === request.containerId);
            if (!container) {
                throw new Error("Container not found while inserting new cell");
            }
            container.cells.splice(cell.index, 0, cell);
            container.cells = container.cells.map((c, index) => {
                // quick and dirty way to shift all index values
                return { ...c, index: index };
            });
        });

        const container = blueprint.containers.find((model) => model.id === request.containerId);
        return Promise.resolve(container);
    },
    updateCellName: (request) => {
        blueprint = produce(blueprint, (draft) => {
            const container = draft.containers.find((model) => model.cells.find((c) => c.id === request.cellId));
            if (!container) {
                throw new Error("Container not found while updating cell name");
            }

            const cell = container.cells.find((c) => c.id === request.cellId);

            if (!cell) {
                throw new Error("Cell not found while updating cell name");
            }

            cell.name = request.body || "";
        });

        return Promise.resolve(blueprint.containers.find((model) => model.cells.find((c) => c.id === request.cellId)));
    },
    updateContainerName: (request) => {
        blueprint = produce(blueprint, (draft) => {
            const container = draft.containers.find((model) => model.id === request.containerId);
            if (!container) {
                throw new Error("Container not found while updating container name");
            }

            container.name = request.body || "";
        });

        return Promise.resolve(blueprint.containers.find((model) => model.id === request.containerId));
    },
    updateContainerDescription: (request) => {
        blueprint = produce(blueprint, (draft) => {
            const container = draft.containers.find((model) => model.id === request.containerId);
            if (!container) {
                throw new Error("Container not found while updating container description");
            }

            container.description = request.body || "";
        });

        return Promise.resolve(blueprint.containers.find((model) => model.id === request.containerId));
    },
    updateContainerPosition: (request) => {
        blueprint = produce(blueprint, (draft) => {
            const container = draft.containers.find((model) => model.id === request.containerId);
            if (!container) {
                throw new Error("Container not found while updating container position");
            }

            container.xPosition = request.doubleDoubleTuple!.item1!;
            container.yPosition = request.doubleDoubleTuple!.item2!;
        });

        return Promise.resolve(blueprint.containers.find((model) => model.id === request.containerId));
    },
    updateContainerPositions: (request) => {
        blueprint = produce(blueprint, (draft) => {
            const input = request.containerPositionBulkUpdateInputModel?.inputs;
            if (!input) {
                return;
            }

            input.forEach((input) => {
                const container = draft.containers.find((model) => model.id === input.id);
                if (!container) {
                    throw new Error("Container not found while updating container positions");
                }

                container.xPosition = input.xPosition;
                container.yPosition = input.yPosition;
            });
        });

        return Promise.resolve(blueprint.containers);
    },
    deleteCell: (request) => {
        blueprint = produce(blueprint, (draft) => {
            const container = draft.containers.find((model) => model.cells.find((c) => c.id === request.cellId));
            if (!container) {
                throw new Error("Container not found while deleting cell");
            }

            container.cells = container.cells
                .filter((c) => c.id !== request.cellId)
                .map((c, index) => {
                    c.index = index;
                    return c;
                });

            draft.connections = draft.connections.filter(
                (c) => c.fromCellId !== request.cellId && c.toCellId !== request.cellId,
            );
        });

        return Promise.resolve(blueprint);
    },
    deleteContainer: (request) => {
        blueprint = produce(blueprint, (draft) => {
            const containerCells = draft.containers.find((c) => c.id === request.containerId)?.cells.map((c) => c.id);
            draft.containers = draft.containers.filter((c) => c.id !== request.containerId);

            if (!containerCells) {
                return;
            }

            draft.connections = draft.connections.filter(
                (c) => !containerCells.includes(c.fromCellId) && !containerCells.includes(c.toCellId),
            );
        });

        return Promise.resolve(blueprint);
    },
    deleteConnection: (request) => {
        blueprint = produce(blueprint, (draft) => {
            draft.connections = draft.connections.filter((c) => c.id !== request.connectionId);
        });

        return Promise.resolve(blueprint);
    },
    // eslint-disable-next-line
    getBlueprintBreadcrumb: (request) => {
        return Promise.resolve(generateBlueprintBreadcrumb(92));
    },
} as BlueprintsApi;

export function RestoreInitialMockData() {
    blueprint = getInitialBlueprint();
    containerChatMessages = getInitialChatMessages();
    memoChatMessages = getInitialChatMessages();
    memoBlockLists = getInitialMemoBlocks();
    designContexts = getInitialContexts();
    adminDesignContexts = getInitialAdminContexts();
    timelineTransactions = getInitialTimelineTransactions();
    users = getInitialUsers();
    organisations = getInitialOrganisations();
}

export const TempProvider = ({ children }: PropsWithChildren<{}>) => {
    return (
        <AuthenticationApiContext.Provider value={MockAuthenticationAPI}>
            <UsersApiContext.Provider value={MockUsersAPI}>
                <BlueprintsApiContext.Provider value={MockBlueprintsAPI}>
                    <ContextsApiContext.Provider value={MockContextsAPI}>
                        <ChatApiContext.Provider value={MockChatAPI}>
                            <TimelineApiContext.Provider value={MockTimelineAPI}>
                                <OrganisationsApiContext.Provider value={MockOrganisationsAPI}>
                                    <MemoboardApiContext.Provider value={MockMemoboardAPI}>
                                        <MemosApiContext.Provider value={MockMemosAPI}>
                                            {children}
                                        </MemosApiContext.Provider>
                                    </MemoboardApiContext.Provider>
                                </OrganisationsApiContext.Provider>
                            </TimelineApiContext.Provider>
                        </ChatApiContext.Provider>
                    </ContextsApiContext.Provider>
                </BlueprintsApiContext.Provider>
            </UsersApiContext.Provider>
        </AuthenticationApiContext.Provider>
    );
};
