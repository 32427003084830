import React, { PropsWithChildren } from "react";
import styles from "./ModalHeader.module.scss";
import classNames from "classnames";
import ImageButton from "../ImageButton/ImageButton";
import { CrossIcon } from "../../assets/images/Images";
import Separator from "../Separator/Separator";
import { HeaderColor } from "../../models/HeaderColor";

type Props = PropsWithChildren<{
    className: string;
    onClose?: () => void;
    color: HeaderColor;
}>;

const ModalHeader = ({ className, onClose, color, children }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <div className={classNames(styles.topLine, styles[color])} />
            <div className={styles.headerContainer}>
                <div className={styles.content}>{children}</div>
                <Separator className={styles.headerItem} orientation="|" />
                <ImageButton className={styles.headerItem} src={CrossIcon} onClick={onClose} data-testid="CloseModal" />
            </div>
        </div>
    );
};

ModalHeader.defaultProps = {
    className: "",
};

export default ModalHeader;
