import { useMemo } from "react";
import { ContainerViewModel } from "../openapi/webservice";
import { getBounds } from "../utils/CanvasUtils";

const MINIMAP_SCALE = 0.2;

export function useBlueprintMinimap(
    canvasHeight: number,
    canvasWidth: number,
    containers: ContainerViewModel[],
    dragPos: { x: number; y: number },
) {
    const { top, left, right, bottom } = useMemo(() => getBounds(containers), [containers]);

    // minimap = frame that displayes the full map
    // grid = actual content that's contained on the map
    const minimapWidth = canvasWidth * MINIMAP_SCALE;
    const minimapHeight = canvasHeight * MINIMAP_SCALE;
    const gridWidth =
        // Determine most left coordinate and most right coordinate
        Math.abs(Math.min(dragPos.x, left, 0)) + Math.abs(Math.max(dragPos.x + canvasWidth, canvasWidth, right));
    const gridHeight =
        // Determine most top coordinate and most bottom coordinate
        Math.abs(Math.min(dragPos.y, top, 0)) + Math.abs(Math.max(dragPos.y + canvasHeight, canvasHeight, bottom));
    const gridScale = Math.min(minimapHeight / gridHeight, minimapWidth / gridWidth);

    const isGridLargerThanViewport = canvasHeight === 0 || canvasWidth === 0 ? false : true;

    const strokeWidth = 1.2 / gridScale;

    const offset = { x: Math.min(0, left, dragPos.x), y: Math.min(0, top, dragPos.y) };

    return {
        canvasHeight,
        canvasWidth,
        offset,
        gridScale,
        minimapWidth,
        minimapHeight,
        isGridLargerThanViewport,
        strokeWidth,
    };
}
