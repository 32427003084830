import React, { useCallback } from "react";
import { DesignContextViewModel, SubContextViewModel } from "../../openapi/webservice";
import RoughContextManagementInput from "./RoughContextManagementInput";
import { useAdminContextStateChanges } from "../../hooks/useAdminContextsStateChanges";

type Props = {
    className: string;
    subContext: SubContextViewModel;
    context: DesignContextViewModel;
    organisationId: string;
};

// @NOTE(Lejun) As the name suggests, this is a rough component used for some internal usage.
//              In later versions this should definitely be replaced.
const TempSubContextManagementInput = ({
    className,
    subContext: { id, designContextId, name },
    context: { name: parentName },
    organisationId,
}: Props) => {
    const { updateSubContext, deleteSubContext } = useAdminContextStateChanges();

    const onSave = useCallback(
        (value: string) => {
            updateSubContext(organisationId, designContextId, id, { name: value });
        },
        [updateSubContext, designContextId, id, organisationId],
    );

    const onDelete = useCallback(() => {
        deleteSubContext(designContextId, id);
    }, [deleteSubContext, designContextId, id]);

    return (
        <RoughContextManagementInput
            title=""
            name={name}
            className={className}
            parentName={parentName}
            saveCallback={onSave}
            deleteCallback={onDelete}
        />
    );
};

TempSubContextManagementInput.defaultProps = {
    className: "",
};

export default TempSubContextManagementInput;
