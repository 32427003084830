import { useContext, useMemo } from "react";
import { useApiCall, useApiCallback } from "./useApiCall";
import { OrganisationsApiContext } from "../contexts/OrganisationsApiContext";

export function useOrganisationsApi() {
    return useContext(OrganisationsApiContext);
}

export function useGetOrganisations() {
    const api = useOrganisationsApi();
    const boundFunc = useMemo(() => api.getOrganisations.bind(api), [api]);

    return useApiCall(boundFunc);
}

export function useGetOrganisationsCallback() {
    const api = useOrganisationsApi();
    const boundFunc = useMemo(() => api.getOrganisations.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useCreateOrganisation() {
    const api = useOrganisationsApi();
    const boundFunc = useMemo(() => api.createOrganisation.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useDeleteOrganisation() {
    const api = useOrganisationsApi();
    const boundFunc = useMemo(() => api.deleteOrganisation.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useUpdateOrganisation() {
    const api = useOrganisationsApi();
    const boundFunc = useMemo(() => api.updateOrganisation.bind(api), [api]);

    return useApiCallback(boundFunc);
}
