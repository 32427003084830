import { useCallback, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { TimelineApiContext } from "../contexts/TimelineApiContext";
import { TRANSACTION_ACTIVE_SEARCHPARAM_KEY } from "../utils/RouterConstants";
import { useApiCall, useApiCallback } from "./useApiCall";
import { useSubContextId } from "./useRouteParams";

export function useTimelineApi() {
    return useContext(TimelineApiContext);
}

export function useGetTransactions() {
    const api = useTimelineApi();
    const subContextId = useSubContextId();
    const [searchParams] = useSearchParams();
    const isActiveTransaction = searchParams.get(TRANSACTION_ACTIVE_SEARCHPARAM_KEY);

    const boundFunc = useCallback(() => {
        if (!subContextId) {
            throw new Error("Missing subcontext id");
        }

        return api.getTimeline({ subContextId });
        // When isActiveTransaction is changed, then the timeline should be fetched
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, subContextId, isActiveTransaction]);

    return useApiCall(boundFunc);
}

export function useSetTransactionToSolution() {
    const api = useTimelineApi();
    const subContextId = useSubContextId();

    const boundFunc = useCallback(() => {
        if (!subContextId) {
            throw new Error("Missing subcontext id");
        }

        return api.setInProgressTransactionToSolution({ subContextId });
    }, [api, subContextId]);

    return useApiCallback(boundFunc);
}

export function useCreateNewTransaction() {
    const api = useTimelineApi();
    const subContextId = useSubContextId();

    const boundFunc = useCallback(
        (name: string, startDate: Date) => {
            if (!subContextId) {
                throw new Error("Missing subcontext id");
            }

            return api.createNewTransaction({
                subContextId,
                transactionInputModel: { name, validTimeStart: startDate },
            });
        },
        [api, subContextId],
    );

    return useApiCallback(boundFunc);
}

export function useDeleteAllChanges() {
    const api = useTimelineApi();
    const subContextId = useSubContextId();

    const boundFunc = useCallback(() => {
        if (!subContextId) {
            throw new Error("Missing subcontext id");
        }

        return api.deleteAllCurrentChanges({
            subContextId,
        });
    }, [api, subContextId]);

    return useApiCallback(boundFunc);
}
