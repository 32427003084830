import classNames from "classnames";
import React, { useCallback, useState } from "react";
import Button from "../Button/Button";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import TextInput from "../TextInput/TextInput";
import styles from "./RoughContextManagementInput.module.scss";

type Props = {
    className: string;
    name: string;
    title: string;
    parentName?: string;
    saveCallback?: (name: string) => void;
    deleteCallback?: () => void;
};

function isValidName(name: string, original: string) {
    const trimmedName = name.trim();

    return trimmedName.length > 0 && trimmedName !== original.trim();
}

function getCombinedContextName(subContext: string, context?: string) {
    if (!context) {
        return subContext;
    }

    return `${context} - ${subContext}`;
}
// @NOTE(Lejun) As the name suggests, this is a rough component used for some internal usage.
//              In later versions this should definitely be replaced.
const RoughContextManagementInput = ({ className, name, title, parentName, saveCallback, deleteCallback }: Props) => {
    const [value, setValue] = useState(name);
    const [isDeleteModalOpen, setShowDeleteModal] = useState(false);
    const closeDeleteModal = useCallback(() => setShowDeleteModal(false), []);
    const openDeleteModal = useCallback(() => setShowDeleteModal(true), []);

    const onNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
        setValue(e.currentTarget.value);
    }, []);

    const onSave = useCallback(() => saveCallback && saveCallback(value.trim()), [saveCallback, value]);
    const onDelete = useCallback(() => {
        deleteCallback && deleteCallback();
        closeDeleteModal();
    }, [deleteCallback, closeDeleteModal]);

    return (
        <div className={classNames(styles.container, className)}>
            <TextInput
                label={title}
                maxLength={28}
                className={styles.textInput}
                placeholder={title}
                value={value}
                onChange={onNameChange}
                data-testid="RoughContextManagementInput"
            />
            <Button
                label="Opslaan"
                displayType="alternative"
                onClick={onSave}
                disabled={!isValidName(value, name)}
                className={styles.button}
                data-testid="ContextSaveButton"
            />
            {deleteCallback && (
                <Button label="Verwijderen" displayType="hollow" onClick={openDeleteModal} className={styles.button} />
            )}
            {isDeleteModalOpen && (
                <ConfirmationModal
                    title="Bevestiging"
                    message={`Weet je zeker dat je "${getCombinedContextName(name, parentName)}" wilt verwijderen?`}
                    yesLabel="Ja, verwijder"
                    noLabel="Annuleren"
                    onConfirm={onDelete}
                    onCancel={closeDeleteModal}
                />
            )}
        </div>
    );
};

RoughContextManagementInput.defaultProps = {
    className: "",
};

export default RoughContextManagementInput;
