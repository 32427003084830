import React from "react";
import { Slide, toast, ToastContent } from "react-toastify";
import { JsonError, StringError } from "../models/DotnetError";

export function notifySuccess(content: ToastContent, id?: string) {
    toast.success(content, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        transition: Slide,
        toastId: id,
    });
}

function notify(content: ToastContent, id?: string) {
    toast.error(content, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        transition: Slide,
        toastId: id,
    });
}

export function notifyWarn(content: ToastContent, id?: string) {
    toast.warn(content, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        transition: Slide,
        toastId: id,
    });
}

export function NotifyError(message: string, e: Error) {
    notify(`${message}: ${e.message}`, `NotifyError ${e.name}`);
}

export function NotifyStringError(message: string, e: StringError, id?: string) {
    notify(`${message}: [${e.status}] ${e.message}`, id);
}

export function NotifyJsonError(message: string, e: JsonError, id?: string) {
    notify(
        <div>
            <div>{`${message}: [${e.status}]`}</div>
            <ul style={{ marginLeft: 20 }}>
                {Object.entries(e.errors).map(([key, [message]]) => (
                    <li key={key}>{`${key}: ${message}`}</li>
                ))}
            </ul>
        </div>,
        id,
    );
}

export function ClearNotification(id?: string) {
    if (!id) {
        return;
    }

    toast.dismiss(id);
}
