import { useContext, useMemo } from "react";
import { AuthenticationApiContext } from "../contexts/AuthenticationApiContext";
import { useApiCallback } from "./useApiCall";

export function useAuthenticationApi() {
    return useContext(AuthenticationApiContext);
}

export function useLogin() {
    const api = useAuthenticationApi();
    const boundFunc = useMemo(() => api.login.bind(api), [api]);

    return useApiCallback(boundFunc);
}
