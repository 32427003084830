import { useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BlueprintsApiContext } from "../contexts/BlueprintsApiContext";
import { TRANSACTION_ACTIVE_SEARCHPARAM_KEY, TRANSACTION_SEARCHPARAM_KEY } from "../utils/RouterConstants";
import { getContextName } from "../utils/StringUtils";
import { TimelineDependantFetch } from "../utils/TimelineDependantFetch";
import { useApiCall, useApiCallback } from "./useApiCall";
import { useBlueprintId } from "./useRouteParams";
import { useVerifiedActiveTransaction } from "./useVerifiedActiveTransaction";

export function useBlueprintsApi() {
    return useContext(BlueprintsApiContext);
}

export function useGetBlueprint() {
    const api = useBlueprintsApi();
    const [searchParams] = useSearchParams();
    const blueprintId = useBlueprintId();
    const atDateString = searchParams.get(TRANSACTION_SEARCHPARAM_KEY);
    const isActiveTransaction = searchParams.get(TRANSACTION_ACTIVE_SEARCHPARAM_KEY);
    const validation = useVerifiedActiveTransaction();

    const boundFunc = useMemo(
        () =>
            TimelineDependantFetch(
                validation,
                api.getActiveBlueprint.bind(api, { id: blueprintId! }),
                api.getHistoricalBlueprint.bind(api, {
                    id: blueprintId!,
                    asOf: atDateString ? new Date(atDateString) : new Date(),
                }),
                { name: "", connections: [], containers: [] },
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [validation, api, atDateString, blueprintId, isActiveTransaction],
    );

    return useApiCall(boundFunc);
}

export function useInsertContainer() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.insertContainer.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useInsertCell() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.insertNewCell.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useUpdateContainerPosition() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.updateContainerPosition.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useUpdateContainerPositionBulk() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.updateContainerPositions.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useInsertConnection() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.insertConnection.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useDeleteConnection() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.deleteConnection.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useUpdateContainerName() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.updateContainerName.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useUpdateContainerDescription() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.updateContainerDescription.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useUpdateCellName() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.updateCellName.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useDeleteContainer() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.deleteContainer.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useDeleteCell() {
    const api = useBlueprintsApi();

    const boundFunc = useMemo(() => api.deleteCell.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useBlueprintName() {
    const api = useBlueprintsApi();
    const blueprintId = useBlueprintId();
    const [name, setName] = useState("");

    useEffect(() => {
        if (!blueprintId) {
            return;
        }

        api.getBlueprintBreadcrumb({ id: blueprintId! })
            .then((breadcrumb) => {
                setName(getContextName(breadcrumb));
            })
            .catch((e) => {
                console.error("Ooops! Error caught in useBlueprintName", e);
            });
    }, [setName, blueprintId, api]);

    return name;
}
