import { Role } from "../models/Role";

const DotnetClaimsRoleKey = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

export function parseJwtPayload(jwt: string) {
    // there's more in the jwt, but this is temporary until we add actual accounts with oidc
    const jwtPayload = JSON.parse(window.atob(jwt.split(".")[1])) as {
        exp: number;
        [DotnetClaimsRoleKey]?: Role;
    };

    return {
        exp: jwtPayload.exp,
        role: jwtPayload[DotnetClaimsRoleKey],
    };
}
