import React, { ComponentProps, useMemo } from "react";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Image";
import { Group, Rect, Image, Text } from "react-konva";
import { ICON_SIZE } from "../../utils/CanvasConstants";
import useImage from "use-image";
import { ExternalLinkIcon } from "../../assets/images/Images";
import { useFontObserver } from "../../hooks/useFontObserver";
import { MemoType } from "../../openapi/webservice";
import { getMemoColorHex } from "../../utils/MemoUtils";
import { removeLineBreaks } from "../../utils/StringUtils";

export type Props = ComponentProps<typeof Group> & {
    name: string;
    type: MemoType;
};

const ICON_PADDING = 6;
const LINE_HEIGHT = 16;
const ESTIMATED_SINGLE_LINE_MAX_CHARS = 12;

function getDynamicFontSize(name: string) {
    return name.length <= ESTIMATED_SINGLE_LINE_MAX_CHARS ? 14 : 12;
}
function getDynamicLineHeightMultiplier(name: string) {
    return name.length <= ESTIMATED_SINGLE_LINE_MAX_CHARS ? 1 : 1.1;
}

export const CellRedirectButton = ({ name, type, ...props }: Props) => {
    const [icon] = useImage(ExternalLinkIcon);
    const isFontLoaded = useFontObserver("Open Sans");
    const normalizedName = useMemo(() => removeLineBreaks(name).trim(), [name]);

    return (
        <Group height={LINE_HEIGHT * 2} {...props} visible={isFontLoaded && props.visible !== false}>
            <Rect
                x={0}
                y={0}
                height={LINE_HEIGHT * 2}
                width={props.width}
                fill="#fff"
                shadowEnabled
                shadowBlur={4}
                shadowOpacity={0.5}
                shadowOffset={{ x: 2, y: 2 }}
                strokeWidth={1}
                stroke={getMemoColorHex(type)}
            />
            <Text
                x={0}
                y={0}
                height={LINE_HEIGHT * 2 * getDynamicLineHeightMultiplier(normalizedName)}
                width={(props.width || 0) - ICON_SIZE + ICON_PADDING}
                text={normalizedName}
                fontFamily="'Open Sans'"
                align="left"
                verticalAlign="middle"
                lineHeight={getDynamicLineHeightMultiplier(normalizedName)}
                fontSize={getDynamicFontSize(normalizedName)}
                ellipsis={true}
                fill="#061133"
                padding={4}
            />
            <Image
                image={icon}
                x={(props.width || 0) - ICON_SIZE + ICON_PADDING + 1}
                y={LINE_HEIGHT + ICON_PADDING - ICON_SIZE / 2}
                height={ICON_SIZE - ICON_PADDING * 2}
                width={ICON_SIZE - ICON_PADDING * 2}
            />
        </Group>
    );
};
