// Source https://www.pluralsight.com/guides/re-render-react-component-on-window-resize

import { useEffect, useState } from "react";

function debounce(fn: () => void, ms: number) {
    let timer: NodeJS.Timeout | undefined;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = undefined;
            fn();
        }, ms);
    };
}

export function useWindowResizeListener(ms: number = 200) {
    const [dimensions, setDimensions] = useState(`${window.innerWidth}x${window.innerHeight}`);

    useEffect(() => {
        const debouncedHandleResize = debounce(() => {
            setDimensions(`${window.innerWidth}x${window.innerHeight}`);
        }, ms);

        window.addEventListener("resize", debouncedHandleResize);

        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    });

    return dimensions;
}
