import React, { PropsWithChildren, useMemo } from "react";
import styles from "./Breadcrumb.module.scss";
import classNames from "classnames";
import { useBreadcrumb } from "../../hooks/useBreadcrumbApi";
import { useSelectedTransaction } from "../../hooks/useSelectedTransaction";
import {
    ArrowUpWhiteIcon,
    BreadcrumbLevel1Icon,
    BreadcrumbLevel2Icon,
    ExternalLinkIcon,
} from "../../assets/images/Images";
import Text from "../Text/Text";
import { useTranslation } from "react-i18next";
import { createSearchParams, Link, To, useSearchParams } from "react-router-dom";
import { getBlueprintRoute, getMemoboardRoute, getTransactionSearchParams } from "../../utils/RouteUtils";
import { formatFromDate } from "../../utils/DateTimeUtils";
import useComponentVisible from "../../hooks/useComponentVisible";
import { getContextName } from "../../utils/StringUtils";

export type Level = 1 | 2;

type Props = {
    className: string;
    level: Level;
};

function getBreadcrumbIcon(level: Level) {
    switch (level) {
        case 1:
            return BreadcrumbLevel1Icon;
        case 2:
            return BreadcrumbLevel2Icon;
        default:
            throw new Error(`Icon not found for breadcrumb level: ${level}`);
    }
}

type ConditionalLinkProps = PropsWithChildren<{
    condition: boolean;
    to: To;
}>;

function ConditionalLink({ condition, to, children }: ConditionalLinkProps) {
    return condition ? (
        <Link className={styles.link} to={to}>
            {children}
            &nbsp;
            <img src={ExternalLinkIcon} />
        </Link>
    ) : (
        <>{children}</>
    );
}

const Breadcrumb = ({ className, level }: Props) => {
    const { t } = useTranslation();
    const [{ value: crumb }] = useBreadcrumb(level);
    const [searchParams] = useSearchParams();
    const transaction = useSelectedTransaction();
    const { ref, isComponentVisible, toggle } = useComponentVisible(false);

    const blueprintLink = useMemo<To>(() => {
        if (!crumb) {
            return "";
        }

        return {
            pathname: getBlueprintRoute(crumb.subContextId, crumb.blueprintId),
            search: createSearchParams(getTransactionSearchParams(searchParams)).toString(),
        };
    }, [crumb, searchParams]);

    const memoboardLink = useMemo<To>(() => {
        if (!crumb) {
            return "";
        }

        return {
            pathname: getMemoboardRoute(crumb.subContextId, crumb.blueprintId, crumb.cellId),
            search: createSearchParams(getTransactionSearchParams(searchParams)).toString(),
        };
    }, [crumb, searchParams]);

    return (
        <div ref={ref} className={classNames(styles.container, isComponentVisible && styles.open, className)}>
            <img
                className={styles.image}
                src={getBreadcrumbIcon(level)}
                alt="breadcrumb-icon"
                data-testid="BreadcrumbIcon"
                onClick={toggle}
            />
            <img className={styles.arrow} src={ArrowUpWhiteIcon} alt="arrow-icon" />
            <div className={styles.tooltip}>
                {crumb && (
                    <>
                        <Text label={getContextName(crumb)} />
                        {transaction && (
                            <Text
                                className={styles.transaction}
                                label={`${transaction.name} \u2013 ${formatFromDate(transaction.validTimeStart)}`}
                            />
                        )}
                        <br />
                        <div className={styles.row}>
                            <ConditionalLink condition={level >= 2} to={blueprintLink}>
                                <Text className={styles.inline} label={`1. ${t("breadcrumb.blueprint")}`} />
                            </ConditionalLink>
                        </div>
                        {level >= 2 && (
                            <div className={styles.row}>
                                <Text
                                    className={styles.inline}
                                    label={`2. ${t("breadcrumb.memoboard")}: ${crumb.containerName} \u2013`}
                                />
                                &nbsp;
                                <ConditionalLink condition={level >= 3} to={memoboardLink}>
                                    <Text className={styles.inline} label={`${crumb.cellName}`} />
                                </ConditionalLink>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

Breadcrumb.defaultProps = {
    className: "",
};

export default Breadcrumb;
