export function isOdd(value: number) {
    return value % 2 !== 0;
}

export function isEven(value: number) {
    return value % 2 === 0;
}

// Source: https://stackoverflow.com/a/7228322
export function getRandomBetween(min: number, max: number) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
}
