import React, { useMemo, useState } from "react";
import styles from "./TempUserManagement.module.scss";
import classNames from "classnames";
import { OrganisationViewModel, UserViewModel } from "../../openapi/webservice";
import TempUserManagementInput from "../RoughUserManagementInput/TempUserManagementInput";
import TempAddUserInputContainer from "../RoughAddUserInput/TempAddUserInputContainer";
import Text from "../Text/Text";
import { useOrganisationOptions } from "../../hooks/useOrganisationOptions";
import DropdownList from "../DropdownList/DropdownList";

type Props = {
    className: string;
    users: UserViewModel[];
    organisations: OrganisationViewModel[];
};

const TempUserManagement = ({ className, users, organisations }: Props) => {
    const [selectedOrg, setSelectedOrg] = useState<OrganisationViewModel | undefined>(undefined);
    const organisationOptions = useOrganisationOptions(organisations, setSelectedOrg, true);
    const filteredUsers = useMemo(
        () => (selectedOrg ? users.filter((u) => u.organisation.id === selectedOrg.id) : users),
        [users, selectedOrg],
    );

    return (
        <div className={classNames(styles.container, className)}>
            <TempAddUserInputContainer className={classNames(styles.row, styles.add)} organisations={organisations} />
            <div className={classNames(styles.row, styles.filterBar)}>
                <Text className={styles.name} type="tooltip" label="Filteren op:  " />
                <DropdownList
                    className={styles.dropdown}
                    items={organisationOptions}
                    initialSelectedItem={organisationOptions[0]}
                />
            </div>
            {filteredUsers.map((u) => {
                return (
                    <TempUserManagementInput key={u.id} className={styles.row} user={u} organisations={organisations} />
                );
            })}
        </div>
    );
};

TempUserManagement.defaultProps = {
    className: "",
};

export default TempUserManagement;
