import React from "react";
import styles from "./PlanningMockup.module.scss";
import classNames from "classnames";
import ModalCard from "../ModalCard/ModalCard";
import Text from "../Text/Text";
import ImageButton from "../ImageButton/ImageButton";
import { DiagramIcon, EditIcon, ValidationBlackIcon } from "../../assets/images/Images";
import WipWrapper from "../WipWrapper/WipWrapper";
import { useTranslation } from "react-i18next";
import { useErrorResponseNotification } from "../../hooks/useErrorNotification";
import RoundImage from "../RoundImage/RoundImage";
import { useGetSelf } from "../../hooks/useUsersApi";

type Props = {
    className: string;
};

const PlanningMockup = ({ className }: Props) => {
    const { t } = useTranslation();
    const { error, value: self } = useGetSelf();
    useErrorResponseNotification(error);

    return (
        <ModalCard className={classNames(styles.container, className)}>
            <div className={styles.header}>
                <Text className={styles.title} label={t("planning.header.version")} type="tooltip" />
                <ImageButton className={styles.icon} src={DiagramIcon} />
            </div>
            <div className={styles.header}>
                <Text className={styles.title} label={t("planning.header.planned")} type="tooltip" />
                <WipWrapper label="Bewerken">
                    <ImageButton className={styles.icon} src={ValidationBlackIcon} />
                </WipWrapper>
                <WipWrapper label="Bewerken">
                    <ImageButton className={styles.icon} src={EditIcon} />
                </WipWrapper>
            </div>
            <div className={styles.header}>
                <Text className={styles.title} label={t("planning.header.actual")} type="tooltip" />
                <WipWrapper label="Bewerken">
                    <ImageButton className={styles.icon} src={EditIcon} />
                </WipWrapper>
            </div>
            <div className={styles.header}>
                <Text className={styles.title} label={t("planning.header.difference")} type="tooltip" />
            </div>
            <div className={styles.body}>
                <div className={styles.row}>
                    <Text className={styles.text} label={t("planning.rows.value")} type="tooltip" />
                </div>
                <div className={styles.row}>
                    <Text className={styles.text} label={t("planning.rows.start_date")} type="tooltip" />
                </div>
                <div className={styles.row}>
                    <Text className={styles.text} label={t("planning.rows.hours")} type="tooltip" />
                </div>
                <div className={styles.row}>
                    <Text className={styles.text} label={t("planning.rows.employee")} type="tooltip" />
                </div>
                <div className={styles.row}>
                    <Text className={styles.text} label={t("planning.rows.done_date")} type="tooltip" />
                </div>
            </div>
            <div className={styles.body}>
                <div className={classNames(styles.row)}>
                    <div className={styles.tickContainer}>
                        <div className={styles.tick} />
                        <div className={styles.tick} />
                    </div>
                    <input type="range" min="1" max="5" className={styles.slider} id="slider1" defaultValue="2" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.neutral)} label="14-03-2021" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.neutral)} label="4" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    {self && (
                        <>
                            <RoundImage className={styles.avatar} src={self.avatarUri} size="small" />
                            <Text className={classNames(styles.text, styles.neutral)} label={self.displayName} />
                        </>
                    )}
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.neutral)} label="16-03-2021" />
                </div>
            </div>
            <div className={styles.body}>
                <div className={classNames(styles.row)}>
                    <div className={styles.tickContainer}>
                        <div className={styles.tick} />
                        <div className={styles.tick} />
                    </div>
                    <input type="range" min="1" max="5" className={styles.slider} id="slider2" defaultValue="3" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.neutral)} label="15-03-2021" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.neutral)} label="2" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    {self && (
                        <>
                            <RoundImage className={styles.avatar} src={self.avatarUri} size="small" />
                            <Text className={classNames(styles.text, styles.neutral)} label={self.displayName} />
                        </>
                    )}
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.neutral)} label="16-03-2021" />
                </div>
            </div>
            <div className={styles.body}>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.positive)} label="+1" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.negative)} label="+1" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.positive)} label="-2" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.neutral)} label="=" />
                </div>
                <div className={classNames(styles.row, styles.value)}>
                    <Text className={classNames(styles.text, styles.neutral)} label="=" />
                </div>
            </div>
        </ModalCard>
    );
};

PlanningMockup.defaultProps = {
    className: "",
};

export default PlanningMockup;
