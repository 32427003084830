import React, { useCallback } from "react";
import { useBlueprintState } from "../../hooks/useBlueprintState";
import { useContainerModalState } from "../../hooks/useContainerModalState";
import { useChangeContainerDescription } from "../../hooks/useBlueprintStateChanges";
import ContainerModal from "./ContainerModal";

type Props = {
    className: string;
};

const ContainerModalContainer = ({ className }: Props) => {
    const [id, setModalState] = useContainerModalState();
    const [blueprint] = useBlueprintState();
    const container = blueprint.containers.find((c) => c.id === id);
    const onChangeContainerDescription = useChangeContainerDescription();

    const onClose = useCallback(() => {
        setModalState(undefined);
    }, [setModalState]);

    const onDescriptionChange = useCallback(
        (value: string) => {
            if (!container) {
                return;
            }

            onChangeContainerDescription(container.id, value);
        },
        [onChangeContainerDescription, container],
    );

    return (
        <ContainerModal
            className={className}
            isOpen={!!id}
            onClose={onClose}
            onChange={onDescriptionChange}
            container={container}
        />
    );
};

ContainerModalContainer.defaultProps = {
    className: "",
};

export default ContainerModalContainer;
