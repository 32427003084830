import React, { PropsWithChildren } from "react";
import { MemoboardApi } from "../openapi/webservice/apis/MemoboardApi";
import { Configuration } from "../openapi/webservice/runtime";
import { CreateApi, ApiContextProvider } from "./WebServiceApiContext";

const createApi: CreateApi<MemoboardApi> = (params) => new MemoboardApi(new Configuration(params));

export const MemoboardApiContext = React.createContext(createApi({}));

export const MemoboardApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={MemoboardApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
