/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    DesignContextViewModel,
    DesignContextViewModelFromJSON,
    DesignContextViewModelFromJSONTyped,
    DesignContextViewModelToJSON,
    ThemeViewModel,
    ThemeViewModelFromJSON,
    ThemeViewModelFromJSONTyped,
    ThemeViewModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganisationOverview
 */
export interface OrganisationOverview {
    /**
     * 
     * @type {string}
     * @memberof OrganisationOverview
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationOverview
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationOverview
     */
    logoUri: string;
    /**
     * 
     * @type {ThemeViewModel}
     * @memberof OrganisationOverview
     */
    defaultTheme: ThemeViewModel;
    /**
     * 
     * @type {Array<DesignContextViewModel>}
     * @memberof OrganisationOverview
     */
    designContexts: Array<DesignContextViewModel>;
}

export function OrganisationOverviewFromJSON(json: any): OrganisationOverview {
    return OrganisationOverviewFromJSONTyped(json, false);
}

export function OrganisationOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationOverview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'logoUri': json['logoUri'],
        'defaultTheme': ThemeViewModelFromJSON(json['defaultTheme']),
        'designContexts': ((json['designContexts'] as Array<any>).map(DesignContextViewModelFromJSON)),
    };
}

export function OrganisationOverviewToJSON(value?: OrganisationOverview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'logoUri': value.logoUri,
        'defaultTheme': ThemeViewModelToJSON(value.defaultTheme),
        'designContexts': ((value.designContexts as Array<any>).map(DesignContextViewModelToJSON)),
    };
}


