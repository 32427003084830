import { useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { MemoboardApiContext } from "../contexts/MemoboardApiContext";
import { InheritanceType } from "../openapi/webservice";
import { MemoType } from "../openapi/webservice/models/MemoType";
import { INHERITANCE_KEY, TRANSACTION_SEARCHPARAM_KEY } from "../utils/RouterConstants";
import { TimelineDependantFetch } from "../utils/TimelineDependantFetch";
import { useApiCall } from "./useApiCall";
import { useVerifiedActiveTransaction } from "./useVerifiedActiveTransaction";

export function useMemoboardApi() {
    return useContext(MemoboardApiContext);
}

export function useGetMemos(cellId: string, type: MemoType) {
    const api = useMemoboardApi();
    const [searchParams] = useSearchParams();
    const atDateString = searchParams.get(TRANSACTION_SEARCHPARAM_KEY);
    const validation = useVerifiedActiveTransaction();
    const inheritance = (searchParams.get(INHERITANCE_KEY) as InheritanceType) || InheritanceType.OWNED;

    const boundFunc = useMemo(
        () =>
            TimelineDependantFetch(
                validation,
                api.getMemosByTypeActive.bind(api, { cellId, type, inheritance }),
                api.getMemosByTypeHistorical.bind(api, {
                    cellId,
                    type,
                    asOf: atDateString ? new Date(atDateString) : new Date(),
                    inheritance,
                }),
                { memos: [] },
            ),
        [api, cellId, type, atDateString, validation, inheritance],
    );

    return useApiCall(boundFunc);
}
