import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import styles from "./ValidateTransactionModal.module.scss";
import classNames from "classnames";
import Title from "../Title/Title";
import Separator from "../Separator/Separator";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import { Checked, Mockup1, Mockup2, Mockup3, Mockup4 } from "../../assets/images/Images";
import { getRandomBetween } from "../../utils/MathUtils";

const DURATION = 7000; // ms
const STEPS = [
    {
        image: Mockup1,
        label: "blueprint.validation.step_1",
    },
    {
        image: Mockup2,
        label: "blueprint.validation.step_2",
    },
    {
        image: Mockup3,
        label: "blueprint.validation.step_3",
    },
    {
        image: Mockup4,
        label: "blueprint.validation.step_4",
    },
];

function randomSteps() {
    const averageDuration = DURATION / STEPS.length;
    const deviation = 0.2;

    const minDuration = Math.round(averageDuration * (1 - deviation));
    const maxDuration = Math.round(averageDuration * (1 + deviation));

    const lastIndex = STEPS.length - 1;

    return STEPS.reduce((list, step, index) => {
        const previous = list[index - 1];
        const previousEnd = (previous?.delay || 0) + (previous?.duration || 0);
        const duration = index === lastIndex ? DURATION - previousEnd : getRandomBetween(minDuration, maxDuration);
        const delay = index === 0 ? 0 : previousEnd;

        list.push({ ...step, duration, delay });
        return list;
    }, [] as Array<{ image: string; label: string; delay: number; duration: number }>);
}

type Props = {
    className: string;
    title: string;
    subtitle?: string;
    onConfirm: () => any;
};

const ValidateTransactionModal = ({ className, title, subtitle, onConfirm }: Props) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(true);
    // @TODO Actually validate different steps in engine
    const [validationSteps] = useState(randomSteps);
    const [isValidated, setIsValidated] = useState(false);

    const close = useCallback(() => {
        setOpen(false);
        onConfirm();
    }, [setOpen, onConfirm]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsValidated(true);
        }, DURATION + 200);

        return () => {
            clearTimeout(timer);
        };
    }, [setIsValidated]);

    return (
        <Modal
            shouldCloseOnOverlayClick={false}
            testId="ProcessTransactionModal"
            overlayClassName={styles.overlay}
            className={classNames(styles.container, className)}
            isOpen={isOpen}
        >
            <div className={styles.header}>
                <Title className={styles.title} heading="h2" label={title} />
                <Separator orientation="|" />
                {subtitle && (
                    <>
                        <Title className={styles.details} heading="h2" label={subtitle} />
                        <Separator orientation="|" />
                    </>
                )}
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.steps}>
                    {validationSteps.map((step, index) => {
                        return (
                            <div key={index} className={styles.step}>
                                <img src={step.image} className={styles.image} alt={`Step ${index}`} />
                                <Text className={styles.name} label={t(step.label as any)} type="tooltip" />
                                <div className={styles.progressContainer}>
                                    <div
                                        className={styles.bar}
                                        style={{
                                            animationDelay: `${step.delay}ms`,
                                            animationDuration: `${step.duration}ms`,
                                        }}
                                    />
                                </div>
                                <div className={styles.checkbox}>
                                    <img
                                        src={Checked}
                                        className={styles.tick}
                                        alt="Tick"
                                        style={{ animationDelay: `${step.delay + step.duration}ms` }}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Button
                    className={styles.confirm}
                    label={t("blueprint.validation.confirm")}
                    displayType="alternative"
                    disabled={!isValidated}
                    onClick={close}
                />
            </div>
        </Modal>
    );
};

ValidateTransactionModal.defaultProps = {
    className: "",
};

export default ValidateTransactionModal;
