import React from "react";
import { useCallback } from "react";
import { useErrorResponseNotification } from "../../hooks/useErrorNotification";
import { useGetSelf } from "../../hooks/useUsersApi";
import { useUser } from "../../hooks/useUser";
import { ActionType } from "../../reducers/UserStateReducer";
import DefaultNavBar, { Props as NavBarProps } from "./DefaultNavBar";

type Props = Pick<NavBarProps, "className">;

const DefaultNavBarContainer = (props: Props) => {
    const { error, value: self } = useGetSelf();
    const [, dispatch] = useUser();
    useErrorResponseNotification(error);

    const logout = useCallback(() => {
        dispatch({ type: ActionType.clear });
    }, [dispatch]);

    return <DefaultNavBar {...props} user={self} logout={logout} />;
};

DefaultNavBarContainer.defaultProps = {
    className: "",
};

export default DefaultNavBarContainer;
