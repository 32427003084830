/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UserInputModel,
    UserInputModelFromJSON,
    UserInputModelToJSON,
    UserViewModel,
    UserViewModelFromJSON,
    UserViewModelToJSON,
} from '../models';

export interface CreateUserRequest {
    userInputModel?: UserInputModel;
}

export interface DeleteUserRequest {
    userId: string;
}

export interface UpdateUserRequest {
    userId: string;
    userInputModel?: UserInputModel;
}

/**
 * no description
 */
export class UsersApi extends runtime.BaseAPI {
    /**
     * Create a new user.
     */
    async createUserRaw(requestParameters: CreateUserRequest): Promise<runtime.ApiResponse<UserViewModel>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInputModelToJSON(requestParameters.userInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserViewModelFromJSON(jsonValue));
    }
    /**
     * Create a new user.
     */
    async createUser(requestParameters: CreateUserRequest): Promise<UserViewModel> {
        const response = await this.createUserRaw(requestParameters);
        return await response.value();
    }
    /**
     * Delete an user.
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteUser.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete an user.
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }
    /**
     * Retrieve the user\'s profile
     */
    async getSelfRaw(): Promise<runtime.ApiResponse<UserViewModel>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve the user\'s profile
     */
    async getSelf(): Promise<UserViewModel> {
        const response = await this.getSelfRaw();
        return await response.value();
    }
    /**
     * Retrieve all users
     */
    async getUsersRaw(): Promise<runtime.ApiResponse<Array<UserViewModel>>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserViewModelFromJSON));
    }
    /**
     * Retrieve all users
     */
    async getUsers(): Promise<Array<UserViewModel>> {
        const response = await this.getUsersRaw();
        return await response.value();
    }
    /**
     * Edit the current user.
     */
    async updateUserRaw(requestParameters: UpdateUserRequest): Promise<runtime.ApiResponse<UserViewModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling updateUser.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserInputModelToJSON(requestParameters.userInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UserViewModelFromJSON(jsonValue));
    }
    /**
     * Edit the current user.
     */
    async updateUser(requestParameters: UpdateUserRequest): Promise<UserViewModel> {
        const response = await this.updateUserRaw(requestParameters);
        return await response.value();
    }
}
