/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserInputModel
 */
export interface UserInputModel {
    /**
     * 
     * @type {string}
     * @memberof UserInputModel
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputModel
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputModel
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputModel
     */
    avatarUri: string;
    /**
     * 
     * @type {string}
     * @memberof UserInputModel
     */
    organisation: string;
}

export function UserInputModelFromJSON(json: any): UserInputModel {
    return UserInputModelFromJSONTyped(json, false);
}

export function UserInputModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInputModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'username': json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'avatarUri': json['avatarUri'],
        'organisation': json['organisation'],
    };
}

export function UserInputModelToJSON(value?: UserInputModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'username': value.username,
        'password': value.password,
        'avatarUri': value.avatarUri,
        'organisation': value.organisation,
    };
}


