import React, { useCallback, useMemo, useState } from "react";
import styles from "./BlueprintBoard.module.scss";
import classNames from "classnames";
import BoardLayout from "../BoardLayout/BoardLayout";
import { useSearchParams } from "react-router-dom";
import {
    AlignIcon,
    BlueprintArrowIcon,
    CellDndIcon,
    CellInIcon,
    CellOutIcon,
    ClusterIcon,
    CustomizeIcon,
    DomainIcon,
    DuplicateIcon,
    FilterActiveIcon,
    FilterIcon,
    QuestionIcon,
    SearchIcon,
} from "../../assets/images/Images";
import { HELP_MODAL_KEY } from "../../utils/RouterConstants";
import ImageButton from "../ImageButton/ImageButton";
import { CanvasMode } from "../../models/CanvasMode";
import Separator from "../Separator/Separator";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import WipWrapper from "../WipWrapper/WipWrapper";
import { useCanvasMode } from "../../hooks/useCanvasMode";
import BlueprintCanvasContainer from "../BlueprintCanvas/BlueprintCanvasContainer";
import { BLUEPRINT_DRAG_TYPE_KEY, BLUEPRINT_DRAG_VALUE_KEY } from "../../utils/DragAndDropUtils";
import { CellType, TransactionState } from "../../openapi/webservice";
import { DragType } from "../../models/DragType";
import BlueprintStatusDropdownContainer from "../DropdownList/BlueprintStatusDropdownContainer";
import { useTranslation } from "react-i18next";
import { useSelectedTransaction } from "../../hooks/useSelectedTransaction";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import BlueprintFilterModal from "../BlueprintFilterModal/BlueprintFilterModal";
import { useBlueprintFilter } from "../../hooks/useBlueprintFilter";
import DuplicateContextModalContainer from "../ContextModal/DuplicateContextModalContainer";
import { useContexts } from "../../hooks/useContexts";

type Props = {
    className: string;
};

function OnStartClusterDrag(event: React.DragEvent<HTMLButtonElement>) {
    event.dataTransfer.setData(BLUEPRINT_DRAG_TYPE_KEY, DragType.Container);
    event.dataTransfer.setData(BLUEPRINT_DRAG_VALUE_KEY, CellType.CLUSTER);
}

function OnStartDomainDrag(event: React.DragEvent<HTMLButtonElement>) {
    event.dataTransfer.setData(BLUEPRINT_DRAG_TYPE_KEY, DragType.Container);
    event.dataTransfer.setData(BLUEPRINT_DRAG_VALUE_KEY, CellType.DOMAIN);
}

function OnStartExternalSourceDrag(event: React.DragEvent<HTMLButtonElement>) {
    event.dataTransfer.setData(BLUEPRINT_DRAG_TYPE_KEY, DragType.Container);
    event.dataTransfer.setData(BLUEPRINT_DRAG_VALUE_KEY, CellType.EXTERNALSOURCE);
}

function OnStartExternalSinkDrag(event: React.DragEvent<HTMLButtonElement>) {
    event.dataTransfer.setData(BLUEPRINT_DRAG_TYPE_KEY, DragType.Container);
    event.dataTransfer.setData(BLUEPRINT_DRAG_VALUE_KEY, CellType.EXTERNALSINK);
}

function OnStartCellDrag(event: React.DragEvent<HTMLButtonElement>) {
    event.dataTransfer.setData(BLUEPRINT_DRAG_TYPE_KEY, DragType.Cell);
}

function shouldHideSecondary(canvasMode: CanvasMode, isInProgress: boolean) {
    return !isInProgress || canvasMode !== CanvasMode.Edit;
}

const BlueprintBoard = ({ className }: Props) => {
    const [contexts] = useContexts();
    const [openFilterMenu, setOpenFilterMenu] = useState(false);
    const [openDuplicateMenu, setOpenDuplicateMenu] = useState(false);
    const { reset: resetFilters, isActive: isFilterActive } = useBlueprintFilter();
    const { t } = useTranslation();
    const [canvasMode, setCanvasMode] = useCanvasMode();
    const transaction = useSelectedTransaction();
    const [isCreateConnectionView, setCreateConnectionView] = useState(false);
    const transactionIsInProgress = useMemo(() => transaction?.state === TransactionState.InProgress, [transaction]);
    const isEditMode = useMemo(() => canvasMode === CanvasMode.Edit, [canvasMode]);

    const [searchParams, setSearchParams] = useSearchParams();
    const onOpenHelp = useCallback(() => {
        const next = new URLSearchParams(searchParams.toString());
        next.set(HELP_MODAL_KEY, "blueprint");
        setSearchParams(next, { replace: true });
    }, [searchParams, setSearchParams]);

    const onChangeCanvasMode = useCallback(() => {
        setCanvasMode((mode) => (mode === CanvasMode.Edit ? CanvasMode.View : CanvasMode.Edit));
        setCreateConnectionView(false);
        resetFilters();
    }, [setCanvasMode, setCreateConnectionView, resetFilters]);

    const doOpenFilter = useCallback(() => {
        setOpenFilterMenu(true);
    }, []);
    const doCloseFilter = useCallback(() => {
        setOpenFilterMenu(false);
    }, []);
    const doOpenDuplicateMenu = useCallback(() => {
        setOpenDuplicateMenu(true);
    }, []);
    const doCloseDuplicateMenu = useCallback(() => {
        setOpenDuplicateMenu(false);
    }, []);

    return (
        <BoardLayout
            className={classNames(styles.container, className)}
            mainActionContent={
                <>
                    <Breadcrumb className={styles.breadcrumb} level={1} />
                    <BlueprintStatusDropdownContainer className={styles.dropdown} disabled={isEditMode} />
                    {transactionIsInProgress && (
                        <>
                            <Separator orientation="|" />
                            <ToggleSwitch
                                className={styles.toggleSwitch}
                                onChange={onChangeCanvasMode}
                                defaultChecked={isEditMode}
                                labelUnchecked={t("toggle.canvas_mode.unchecked")}
                                labelChecked={t("toggle.canvas_mode.checked")}
                            />
                        </>
                    )}
                    {!isEditMode && (
                        <>
                            <Separator orientation="|" />
                            <WipWrapper label="Delta">
                                <ToggleSwitch
                                    className={styles.toggleSwitch}
                                    labelUnchecked={t("toggle.delta.unchecked")}
                                    labelChecked={t("toggle.delta.checked")}
                                />
                            </WipWrapper>
                            {transactionIsInProgress && (
                                <>
                                    <Separator orientation="|" />
                                    <WipWrapper label="Mijn">
                                        <ToggleSwitch
                                            className={styles.toggleSwitch}
                                            labelUnchecked={t("toggle.mine.unchecked")}
                                            labelChecked={t("toggle.mine.checked")}
                                        />
                                    </WipWrapper>
                                </>
                            )}
                        </>
                    )}
                    <Separator orientation="|" />
                    <div className={styles.filler} />
                    <Separator orientation="|" />
                    {!isEditMode && (
                        <>
                            <ImageButton
                                src={DuplicateIcon}
                                onClick={doOpenDuplicateMenu}
                                data-testid="BlueprintDuplicateButton"
                            />
                            <DuplicateContextModalContainer
                                // key is used to clear content of modal
                                key={openDuplicateMenu ? "openDuplicate" : "closedDuplicate"}
                                isOpen={openDuplicateMenu}
                                onClose={doCloseDuplicateMenu}
                                list={contexts}
                            />
                            <Separator orientation="|" />
                            <ImageButton
                                src={FilterIcon}
                                onClick={doOpenFilter}
                                data-testid="BlueprintFilterButton"
                                active={isFilterActive}
                                activeSrc={FilterActiveIcon}
                            />
                            {openFilterMenu && <BlueprintFilterModal onClose={doCloseFilter} />}
                            <Separator orientation="|" />
                            <WipWrapper label="Zoek">
                                <ImageButton src={SearchIcon} />
                            </WipWrapper>
                            <Separator orientation="|" />
                        </>
                    )}
                    <WipWrapper label="Instellingen">
                        <ImageButton src={CustomizeIcon} />
                    </WipWrapper>
                </>
            }
            secondaryActionContent={
                <>
                    <ImageButton src={ClusterIcon} data-testid="DragCluster" onDragStart={OnStartClusterDrag} />
                    <ImageButton src={DomainIcon} data-testid="DragDomain" onDragStart={OnStartDomainDrag} />
                    <ImageButton src={CellDndIcon} data-testid="DragCell" onDragStart={OnStartCellDrag} />
                    <ImageButton
                        src={BlueprintArrowIcon}
                        data-testid="CreateConnection"
                        draggable={false}
                        onClick={() => {
                            setCreateConnectionView((value) => !value);
                        }}
                        active={isCreateConnectionView}
                    />
                    <ImageButton
                        src={CellInIcon}
                        data-testid="DragExternalSink"
                        onDragStart={OnStartExternalSinkDrag}
                    />
                    <ImageButton
                        src={CellOutIcon}
                        data-testid="DragExternalSource"
                        onDragStart={OnStartExternalSourceDrag}
                    />
                    <Separator orientation="-" />
                    <WipWrapper label="Uitlijnen">
                        <ImageButton src={AlignIcon} />
                    </WipWrapper>
                    <Separator orientation="-" />
                </>
            }
            help={<ImageButton src={QuestionIcon} onClick={onOpenHelp} data-testid="GetHelpButton" />}
            content={
                <BlueprintCanvasContainer className={styles.canvas} isCreateConnectionView={isCreateConnectionView} />
            }
            hideSecondary={shouldHideSecondary(canvasMode, transactionIsInProgress)}
        />
    );
};

BlueprintBoard.defaultProps = {
    className: "",
};

export default BlueprintBoard;
