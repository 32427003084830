import React, { PropsWithChildren } from "react";
import { Configuration, ContextsApi } from "../openapi/webservice";
import { CreateApi, ApiContextProvider } from "./WebServiceApiContext";

const createApi: CreateApi<ContextsApi> = (params) => new ContextsApi(new Configuration(params));

export const ContextsApiContext = React.createContext(createApi({}));

export const ContextsApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={ContextsApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
