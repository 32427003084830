import React, { useCallback, useMemo, useState } from "react";
import styles from "./MemoBoard.module.scss";
import classNames from "classnames";
import ImageButton from "../ImageButton/ImageButton";
import {
    ExpandIcon,
    InheritHorizontalIcon,
    InheritIcon,
    InheritVerticalIcon,
    MemoNoteIcon,
    QuestionIcon,
    RetractIcon,
    SearchIcon,
} from "../../assets/images/Images";
import WipWrapper from "../WipWrapper/WipWrapper";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import Separator from "../Separator/Separator";
import MemoCanvasContainer from "../MemoCanvas/MemoCanvasContainer";
import MemoTypeTitle from "../MemoTypeTitle/MemoTypeTitle";
import { DRAG_MEMO_KEY } from "../../utils/DragAndDropUtils";
import { MemoType } from "../../openapi/webservice/models/MemoType";
import { MemosStateContextProvider } from "../../contexts/MemosStateContext";
import { useCanvasMode } from "../../hooks/useCanvasMode";
import { CanvasMode } from "../../models/CanvasMode";
import { useSearchParams } from "react-router-dom";
import { HELP_MODAL_KEY, INHERITANCE_KEY } from "../../utils/RouterConstants";
import BoardLayout from "../BoardLayout/BoardLayout";
import BlueprintStatusDropdownContainer from "../DropdownList/BlueprintStatusDropdownContainer";
import { useTranslation } from "react-i18next";
import { useSelectedTransaction } from "../../hooks/useSelectedTransaction";
import { InheritanceType, TransactionState } from "../../openapi/webservice";
import DropdownList, { DropdownItem } from "../DropdownList/DropdownList";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { updateSearchParams } from "../../utils/RouteUtils";
import { isViewingInheritance } from "../../utils/MemoUtils";
import { useCellId, useInheritance } from "../../hooks/useRouteParams";
import { MemoBlockProvider } from "../../contexts/MemoBlockContext";
import { useMemoBlockState } from "../../hooks/useMemoBlockState";

type Props = {
    className: string;
};

function OnStartMemoDrag(event: React.DragEvent<HTMLButtonElement>) {
    // Set data so drop element knows this is a memo.
    // any truthy value is fine
    event.dataTransfer.setData(DRAG_MEMO_KEY, "1");
}

const BLOCKS: MemoType[] = [
    MemoType.Tables,
    MemoType.Computations,
    MemoType.Interfaces,
    MemoType.Validations,
    MemoType.Mutations,
    MemoType.Processes,
];

const MemoBlock = ({ expand, type }: { expand: (type: MemoType) => any; type: MemoType }) => {
    const [{ isMemoOutsideOfViewport }] = useMemoBlockState();
    return (
        <MemosStateContextProvider>
            <div className={classNames(styles.block, styles[type.toLowerCase()])}>
                <MemoCanvasContainer className={styles.canvas} type={type} key={type} />
                <MemoTypeTitle className={styles.canvasTitle} type={type} />
                <ImageButton
                    className={styles.expand}
                    src={ExpandIcon}
                    rounded
                    onClick={() => {
                        expand(type);
                    }}
                    data-testid={`Expand${type}`}
                    hide={!isMemoOutsideOfViewport}
                />
            </div>
        </MemosStateContextProvider>
    );
};

const MemoBoard = ({ className }: Props) => {
    const { t } = useTranslation();
    const [expandBoard, setExpandBoard] = useState<MemoType | undefined>();
    const [canvasMode, setCanvasMode] = useCanvasMode();
    const [searchParams, setSearchParams] = useSearchParams();
    const transaction = useSelectedTransaction();
    const transactionIsInProgress = useMemo(() => transaction?.state === TransactionState.InProgress, [transaction]);
    const isEditMode = useMemo(() => canvasMode === CanvasMode.Edit, [canvasMode]);
    const inheritance = useInheritance();
    const cellId = useCellId();

    const inheritanceOptions: DropdownItem[] = useMemo(
        () => [
            {
                id: InheritanceType.OWNED,
                icon: InheritIcon,
                label: t("inheritance.owner"),
                onSelect: () =>
                    updateSearchParams(searchParams, setSearchParams, INHERITANCE_KEY, InheritanceType.OWNED),
            },
            {
                id: InheritanceType.VERTICAL,
                icon: InheritVerticalIcon,
                label: t("inheritance.vertical"),
                onSelect: () =>
                    updateSearchParams(searchParams, setSearchParams, INHERITANCE_KEY, InheritanceType.VERTICAL),
            },
            {
                id: InheritanceType.HORIZONTAL,
                icon: InheritHorizontalIcon,
                label: t("inheritance.horizontal"),
                onSelect: () =>
                    updateSearchParams(searchParams, setSearchParams, INHERITANCE_KEY, InheritanceType.HORIZONTAL),
            },
        ],
        [t, searchParams, setSearchParams],
    );

    const defaultSelectedInheritance = useMemo(
        () => inheritanceOptions.find((value) => value.id === inheritance) || inheritanceOptions[0],
        [inheritanceOptions, inheritance],
    );

    const onChangeCanvasMode = useCallback(
        () => setCanvasMode((mode) => (mode === CanvasMode.Edit ? CanvasMode.View : CanvasMode.Edit)),
        [setCanvasMode],
    );

    const onOpenHelp = useCallback(() => {
        const next = new URLSearchParams(searchParams.toString());
        next.set(HELP_MODAL_KEY, "memo");
        setSearchParams(next, { replace: true });
    }, [searchParams, setSearchParams]);

    return (
        <BoardLayout
            className={classNames(styles.container, className)}
            mainActionContent={
                <>
                    <Breadcrumb className={styles.breadcrumb} level={2} />
                    <BlueprintStatusDropdownContainer className={styles.dropdown} disabled={true} />
                    <Separator orientation="|" />
                    <DropdownList
                        key={`inheritance-${cellId}`}
                        className={classNames(styles.dropdown, styles.inheritanceDropdown)}
                        items={inheritanceOptions}
                        disabled={isEditMode}
                        initialSelectedItem={defaultSelectedInheritance}
                    />
                    {transactionIsInProgress && (
                        <>
                            <Separator orientation="|" />
                            <ToggleSwitch
                                className={styles.toggleSwitch}
                                onChange={onChangeCanvasMode}
                                defaultChecked={isEditMode}
                                labelUnchecked={t("toggle.canvas_mode.unchecked")}
                                labelChecked={t("toggle.canvas_mode.checked")}
                                disabled={isViewingInheritance(inheritance)}
                                data-testid="ViewModeToggle"
                            />
                        </>
                    )}
                    <Separator orientation="|" />
                    {!isEditMode && (
                        <>
                            <WipWrapper label="Delta">
                                <ToggleSwitch
                                    className={styles.toggleSwitch}
                                    labelUnchecked={t("toggle.delta.unchecked")}
                                    labelChecked={t("toggle.delta.checked")}
                                />
                            </WipWrapper>
                            {transactionIsInProgress && (
                                <>
                                    <Separator orientation="|" />
                                    <WipWrapper label="Mijn memo's">
                                        <ToggleSwitch
                                            className={styles.toggleSwitch}
                                            labelUnchecked={t("toggle.mine.unchecked")}
                                            labelChecked={t("toggle.mine.checked")}
                                        />
                                    </WipWrapper>
                                </>
                            )}
                            <Separator orientation="|" />
                            <div className={styles.filler} />
                            <Separator orientation="|" />
                            <WipWrapper label="Zoek">
                                <ImageButton src={SearchIcon} />
                            </WipWrapper>
                        </>
                    )}
                    {isEditMode && (
                        <>
                            <ImageButton
                                src={MemoNoteIcon}
                                draggable
                                data-testid="Note"
                                onDragStart={OnStartMemoDrag}
                            />
                            <Separator orientation="|" />
                        </>
                    )}
                </>
            }
            help={<ImageButton src={QuestionIcon} onClick={onOpenHelp} data-testid="GetHelpButton" />}
            content={
                expandBoard === undefined ? (
                    <div className={styles.board}>
                        {BLOCKS.map((block) => (
                            <MemoBlockProvider key={block}>
                                <MemoBlock expand={setExpandBoard} type={block} />
                            </MemoBlockProvider>
                        ))}
                    </div>
                ) : (
                    <MemosStateContextProvider>
                        <div className={styles.fullscreen} data-testid="FullscreenCanvas">
                            <div className={styles.subbar}>
                                <MemoTypeTitle className={styles.canvasTitle} type={expandBoard} />
                                <Separator orientation="|" />
                                <ImageButton
                                    className={styles.collapse}
                                    src={RetractIcon}
                                    onClick={() => {
                                        setExpandBoard(undefined);
                                    }}
                                    data-testid="ExitFullscreen"
                                />
                            </div>
                            <MemoCanvasContainer className={styles.canvas} type={expandBoard} key={expandBoard} />
                        </div>
                    </MemosStateContextProvider>
                )
            }
            secondaryActionContent={null}
            hideSecondary={true}
        />
    );
};

MemoBoard.defaultProps = {
    className: "",
};

export default MemoBoard;
