import React, { useCallback, useState } from "react";
import styles from "./ChatInput.module.scss";
import classNames from "classnames";
import TextareaAutosize from "react-textarea-autosize";
import { EmojiIcon, LinkIcon, PaperclipIcon, SendIcon } from "../../assets/images/Images";
import ImageButton from "../ImageButton/ImageButton";
import WipWrapper from "../WipWrapper/WipWrapper";

type Props = {
    className: string;
    defaultValue: string;
    onSubmit?: (text: string) => void;
    placeholder?: string;
};

const ChatInput = ({ className, defaultValue, onSubmit, placeholder }: Props) => {
    const [value, setValue] = useState(defaultValue);

    const onTextChange = useCallback<React.ChangeEventHandler<HTMLTextAreaElement>>((event) => {
        const text = event.currentTarget.value;
        setValue(text);
    }, []);

    const submitCallback = useCallback(() => {
        const trimmedValue = value.trim();
        if (!onSubmit || trimmedValue.length === 0) {
            return;
        }

        onSubmit(trimmedValue);
        setValue("");
    }, [onSubmit, value]);

    return (
        <div className={classNames(styles.container, className)}>
            <TextareaAutosize
                className={styles.input}
                minRows={1}
                maxRows={6}
                data-testid="ChatInput"
                value={value}
                onChange={onTextChange}
                placeholder={placeholder}
            />
            <WipWrapper className={styles.button} label="Linken">
                <ImageButton src={LinkIcon} rounded />
            </WipWrapper>
            <WipWrapper className={styles.button} label="Bijlage">
                <ImageButton src={PaperclipIcon} rounded />
            </WipWrapper>
            <WipWrapper className={styles.button} label="Emoji">
                <ImageButton src={EmojiIcon} rounded />
            </WipWrapper>
            <ImageButton
                className={styles.button}
                // Missing real icon :(
                src={SendIcon}
                rounded
                background="dark"
                data-testid="ChatSubmitButton"
                onClick={submitCallback}
            />
        </div>
    );
};

ChatInput.defaultProps = {
    className: "",
    defaultValue: "",
};

export default ChatInput;
