import React, { useCallback } from "react";
import styles from "./MemoSidebar.module.scss";
import classNames from "classnames";
import CanvasCard from "../CanvasCard/CanvasCard";
import Title from "../Title/Title";
import { useTranslation } from "react-i18next";
import WipWrapper from "../WipWrapper/WipWrapper";
import ImageButton from "../ImageButton/ImageButton";
import { CanvasPlan, CellIcon, SidebarCollapse } from "../../assets/images/Images";
import Icon from "../Icon/Icon";
import Separator from "../Separator/Separator";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { getBlueprintRoute, getTransactionSearchParams } from "../../utils/RouteUtils";
import { useBlueprintId, useSubContextId } from "../../hooks/useRouteParams";
import CompactBlueprintContainer from "../CompactBlueprint/CompactBlueprintContainer";

type Props = {
    className: string;
};

const MemoSidebar = ({ className }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const subContextId = useSubContextId();
    const blueprintId = useBlueprintId();

    const toBlueprint = useCallback(() => {
        navigate({
            pathname: getBlueprintRoute(subContextId, blueprintId),
            search: createSearchParams(getTransactionSearchParams(searchParams)).toString(),
        });
    }, [navigate, subContextId, blueprintId, searchParams]);

    return (
        <CanvasCard className={classNames(styles.container, className)}>
            <div className={styles.top}>
                <div className={styles.description}>
                    <Icon className={styles.icon} src={CellIcon} />
                    <Title className={styles.label} label={t("memoboard.sidebar.title")} heading="h2" />
                </div>
                <Separator orientation="|" />
                <ImageButton src={CanvasPlan} onClick={toBlueprint} data-testid="BackToBlueprintButton" />
                <Separator orientation="|" />
                <WipWrapper label="Zijmenu inklappen">
                    <ImageButton src={SidebarCollapse} />
                </WipWrapper>
            </div>
            <CompactBlueprintContainer className={styles.blueprint} />
        </CanvasCard>
    );
};

MemoSidebar.defaultProps = {
    className: "",
};

export default MemoSidebar;
