/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BreadcrumbViewModel
 */
export interface BreadcrumbViewModel {
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbViewModel
     */
    designContextId?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbViewModel
     */
    designContextName?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbViewModel
     */
    subContextId?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbViewModel
     */
    subContextName?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbViewModel
     */
    blueprintId?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbViewModel
     */
    containerId?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbViewModel
     */
    containerName?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbViewModel
     */
    cellId?: string;
    /**
     * 
     * @type {string}
     * @memberof BreadcrumbViewModel
     */
    cellName?: string;
}

export function BreadcrumbViewModelFromJSON(json: any): BreadcrumbViewModel {
    return BreadcrumbViewModelFromJSONTyped(json, false);
}

export function BreadcrumbViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BreadcrumbViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'designContextId': !exists(json, 'designContextId') ? undefined : json['designContextId'],
        'designContextName': !exists(json, 'designContextName') ? undefined : json['designContextName'],
        'subContextId': !exists(json, 'subContextId') ? undefined : json['subContextId'],
        'subContextName': !exists(json, 'subContextName') ? undefined : json['subContextName'],
        'blueprintId': !exists(json, 'blueprintId') ? undefined : json['blueprintId'],
        'containerId': !exists(json, 'containerId') ? undefined : json['containerId'],
        'containerName': !exists(json, 'containerName') ? undefined : json['containerName'],
        'cellId': !exists(json, 'cellId') ? undefined : json['cellId'],
        'cellName': !exists(json, 'cellName') ? undefined : json['cellName'],
    };
}

export function BreadcrumbViewModelToJSON(value?: BreadcrumbViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'designContextId': value.designContextId,
        'designContextName': value.designContextName,
        'subContextId': value.subContextId,
        'subContextName': value.subContextName,
        'blueprintId': value.blueprintId,
        'containerId': value.containerId,
        'containerName': value.containerName,
        'cellId': value.cellId,
        'cellName': value.cellName,
    };
}


