import produce from "immer";
import { MemoLikeModel } from "../models/MemoLikeViewModel";
import { MemoViewModel } from "../openapi/webservice";

export type MemosState = MemoLikeModel[];

export enum ActionType {
    set,
    insert,
    update,
    delete,
}

interface SetAction {
    type: ActionType.set;
    value: MemoLikeModel[];
}

interface InsertAction {
    type: ActionType.insert;
    value: MemoViewModel;
}

interface UpdateAction {
    type: ActionType.update;
    value: MemoViewModel;
}

interface DeleteAction {
    type: ActionType.delete;
    value: MemoViewModel;
}

export type MemosStateAction = SetAction | InsertAction | UpdateAction | DeleteAction;

export function getInitialState(): MemosState {
    return [];
}

export const MemosStateReducer = (state: MemosState, action: MemosStateAction) => {
    switch (action.type) {
        case ActionType.set:
            return JSON.parse(JSON.stringify(action.value));
        case ActionType.insert:
            return produce(state, (draft: MemosState) => {
                draft.push(action.value);
            });
        case ActionType.update:
            return produce(state, (draft: MemosState) => {
                const index = draft.findIndex((memo) => memo.id === action.value.id);

                if (index !== -1) {
                    draft[index] = action.value;
                }
            });
        case ActionType.delete:
            return produce(state, (draft: MemosState) => {
                const index = draft.findIndex((memo) => memo.id === action.value.id);

                if (index !== -1) {
                    draft.splice(index, 1);
                }
            });
        default:
            return state;
    }
};
