import React, { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import { getInitialState, State, StateAction, GenericStateReducer } from "../reducers/GenericArrayStateReducer";
import { OrganisationViewModel } from "../openapi/webservice";

type ReducerT = [State<OrganisationViewModel>, Dispatch<StateAction<OrganisationViewModel>>];

export const OrganisationsStateContext = createContext<ReducerT>([getInitialState(), () => {}]);

export const OrganisationsStateContextProvider = ({ children }: PropsWithChildren<{}>) => {
    const reducer = useReducer(GenericStateReducer, getInitialState()) as ReducerT;

    return <OrganisationsStateContext.Provider value={reducer}>{children}</OrganisationsStateContext.Provider>;
};
