import React from "react";
import styles from "./Copyright.module.scss";
import classNames from "classnames";
import { DateTime } from "luxon";

type Size = "normal" | "small";

type Props = {
    className: string;
    size: Size;
};

function getSizeStyle(size: Size) {
    switch (size) {
        case "normal":
            return styles.normalSize;
        case "small":
            return styles.smallSize;
    }
}

const Copyright = ({ className, size }: Props) => {
    return (
        <div className={classNames(styles.container, getSizeStyle(size), className)}>
            &copy; 2021 - {DateTime.now().toFormat("yyyy")} SixBlocks solution Holding B.V.
        </div>
    );
};

Copyright.defaultProps = {
    className: "",
    size: "normal",
};

export default Copyright;
