import React from "react";
import styles from "./TextInput.module.scss";
import classNames from "classnames";
import Text from "../Text/Text";
import { HtmlInputProps } from "../ReusableTypes";

type Props = HtmlInputProps & {
    label: string;
};

const TextInput = ({ className, label, ...props }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <label>
                {label.length > 0 && <Text label={label} className={styles.text} />}
                <input type="text" data-testid="TextInput" {...props} className={styles.input} />
            </label>
        </div>
    );
};

TextInput.defaultProps = {
    className: "",
};

export default TextInput;
