import { useSearchParams } from "react-router-dom";
import { TRANSACTION_SEARCHPARAM_KEY } from "../utils/RouterConstants";
import { useTimelineTransactions } from "./useTimelineTransactions";

export function useSelectedTransaction() {
    const [searchParams] = useSearchParams();
    const atDateString = searchParams.get(TRANSACTION_SEARCHPARAM_KEY);
    const [transactions] = useTimelineTransactions();

    return transactions.find((t) => t.validTimeStart.getTime() === new Date(atDateString || "").getTime());
}
