import React from "react";
import { Group, Image, Rect } from "react-konva";
import "konva/lib/shapes/Image";
import "konva/lib/shapes/Rect";
import { KonvaEventObject } from "konva/lib/Node";
import { ICON_SIZE } from "../../utils/CanvasConstants";
import useImage from "use-image";
import { TrashIcon } from "../../assets/images/Images";

type Props = {
    onClick?: (event: KonvaEventObject<MouseEvent>) => void;
    x: number;
    y: number;
    visible?: boolean;
};

const TrashButton = ({ onClick, x, y, visible }: Props) => {
    const [trashIcon] = useImage(TrashIcon);

    if (!trashIcon) {
        return null;
    }

    return (
        <Group x={x} y={y} height={ICON_SIZE} width={ICON_SIZE} visible={visible} onClick={onClick}>
            <Rect
                x={0}
                y={0}
                height={ICON_SIZE}
                width={ICON_SIZE}
                fill="#fff"
                shadowEnabled
                shadowBlur={8}
                shadowOpacity={0.7}
                shadowOffset={{ x: 3, y: 3 }}
            />
            <Image image={trashIcon} x={4} y={4} height={ICON_SIZE - 8} width={ICON_SIZE - 8} />
        </Group>
    );
};

TrashButton.defaultProps = {
    visible: true,
};

export default TrashButton;
