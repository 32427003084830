import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from "react-router-dom";
import styles from "./App.module.scss";
import { Bouncer } from "./components/Bouncer/Bouncer";
import Title from "./components/Title/Title";
import LoginPageContainer from "./pages/LoginPage/LoginPageContainer";
import DefaultPage from "./pages/DefaultPage/DefaultPage";
import { PageViewsReporter } from "./utils/Analytics";
import MemoboardPage from "./pages/MemoboardPage/MemoboardPage";
import BlueprintPage from "./pages/BlueprintPage/BlueprintPage";
import TempContextManagementPage from "./pages/TempContextManagementPage/TempContextManagementPage";
import TempOrganisationManagementPage from "./pages/TempOrganisationManagementPage/TempOrganisationManagementPage";
import TempUserManagementPage from "./pages/TempUserManagementPage/TempUserManagementPage";
import TempSettingsPage from "./pages/TempSettingsPage/TempSettingsPage";
import { AdminBouncer } from "./components/AdminBouncer/AdminBouncer";

function App() {
    return (
        <div className={styles.app}>
            <Helmet titleTemplate="%s | Business Rules Canvas" defaultTitle="Business Rules Canvas">
                <meta
                    name="description"
                    content="Inspirerend platform ten behoeve van het integraal changemanagement voor alle bedrijfsprocessen met een verbindende en verantwoordelijke organisatie."
                />
            </Helmet>
            <Router>
                <PageViewsReporter>
                    <Routes>
                        <Route path="/">
                            <Route index element={<Navigate to="/plattegrond" replace />} />
                            {/** START Protected routes */}
                            <Route
                                element={
                                    <Bouncer>
                                        <Outlet />
                                    </Bouncer>
                                }
                            >
                                <Route
                                    element={
                                        <DefaultPage>
                                            <Outlet />
                                        </DefaultPage>
                                    }
                                >
                                    <Route path="plattegrond">
                                        <Route index element={<BlueprintPage />} />
                                    </Route>
                                    <Route path="subcontext/:subContextId">
                                        <Route path="plattegrond/:blueprintId">
                                            <Route index element={<BlueprintPage />} />
                                            <Route path="memobord/:cellId">
                                                <Route index element={<MemoboardPage />} />
                                            </Route>
                                        </Route>
                                    </Route>
                                    <Route
                                        path="blok/:cellId"
                                        element={
                                            // TODO but also not in scope?
                                            <DefaultPage>
                                                <Title label="Blokken bewerken: Work in progress" heading="h1" />
                                            </DefaultPage>
                                        }
                                    />
                                </Route>

                                <Route
                                    path="demo-instellingen"
                                    element={
                                        <AdminBouncer>
                                            <Outlet />
                                        </AdminBouncer>
                                    }
                                >
                                    <Route path="context" element={<TempContextManagementPage />} />
                                    <Route path="organisaties" element={<TempOrganisationManagementPage />} />
                                    <Route path="gebruikers" element={<TempUserManagementPage />} />
                                    <Route index element={<TempSettingsPage />} />
                                </Route>
                            </Route>
                            {/** END Protected routes */}
                        </Route>
                        <Route path="/login" element={<LoginPageContainer />} />
                        {/** SIX-137 Replace this catching route with a proper 404 page */}
                        <Route path="*" element={<Navigate to="/plattegrond" replace />} />
                    </Routes>
                </PageViewsReporter>
            </Router>
        </div>
    );
}

export default App;
