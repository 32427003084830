import React, { useCallback } from "react";
import { OrganisationViewModel, UserInputModel, UserViewModel } from "../../openapi/webservice";
import RoughUserManagementInput from "./RoughUserManagementInput";
import { useUsersStateChanges } from "../../hooks/useUsersStateChanges";

type Props = {
    className: string;
    user: UserViewModel;
    organisations: OrganisationViewModel[];
};

// @NOTE(Lejun) As the name suggests, this is a rough component used for some internal usage.
//              In later versions this should definitely be replaced.
const TempUserManagementInput = ({ className, user, organisations }: Props) => {
    const { update, _delete } = useUsersStateChanges();

    const onSave = useCallback(
        (input: UserInputModel) => {
            update(user.id, input);
        },
        [update, user.id],
    );

    const onDelete = useCallback(() => {
        _delete(user.id);
    }, [_delete, user.id]);

    return (
        <RoughUserManagementInput
            user={user}
            organisations={organisations}
            className={className}
            saveCallback={onSave}
            deleteCallback={onDelete}
        />
    );
};

TempUserManagementInput.defaultProps = {
    className: "",
};

export default TempUserManagementInput;
