import React from "react";
import styles from "./CanvasCard.module.scss";
import classNames from "classnames";
import { HtmlDivProps } from "../ReusableTypes";

type Props = Omit<HtmlDivProps, "className"> & {
    className: string;
};

const CanvasCard = ({ className, ...props }: Props) => {
    return <div className={classNames(styles.container, className)} {...props} />;
};

CanvasCard.defaultProps = {
    className: "",
};

export default CanvasCard;
