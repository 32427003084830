import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OrganisationInputModel } from "../openapi/webservice";
import { notifySuccess } from "../utils/NotificationUtils";
import { useErrorResponseNotification } from "./useErrorNotification";
import {
    useCreateOrganisation,
    useDeleteOrganisation,
    useGetOrganisationsCallback,
    useUpdateOrganisation,
} from "./useOrganisationsApi";
import { useOrganisations } from "./useOrganisations";
import { ActionType } from "../reducers/GenericArrayStateReducer";

export function useOrganisationsStateChanges() {
    const { t } = useTranslation();
    const [, dispatch] = useOrganisations();
    const [{ error: createError }, createCallback] = useCreateOrganisation();
    const [{ error: deleteError }, deleteCallback] = useDeleteOrganisation();
    const [{ error: updateError }, updateCallback] = useUpdateOrganisation();
    const [{ error: getError }, getCallback] = useGetOrganisationsCallback();
    useErrorResponseNotification(createError);
    useErrorResponseNotification(deleteError);
    useErrorResponseNotification(updateError);
    useErrorResponseNotification(getError);

    const create = useCallback(
        async (input: OrganisationInputModel) => {
            const response = await createCallback({
                organisationInputModel: input,
            });
            dispatch({ type: ActionType.add, value: response });
        },
        [createCallback, dispatch],
    );

    const _delete = useCallback(
        async (id: string) => {
            await deleteCallback({
                organisationId: id,
            });

            const organisations = await getCallback();
            dispatch({ type: ActionType.set, value: organisations });

            notifySuccess(t("toast.success.delete"));
        },
        [deleteCallback, getCallback, dispatch, t],
    );

    const update = useCallback(
        async (id: string, input: OrganisationInputModel) => {
            const response = await updateCallback({
                organisationId: id,
                organisationInputModel: input,
            });
            dispatch({ type: ActionType.update, value: response });

            notifySuccess(t("toast.success.update"));
        },
        [updateCallback, dispatch, t],
    );

    return useMemo(() => ({ create, _delete, update }), [create, _delete, update]);
}
