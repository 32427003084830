import React, { useCallback, useState } from "react";
import { useUsersStateChanges } from "../../hooks/useUsersStateChanges";
import RoughAddUserInput from "./RoughAddUserInput";
import { OrganisationViewModel, UserInputModel } from "../../openapi/webservice";

type Props = {
    className: string;
    organisations: OrganisationViewModel[];
};

// @NOTE(Lejun) As the name suggests, this is a rough component used for some internal usage.
//              In later versions this should definitely be replaced.
const TempAddUserInputContainer = ({ className, organisations }: Props) => {
    const { create } = useUsersStateChanges();
    const [version, setVersion] = useState(1);

    const onSave = useCallback(
        async (input: UserInputModel) => {
            await create(input);
            // Update version to clear form
            setVersion((value) => value + (1 % 1000));
        },
        [create],
    );

    return (
        <RoughAddUserInput key={version} className={className} saveCallback={onSave} organisations={organisations} />
    );
};

TempAddUserInputContainer.defaultProps = {
    className: "",
};

export default TempAddUserInputContainer;
