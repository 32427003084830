/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    DesignContextViewModel,
    DesignContextViewModelFromJSON,
    DesignContextViewModelFromJSONTyped,
    DesignContextViewModelToJSON,
    SubContextViewModel,
    SubContextViewModelFromJSON,
    SubContextViewModelFromJSONTyped,
    SubContextViewModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatedSubContextViewModel
 */
export interface CreatedSubContextViewModel {
    /**
     * 
     * @type {Array<DesignContextViewModel>}
     * @memberof CreatedSubContextViewModel
     */
    readonly contexts: Array<DesignContextViewModel>;
    /**
     * 
     * @type {SubContextViewModel}
     * @memberof CreatedSubContextViewModel
     */
    created?: SubContextViewModel;
}

export function CreatedSubContextViewModelFromJSON(json: any): CreatedSubContextViewModel {
    return CreatedSubContextViewModelFromJSONTyped(json, false);
}

export function CreatedSubContextViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatedSubContextViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contexts': ((json['contexts'] as Array<any>).map(DesignContextViewModelFromJSON)),
        'created': !exists(json, 'created') ? undefined : SubContextViewModelFromJSON(json['created']),
    };
}

export function CreatedSubContextViewModelToJSON(value?: CreatedSubContextViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': SubContextViewModelToJSON(value.created),
    };
}


