import React, { useEffect } from "react";
import { RequestState } from "../../hooks/useApiCall";
import TempUserManagement from "./TempUserManagement";
import { useGetUsers } from "../../hooks/useUsersApi";
import { useUsers } from "../../hooks/useUsers";
import { ActionType } from "../../reducers/GenericArrayStateReducer";
import { useGetOrganisations } from "../../hooks/useOrganisationsApi";
import { OrganisationViewModel } from "../../openapi/webservice";

type Props = {
    className: string;
};

const defaultOrganisations: OrganisationViewModel[] = [];

const TempUserManagementContainer = ({ className }: Props) => {
    const { state: userState, value: userValue } = useGetUsers();
    const { state: organisationState, value: organisationValue } = useGetOrganisations();
    const [users, dispatch] = useUsers();

    useEffect(() => {
        if (userState !== RequestState.DONE || organisationState !== RequestState.DONE) {
            return;
        }

        dispatch({ type: ActionType.set, value: userValue ?? [] });
    }, [userState, organisationState, userValue, dispatch]);

    return (
        <TempUserManagement
            className={className}
            organisations={organisationValue || defaultOrganisations}
            users={users}
        />
    );
};

TempUserManagementContainer.defaultProps = {
    className: "",
};

export default TempUserManagementContainer;
