import { useCallback, useMemo } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import {
    FILTER_CLUSTER_KEY,
    FILTER_DOMAIN_KEY,
    FILTER_EXTERNAL_SINK_KEY,
    FILTER_EXTERNAL_SOURCE_KEY,
} from "../utils/RouterConstants";

type FilterKeys =
    | typeof FILTER_CLUSTER_KEY
    | typeof FILTER_DOMAIN_KEY
    | typeof FILTER_EXTERNAL_SINK_KEY
    | typeof FILTER_EXTERNAL_SOURCE_KEY;

export function useBlueprintFilter() {
    const [searchParams, setSearchParams] = useSearchParams();
    const filterClusters = !!searchParams.get(FILTER_CLUSTER_KEY);
    const filterDomains = !!searchParams.get(FILTER_DOMAIN_KEY);
    const filterExternalSinks = !!searchParams.get(FILTER_EXTERNAL_SINK_KEY);
    const filterExternalSources = !!searchParams.get(FILTER_EXTERNAL_SOURCE_KEY);

    const update = useCallback(
        (key: FilterKeys, value: boolean) => {
            setSearchParams(
                (search) => {
                    const next = createSearchParams(search);
                    const currentValue = !!search.get(key);

                    if (currentValue !== value) {
                        if (value) {
                            next.set(key, "1");
                        } else {
                            next.delete(key);
                        }
                        return next;
                    }

                    return search;
                },
                { replace: true },
            );
        },
        [setSearchParams],
    );

    const reset = useCallback(() => {
        setSearchParams((search) => {
            const next = createSearchParams(search);
            let changed = false;
            [FILTER_CLUSTER_KEY, FILTER_DOMAIN_KEY, FILTER_EXTERNAL_SINK_KEY, FILTER_EXTERNAL_SOURCE_KEY].forEach(
                (key) => {
                    if (!next.get(key)) {
                        return;
                    }
                    changed = true;
                    next.delete(key);
                },
            );

            return changed ? next : search;
        });
    }, [setSearchParams]);

    const isActive = useMemo(
        () => [filterClusters, filterDomains, filterExternalSinks, filterExternalSources].some((f) => f),
        [filterClusters, filterDomains, filterExternalSinks, filterExternalSources],
    );

    return useMemo(
        () => ({
            filters: {
                [FILTER_CLUSTER_KEY]: filterClusters,
                [FILTER_DOMAIN_KEY]: filterDomains,
                [FILTER_EXTERNAL_SINK_KEY]: filterExternalSinks,
                [FILTER_EXTERNAL_SOURCE_KEY]: filterExternalSources,
            },
            update,
            reset,
            isActive,
        }),
        [filterClusters, filterDomains, filterExternalSinks, filterExternalSources, update, reset, isActive],
    );
}
