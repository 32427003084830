/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BlueprintViewModel,
    BlueprintViewModelFromJSON,
    BlueprintViewModelToJSON,
    BreadcrumbViewModel,
    BreadcrumbViewModelFromJSON,
    BreadcrumbViewModelToJSON,
    CellInputModel,
    CellInputModelFromJSON,
    CellInputModelToJSON,
    ConnectionInputModel,
    ConnectionInputModelFromJSON,
    ConnectionInputModelToJSON,
    ConnectionViewModel,
    ConnectionViewModelFromJSON,
    ConnectionViewModelToJSON,
    ContainerInputModel,
    ContainerInputModelFromJSON,
    ContainerInputModelToJSON,
    ContainerPositionBulkUpdateInputModel,
    ContainerPositionBulkUpdateInputModelFromJSON,
    ContainerPositionBulkUpdateInputModelToJSON,
    ContainerViewModel,
    ContainerViewModelFromJSON,
    ContainerViewModelToJSON,
    DoubleDoubleTuple,
    DoubleDoubleTupleFromJSON,
    DoubleDoubleTupleToJSON,
} from '../models';

export interface DeleteCellRequest {
    blueprintId: string;
    cellId: string;
}

export interface DeleteConnectionRequest {
    blueprintId: string;
    connectionId: string;
}

export interface DeleteContainerRequest {
    blueprintId: string;
    containerId: string;
}

export interface GetActiveBlueprintRequest {
    id: string;
}

export interface GetBlueprintRequest {
    id: string;
}

export interface GetBlueprintBreadcrumbRequest {
    id: string;
}

export interface GetHistoricalBlueprintRequest {
    id: string;
    asOf?: Date;
}

export interface InsertConnectionRequest {
    blueprintId: string;
    connectionInputModel?: ConnectionInputModel;
}

export interface InsertContainerRequest {
    blueprintId: string;
    containerInputModel?: ContainerInputModel;
}

export interface InsertNewCellRequest {
    blueprintId: string;
    containerId: string;
    cellInputModel?: CellInputModel;
}

export interface UpdateCellNameRequest {
    blueprintId: string;
    cellId: string;
    body?: string;
}

export interface UpdateContainerDescriptionRequest {
    blueprintId: string;
    containerId: string;
    body?: string;
}

export interface UpdateContainerNameRequest {
    blueprintId: string;
    containerId: string;
    body?: string;
}

export interface UpdateContainerPositionRequest {
    blueprintId: string;
    containerId: string;
    doubleDoubleTuple?: DoubleDoubleTuple;
}

export interface UpdateContainerPositionsRequest {
    blueprintId: string;
    containerPositionBulkUpdateInputModel?: ContainerPositionBulkUpdateInputModel;
}

/**
 * no description
 */
export class BlueprintsApi extends runtime.BaseAPI {
    /**
     * Remove an existing cell from the blueprint.
     */
    async deleteCellRaw(requestParameters: DeleteCellRequest): Promise<runtime.ApiResponse<BlueprintViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling deleteCell.');
        }
        if (requestParameters.cellId === null || requestParameters.cellId === undefined) {
            throw new runtime.RequiredError('cellId','Required parameter requestParameters.cellId was null or undefined when calling deleteCell.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/cell/{cellId}`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))).replace(`{${"cellId"}}`, encodeURIComponent(String(requestParameters.cellId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BlueprintViewModelFromJSON(jsonValue));
    }
    /**
     * Remove an existing cell from the blueprint.
     */
    async deleteCell(requestParameters: DeleteCellRequest): Promise<BlueprintViewModel> {
        const response = await this.deleteCellRaw(requestParameters);
        return await response.value();
    }
    /**
     * Remove an existing connection from the blueprint.
     */
    async deleteConnectionRaw(requestParameters: DeleteConnectionRequest): Promise<runtime.ApiResponse<BlueprintViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling deleteConnection.');
        }
        if (requestParameters.connectionId === null || requestParameters.connectionId === undefined) {
            throw new runtime.RequiredError('connectionId','Required parameter requestParameters.connectionId was null or undefined when calling deleteConnection.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/connection/{connectionId}`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))).replace(`{${"connectionId"}}`, encodeURIComponent(String(requestParameters.connectionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BlueprintViewModelFromJSON(jsonValue));
    }
    /**
     * Remove an existing connection from the blueprint.
     */
    async deleteConnection(requestParameters: DeleteConnectionRequest): Promise<BlueprintViewModel> {
        const response = await this.deleteConnectionRaw(requestParameters);
        return await response.value();
    }
    /**
     * Remove an existing container from the blueprint.
     */
    async deleteContainerRaw(requestParameters: DeleteContainerRequest): Promise<runtime.ApiResponse<BlueprintViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling deleteContainer.');
        }
        if (requestParameters.containerId === null || requestParameters.containerId === undefined) {
            throw new runtime.RequiredError('containerId','Required parameter requestParameters.containerId was null or undefined when calling deleteContainer.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/container/{containerId}`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))).replace(`{${"containerId"}}`, encodeURIComponent(String(requestParameters.containerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BlueprintViewModelFromJSON(jsonValue));
    }
    /**
     * Remove an existing container from the blueprint.
     */
    async deleteContainer(requestParameters: DeleteContainerRequest): Promise<BlueprintViewModel> {
        const response = await this.deleteContainerRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve the blueprint as of the given date.
     */
    async getActiveBlueprintRaw(requestParameters: GetActiveBlueprintRequest): Promise<runtime.ApiResponse<BlueprintViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getActiveBlueprint.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{id}/active`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BlueprintViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve the blueprint as of the given date.
     */
    async getActiveBlueprint(requestParameters: GetActiveBlueprintRequest): Promise<BlueprintViewModel> {
        const response = await this.getActiveBlueprintRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve the blueprint as per the current date.
     */
    async getBlueprintRaw(requestParameters: GetBlueprintRequest): Promise<runtime.ApiResponse<BlueprintViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBlueprint.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BlueprintViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve the blueprint as per the current date.
     */
    async getBlueprint(requestParameters: GetBlueprintRequest): Promise<BlueprintViewModel> {
        const response = await this.getBlueprintRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve the breadcrumb for the blueprint as per the current date.
     */
    async getBlueprintBreadcrumbRaw(requestParameters: GetBlueprintBreadcrumbRequest): Promise<runtime.ApiResponse<BreadcrumbViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBlueprintBreadcrumb.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{id}/breadCrumb`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BreadcrumbViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve the breadcrumb for the blueprint as per the current date.
     */
    async getBlueprintBreadcrumb(requestParameters: GetBlueprintBreadcrumbRequest): Promise<BreadcrumbViewModel> {
        const response = await this.getBlueprintBreadcrumbRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve the blueprint as of the given date.
     */
    async getHistoricalBlueprintRaw(requestParameters: GetHistoricalBlueprintRequest): Promise<runtime.ApiResponse<BlueprintViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getHistoricalBlueprint.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.asOf !== undefined) {
            queryParameters['asOf'] = (requestParameters.asOf as any).toUTCString();
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{id}/historical`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BlueprintViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve the blueprint as of the given date.
     */
    async getHistoricalBlueprint(requestParameters: GetHistoricalBlueprintRequest): Promise<BlueprintViewModel> {
        const response = await this.getHistoricalBlueprintRaw(requestParameters);
        return await response.value();
    }
    /**
     * Insert a connection between two existing cells on the blueprint.
     */
    async insertConnectionRaw(requestParameters: InsertConnectionRequest): Promise<runtime.ApiResponse<ConnectionViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling insertConnection.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/connection`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectionInputModelToJSON(requestParameters.connectionInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ConnectionViewModelFromJSON(jsonValue));
    }
    /**
     * Insert a connection between two existing cells on the blueprint.
     */
    async insertConnection(requestParameters: InsertConnectionRequest): Promise<ConnectionViewModel> {
        const response = await this.insertConnectionRaw(requestParameters);
        return await response.value();
    }
    /**
     * Add a new container to the blueprint.
     */
    async insertContainerRaw(requestParameters: InsertContainerRequest): Promise<runtime.ApiResponse<ContainerViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling insertContainer.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/container`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContainerInputModelToJSON(requestParameters.containerInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerViewModelFromJSON(jsonValue));
    }
    /**
     * Add a new container to the blueprint.
     */
    async insertContainer(requestParameters: InsertContainerRequest): Promise<ContainerViewModel> {
        const response = await this.insertContainerRaw(requestParameters);
        return await response.value();
    }
    /**
     * Insert a cell in an existing container on the blueprint.
     */
    async insertNewCellRaw(requestParameters: InsertNewCellRequest): Promise<runtime.ApiResponse<ContainerViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling insertNewCell.');
        }
        if (requestParameters.containerId === null || requestParameters.containerId === undefined) {
            throw new runtime.RequiredError('containerId','Required parameter requestParameters.containerId was null or undefined when calling insertNewCell.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/container/{containerId}/cell`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))).replace(`{${"containerId"}}`, encodeURIComponent(String(requestParameters.containerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CellInputModelToJSON(requestParameters.cellInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerViewModelFromJSON(jsonValue));
    }
    /**
     * Insert a cell in an existing container on the blueprint.
     */
    async insertNewCell(requestParameters: InsertNewCellRequest): Promise<ContainerViewModel> {
        const response = await this.insertNewCellRaw(requestParameters);
        return await response.value();
    }
    /**
     * Edit the name of an existing cell on the blueprint.
     */
    async updateCellNameRaw(requestParameters: UpdateCellNameRequest): Promise<runtime.ApiResponse<ContainerViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling updateCellName.');
        }
        if (requestParameters.cellId === null || requestParameters.cellId === undefined) {
            throw new runtime.RequiredError('cellId','Required parameter requestParameters.cellId was null or undefined when calling updateCellName.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/cell/{cellId}`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))).replace(`{${"cellId"}}`, encodeURIComponent(String(requestParameters.cellId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerViewModelFromJSON(jsonValue));
    }
    /**
     * Edit the name of an existing cell on the blueprint.
     */
    async updateCellName(requestParameters: UpdateCellNameRequest): Promise<ContainerViewModel> {
        const response = await this.updateCellNameRaw(requestParameters);
        return await response.value();
    }
    /**
     * Edit the description of an existing container on the blueprint.
     */
    async updateContainerDescriptionRaw(requestParameters: UpdateContainerDescriptionRequest): Promise<runtime.ApiResponse<ContainerViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling updateContainerDescription.');
        }
        if (requestParameters.containerId === null || requestParameters.containerId === undefined) {
            throw new runtime.RequiredError('containerId','Required parameter requestParameters.containerId was null or undefined when calling updateContainerDescription.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/container/{containerId}/description`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))).replace(`{${"containerId"}}`, encodeURIComponent(String(requestParameters.containerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerViewModelFromJSON(jsonValue));
    }
    /**
     * Edit the description of an existing container on the blueprint.
     */
    async updateContainerDescription(requestParameters: UpdateContainerDescriptionRequest): Promise<ContainerViewModel> {
        const response = await this.updateContainerDescriptionRaw(requestParameters);
        return await response.value();
    }
    /**
     * Edit the name of an existing container on the blueprint.
     */
    async updateContainerNameRaw(requestParameters: UpdateContainerNameRequest): Promise<runtime.ApiResponse<ContainerViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling updateContainerName.');
        }
        if (requestParameters.containerId === null || requestParameters.containerId === undefined) {
            throw new runtime.RequiredError('containerId','Required parameter requestParameters.containerId was null or undefined when calling updateContainerName.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/container/{containerId}/name`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))).replace(`{${"containerId"}}`, encodeURIComponent(String(requestParameters.containerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerViewModelFromJSON(jsonValue));
    }
    /**
     * Edit the name of an existing container on the blueprint.
     */
    async updateContainerName(requestParameters: UpdateContainerNameRequest): Promise<ContainerViewModel> {
        const response = await this.updateContainerNameRaw(requestParameters);
        return await response.value();
    }
    /**
     * Edit the position of an existing container on the blueprint.
     */
    async updateContainerPositionRaw(requestParameters: UpdateContainerPositionRequest): Promise<runtime.ApiResponse<ContainerViewModel>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling updateContainerPosition.');
        }
        if (requestParameters.containerId === null || requestParameters.containerId === undefined) {
            throw new runtime.RequiredError('containerId','Required parameter requestParameters.containerId was null or undefined when calling updateContainerPosition.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/container/{containerId}/position`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))).replace(`{${"containerId"}}`, encodeURIComponent(String(requestParameters.containerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DoubleDoubleTupleToJSON(requestParameters.doubleDoubleTuple),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => ContainerViewModelFromJSON(jsonValue));
    }
    /**
     * Edit the position of an existing container on the blueprint.
     */
    async updateContainerPosition(requestParameters: UpdateContainerPositionRequest): Promise<ContainerViewModel> {
        const response = await this.updateContainerPositionRaw(requestParameters);
        return await response.value();
    }
    /**
     * Edit the positions of an multiple existing containers on the blueprint.
     */
    async updateContainerPositionsRaw(requestParameters: UpdateContainerPositionsRequest): Promise<runtime.ApiResponse<Array<ContainerViewModel>>> {
        if (requestParameters.blueprintId === null || requestParameters.blueprintId === undefined) {
            throw new runtime.RequiredError('blueprintId','Required parameter requestParameters.blueprintId was null or undefined when calling updateContainerPositions.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Blueprints/{blueprintId}/container/positions`.replace(`{${"blueprintId"}}`, encodeURIComponent(String(requestParameters.blueprintId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContainerPositionBulkUpdateInputModelToJSON(requestParameters.containerPositionBulkUpdateInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContainerViewModelFromJSON));
    }
    /**
     * Edit the positions of an multiple existing containers on the blueprint.
     */
    async updateContainerPositions(requestParameters: UpdateContainerPositionsRequest): Promise<Array<ContainerViewModel>> {
        const response = await this.updateContainerPositionsRaw(requestParameters);
        return await response.value();
    }
}
