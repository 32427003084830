/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    ContainerPositionUpdateInputModel,
    ContainerPositionUpdateInputModelFromJSON,
    ContainerPositionUpdateInputModelFromJSONTyped,
    ContainerPositionUpdateInputModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContainerPositionBulkUpdateInputModel
 */
export interface ContainerPositionBulkUpdateInputModel {
    /**
     * 
     * @type {Array<ContainerPositionUpdateInputModel>}
     * @memberof ContainerPositionBulkUpdateInputModel
     */
    inputs: Array<ContainerPositionUpdateInputModel>;
}

export function ContainerPositionBulkUpdateInputModelFromJSON(json: any): ContainerPositionBulkUpdateInputModel {
    return ContainerPositionBulkUpdateInputModelFromJSONTyped(json, false);
}

export function ContainerPositionBulkUpdateInputModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainerPositionBulkUpdateInputModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inputs': ((json['inputs'] as Array<any>).map(ContainerPositionUpdateInputModelFromJSON)),
    };
}

export function ContainerPositionBulkUpdateInputModelToJSON(value?: ContainerPositionBulkUpdateInputModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inputs': ((value.inputs as Array<any>).map(ContainerPositionUpdateInputModelToJSON)),
    };
}


