import React, { PropsWithChildren, useEffect } from "react";
import ReactGA from "react-ga4";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router-dom";
import { Env } from "./Env";
import { reportException } from "../CrashReporting";

export function initAnalytics() {
    if (!Env.REACT_APP_GA_ID) {
        console.info("[CrashReporting] REACT_APP_GA_ID not set. Analytics and crash reporting disabled.");
        return;
    }

    ReactGA.initialize(Env.REACT_APP_GA_ID, { gtagOptions: { debug_mode: false } });
}

export function PageViewsReporter({ children }: PropsWithChildren<{}>) {
    usePageViews();
    return <React.Fragment>{children}</React.Fragment>;
}

export function usePageViews() {
    const { pathname, search } = useLocation();
    const route = pathname + search;

    useEffect(() => {
        if (!ReactGA.isInitialized) {
            return;
        }

        ReactGA.send({ hitType: "pageview", page: route });
    }, [route]);
}

// @NOTE(Lejun) SIX-107 Make this pretty or remove?
function ErrorFallback({ error }: { error: Error }) {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <pre>{error.stack}</pre>
        </div>
    );
}

function GaErrorHandler(error: Error, info: { componentStack: string }) {
    // Use GlitchTip for more comprehensive error  for now.
    reportGaException(error, true, {
        componentStack: info.componentStack,
    });
}

export function LoggingErrorBoundary({ children }: PropsWithChildren<{}>) {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={GaErrorHandler}>
            {children}
        </ErrorBoundary>
    );
}

export function reportGaException(error: Error, fatal: boolean, extra?: Record<string, string | boolean | number>) {
    // Use GlitchTip for more comprehensive error reporting for now.
    reportException(error, {
        fatal,
        ...extra,
    });

    if (!ReactGA.isInitialized) {
        console.info("Google Analytics not initialized, skipping reporting exception");
        return;
    }

    ReactGA.event("exception", { fatal, description: error });
}
