/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TransactionState {
    Solution = 'Solution',
    InProgress = 'InProgress',
    Scheduled = 'Scheduled',
    InAcceptation = 'InAcceptation',
    InParking = 'InParking'
}

export function TransactionStateFromJSON(json: any): TransactionState {
    return TransactionStateFromJSONTyped(json, false);
}

export function TransactionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionState {
    return json as TransactionState;
}

export function TransactionStateToJSON(value?: TransactionState | null): any {
    return value as any;
}

