/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CellInputModel
 */
export interface CellInputModel {
    /**
     * 
     * @type {string}
     * @memberof CellInputModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CellInputModel
     */
    index: number;
}

export function CellInputModelFromJSON(json: any): CellInputModel {
    return CellInputModelFromJSONTyped(json, false);
}

export function CellInputModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CellInputModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'index': json['index'],
    };
}

export function CellInputModelToJSON(value?: CellInputModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'index': value.index,
    };
}


