/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateSubContextInputModel,
    CreateSubContextInputModelFromJSON,
    CreateSubContextInputModelToJSON,
    CreatedSubContextViewModel,
    CreatedSubContextViewModelFromJSON,
    CreatedSubContextViewModelToJSON,
    DesignContextInputModel,
    DesignContextInputModelFromJSON,
    DesignContextInputModelToJSON,
    DesignContextOverview,
    DesignContextOverviewFromJSON,
    DesignContextOverviewToJSON,
    DesignContextViewModel,
    DesignContextViewModelFromJSON,
    DesignContextViewModelToJSON,
    SubContextInputModel,
    SubContextInputModelFromJSON,
    SubContextInputModelToJSON,
    SubContextViewModel,
    SubContextViewModelFromJSON,
    SubContextViewModelToJSON,
} from '../models';

export interface CreateSubContextRequest {
    createSubContextInputModel?: CreateSubContextInputModel;
}

export interface DeleteSubContextRequest {
    contextId: string;
    subContextId: string;
}

export interface UpdateDesignContextRequest {
    id: string;
    designContextInputModel?: DesignContextInputModel;
}

export interface UpdateSubContextRequest {
    contextId: string;
    subContextId: string;
    subContextInputModel?: SubContextInputModel;
}

/**
 * no description
 */
export class ContextsApi extends runtime.BaseAPI {
    /**
     * Add a new subcontext and corresponding blueprint.
     */
    async createSubContextRaw(requestParameters: CreateSubContextRequest): Promise<runtime.ApiResponse<CreatedSubContextViewModel>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Contexts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSubContextInputModelToJSON(requestParameters.createSubContextInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => CreatedSubContextViewModelFromJSON(jsonValue));
    }
    /**
     * Add a new subcontext and corresponding blueprint.
     */
    async createSubContext(requestParameters: CreateSubContextRequest): Promise<CreatedSubContextViewModel> {
        const response = await this.createSubContextRaw(requestParameters);
        return await response.value();
    }
    /**
     * Remove the given subcontext. If no subcontexts remain, the parent context is also removed.
     * Remove subcontext with the given id.
     */
    async deleteSubContextRaw(requestParameters: DeleteSubContextRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.contextId === null || requestParameters.contextId === undefined) {
            throw new runtime.RequiredError('contextId','Required parameter requestParameters.contextId was null or undefined when calling deleteSubContext.');
        }
        if (requestParameters.subContextId === null || requestParameters.subContextId === undefined) {
            throw new runtime.RequiredError('subContextId','Required parameter requestParameters.subContextId was null or undefined when calling deleteSubContext.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Contexts/{contextId}/subContext/{subContextId}`.replace(`{${"contextId"}}`, encodeURIComponent(String(requestParameters.contextId))).replace(`{${"subContextId"}}`, encodeURIComponent(String(requestParameters.subContextId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Remove the given subcontext. If no subcontexts remain, the parent context is also removed.
     * Remove subcontext with the given id.
     */
    async deleteSubContext(requestParameters: DeleteSubContextRequest): Promise<void> {
        await this.deleteSubContextRaw(requestParameters);
    }
    /**
     * Retrieve contexts organised by their organisation.
     */
    async getAllDesignContextsRaw(): Promise<runtime.ApiResponse<DesignContextOverview>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Contexts/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => DesignContextOverviewFromJSON(jsonValue));
    }
    /**
     * Retrieve contexts organised by their organisation.
     */
    async getAllDesignContexts(): Promise<DesignContextOverview> {
        const response = await this.getAllDesignContextsRaw();
        return await response.value();
    }
    /**
     * Retrieve all contexts available to the user.
     */
    async getDesignContextsRaw(): Promise<runtime.ApiResponse<Array<DesignContextViewModel>>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Contexts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DesignContextViewModelFromJSON));
    }
    /**
     * Retrieve all contexts available to the user.
     */
    async getDesignContexts(): Promise<Array<DesignContextViewModel>> {
        const response = await this.getDesignContextsRaw();
        return await response.value();
    }
    /**
     * Edit the context with the given id.
     */
    async updateDesignContextRaw(requestParameters: UpdateDesignContextRequest): Promise<runtime.ApiResponse<DesignContextViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDesignContext.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Contexts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DesignContextInputModelToJSON(requestParameters.designContextInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => DesignContextViewModelFromJSON(jsonValue));
    }
    /**
     * Edit the context with the given id.
     */
    async updateDesignContext(requestParameters: UpdateDesignContextRequest): Promise<DesignContextViewModel> {
        const response = await this.updateDesignContextRaw(requestParameters);
        return await response.value();
    }
    /**
     * Edit the subContext with the given id.
     */
    async updateSubContextRaw(requestParameters: UpdateSubContextRequest): Promise<runtime.ApiResponse<SubContextViewModel>> {
        if (requestParameters.contextId === null || requestParameters.contextId === undefined) {
            throw new runtime.RequiredError('contextId','Required parameter requestParameters.contextId was null or undefined when calling updateSubContext.');
        }
        if (requestParameters.subContextId === null || requestParameters.subContextId === undefined) {
            throw new runtime.RequiredError('subContextId','Required parameter requestParameters.subContextId was null or undefined when calling updateSubContext.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Contexts/{contextId}/subContext/{subContextId}`.replace(`{${"contextId"}}`, encodeURIComponent(String(requestParameters.contextId))).replace(`{${"subContextId"}}`, encodeURIComponent(String(requestParameters.subContextId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubContextInputModelToJSON(requestParameters.subContextInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => SubContextViewModelFromJSON(jsonValue));
    }
    /**
     * Edit the subContext with the given id.
     */
    async updateSubContext(requestParameters: UpdateSubContextRequest): Promise<SubContextViewModel> {
        const response = await this.updateSubContextRaw(requestParameters);
        return await response.value();
    }
}
