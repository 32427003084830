import React, { PropsWithChildren } from "react";
import { Configuration, OrganisationsApi } from "../openapi/webservice";
import { CreateApi, ApiContextProvider } from "./WebServiceApiContext";

const createApi: CreateApi<OrganisationsApi> = (params) => new OrganisationsApi(new Configuration(params));

export const OrganisationsApiContext = React.createContext(createApi({}));

export const OrganisationsApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={OrganisationsApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
