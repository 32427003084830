import React from "react";
import styles from "./Title.module.scss";
import classNames from "classnames";

type HeadingLevel = "h1" | "h2";

type Props = {
    className: string;
    label: string;
    heading: HeadingLevel;
};

const Title = ({ className, label, heading: Heading }: Props) => {
    return (
        <Heading className={classNames(styles.container, styles[Heading], className)} title={label}>
            {label}
        </Heading>
    );
};

Title.defaultProps = {
    className: "",
};

export default Title;
