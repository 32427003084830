/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ThemeViewModel
 */
export interface ThemeViewModel {
    /**
     * 
     * @type {string}
     * @memberof ThemeViewModel
     */
    readonly clusterColor: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeViewModel
     */
    readonly domainColor: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeViewModel
     */
    readonly cellColor: string;
    /**
     * 
     * @type {string}
     * @memberof ThemeViewModel
     */
    readonly connectionColor: string;
}

export function ThemeViewModelFromJSON(json: any): ThemeViewModel {
    return ThemeViewModelFromJSONTyped(json, false);
}

export function ThemeViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThemeViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clusterColor': json['clusterColor'],
        'domainColor': json['domainColor'],
        'cellColor': json['cellColor'],
        'connectionColor': json['connectionColor'],
    };
}

export function ThemeViewModelToJSON(value?: ThemeViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


