import { CellType, CellViewModel, ConnectionViewModel, ContainerViewModel } from "../openapi/webservice";
import {
    FILTER_CLUSTER_KEY,
    FILTER_DOMAIN_KEY,
    FILTER_EXTERNAL_SINK_KEY,
    FILTER_EXTERNAL_SOURCE_KEY,
} from "./RouterConstants";

export function getContainerBackgroundColor(type: CellType) {
    switch (type) {
        case CellType.DOMAIN:
            return "#28B6EB";
        case CellType.CLUSTER:
            return "#F98525";
        case CellType.EXTERNALSINK:
        case CellType.EXTERNALSOURCE:
        case CellType.EXTERNALSYSTEM:
            return "#F5F5F5";
    }
}

// Asserts bunch of conditions for drawing arrows (with pathfinding).
export function isInteractiveConnection(connection: ConnectionViewModel, containers: ContainerViewModel[]) {
    const cells = containers.reduce((cells, container) => {
        return [...cells, ...container.cells];
    }, [] as CellViewModel[]);

    const fromCell = cells.find((c) => c.id === connection.fromCellId);
    const toCell = cells.find((c) => c.id === connection.toCellId);

    // Both cells should exist
    if (!fromCell || !toCell) {
        return false;
    }

    // Cells shouldn't be from the same container
    return fromCell.containerId !== toCell.containerId;
}

function routeKeyToContainerType(key: string) {
    switch (key) {
        case FILTER_CLUSTER_KEY:
            return CellType.CLUSTER;
        case FILTER_DOMAIN_KEY:
            return CellType.DOMAIN;
        case FILTER_EXTERNAL_SINK_KEY:
            return CellType.EXTERNALSINK;
        case FILTER_EXTERNAL_SOURCE_KEY:
            return CellType.EXTERNALSOURCE;
    }

    return undefined;
}

export function parseFilterToContainerType(filter: { [key: string]: boolean }) {
    return Object.entries(filter).reduce((filterList, [key, value]) => {
        if (value) {
            const type = routeKeyToContainerType(key);

            if (type) {
                filterList.push(type);
            }
        }

        return filterList;
    }, [] as CellType[]);
}
