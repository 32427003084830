/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CellType {
    DOMAIN = 'DOMAIN',
    CLUSTER = 'CLUSTER',
    EXTERNALSYSTEM = 'EXTERNAL_SYSTEM',
    EXTERNALSOURCE = 'EXTERNAL_SOURCE',
    EXTERNALSINK = 'EXTERNAL_SINK'
}

export function CellTypeFromJSON(json: any): CellType {
    return CellTypeFromJSONTyped(json, false);
}

export function CellTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CellType {
    return json as CellType;
}

export function CellTypeToJSON(value?: CellType | null): any {
    return value as any;
}

