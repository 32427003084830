/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    TransactionState,
    TransactionStateFromJSON,
    TransactionStateFromJSONTyped,
    TransactionStateToJSON,
} from './';

/**
 * 
 * @export
 * @interface TransactionViewModel
 */
export interface TransactionViewModel {
    /**
     * 
     * @type {string}
     * @memberof TransactionViewModel
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionViewModel
     */
    readonly subContextId: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionViewModel
     */
    readonly name: string;
    /**
     * 
     * @type {Date}
     * @memberof TransactionViewModel
     */
    readonly validTimeStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof TransactionViewModel
     */
    readonly validTimeEnd: Date;
    /**
     * 
     * @type {TransactionState}
     * @memberof TransactionViewModel
     */
    state: TransactionState;
}

export function TransactionViewModelFromJSON(json: any): TransactionViewModel {
    return TransactionViewModelFromJSONTyped(json, false);
}

export function TransactionViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransactionViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'subContextId': json['subContextId'],
        'name': json['name'],
        'validTimeStart': (new Date(json['validTimeStart'])),
        'validTimeEnd': (new Date(json['validTimeEnd'])),
        'state': TransactionStateFromJSON(json['state']),
    };
}

export function TransactionViewModelToJSON(value?: TransactionViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': TransactionStateToJSON(value.state),
    };
}


