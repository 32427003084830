import React from "react";
import styles from "./BrandLogo.module.scss";
import classNames from "classnames";

type Props = {
    className: string;
    src: string;
    size: "default" | "large";
    onClick?: () => void;
};

const BrandLogo = ({ className, src, size, onClick }: Props) => {
    return (
        <div
            style={{ backgroundImage: `url(${src})` }}
            className={classNames(styles.container, className, size === "large" && styles.large)}
            onClick={onClick}
        />
    );
};

BrandLogo.defaultProps = {
    className: "",
    size: "default",
};

export default BrandLogo;
