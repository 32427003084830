import { useContext, useMemo } from "react";
import { ContextsApiContext } from "../contexts/ContextsApiContext";
import { useApiCall, useApiCallback } from "./useApiCall";

export function useContextsApi() {
    return useContext(ContextsApiContext);
}

export function useGetAdminContexts() {
    const api = useContextsApi();
    const boundFunc = useMemo(() => api.getAllDesignContexts.bind(api), [api]);

    return useApiCall(boundFunc);
}

export function useGetAdminContextsCallback() {
    const api = useContextsApi();
    const boundFunc = useMemo(() => api.getAllDesignContexts.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useGetContexts() {
    const api = useContextsApi();
    const boundFunc = useMemo(() => api.getDesignContexts.bind(api), [api]);

    return useApiCall(boundFunc);
}

export function useGetContextsCallback() {
    const api = useContextsApi();
    const boundFunc = useMemo(() => api.getDesignContexts.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useCreateSubContext() {
    const api = useContextsApi();

    const boundFunc = useMemo(() => api.createSubContext.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useDeleteSubContext() {
    const api = useContextsApi();

    const boundFunc = useMemo(() => api.deleteSubContext.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useUpdateSubContext() {
    const api = useContextsApi();

    const boundFunc = useMemo(() => api.updateSubContext.bind(api), [api]);

    return useApiCallback(boundFunc);
}

export function useUpdateDesignContext() {
    const api = useContextsApi();

    const boundFunc = useMemo(() => api.updateDesignContext.bind(api), [api]);

    return useApiCallback(boundFunc);
}
