import React from "react";
import styles from "./TempOrganisationManagement.module.scss";
import classNames from "classnames";
import { OrganisationViewModel } from "../../openapi/webservice";
import TempOrganisationManagementInput from "../RoughOrganisationManagementInput/TempOrganisationManagementInput";
import TempAddOrganisationInputContainer from "../RoughAddOrganisationInput/TempAddOrganisationInputContainer";

type Props = {
    className: string;
    organisations: OrganisationViewModel[];
};

const TempOrganisationManagement = ({ className, organisations }: Props) => {
    return (
        <div className={classNames(styles.container, className)}>
            <TempAddOrganisationInputContainer className={styles.row} />
            {organisations.map((org) => {
                return <TempOrganisationManagementInput key={org.id} className={styles.row} organisation={org} />;
            })}
        </div>
    );
};

TempOrganisationManagement.defaultProps = {
    className: "",
};

export default TempOrganisationManagement;
