/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
import {
    MemoBlockMemoViewModel,
    MemoBlockMemoViewModelFromJSON,
    MemoBlockMemoViewModelFromJSONTyped,
    MemoBlockMemoViewModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface MemoBlockViewModel
 */
export interface MemoBlockViewModel {
    /**
     * 
     * @type {Array<MemoBlockMemoViewModel>}
     * @memberof MemoBlockViewModel
     */
    memos: Array<MemoBlockMemoViewModel>;
}

export function MemoBlockViewModelFromJSON(json: any): MemoBlockViewModel {
    return MemoBlockViewModelFromJSONTyped(json, false);
}

export function MemoBlockViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemoBlockViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memos': ((json['memos'] as Array<any>).map(MemoBlockMemoViewModelFromJSON)),
    };
}

export function MemoBlockViewModelToJSON(value?: MemoBlockViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memos': ((value.memos as Array<any>).map(MemoBlockMemoViewModelToJSON)),
    };
}


