import React, { Fragment, useCallback, useMemo } from "react";
import styles from "./MemoModal.module.scss";
import classNames from "classnames";
import Modal from "react-modal";
import { MemoViewOption } from "../../models/MemoViewOption";
import { useTranslation } from "react-i18next";
import Icon from "../Icon/Icon";
import Title from "../Title/Title";
import { CalendarIcon, ChatIcon, DependenciesIcon } from "../../assets/images/Images";
import Separator from "../Separator/Separator";
import ImageButton from "../ImageButton/ImageButton";
import CanvasCard from "../CanvasCard/CanvasCard";
import MemoInput from "../MemoInput/MemoInput";
import TextEditorInput from "../TextEditorInput/TextEditorInput";
import { useMemoModalState } from "../../hooks/useMemoModalState";
import produce from "immer";
import ChatWindowContainer from "../ChatWindow/ChatWindowContainer";
import { ChatType } from "../../models/ChatType";
import ModalHeader from "../ModalHeader/ModalHeader";
import { getMemoColor } from "../../utils/MemoUtils";
import PlanningMockup from "../PlanningMockup/PlanningMockup";
import { TransactionValidation } from "../../models/TransactionValidation";
import { useVerifiedActiveTransaction } from "../../hooks/useVerifiedActiveTransaction";
import { MemoViewModel } from "../../openapi/webservice";

const TABS = [
    { option: MemoViewOption.CHAT, label: "modal.tab.chat", icon: ChatIcon },
    { option: MemoViewOption.PLANNING, label: "modal.tab.planning", icon: CalendarIcon },
    { option: MemoViewOption.DEPENDENCIES, label: "modal.tab.dependencies", icon: DependenciesIcon },
];

type Props = Omit<Modal.Props, "overlayClassName" | "className"> & {
    className: string;
    memo?: MemoViewModel;
    defaultTab: MemoViewOption;
    onClose?: () => void;
    onChange?: (note: MemoViewModel) => void;
};

const MemoModal = ({ className, memo, defaultTab, onClose, onChange, ...props }: Props) => {
    const [{ tab }, setModalState] = useMemoModalState();
    const { t } = useTranslation();
    const validation = useVerifiedActiveTransaction();

    const buttons = useMemo(
        () =>
            TABS.filter((t) => {
                const selectedTab = tab || defaultTab;

                return t.option !== selectedTab;
            }),
        [tab, defaultTab],
    );
    const active = useMemo(
        () =>
            TABS.find((t) => {
                const selectedTab = tab || defaultTab;
                return t.option === selectedTab;
            }),
        [tab, defaultTab],
    )!;

    const onNameChange = useCallback(
        (name: string) => {
            if (!onChange || !memo) {
                return;
            }

            const next = produce(memo, (draft) => {
                draft.name = name;
            });
            onChange(next);
        },
        [onChange, memo],
    );

    const onDescriptionChange = useCallback(
        (description: string) => {
            if (!onChange || !memo) {
                return;
            }

            const next = produce(memo, (draft) => {
                draft.description = description;
            });
            onChange(next);
        },
        [onChange, memo],
    );

    return (
        <Modal
            shouldCloseOnOverlayClick={true}
            testId="MemoModal"
            overlayClassName={styles.overlay}
            className={classNames(styles.container, className)}
            {...props}
        >
            <ModalHeader className={styles.header} color={getMemoColor(memo?.type)} onClose={onClose}>
                <div className={classNames(styles.title, styles.headerItem)}>
                    <Icon className={styles.icon} src={active.icon} />
                    <Title className={styles.label} label={t(active.label as any)} heading="h2" />
                </div>
                <Separator className={styles.headerItem} orientation="|" />
                <div className={styles.filler} />
                {buttons.map((t) => (
                    <Fragment key={MemoViewOption[t.option]}>
                        <Separator className={styles.headerItem} orientation="|" />
                        <ImageButton
                            className={styles.headerItem}
                            src={t.icon}
                            onClick={() => setModalState((oldState) => ({ ...oldState, tab: t.option }))}
                        />
                    </Fragment>
                ))}
            </ModalHeader>

            {memo && (
                <div className={styles.contentContainer}>
                    <MemoInput
                        name={memo.name}
                        type={memo.type}
                        className={styles.memo}
                        onChange={onNameChange}
                        disabled={validation !== TransactionValidation.Valid}
                    />
                    <TextEditorInput
                        className={styles.description}
                        content={memo.description}
                        title={t("modal.description.title")}
                        placeholder={t("modal.description.placeholder")}
                        onChange={onDescriptionChange}
                        disabled={validation !== TransactionValidation.Valid}
                    />
                    {active.option === MemoViewOption.CHAT ? (
                        <ChatWindowContainer className={styles.content} id={memo.id} type={ChatType.Memo} />
                    ) : active.option === MemoViewOption.PLANNING ? (
                        <PlanningMockup className={styles.content} />
                    ) : (
                        <CanvasCard className={styles.content}>WIP: {MemoViewOption[active.option]}</CanvasCard>
                    )}
                </div>
            )}
        </Modal>
    );
};

MemoModal.defaultProps = {
    className: "",
    defaultTab: MemoViewOption.CHAT,
};

export default MemoModal;
