/**
 * Source: https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/src/hooks/UseApiCall.ts
 *
 * @TODO(Lejun) Extract to npm for shared usage if this works handy. Together with `WebServiceApiContext`
 */

import { useCallback, useEffect, useState } from "react";
import { DotnetError } from "../models/DotnetError";

export enum RequestState {
    IDLE,
    LOADING,
    DONE,
    ERROR,
}

export type ApiCallNoResult = {
    state: RequestState.IDLE | RequestState.LOADING;
    value?: never;
    error?: never;
};

export type ApiCallResolved<T> = {
    state: RequestState.DONE;
    value: T;
    error?: never;
};

export type ApiCallFailed = {
    state: RequestState.ERROR;
    value?: never;
    error: DotnetError;
};

export type ApiCallState<T> = ApiCallNoResult | ApiCallResolved<T> | ApiCallFailed;

export type ApiFunction<T, A extends Array<any>> = (...args: A) => Promise<T>;

export const readyForRequest = (state: RequestState): boolean => state !== RequestState.LOADING;
export const readyForSingleRequest = (state: RequestState): boolean =>
    readyForRequest(state) && state !== RequestState.DONE;

export function useApiCall<T>(apiCall: () => Promise<T>): ApiCallState<T> {
    const [callState, go] = useApiCallback(apiCall);

    useEffect(() => {
        go();
    }, [go]);

    return callState;
}

export function useApiCallback<T, A extends Array<any>>(
    apiCall: ApiFunction<T, A>,
): [ApiCallState<T>, ApiFunction<T, A>] {
    const [callState, setCallState] = useState<ApiCallState<T>>(() => ({ state: RequestState.IDLE }));

    const callback = useCallback(
        async (...args: A) => {
            setCallState({ state: RequestState.LOADING });
            try {
                const response = await apiCall(...args);
                setCallState({ state: RequestState.DONE, value: response });
                return response;
            } catch (error: any) {
                if (!(error instanceof Response)) {
                    throw error;
                }

                let actualError: DotnetError;
                if (error.headers.get("Content-Type")?.toLowerCase().includes("json")) {
                    const json = await error.json();
                    actualError = {
                        ...json,
                        type: "JsonError",
                    };
                } else {
                    const message = await error.text();
                    actualError = {
                        status: error.status,
                        message,
                        type: "StringError",
                    };
                }
                setCallState({ state: RequestState.ERROR, error: actualError });
                return Promise.reject(actualError);
            }
        },
        [apiCall],
    );

    return [callState, callback];
}
