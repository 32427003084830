import produce from "immer";
import React, { useCallback, useEffect, useState } from "react";
import { RequestState } from "../../hooks/useApiCall";
import { useBlueprintState } from "../../hooks/useBlueprintState";
import { useGetChat, useInsertChatMessage } from "../../hooks/useChatApi";
import { useMemos } from "../../hooks/useMemos";
import { ChatType } from "../../models/ChatType";
import { ChatMessageViewModel } from "../../openapi/webservice";
import { ActionType as BlueprintActionType } from "../../reducers/BlueprintStateReducer";
import { ActionType as MemoActionType } from "../../reducers/MemosStateReducer";
import ChatWindow from "./ChatWindow";

type Props = {
    className: string;
    type: ChatType;
    id: string;
};

const ChatWindowContainer = ({ type, id, ...props }: Props) => {
    const [memos, dispatchMemos] = useMemos();
    const [blueprint, dispatchBlueprint] = useBlueprintState();
    const { state, value } = useGetChat(type, id);
    const [messages, setMessages] = useState<Array<ChatMessageViewModel>>([]);
    const [, insert] = useInsertChatMessage(type, id);

    useEffect(() => {
        if (!value) {
            return;
        }

        setMessages(value);
    }, [value, setMessages]);

    const onSubmit = useCallback(
        async (text: string) => {
            const result = await insert(text);
            setMessages((messages) => [...messages, result]);

            if (type === ChatType.Memo) {
                const memo = memos.find((m) => m.id === id);

                if (!memo) {
                    return;
                }

                const updatedMemo = produce(memo, (draft) => {
                    // If result was successful then it should have a chat.
                    draft.hasChat = !!result;
                });

                dispatchMemos({ type: MemoActionType.update, value: updatedMemo });
            } else {
                const container = blueprint.containers.find((c) => c.id === id);

                if (!container) {
                    return;
                }

                const updatedContainer = produce(container, (draft) => {
                    // If result was successful then it should have a chat.
                    draft.hasChat = !!result;
                });

                dispatchBlueprint({ type: BlueprintActionType.updateContainer, value: updatedContainer });
            }
        },
        [setMessages, insert, memos, dispatchMemos, type, blueprint, dispatchBlueprint, id],
    );

    if (state !== RequestState.DONE) {
        return null;
    }

    return <ChatWindow {...props} messages={messages} onSubmit={onSubmit} />;
};

ChatWindowContainer.defaultProps = {
    className: "",
};

export default ChatWindowContainer;
