import { useSearchParams } from "react-router-dom";
import { TransactionValidation } from "../models/TransactionValidation";
import { TRANSACTION_SEARCHPARAM_KEY, TRANSACTION_ACTIVE_SEARCHPARAM_KEY } from "../utils/RouterConstants";
import { useTimelineTransactions } from "./useTimelineTransactions";

/**
 * @NOTE(Lejun) Due to additional isActiveTransaction tracking on the frontend it became a bit more complicated than I wanted.
 * By default the historical request will be called.
 * Depending on `isActiveTransaction` the active request will be called instead, however the active request has some extra checks
 * to prevent accidental editing on random transactions. Basically only the actual "In Progress" transaction should allow changes.
 * When `isActiveTransaction` is true, 3 cases can be identified
 * 1)   transactions are empty, can be due to async requests that transactions not loaded yet or transactions are actually empty.
 *      Regardless of reason, return empty T list.
 * 2)   `atDateString` matches with a value in transactions. This means it's a "verified" transaction in progress.
 *      Request active T.
 * 3)   Any other cases return the default historical T.
 *
 * Use in combination with TimelineDependantFetch
 */
export function useVerifiedActiveTransaction() {
    const [searchParams] = useSearchParams();
    const atDateString = searchParams.get(TRANSACTION_SEARCHPARAM_KEY);
    const isActiveTransaction = !!searchParams.get(TRANSACTION_ACTIVE_SEARCHPARAM_KEY);
    const [transactions] = useTimelineTransactions();

    if (isActiveTransaction) {
        if (transactions.length === 0) {
            return TransactionValidation.LoadingOrNotFound;
        } else if (transactions.find((t) => t.validTimeStart.getTime() === new Date(atDateString || "").getTime())) {
            return TransactionValidation.Valid;
        }
    }

    return TransactionValidation.Invalid;
}
