import React, { PropsWithChildren } from "react";
import styles from "./DefaultPage.module.scss";
import classNames from "classnames";
import FooterBar from "../../components/FooterBar/FooterBar";
import DefaultNavBarContainer from "../../components/DefaultNavBar/DefaultNavBarContainer";

type Props = {
    className: string;
};

const DefaultPage = ({ className, children }: PropsWithChildren<Props>) => {
    return (
        <div className={classNames(styles.container, className)}>
            <DefaultNavBarContainer className={styles.navbar} />
            <div className={styles.content}>{children}</div>

            <FooterBar className={styles.footer} />
        </div>
    );
};

DefaultPage.defaultProps = {
    className: "",
};

export default DefaultPage;
