// Based on example from React Router, probably replace this based on actual oidc implementation
// source: https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx

import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { isAdminRole } from "../../utils/Permissions";

type Props = {
    redirect?: string;
};

export type BouncerProps = PropsWithChildren<Props>;

// Temporary bouncer until we actually swap with oidc client and a real role system
export const AdminBouncer = ({ children, redirect }: BouncerProps) => {
    const [user] = useUser();

    if (!isAdminRole(user)) {
        return <Navigate to={redirect || "/plattegrond"} replace />;
    }

    return <React.Fragment>{children}</React.Fragment>;
};
