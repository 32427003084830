/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TransactionInputModel,
    TransactionInputModelFromJSON,
    TransactionInputModelToJSON,
    TransactionViewModel,
    TransactionViewModelFromJSON,
    TransactionViewModelToJSON,
} from '../models';

export interface CreateNewTransactionRequest {
    subContextId: string;
    transactionInputModel?: TransactionInputModel;
}

export interface DeleteAllCurrentChangesRequest {
    subContextId: string;
}

export interface GetTimelineRequest {
    subContextId: string;
}

export interface SetInProgressTransactionToSolutionRequest {
    subContextId: string;
}

/**
 * no description
 */
export class TimelineApi extends runtime.BaseAPI {
    /**
     * Create a new in progress transaction.
     */
    async createNewTransactionRaw(requestParameters: CreateNewTransactionRequest): Promise<runtime.ApiResponse<TransactionViewModel>> {
        if (requestParameters.subContextId === null || requestParameters.subContextId === undefined) {
            throw new runtime.RequiredError('subContextId','Required parameter requestParameters.subContextId was null or undefined when calling createNewTransaction.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Timeline/{subContextId}/transaction`.replace(`{${"subContextId"}}`, encodeURIComponent(String(requestParameters.subContextId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TransactionInputModelToJSON(requestParameters.transactionInputModel),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => TransactionViewModelFromJSON(jsonValue));
    }
    /**
     * Create a new in progress transaction.
     */
    async createNewTransaction(requestParameters: CreateNewTransactionRequest): Promise<TransactionViewModel> {
        const response = await this.createNewTransactionRaw(requestParameters);
        return await response.value();
    }
    /**
     * Remove the current in progress transaction.
     */
    async deleteAllCurrentChangesRaw(requestParameters: DeleteAllCurrentChangesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subContextId === null || requestParameters.subContextId === undefined) {
            throw new runtime.RequiredError('subContextId','Required parameter requestParameters.subContextId was null or undefined when calling deleteAllCurrentChanges.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Timeline/{subContextId}`.replace(`{${"subContextId"}}`, encodeURIComponent(String(requestParameters.subContextId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Remove the current in progress transaction.
     */
    async deleteAllCurrentChanges(requestParameters: DeleteAllCurrentChangesRequest): Promise<void> {
        await this.deleteAllCurrentChangesRaw(requestParameters);
    }
    /**
     * Get all transaction as they are known at the most recent transaction time.
     */
    async getTimelineRaw(requestParameters: GetTimelineRequest): Promise<runtime.ApiResponse<Array<TransactionViewModel>>> {
        if (requestParameters.subContextId === null || requestParameters.subContextId === undefined) {
            throw new runtime.RequiredError('subContextId','Required parameter requestParameters.subContextId was null or undefined when calling getTimeline.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Timeline/{subContextId}`.replace(`{${"subContextId"}}`, encodeURIComponent(String(requestParameters.subContextId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TransactionViewModelFromJSON));
    }
    /**
     * Get all transaction as they are known at the most recent transaction time.
     */
    async getTimeline(requestParameters: GetTimelineRequest): Promise<Array<TransactionViewModel>> {
        const response = await this.getTimelineRaw(requestParameters);
        return await response.value();
    }
    /**
     * Edit the state for a transaction.
     */
    async setInProgressTransactionToSolutionRaw(requestParameters: SetInProgressTransactionToSolutionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.subContextId === null || requestParameters.subContextId === undefined) {
            throw new runtime.RequiredError('subContextId','Required parameter requestParameters.subContextId was null or undefined when calling setInProgressTransactionToSolution.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Timeline/{subContextId}/transaction/solution`.replace(`{${"subContextId"}}`, encodeURIComponent(String(requestParameters.subContextId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Edit the state for a transaction.
     */
    async setInProgressTransactionToSolution(requestParameters: SetInProgressTransactionToSolutionRequest): Promise<void> {
        await this.setInProgressTransactionToSolutionRaw(requestParameters);
    }
}
