/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/modelGeneric.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - Disregard nullable fields, in our context these are simply unrequired
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChatMessageInputModel
 */
export interface ChatMessageInputModel {
    /**
     * 
     * @type {string}
     * @memberof ChatMessageInputModel
     */
    messageContent: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessageInputModel
     */
    whileLookingAtTransaction: string;
}

export function ChatMessageInputModelFromJSON(json: any): ChatMessageInputModel {
    return ChatMessageInputModelFromJSONTyped(json, false);
}

export function ChatMessageInputModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessageInputModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageContent': json['messageContent'],
        'whileLookingAtTransaction': json['whileLookingAtTransaction'],
    };
}

export function ChatMessageInputModelToJSON(value?: ChatMessageInputModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messageContent': value.messageContent,
        'whileLookingAtTransaction': value.whileLookingAtTransaction,
    };
}


