import { ContextInfo } from "../models/ContextInfo";
import { useContexts } from "./useContexts";
import { useBlueprintId } from "./useRouteParams";
import { useSelectedTransaction } from "./useSelectedTransaction";

export function useContextInfo(): ContextInfo {
    const [contexts] = useContexts();
    const blueprintId = useBlueprintId();
    const transaction = useSelectedTransaction();
    const subcontext = contexts.flatMap((c) => c.subContexts).find((sc) => sc.blueprintId === blueprintId);

    return {
        contextName: contexts.find((c) => c.id === subcontext?.designContextId)?.name,
        subContextName: subcontext?.name,
        transaction,
    };
}
