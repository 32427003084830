import React, { SetStateAction } from "react";
import { createContext, Dispatch, PropsWithChildren } from "react";
import { useMultiSearchParamsState } from "../hooks/useSearchParamsState";
import { MemoViewOption } from "../models/MemoViewOption";
import { SELECTED_MEMO_KEY, TAB_KEY } from "../utils/RouterConstants";

type Model = { [SELECTED_MEMO_KEY]: string | undefined; [TAB_KEY]: MemoViewOption | undefined };

type ContextType = [Model, Dispatch<SetStateAction<Model>>];

export const MemoModalContext = createContext<ContextType>([
    { [SELECTED_MEMO_KEY]: undefined, [TAB_KEY]: undefined },
    () => {},
]);

export const MemoModalProvider = ({ children }: PropsWithChildren<{}>) => {
    const state = useMultiSearchParamsState<Model>({ [SELECTED_MEMO_KEY]: undefined, [TAB_KEY]: undefined });

    return <MemoModalContext.Provider value={state}>{children}</MemoModalContext.Provider>;
};
