import React from "react";
import { Group, Rect } from "react-konva";
import "konva/lib/shapes/Rect";
import { KonvaEventObject } from "konva/lib/Node";
import { ICON_SIZE } from "../../../utils/CanvasConstants";
import TrashButton from "../../KonvaShared/TrashButton";

type Props = {
    left: number;
    top: number;
    width: number;
    height: number;
    onDelete?: (event: KonvaEventObject<MouseEvent>) => void;
};

const SelectionBox = ({ left, top, width, height, onDelete }: Props) => {
    return (
        <Group x={left} y={top} width={width} height={height}>
            <Rect width={width} height={height} stroke="#061133" strokeWidth={3} cornerRadius={2} dash={[12, 4]} />
            <TrashButton x={width - ICON_SIZE / 2} y={-ICON_SIZE / 2} onClick={onDelete} />
        </Group>
    );
};
SelectionBox.defaultProps = {};

export default SelectionBox;
