import { DateTime } from "luxon";

export function formatFromMillis(ms: number) {
    return DateTime.fromMillis(ms).toFormat("dd-MM-yyyy");
}

export function formatFromDate(date: Date) {
    return formatFromMillis(date.getTime());
}

export function formatToFull(date: Date) {
    return date.toLocaleDateString("nl-NL", { dateStyle: "full" });
}

export function offsetToAmsterdamTime(date: Date) {
    const utcDateTime = new Date(date.valueOf() - date.getTimezoneOffset() * 60 * 1000);
    const desiredTimeZone = DateTime.fromJSDate(date, { zone: "Europe/Amsterdam" });

    return new Date(utcDateTime.valueOf() - desiredTimeZone.offset * 60 * 1000);
}
