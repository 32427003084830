import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CreateSubContextInputModel, DesignContextInputModel, SubContextInputModel } from "../openapi/webservice";
import { ActionType } from "../reducers/ContextsStateReducer";
import { notifySuccess } from "../utils/NotificationUtils";
import { getBlueprintRoute } from "../utils/RouteUtils";
import { useContexts } from "./useContexts";
import {
    useCreateSubContext,
    useDeleteSubContext,
    useGetContextsCallback,
    useUpdateDesignContext,
    useUpdateSubContext,
} from "./useContextsApi";
import { useErrorResponseNotification } from "./useErrorNotification";

export function useContextStateChanges() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [, dispatchContexts] = useContexts();
    const [{ error: createSubContextError }, createSubContextCallback] = useCreateSubContext();
    const [{ error: deleteSubContextError }, deleteSubContextCallback] = useDeleteSubContext();
    const [{ error: updateSubContextError }, updateSubContextCallback] = useUpdateSubContext();
    const [{ error: updateContextError }, updateContextCallback] = useUpdateDesignContext();
    const [{ error: getContextsError }, getContextsCallback] = useGetContextsCallback();
    useErrorResponseNotification(createSubContextError);
    useErrorResponseNotification(deleteSubContextError);
    useErrorResponseNotification(updateSubContextError);
    useErrorResponseNotification(updateContextError);
    useErrorResponseNotification(getContextsError);

    const createSubContext = useCallback(
        async (subContext: CreateSubContextInputModel) => {
            const response = await createSubContextCallback({
                createSubContextInputModel: subContext,
            });
            dispatchContexts({ type: ActionType.set, value: response.contexts });

            // If response.created is set, then it was created under the same organisation
            // When response.created is undefined, then it was duplicated to a different organisation, hence no navigation
            if (response.created) {
                navigate(getBlueprintRoute(response.created.id, response.created.blueprintId));
            }

            notifySuccess(t("toast.success.create"));
        },
        [createSubContextCallback, dispatchContexts, navigate, t],
    );

    const deleteSubContext = useCallback(
        async (contextId: string, subContextId: string) => {
            await deleteSubContextCallback({
                contextId,
                subContextId,
            });

            const contexts = await getContextsCallback();
            dispatchContexts({ type: ActionType.set, value: contexts });

            notifySuccess(t("toast.success.delete"));
        },
        [deleteSubContextCallback, getContextsCallback, dispatchContexts, t],
    );

    const updateSubContext = useCallback(
        async (contextId: string, subContextId: string, input: SubContextInputModel) => {
            const response = await updateSubContextCallback({
                contextId,
                subContextId,
                subContextInputModel: input,
            });
            dispatchContexts({ type: ActionType.updateSubContext, value: response });

            notifySuccess(t("toast.success.update"));
        },
        [updateSubContextCallback, dispatchContexts, t],
    );

    const updateDesignContext = useCallback(
        async (contextId: string, input: DesignContextInputModel) => {
            const response = await updateContextCallback({
                id: contextId,
                designContextInputModel: input,
            });
            dispatchContexts({ type: ActionType.updateDesignContext, value: response });

            notifySuccess(t("toast.success.update"));
        },
        [updateContextCallback, dispatchContexts, t],
    );

    return useMemo(
        () => ({ createSubContext, deleteSubContext, updateSubContext, updateDesignContext }),
        [createSubContext, deleteSubContext, updateSubContext, updateDesignContext],
    );
}
