import React, { useCallback } from "react";
import LoginPage from "./LoginPage";
import { Location, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/useUser";
import { ActionType } from "../../reducers/UserStateReducer";
import { useLogin } from "../../hooks/useAuthenticationApi";

type Props = {
    className: string;
};
type LoginLocation = Location & {
    state?: {
        from?: Location;
    };
};
const LoginPageContainer = ({ className }: Props) => {
    const navigate = useNavigate();
    const [, login] = useLogin();
    const [, dispatchUser] = useUser();
    const location = useLocation() as LoginLocation;

    const from = location.state?.from?.pathname || "/plattegrond";

    const submit = useCallback(
        async (username: string, password: string) => {
            const result = await login({
                loginInputModel: {
                    username,
                    password,
                },
            });

            dispatchUser({ type: ActionType.update, jwt: result.token });

            if (result) {
                navigate(from, { replace: true });
            }
        },
        [navigate, login, dispatchUser, from],
    );

    return <LoginPage className={className} submit={submit} />;
};

LoginPageContainer.defaultProps = {
    className: "",
};

export default LoginPageContainer;
