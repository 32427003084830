import React from "react";
import { RequestState } from "../../hooks/useApiCall";
import { useGetBlueprint } from "../../hooks/useBlueprintsApi";

import CompactBlueprint from "./CompactBlueprint";

type Props = {
    className: string;
};

const CompactBlueprintContainer = (props: Props) => {
    const { state, value } = useGetBlueprint();

    if (state !== RequestState.DONE) {
        return null;
    }

    return <CompactBlueprint {...props} blueprint={value} />;
};

CompactBlueprintContainer.defaultProps = {
    className: "",
};

export default CompactBlueprintContainer;
