import React, { PropsWithChildren } from "react";
import { Configuration, ChatApi } from "../openapi/webservice";
import { CreateApi, ApiContextProvider } from "./WebServiceApiContext";

const createApi: CreateApi<ChatApi> = (params) => new ChatApi(new Configuration(params));

export const ChatApiContext = React.createContext(createApi({}));

export const ChatApiProvider = ({ children }: PropsWithChildren<{}>) => (
    <ApiContextProvider contextProvider={ChatApiContext.Provider} createApi={createApi}>
        {children}
    </ApiContextProvider>
);
