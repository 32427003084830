import React, { SetStateAction } from "react";
import { createContext, Dispatch, PropsWithChildren } from "react";
import { useSearchParamsState } from "../hooks/useSearchParamsState";
import { CanvasMode } from "../models/CanvasMode";
import { MODE_KEY } from "../utils/RouterConstants";

export const CanvasModeContext = createContext<[CanvasMode, Dispatch<SetStateAction<CanvasMode>>]>([
    CanvasMode.View,
    () => {},
]);

export const CanvasModeProvider = ({ children }: PropsWithChildren<{}>) => {
    const state = useSearchParamsState<CanvasMode>(MODE_KEY, CanvasMode.View);

    return <CanvasModeContext.Provider value={state}>{children}</CanvasModeContext.Provider>;
};
