/**
 * Source: https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/src/hooks/UseApiCall.ts
 *
 * @TODO(Lejun) Extract to npm for shared usage if this works handy. Together with `useApiCall.ts`.
 */

import React, { PropsWithChildren, useMemo } from "react";
import { useUser } from "../hooks/useUser";
import { BaseAPI, Configuration, ConfigurationParameters } from "../openapi/webservice/runtime";
import { Env } from "../utils/Env";

export type CreateApi<T> = (params: ConfigurationParameters) => T;
export type CreateConfiguration = (params: ConfigurationParameters) => Configuration;

type ApiContextProps<T extends BaseAPI> = {
    contextProvider: React.Provider<T>;
    createApi: CreateApi<T>;
};

const backendParams: ConfigurationParameters = { basePath: Env.REACT_APP_BACKEND_URL };

const includeAuthToken = (
    params: ConfigurationParameters,
    isAuthenticated: boolean,
    token?: string,
): ConfigurationParameters => {
    if (!token || !isAuthenticated) {
        return params;
    }

    return {
        headers: { Authorization: "Bearer " + token },
        ...params,
    };
};

export const ApiContextProvider = <T extends BaseAPI>({
    contextProvider: ContextProvider,
    createApi,
    children,
}: PropsWithChildren<ApiContextProps<T>>) => {
    // IMPORTANT: if more things will alter backendParams it will need a seperate state
    // so we can replace the current config

    const [{ jwt }] = useUser();
    const token = jwt;
    const isAuthenticated = !!jwt;
    const api = useMemo(() => {
        const params = includeAuthToken(backendParams, isAuthenticated, token);
        return createApi(params);
    }, [token, isAuthenticated, createApi]);

    return <ContextProvider value={api}>{children}</ContextProvider>;
};
