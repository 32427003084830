import React, { useMemo } from "react";
import styles from "./ChatMessage.module.scss";
import classNames from "classnames";
import { ChatMessageViewModel } from "../../openapi/webservice";
import RoundImage from "../RoundImage/RoundImage";
import Text from "../Text/Text";
import ImageButton from "../ImageButton/ImageButton";
import { StarFilledIcon, StarOutlineIcon } from "../../assets/images/Images";
import WipWrapper from "../WipWrapper/WipWrapper";
import ModalCard from "../ModalCard/ModalCard";
import { DateTime } from "luxon";

type Props = {
    className: string;
    message: ChatMessageViewModel;
};

const ChatMessage = ({ className, message }: Props) => {
    const { avatar, isFavorite, messageContent, name, timestamp } = message;
    const dateLabel = useMemo(() => DateTime.fromJSDate(timestamp).toFormat("dd-LL-yyyy HH:mm:ss"), [timestamp]);

    return (
        <div className={classNames(styles.container, className)} data-testid="ChatMessage">
            <div className={styles.info}>
                <RoundImage className={styles.avatar} size="small" src={avatar} />
                <Text className={styles.name} label={name} type="bold" />
                <WipWrapper className={styles.favorite} label="Favorieten">
                    <ImageButton src={isFavorite ? StarFilledIcon : StarOutlineIcon} />
                </WipWrapper>
                <Text className={styles.timestamp} type="body" label={dateLabel} />
            </div>
            <ModalCard className={styles.bubble} data-testid="MessageContent">
                {messageContent}
            </ModalCard>
        </div>
    );
};

ChatMessage.defaultProps = {
    className: "",
};

export default ChatMessage;
