/**
 * Copied from SBG https://git.milvum.com/sbg/sbg-mono/-/blob/develop/backoffice-web/openapi-templates/modelGeneric.mustache who in turn
 * Copied from https://github.com/OpenAPITools/openapi-generator/blob/v4.3.0/modules/openapi-generator/src/main/resources/typescript-fetch/apis.mustache
 * Required for fixing bugs in the generation.
 * Changelog:
 * - No longer serialize arrays as csv strings in form data
 * - Append object fields in array as formdata fields (only works for single depth)
 * - Correct flawed boolean logic causing dead code.
 * - Change date generation to UTC instead of ISO as backend does not accept the latter.
 * - use multipart/form-data instead of application/x-www-form-urlencoded since there is no working check for nested IFormFile
 * - Don't send array parameters when the list is empty
 * - Correctly parse dates in form data
 * - Do not set a body when formparams are empty (e.g. all fields were optional and none were set)
 */

/* tslint:disable */
/* eslint-disable */
/**
 * Sixblocks Business Rules Canvas API
 * .NET Core API
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BreadcrumbViewModel,
    BreadcrumbViewModelFromJSON,
    BreadcrumbViewModelToJSON,
    InheritanceType,
    InheritanceTypeFromJSON,
    InheritanceTypeToJSON,
    MemoBlockViewModel,
    MemoBlockViewModelFromJSON,
    MemoBlockViewModelToJSON,
    MemoType,
    MemoTypeFromJSON,
    MemoTypeToJSON,
} from '../models';

export interface GetActiveCellBreadcrumbRequest {
    id: string;
}

export interface GetCellBreadcrumbRequest {
    id: string;
}

export interface GetHistoricalCellBreadcrumbRequest {
    id: string;
    asOf?: Date;
}

export interface GetMemosByTypeRequest {
    cellId: string;
    type?: MemoType;
    inheritance?: InheritanceType;
}

export interface GetMemosByTypeActiveRequest {
    cellId: string;
    type?: MemoType;
    inheritance?: InheritanceType;
}

export interface GetMemosByTypeHistoricalRequest {
    cellId: string;
    asOf?: Date;
    type?: MemoType;
    inheritance?: InheritanceType;
}

/**
 * no description
 */
export class MemoboardApi extends runtime.BaseAPI {
    /**
     * Retrieve the breadcrumb for the cell as of the given date.
     */
    async getActiveCellBreadcrumbRaw(requestParameters: GetActiveCellBreadcrumbRequest): Promise<runtime.ApiResponse<BreadcrumbViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getActiveCellBreadcrumb.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memoboard/{id}/active/breadcrumb`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BreadcrumbViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve the breadcrumb for the cell as of the given date.
     */
    async getActiveCellBreadcrumb(requestParameters: GetActiveCellBreadcrumbRequest): Promise<BreadcrumbViewModel> {
        const response = await this.getActiveCellBreadcrumbRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve the breadcrumb for the cell as per the current date.
     */
    async getCellBreadcrumbRaw(requestParameters: GetCellBreadcrumbRequest): Promise<runtime.ApiResponse<BreadcrumbViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCellBreadcrumb.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memoboard/{id}/breadcrumb`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BreadcrumbViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve the breadcrumb for the cell as per the current date.
     */
    async getCellBreadcrumb(requestParameters: GetCellBreadcrumbRequest): Promise<BreadcrumbViewModel> {
        const response = await this.getCellBreadcrumbRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve the breadcrumb for the cell as of the given date.
     */
    async getHistoricalCellBreadcrumbRaw(requestParameters: GetHistoricalCellBreadcrumbRequest): Promise<runtime.ApiResponse<BreadcrumbViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getHistoricalCellBreadcrumb.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.asOf !== undefined) {
            queryParameters['asOf'] = (requestParameters.asOf as any).toUTCString();
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memoboard/{id}/historical/breadcrumb`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => BreadcrumbViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve the breadcrumb for the cell as of the given date.
     */
    async getHistoricalCellBreadcrumb(requestParameters: GetHistoricalCellBreadcrumbRequest): Promise<BreadcrumbViewModel> {
        const response = await this.getHistoricalCellBreadcrumbRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve all memos of a certain type for a cell as of the current date.
     */
    async getMemosByTypeRaw(requestParameters: GetMemosByTypeRequest): Promise<runtime.ApiResponse<MemoBlockViewModel>> {
        if (requestParameters.cellId === null || requestParameters.cellId === undefined) {
            throw new runtime.RequiredError('cellId','Required parameter requestParameters.cellId was null or undefined when calling getMemosByType.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }
        if (requestParameters.inheritance !== undefined) {
            queryParameters['Inheritance'] = requestParameters.inheritance;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memoboard/{cellId}`.replace(`{${"cellId"}}`, encodeURIComponent(String(requestParameters.cellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MemoBlockViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve all memos of a certain type for a cell as of the current date.
     */
    async getMemosByType(requestParameters: GetMemosByTypeRequest): Promise<MemoBlockViewModel> {
        const response = await this.getMemosByTypeRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve all memos of a certain type for a cell for the currently active transaction.
     */
    async getMemosByTypeActiveRaw(requestParameters: GetMemosByTypeActiveRequest): Promise<runtime.ApiResponse<MemoBlockViewModel>> {
        if (requestParameters.cellId === null || requestParameters.cellId === undefined) {
            throw new runtime.RequiredError('cellId','Required parameter requestParameters.cellId was null or undefined when calling getMemosByTypeActive.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }
        if (requestParameters.inheritance !== undefined) {
            queryParameters['Inheritance'] = requestParameters.inheritance;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memoboard/{cellId}/active`.replace(`{${"cellId"}}`, encodeURIComponent(String(requestParameters.cellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MemoBlockViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve all memos of a certain type for a cell for the currently active transaction.
     */
    async getMemosByTypeActive(requestParameters: GetMemosByTypeActiveRequest): Promise<MemoBlockViewModel> {
        const response = await this.getMemosByTypeActiveRaw(requestParameters);
        return await response.value();
    }
    /**
     * Retrieve all memos of a certain type for a cell as of the given date.
     */
    async getMemosByTypeHistoricalRaw(requestParameters: GetMemosByTypeHistoricalRequest): Promise<runtime.ApiResponse<MemoBlockViewModel>> {
        if (requestParameters.cellId === null || requestParameters.cellId === undefined) {
            throw new runtime.RequiredError('cellId','Required parameter requestParameters.cellId was null or undefined when calling getMemosByTypeHistorical.');
        }
        const queryParameters: runtime.HTTPQuery = {};
        if (requestParameters.asOf !== undefined) {
            queryParameters['asOf'] = (requestParameters.asOf as any).toUTCString();
        }
        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }
        if (requestParameters.inheritance !== undefined) {
            queryParameters['Inheritance'] = requestParameters.inheritance;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("Bearer", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/Memoboard/{cellId}/historical`.replace(`{${"cellId"}}`, encodeURIComponent(String(requestParameters.cellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => MemoBlockViewModelFromJSON(jsonValue));
    }
    /**
     * Retrieve all memos of a certain type for a cell as of the given date.
     */
    async getMemosByTypeHistorical(requestParameters: GetMemosByTypeHistoricalRequest): Promise<MemoBlockViewModel> {
        const response = await this.getMemosByTypeHistoricalRaw(requestParameters);
        return await response.value();
    }
}
