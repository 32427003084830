import React from "react";
import styles from "./MemoTypeTitle.module.scss";
import classNames from "classnames";
import Title from "../Title/Title";
import { useMemoTypeTitle } from "../../hooks/useMemoTypeTitle";
import {
    ComputationsIcon,
    InterfacesIcon,
    MutationsIcon,
    ProcessesIcon,
    TablesIcon,
    ValidationsIcon,
} from "../../assets/images/Images";
import { MemoType } from "../../openapi/webservice/models/MemoType";

type Props = {
    className: string;
    type: MemoType;
};

function getTypeStyle(type: MemoType) {
    let result = "";
    switch (type) {
        case MemoType.Computations:
            result = styles.computations;
            break;
        case MemoType.Interfaces:
            result = styles.interfaces;
            break;
        case MemoType.Mutations:
            result = styles.mutations;
            break;
        case MemoType.Processes:
            result = styles.processes;
            break;
        case MemoType.Tables:
            result = styles.tables;
            break;
        case MemoType.Validations:
            result = styles.validations;
            break;
    }

    return result;
}

function getIcon(type: MemoType) {
    let result = "";
    switch (type) {
        case MemoType.Computations:
            result = ComputationsIcon;
            break;
        case MemoType.Interfaces:
            result = InterfacesIcon;
            break;
        case MemoType.Mutations:
            result = MutationsIcon;
            break;
        case MemoType.Processes:
            result = ProcessesIcon;
            break;
        case MemoType.Tables:
            result = TablesIcon;
            break;
        case MemoType.Validations:
            result = ValidationsIcon;
            break;
    }
    return result;
}

const MemoTypeTitle = ({ className, type }: Props) => {
    const title = useMemoTypeTitle(type);
    return (
        <div className={classNames(styles.container, className)}>
            <img className={styles.icon} src={getIcon(type)} alt="Icon" />
            <Title heading="h2" className={classNames(styles.title, getTypeStyle(type))} label={title} />
        </div>
    );
};

MemoTypeTitle.defaultProps = {
    className: "",
};

export default MemoTypeTitle;
