import { CellType, CellViewModel, ConnectionViewModel, ContainerViewModel, MemoViewModel } from "../openapi/webservice";
import {
    CELL_CENTER_Y,
    CELL_HEIGHT,
    CELL_MARGIN,
    CONTAINER_DIAGONAL_OFFSET,
    CONTAINER_NAME_HEIGHT,
    CONTAINER_SPACE_BUBBLE,
    CONTAINER_WIDTH,
    EXTERNAL_SYSTEM_HEIGHT,
    EXTERNAL_SYSTEM_WIDTH,
    INNER_CELL_ARROW_LENGTH,
    MEMO_MAX_X,
    MEMO_MAX_Y,
    MEMO_MIN_X,
    MEMO_MIN_Y,
    MEMO_SIZE,
} from "./CanvasConstants";
import { isEven } from "./MathUtils";
import PF, { DiagonalMovement, JumpPointFinder } from "pathfinding";
import { ConnectionType } from "../models/ConnectionType";
import Konva from "konva";
import SAT from "sat";
import { CellDndIcon, CellInIcon, CellOutIcon } from "../assets/images/Images";

interface CellInfo {
    cell: CellViewModel;
    container: ContainerViewModel;
}
interface GridWithOffset {
    grid: number[][];
    offset: { x: number; y: number };
}

type Position = { x: number; y: number };

type ContainerSATViewModel = ContainerViewModel & { box: SAT.Box; isHeavy: boolean };

export function getBoundedCoordinate(value: number, min: number, max: number) {
    return Math.max(Math.min(value, max), min);
}

export function getBoundedXForMemoCanvas(x: number) {
    return getBoundedCoordinate(x, MEMO_MIN_X, MEMO_MAX_X);
}

export function getBoundedYForMemoCanvas(y: number) {
    return getBoundedCoordinate(y, MEMO_MIN_Y, MEMO_MAX_Y);
}

export function getBoundedXForBlueprintCanvas(x: number) {
    return x;
}

export function getBoundedYForBlueprintCanvas(y: number) {
    return y;
}

function isContainerViewModel(input: ContainerViewModel | CellType): input is ContainerViewModel {
    return (input as ContainerViewModel).cellType !== undefined;
}

export function isExternalSystem(input: ContainerViewModel | CellType) {
    const cellType = isContainerViewModel(input) ? input.cellType : input;
    return [CellType.EXTERNALSINK, CellType.EXTERNALSOURCE, CellType.EXTERNALSYSTEM].includes(cellType);
}

export function calculateContainerHeight(container: ContainerViewModel) {
    if (isExternalSystem(container)) {
        return EXTERNAL_SYSTEM_HEIGHT;
    }

    const cellsCount = container.cells.length;

    return (
        CONTAINER_NAME_HEIGHT +
        (cellsCount > 0 ? CELL_HEIGHT * cellsCount + INNER_CELL_ARROW_LENGTH * (cellsCount - 1) + CELL_MARGIN : 0)
    );
}
export function calculateContainerWidth(container: ContainerViewModel) {
    if (isExternalSystem(container)) {
        return EXTERNAL_SYSTEM_WIDTH;
    }

    return CONTAINER_WIDTH;
}

export function calculateContainerHitBounds(container: ContainerViewModel, offset = 0) {
    const containerHeight = calculateContainerHeight(container);
    const containerWidth = calculateContainerWidth(container);

    const left = container.xPosition - offset;
    const top = container.yPosition - offset;
    const right = left + containerWidth + 2 * offset;
    const bottom = top + containerHeight + 2 * offset;
    const width = right - left;
    const height = bottom - top;

    return {
        left,
        top,
        width,
        height,
        right,
        bottom,
    };
}

function isBetween(value: number, min: number, max: number) {
    return value >= min && value <= max;
}

export function getContainerAtPosition(containers: ContainerViewModel[], position: Position) {
    return containers.find((c) => {
        const bounds = calculateContainerHitBounds(c);
        return isBetween(position.x, bounds.left, bounds.right) && isBetween(position.y, bounds.top, bounds.bottom);
    });
}

/**
 * Function to calculate which pixel ranges are considered dropping at index x
 */
function getCellIntervals(container: ContainerViewModel) {
    if (container.cells.length === 0) {
        return [];
    }

    const ranges = container.cells.reduce(
        (current) => {
            const previous = current.at(-1)!;
            current.push({
                min: previous.max + 1,
                max: previous.max + CELL_HEIGHT + INNER_CELL_ARROW_LENGTH,
            });

            return current;
        },
        [
            {
                min: container.yPosition,
                max: container.yPosition + CONTAINER_NAME_HEIGHT + CELL_CENTER_Y,
            },
        ] as { min: number; max: number }[],
    );

    return ranges;
}

/**
 * Function calculates cell positions in the canvas and determines between which cells the position is.
 * Using simple distribution (half of cell height) it will be determined if the position is above or below a certain cell.
 */
export function getCellIndexAtPosition(container: ContainerViewModel, position: Position) {
    const intervals = getCellIntervals(container);

    return Math.max(
        intervals.findIndex((interval) => isBetween(position.y, interval.min, interval.max)),
        0,
    );
}

function getCellsFromContainers(containers: ContainerViewModel[]) {
    return containers.reduce((cells, container) => {
        return [...cells, ...container.cells];
    }, [] as CellViewModel[]);
}

function getCell(cells: CellViewModel[], id?: string) {
    return cells.find((c) => c.id === id);
}

function findCellInfo(containers: ContainerViewModel[], cellId: string): CellInfo {
    const cells = getCellsFromContainers(containers);

    const cell = getCell(cells, cellId);
    if (!cell) {
        throw new Error("Cell not found");
    }

    const container = containers.find((c) => c.id === cell?.containerId);
    if (!container) {
        throw new Error("Container not found");
    }

    return { cell, container };
}

enum Anchor {
    TOP = 0,
    RIGHT,
    BOTTOM,
    LEFT,
    TOP_LEFT,
    TOP_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_RIGHT,
}

function getAnchorX(anchor: Anchor, container: ContainerViewModel) {
    const containerWidth = isExternalSystem(container) ? EXTERNAL_SYSTEM_WIDTH : CONTAINER_WIDTH;
    let x: number;
    switch (anchor) {
        case Anchor.RIGHT:
        case Anchor.TOP_RIGHT:
        case Anchor.BOTTOM_RIGHT:
            x = container.xPosition + containerWidth;
            break;
        case Anchor.LEFT:
        case Anchor.TOP_LEFT:
        case Anchor.BOTTOM_LEFT:
            x = container.xPosition - 1;
            break;
        case Anchor.TOP:
        case Anchor.BOTTOM:
            x = Math.floor(container.xPosition + containerWidth / 2);
            break;
    }
    return x;
}

function getAnchorY(anchor: Anchor, container: ContainerViewModel, cell: CellViewModel) {
    let y: number;
    switch (anchor) {
        case Anchor.TOP:
        case Anchor.TOP_RIGHT:
        case Anchor.TOP_LEFT:
            y =
                (isExternalSystem(container)
                    ? container.yPosition
                    : container.yPosition +
                      CONTAINER_NAME_HEIGHT +
                      cell.index * (CELL_HEIGHT + INNER_CELL_ARROW_LENGTH)) - 1;
            break;
        case Anchor.BOTTOM:
        case Anchor.BOTTOM_LEFT:
        case Anchor.BOTTOM_RIGHT:
            y = isExternalSystem(container)
                ? Math.floor(container.yPosition + EXTERNAL_SYSTEM_HEIGHT)
                : Math.floor(
                      container.yPosition +
                          CONTAINER_NAME_HEIGHT +
                          CELL_HEIGHT +
                          cell.index * (CELL_HEIGHT + INNER_CELL_ARROW_LENGTH),
                  );
            break;
        case Anchor.RIGHT:
        case Anchor.LEFT:
            y = isExternalSystem(container)
                ? Math.floor(container.yPosition + EXTERNAL_SYSTEM_HEIGHT / 2)
                : Math.floor(
                      container.yPosition +
                          CONTAINER_NAME_HEIGHT +
                          CELL_HEIGHT / 2 +
                          cell.index * (CELL_HEIGHT + INNER_CELL_ARROW_LENGTH),
                  );
            break;
    }
    return y;
}

// Returns an array of [x, y] coordinates for inner and outer points
function getAnchorCoordinatesForCell(
    cell: CellViewModel,
    container: ContainerViewModel,
    anchor: Anchor,
): { inner: [number, number]; outer: [number, number] } {
    const x = getAnchorX(anchor, container);
    const y = getAnchorY(anchor, container, cell);

    if (isExternalSystem(container)) {
        return {
            inner: [x, y],
            outer: [
                x +
                    ([Anchor.LEFT].includes(anchor) ? -1 : [Anchor.RIGHT].includes(anchor) ? 1 : 0) *
                        CONTAINER_SPACE_BUBBLE,
                y +
                    ([Anchor.TOP].includes(anchor) ? -1 : [Anchor.BOTTOM].includes(anchor) ? 1 : 0) *
                        CONTAINER_SPACE_BUBBLE,
            ],
        };
    }

    return {
        inner: [x + ([Anchor.LEFT, Anchor.TOP_LEFT, Anchor.BOTTOM_LEFT].includes(anchor) ? 1 : -1) * CELL_MARGIN, y],
        outer: [
            x + ([Anchor.LEFT, Anchor.TOP_LEFT, Anchor.BOTTOM_LEFT].includes(anchor) ? -1 : 1) * CONTAINER_SPACE_BUBBLE,
            [Anchor.TOP_LEFT, Anchor.TOP_RIGHT].includes(anchor)
                ? y - CONTAINER_DIAGONAL_OFFSET
                : [Anchor.BOTTOM_LEFT, Anchor.BOTTOM_RIGHT].includes(anchor)
                ? y + CONTAINER_DIAGONAL_OFFSET
                : y,
        ],
    };
}

function getAllCombinations<T>(input1: T[], input2: T[]): Array<[T, T]> {
    const combinations: Array<[T, T]> = [];

    for (let i = 0; i < input1.length; i++) {
        for (let j = 0; j < input2.length; j++) {
            combinations.push([input1[i], input2[j]]);
        }
    }

    return combinations;
}

// Function does a simple approximation of path distance by adding the x and y values
function getDistance(from: [number, number], to: [number, number]) {
    return Math.abs(from[0] - to[0]) + Math.abs(from[1] - to[1]);
}

function getAnchorPointsForCells(from: CellInfo, to: CellInfo) {
    if (!isExternalSystem(from.container) && !isExternalSystem(to.container)) {
        return { fromAnchorPoints: [Anchor.LEFT, Anchor.RIGHT], toAnchorPoints: [Anchor.LEFT, Anchor.RIGHT] };
    }

    let fromAnchorPoints: Anchor[];
    let toAnchorPoints: Anchor[];
    if (isExternalSystem(from.container)) {
        fromAnchorPoints = [Anchor.LEFT, Anchor.TOP, Anchor.RIGHT, Anchor.BOTTOM];
    } else {
        fromAnchorPoints = [Anchor.TOP_LEFT, Anchor.TOP_RIGHT, Anchor.BOTTOM_RIGHT, Anchor.BOTTOM_LEFT];
    }

    if (isExternalSystem(to.container)) {
        toAnchorPoints = [Anchor.LEFT, Anchor.TOP, Anchor.RIGHT, Anchor.BOTTOM];
    } else {
        toAnchorPoints = [Anchor.TOP_LEFT, Anchor.TOP_RIGHT, Anchor.BOTTOM_RIGHT, Anchor.BOTTOM_LEFT];
    }

    return { fromAnchorPoints, toAnchorPoints };
}

function getBestAnchorPointsForConnection(from: CellInfo, to: CellInfo) {
    const { fromAnchorPoints, toAnchorPoints } = getAnchorPointsForCells(from, to);
    const combinations = getAllCombinations(fromAnchorPoints, toAnchorPoints);

    const distances = combinations.map((anchors) => {
        const fromCoordinates = getAnchorCoordinatesForCell(from.cell, from.container, anchors[0]);
        const toCoordinates = getAnchorCoordinatesForCell(to.cell, to.container, anchors[1]);

        return {
            connection: anchors,
            distance: getDistance(fromCoordinates.inner, toCoordinates.inner),
            from: fromCoordinates,
            to: toCoordinates,
        };
    });

    const bestDistance = distances.sort((d1, d2) => d1.distance - d2.distance)[0];

    return {
        from: bestDistance.from,
        to: bestDistance.to,
    };
}

// Returns true when all values are the same
function isTriplet(value1: number, value2: number, value3: number) {
    return value1 === value2 && value2 === value3;
}

// Removes redundant points in path
// Inspiration: https://www.techiedelight.com/remove-redundant-nodes-path-formed-linked-list/
function simplifyPath(path: number[][]) {
    return path.reduce((points, point, index, source) => {
        const prev = source[index - 1];
        const next = source[index + 1];
        if (prev === undefined || next === undefined) {
            points.push(point);
        } else if (!isTriplet(prev[0], point[0], next[0]) && !isTriplet(prev[1], point[1], next[1])) {
            points.push(point);
        }

        return points;
    }, [] as number[][]);
}

export function getConnectionPath(
    containers: ContainerViewModel[],
    connection: ConnectionViewModel,
    gridWithOffset: GridWithOffset,
) {
    const { grid, offset } = gridWithOffset;
    const from = findCellInfo(containers, connection.fromCellId);
    const to = findCellInfo(containers, connection.toCellId);

    const anchors = getBestAnchorPointsForConnection(from, to);
    let gridMap = new PF.Grid(grid);

    const finder = JumpPointFinder({ diagonalMovement: DiagonalMovement.Never });
    const path = finder.findPath(
        anchors.from.outer[0] - offset.x,
        anchors.from.outer[1] - offset.y,
        anchors.to.outer[0] - offset.x,
        anchors.to.outer[1] - offset.y,
        gridMap,
    );

    // When path is empty, then there was no path found, return straight path instead
    if (path.length === 0) {
        return [...anchors.from.inner, ...anchors.to.inner];
    }

    // Path was calculated from the outer anchors, manually connect to the inner anchors
    return [
        ...anchors.from.inner,
        ...simplifyPath(path).reduce((points, point) => {
            return [...points, point[0] + offset.x, point[1] + offset.y];
        }, [] as number[]),
        ...anchors.to.inner,
    ];
}

export function getArrowViewBox(points: number[], offset = 0) {
    const xCoordinates = [] as number[];
    const yCoordinates = [] as number[];

    points.forEach((value, index) => {
        if (isEven(index)) {
            xCoordinates.push(value);
        } else {
            yCoordinates.push(value);
        }
    });

    const left = Math.min(...xCoordinates) - offset;
    const top = Math.min(...yCoordinates) - offset;
    const right = Math.max(...xCoordinates) + offset;
    const bottom = Math.max(...yCoordinates) + offset;
    const width = right - left;
    const height = bottom - top;

    return {
        left,
        top,
        right,
        bottom,
        width,
        height,
    };
}

export function getBounds(containers: ContainerViewModel[], offset: number = CONTAINER_SPACE_BUBBLE) {
    const containerBounds = containers.map((c) => calculateContainerHitBounds(c, offset));

    const left = Math.min(...containerBounds.map((b) => b.left));
    const top = Math.min(...containerBounds.map((b) => b.top));
    const right = Math.max(...containerBounds.map((b) => b.right));
    const bottom = Math.max(...containerBounds.map((b) => b.bottom));

    return { left, top, right, bottom, containerBounds };
}

export function getBlueprintGrid(containers: ContainerViewModel[]): GridWithOffset {
    const { left, top, right, bottom, containerBounds } = getBounds(containers);

    const grid = Array(bottom - top + 2 * CONTAINER_SPACE_BUBBLE)
        .fill(undefined)
        .map(() => Array(right - left + 2 * CONTAINER_SPACE_BUBBLE).fill(0));

    const offsetX = left - CONTAINER_SPACE_BUBBLE;
    const offsetY = top - CONTAINER_SPACE_BUBBLE;

    containerBounds.forEach((b) => {
        for (let y = b.top - offsetY; y < b.bottom - offsetY; y++) {
            for (let x = b.left - offsetX; x < b.right - offsetX; x++) {
                grid[y][x] = 1;
            }
        }
    });

    return { grid, offset: { x: offsetX, y: offsetY } };
}

function getContainer(containers: ContainerViewModel[], cellId: string) {
    return containers.find((container) => container.cells.find((cell) => cell.id === cellId));
}

export function getConnectionType(containers: ContainerViewModel[], connection: ConnectionViewModel): ConnectionType {
    const fromContainer = getContainer(containers, connection.fromCellId);
    const toContainer = getContainer(containers, connection.toCellId);

    //@NOTE(Lejun) Depending on limited version, this will include more types/checks
    switch (true) {
        case [fromContainer?.cellType, toContainer?.cellType].includes(CellType.EXTERNALSINK):
        case [fromContainer?.cellType, toContainer?.cellType].includes(CellType.EXTERNALSOURCE):
            return ConnectionType.System;
        case [fromContainer?.cellType, toContainer?.cellType].includes(CellType.DOMAIN):
            return ConnectionType.Domain;
        default:
            return ConnectionType.Default;
    }
}

export function getRelativeCanvasPoint(stage: Konva.Stage, event: React.DragEvent<HTMLDivElement>) {
    const target = event.currentTarget.getBoundingClientRect();
    // Inspired by https://stackoverflow.com/a/56870752
    // Stage is draggable, therefore a reverse translation needs to be applied
    // to the dropping position to get the correct coordinates
    const point = stage
        .getAbsoluteTransform()
        .copy()
        .invert()
        .point({ x: event.clientX - target.left, y: event.clientY - target.top });

    return { x: point.x, y: point.y };
}

// Need +1 so we always are on walkable points for the pathfinding
const CONTAINER_BOUNDS = CONTAINER_SPACE_BUBBLE + 1;

function mapWithSAT(container: ContainerViewModel, isHeavy: boolean): ContainerSATViewModel {
    const box = calculateContainerHitBounds(container, CONTAINER_BOUNDS);

    return { ...container, box: new SAT.Box(new SAT.Vector(box.left, box.top), box.width, box.height), isHeavy };
}

function roundAwayFromZero(value: number) {
    return value >= 0 ? Math.ceil(value) : Math.floor(value);
}

function reactToCollision(container: ContainerSATViewModel, other: ContainerSATViewModel, response: SAT.Response) {
    if (container.isHeavy && other.isHeavy) {
        return;
    }

    if (response.overlapV.x % 1 !== 0 || response.overlapV.y % 1 !== 0) {
        response.overlapV.x = roundAwayFromZero(response.overlapV.x);
        response.overlapV.y = roundAwayFromZero(response.overlapV.y);
    }
    if (container.isHeavy) {
        other.box.pos.add(response.overlapV);
    } else if (other.isHeavy) {
        container.box.pos.sub(response.overlapV);
    } else {
        response.overlapV.scale(0.5);
        container.box.pos.sub(response.overlapV);
        other.box.pos.add(response.overlapV);
    }
}

// Inspired by https://codepen.io/osublake/pen/NMQOoJ/bb6983d03e1c3582f9aac486ab9069f8?editors=0010
export function applyCollisionPhysics(
    containers: ContainerViewModel[],
    change: ContainerViewModel,
): ContainerViewModel[] {
    const others = containers.filter((c) => c.id !== change.id).map((c) => mapWithSAT(c, false));
    const combined = [mapWithSAT(change, true), ...others];
    const response = new SAT.Response();

    if (others.length === 0) {
        return [change];
    }

    const loopCount = 20;
    // loopCount determines how many iterations the collision detection can run before everything is settled
    for (let i = 0; i < loopCount; i++) {
        for (let j = 0; j < combined.length; j++) {
            const a = combined[j];
            for (let k = j + 1; k < combined.length; k++) {
                const b = combined[k];
                response.clear();

                const collided = SAT.testPolygonPolygon(a.box.toPolygon(), b.box.toPolygon(), response);

                if (collided) {
                    reactToCollision(a, b, response);
                }
            }
        }
    }

    return combined.map((c) => {
        // eslint-disable-next-line
        const { box, isHeavy, ...vm } = c;
        vm.xPosition = roundAwayFromZero(box.pos.x + CONTAINER_BOUNDS);
        vm.yPosition = roundAwayFromZero(box.pos.y + CONTAINER_BOUNDS);
        return vm;
    });
}

export function getContainerIcon(type: CellType) {
    switch (type) {
        case CellType.EXTERNALSINK:
            return CellInIcon;
        case CellType.EXTERNALSOURCE:
            return CellOutIcon;
        default:
            return CellDndIcon;
    }
}

// Asserts bunch of conditions for drawing arrows (with pathfinding).
export function isExternalSystemConnection(connection: ConnectionViewModel, containers: ContainerViewModel[]) {
    const fromContainer = getContainer(containers, connection.fromCellId);
    const toContainer = getContainer(containers, connection.toCellId);

    // Both containers should exist
    if (!fromContainer || !toContainer) {
        return false;
    }

    return isExternalSystem(fromContainer) || isExternalSystem(toContainer);
}

export function isMemoOutsideOfViewBounds(memos: MemoViewModel[], right: number, bottom: number) {
    return memos.some((memo) => memo.xPosition + MEMO_SIZE >= right || memo.yPosition + MEMO_SIZE >= bottom);
}

export function filterNonConnectedConnections<T extends ConnectionViewModel>(
    connections: Array<T>,
    containers: ContainerViewModel[],
): Array<T> {
    return connections.filter((conn) => {
        const fromContainer = getContainer(containers, conn.fromCellId);
        const toContainer = getContainer(containers, conn.toCellId);

        return !!fromContainer && !!toContainer;
    });
}
