import { useTranslation } from "react-i18next";
import { MemoType } from "../openapi/webservice/models/MemoType";

export function useMemoTypeTitle(type: MemoType) {
    const { t } = useTranslation();
    let result = "";
    switch (type) {
        case MemoType.Computations:
            result = t("memoboard.type.computations");
            break;
        case MemoType.Interfaces:
            result = t("memoboard.type.interfaces");
            break;
        case MemoType.Mutations:
            result = t("memoboard.type.mutations");
            break;
        case MemoType.Processes:
            result = t("memoboard.type.processes");
            break;
        case MemoType.Tables:
            result = t("memoboard.type.tables");
            break;
        case MemoType.Validations:
            result = t("memoboard.type.validations");
            break;
    }

    return result;
}
