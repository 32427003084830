import { useState, useEffect, useRef, useMemo, useCallback } from "react";

export default function useComponentVisible(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as any)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const toggle = useCallback(() => {
        setIsComponentVisible((isVisible) => !isVisible);
    }, [setIsComponentVisible]);

    return useMemo(
        () => ({ ref, isComponentVisible, setIsComponentVisible, toggle }),
        [ref, isComponentVisible, setIsComponentVisible, toggle],
    );
}
