import classNames from "classnames";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import "konva/lib/shapes/Arrow";
import "konva/lib/shapes/Rect";
import "konva/lib/shapes/Text";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Arrow, Layer, Rect, Stage } from "react-konva";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { useBlueprintFilter } from "../../hooks/useBlueprintFilter";
import { useBlueprintMinimap } from "../../hooks/useBlueprintMinimap";
import { useBlueprintState } from "../../hooks/useBlueprintState";
import { useFontObserver } from "../../hooks/useFontObserver";
import { useRefState } from "../../hooks/useRefState";
import { useBlueprintId, useSubContextId } from "../../hooks/useRouteParams";
import { useWindowResizeListener } from "../../hooks/useWindowResizeListener";
import { CanvasMode } from "../../models/CanvasMode";
import { ConnectionType } from "../../models/ConnectionType";
import {
    CellType,
    CellViewModel,
    ConnectionViewModel,
    ContainerViewModel,
    DoubleDoubleTuple,
} from "../../openapi/webservice";
import {
    filterNonConnectedConnections,
    getArrowViewBox,
    getBlueprintGrid,
    getConnectionPath,
    getConnectionType,
    isExternalSystem,
} from "../../utils/CanvasUtils";
import { parseFilterToContainerType } from "../../utils/ContainerUtils";
import { onDragOver } from "../../utils/DragAndDropUtils";
import { getValueOrDefault } from "../../utils/RetrievalUtils";
import { getMemoboardRoute, getTransactionSearchParams } from "../../utils/RouteUtils";
import ContainerModalContainer from "../ContainerModal/ContainerModalContainer";
import styles from "./BlueprintCanvas.module.scss";
import ArrowView from "./elements/ArrowView";
import ContainerView from "./elements/ContainerView";
import SelectionBox from "./elements/SelectionBox";
import { notifyWarn } from "../../utils/NotificationUtils";

type Props = {
    className: string;
    stageRef?: React.RefObject<Konva.Stage>;
    mode: CanvasMode;
    onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
    onMoveContainer?: (containerId: string, position: DoubleDoubleTuple) => void;
    onRenameContainer?: (containerId: string, name: string) => void;
    onRenameCell?: (cellId: string, name: string) => void;
    onCreateConnection?: (fromCellId: string, toCellId: string) => void;
    onDeleteConnection?: (connectionId: string) => void;
    onDeleteContainer?: (containerId: string) => void;
    onDeleteCell?: (cellId: string) => void;
    isCreateConnectionView: boolean;
    onOpenChat?: (containerId: string) => void;
};

const VALID_CONNECTIONS: Array<[CellType, CellType]> = [
    [CellType.CLUSTER, CellType.CLUSTER],
    [CellType.DOMAIN, CellType.CLUSTER],
    [CellType.DOMAIN, CellType.DOMAIN],
    [CellType.EXTERNALSOURCE, CellType.CLUSTER],
    [CellType.EXTERNALSOURCE, CellType.DOMAIN],
    [CellType.DOMAIN, CellType.EXTERNALSINK],
    [CellType.CLUSTER, CellType.EXTERNALSINK],
];

/**
 * Is valid if
 * - Cell other than self
 * - Cells are in different containers
 * - Connection doesn't exist yet
 * - Connection combination exists in VALID_CONNECTIONS
 */
function isValidConnection(
    fromCell: CellViewModel,
    fromContainer: ContainerViewModel,
    toCell: CellViewModel,
    toContainer: ContainerViewModel,
    connections: ConnectionViewModel[],
) {
    return (
        fromCell.id !== toCell.id &&
        fromContainer.id !== toContainer.id &&
        !connections.find((c) => c.fromCellId === fromCell.id && c.toCellId === toCell.id) &&
        !!VALID_CONNECTIONS.find(
            ([outType, inType]) => outType === fromContainer.cellType && inType === toContainer.cellType,
        )
    );
}

function shouldNavigate(isCreateConnectionView: boolean, isEditMode: boolean) {
    return !isCreateConnectionView && !isEditMode;
}

function shouldClearSelectedConnection(event: KonvaEventObject<MouseEvent>, isConnectionSelected: boolean) {
    return event.target.name() !== "connection" && isConnectionSelected;
}

function shouldClearTempConnection(
    isCreateConnectionView: boolean,
    event: KonvaEventObject<MouseEvent>,
    hasTempConnection: boolean,
) {
    return isCreateConnectionView && event.target.name() !== "cell" && hasTempConnection;
}

function shouldShowSelectionBox(hasSelectedConnection: boolean, isCreateConnectionView: boolean, isEditMode: boolean) {
    return hasSelectedConnection && !isCreateConnectionView && isEditMode;
}

interface TempConnection {
    position: { x: number; y: number };
    cell: CellViewModel;
    container: ContainerViewModel;
}

const BlueprintCanvas = ({
    className,
    stageRef,
    mode,
    onDrop,
    onMoveContainer,
    onRenameContainer,
    onRenameCell,
    onCreateConnection,
    onDeleteConnection,
    onDeleteContainer,
    onDeleteCell,
    isCreateConnectionView,
    onOpenChat,
}: Props) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { filters } = useBlueprintFilter();
    const subContextId = useSubContextId();
    const blueprintId = useBlueprintId();
    const { t } = useTranslation();
    const [parentRef, setRef] = useRefState<HTMLDivElement>();
    const isFontLoaded = useFontObserver("Open Sans");
    const [{ containers, connections }] = useBlueprintState();
    const isEditMode = useMemo(() => mode === CanvasMode.Edit, [mode]);
    const [tempConnection, setTempConnection] = useState<TempConnection | undefined>(undefined);
    const [selectedConnection, setSelectedConnection] = useState<ConnectionViewModel | undefined>(undefined);
    const [mousePosition, setMousePosition] = useState<[number, number]>([0, 0]);
    const [enhancedConnections, setEnhancedConnections] = useState<
        Array<ConnectionViewModel & { path: number[]; type: ConnectionType }>
    >([]);
    const [dragPos, setDragPos] = useState({ x: 0, y: 0 });
    useWindowResizeListener();

    const {
        canvasHeight,
        canvasWidth,
        offset,
        gridScale,
        minimapWidth,
        minimapHeight,
        isGridLargerThanViewport,
        strokeWidth,
    } = useBlueprintMinimap(
        getValueOrDefault(parentRef?.clientHeight, 0),
        getValueOrDefault(parentRef?.clientWidth, 0),
        containers,
        dragPos,
    );

    const onDragMove = useCallback(() => {
        if (stageRef && stageRef.current !== null) {
            const { x, y } = stageRef.current.getPosition();
            setDragPos({ x: x * -1, y: y * -1 });
        }
    }, [stageRef]);

    useEffect(() => {
        // Avoid expensive calculations when loading is not finished or containers are still 0
        if (containers.length === 0) {
            setEnhancedConnections([]);
            return;
        }

        const grid = getBlueprintGrid(containers);

        const paths = connections.map((c) => {
            return { ...c, path: getConnectionPath(containers, c, grid), type: getConnectionType(containers, c) };
        });
        setEnhancedConnections(paths);
    }, [connections, containers]);

    const connectionViewBox = useMemo(
        () => getArrowViewBox(enhancedConnections.find((c) => c.id === selectedConnection?.id)?.path || [], 4),
        [enhancedConnections, selectedConnection],
    );

    const onStageMouseMove = useCallback((event: KonvaEventObject<MouseEvent>) => {
        const position = event.target.getStage()?.getRelativePointerPosition();
        setMousePosition(position ? [position.x, position.y] : [0, 0]);
    }, []);

    const onStageClick = useCallback(
        (event: KonvaEventObject<MouseEvent>) => {
            // Give user a way to cancel connection creation
            if (shouldClearTempConnection(isCreateConnectionView, event, !!tempConnection)) {
                setTempConnection(undefined);
            }

            if (shouldClearSelectedConnection(event, !!selectedConnection)) {
                setSelectedConnection(undefined);
            }
        },
        [tempConnection, isCreateConnectionView, selectedConnection],
    );

    const onCellClick = useCallback(
        (event: KonvaEventObject<MouseEvent>, cell: CellViewModel, container: ContainerViewModel) => {
            if (shouldNavigate(isCreateConnectionView, isEditMode)) {
                if (isExternalSystem(container)) {
                    notifyWarn(t("blueprint.notification.external_system_wip", `CellWip_${cell.id}`));
                    return;
                }

                navigate({
                    pathname: getMemoboardRoute(subContextId, blueprintId, cell.id),
                    search: createSearchParams(getTransactionSearchParams(searchParams)).toString(),
                });
                return;
            }

            if (tempConnection) {
                if (
                    onCreateConnection &&
                    isValidConnection(tempConnection.cell, tempConnection.container, cell, container, connections)
                ) {
                    onCreateConnection(tempConnection.cell.id, cell.id);
                }

                setTempConnection(undefined);
                return;
            }
            const mousePosition = event.target.getStage()?.getRelativePointerPosition();
            if (!mousePosition) {
                return;
            }
            setMousePosition([mousePosition.x, mousePosition.y]);

            setTempConnection({
                position: { x: mousePosition.x, y: mousePosition.y },
                cell,
                container,
            });
        },
        [
            isCreateConnectionView,
            tempConnection,
            onCreateConnection,
            navigate,
            connections,
            isEditMode,
            subContextId,
            blueprintId,
            searchParams,
            t,
        ],
    );

    const displayedContainers = useMemo(() => {
        const typeFilters = parseFilterToContainerType(filters);

        if (typeFilters.length === 0 || isEditMode) {
            return containers;
        }

        return containers.filter((c) => !typeFilters.includes(c.cellType));
    }, [containers, filters, isEditMode]);

    const displayedConnections = useMemo(() => {
        // Just remove all connections which are not connected on both ends
        return filterNonConnectedConnections(enhancedConnections, displayedContainers);
    }, [displayedContainers, enhancedConnections]);

    return (
        <div
            id="blueprint"
            ref={setRef}
            className={classNames(styles.container, className)}
            data-testid="BlueprintCanvas"
            onDrop={onDrop}
            onDragOver={onDragOver}
        >
            <Stage
                container="blueprint"
                ref={stageRef}
                width={getValueOrDefault(parentRef?.clientWidth, 0)}
                height={getValueOrDefault(parentRef?.clientHeight, 0)}
                listening={isFontLoaded}
                visible={isFontLoaded}
                onMouseMove={
                    // Track mouse position for preview arrow
                    tempConnection ? onStageMouseMove : undefined
                }
                onClick={onStageClick}
                draggable={true}
                onDragMove={onDragMove}
            >
                <Layer>
                    {displayedContainers.map((container) => {
                        return (
                            <ContainerView
                                key={container.id}
                                container={container}
                                isEditMode={isEditMode}
                                onMoveContainer={onMoveContainer}
                                onRenameContainer={onRenameContainer}
                                onRenameCell={onRenameCell}
                                highlight={isCreateConnectionView ? "cell" : "none"}
                                onCellClick={onCellClick}
                                onDeleteContainer={onDeleteContainer}
                                onDeleteCell={onDeleteCell}
                                onOpenChat={onOpenChat}
                            />
                        );
                    })}
                    {displayedConnections.map((arrow) => {
                        const { path, id, type } = arrow;
                        return (
                            <ArrowView
                                key={id}
                                path={path}
                                type={type}
                                onClick={() => {
                                    if (mode !== CanvasMode.Edit || isCreateConnectionView) {
                                        return;
                                    }

                                    setSelectedConnection(arrow);
                                }}
                            />
                        );
                    })}
                    {tempConnection && (
                        <Arrow
                            points={[tempConnection.position.x, tempConnection.position.y, ...mousePosition]}
                            strokeWidth={5}
                            pointerAtEnding={true}
                            pointerLength={10}
                            pointerWidth={10}
                            stroke="#061133"
                            fill="#061133"
                            dash={[5, 10]}
                            // listening = false, because it would eat up the click events otherwise
                            listening={false}
                        />
                    )}
                    {shouldShowSelectionBox(!!selectedConnection, isCreateConnectionView, isEditMode) && (
                        <SelectionBox
                            left={connectionViewBox.left}
                            top={connectionViewBox.top}
                            width={connectionViewBox.width}
                            height={connectionViewBox.height}
                            onDelete={(e) => {
                                e.cancelBubble = true;
                                if (window.confirm(t("blueprint.delete.connection.confirm")) && onDeleteConnection) {
                                    onDeleteConnection(selectedConnection!.id);
                                    setSelectedConnection(undefined);
                                }
                            }}
                        />
                    )}
                </Layer>
            </Stage>
            <div id="miniMap" className={classNames(styles.miniMap, !isGridLargerThanViewport && styles.hide)}>
                <Stage
                    container="miniMap"
                    width={minimapWidth}
                    height={minimapHeight}
                    scale={{ x: gridScale, y: gridScale }}
                    offset={offset}
                >
                    <Layer>
                        {displayedContainers.map((container) => {
                            return (
                                <ContainerView
                                    key={container.id}
                                    container={container}
                                    isEditMode={false}
                                    highlight="none"
                                />
                            );
                        })}
                        {displayedConnections.map((arrow) => {
                            const { path, id, type } = arrow;
                            return <ArrowView key={id} path={path} type={type} />;
                        })}
                        {/* Viewport frame */}
                        <Rect
                            x={dragPos.x + strokeWidth}
                            y={dragPos.y + strokeWidth}
                            width={canvasWidth - 2 * strokeWidth}
                            height={canvasHeight - 2 * strokeWidth}
                            fill="rgba(86, 204, 242, 0.08)"
                            stroke="#2d9cdb"
                            strokeWidth={strokeWidth}
                        />
                    </Layer>
                </Stage>
            </div>
            <ContainerModalContainer />
        </div>
    );
};

BlueprintCanvas.defaultProps = {
    className: "",
    isCreateConnectionView: false,
};

export default BlueprintCanvas;
