import { useCallback, useContext, useMemo } from "react";
import { ChatApiContext } from "../contexts/ChatApiContext";
import { ChatType } from "../models/ChatType";
import { TEMP_TRANSACTION_GUID } from "../utils/FakeItTillYouMakeIt";
import { useApiCall, useApiCallback } from "./useApiCall";

export function useChatApi() {
    return useContext(ChatApiContext);
}

export function useGetChat(type: ChatType, id: string) {
    const api = useChatApi();

    const boundFunc = useCallback(
        () => (type === ChatType.Memo ? api.getChatForMemo({ id }) : api.getChatForContainer({ id })),
        [api, type, id],
    );

    return useApiCall(boundFunc);
}

export function useInsertChatMessage(type: ChatType, id: string) {
    const api = useChatApi();

    const boundFunc = useMemo(
        () => async (text: string) => {
            const insert =
                type === ChatType.Memo ? api.insertChatForMemo.bind(api) : api.insertChatForContainer.bind(api);

            const model = await insert({
                id,
                // @TODO(Lejun) SIX-89 whileLookingAtTransaction
                chatMessageInputModel: {
                    messageContent: text,
                    whileLookingAtTransaction: TEMP_TRANSACTION_GUID,
                },
            });

            return model;
        },
        [api, type, id],
    );

    return useApiCallback(boundFunc);
}
