import { useMemo } from "react";
import { DropdownItem } from "../components/DropdownList/DropdownList";
import { OrganisationViewModel } from "../openapi/webservice";

export function useOrganisationOptions(
    organisations: Array<OrganisationViewModel> | undefined,
    onSelect: (org: OrganisationViewModel | undefined) => any,
    placeholder?: boolean,
): DropdownItem[] {
    return useMemo(() => {
        const list =
            organisations?.map((o) => ({
                id: o.id,
                icon: o.logoUri,
                label: o.name,
                onSelect: () => onSelect(o),
            })) || [];

        if (placeholder) {
            list.unshift({
                id: "",
                icon: "",
                label: "-",
                onSelect: () => onSelect(undefined),
            });
        }
        return list;
    }, [organisations, onSelect, placeholder]);
}
